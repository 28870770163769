export const en = {
  Footer: {
    infoTitle: 'Global sites',
    infoDescription:
      "This website is intended for people seeking information on AstraZeneca's worldwide business. We also have country sites; click the 'Global sites' link for the directory.",
    altLogo: 'astraZeneca logo',
    relatedToHealth: 'Related to health',
    azWebsite: 'AstraZeneca Italy',
    legalInfo: 'Legal Information ',
    privacy: 'Privacy policy',
    contacts: 'Contact us',
    cookies: 'Info Cookies',
    reports: 'Reports adverse events',
    altFb: 'link FaceBook',
    altIn: 'link Linkedin',
    infoId: '© AstraZeneca - Codice IT-9556 - Data di approvazione: 24/05/2023',
  },
  Button: {
    next: 'NEXT',
    cancel: 'CANCEL',
    new: 'NEW',
    newSurvey: 'NEW SURVEY',
  },
  Homepage: {
    goToQuestionnaire: 'Go to questionnaire',
    goToMap: 'Go to map',
    resources: {
      errorMessage: 'Resources not available',
      label: 'Resources',
      showAll: 'Show all',
    },
  },
  Resources: {
    title: 'Resources',
    home: 'Home',
    filters: {
      all: 'All',
    },
    noResources: 'No resources found',
  },
  Header: {
    home: 'HOME',
    questionnaire: 'QUESTIONNAIRE',
    patients: 'YOUR PATIENTS',
    profile: 'PROFILE',
    logout: 'Logout',
    user: 'USER',
    access: 'LOGIN AS',
    healthPro: 'HEALTH PROFESSIONAL',
    centersMap: 'CENTERS MAP',
    resources: 'RESOURCES',
  },
  Login: {
    title: 'Login User Area',
    titleMMG: 'Login Health Professional Area',
    mmgFirstAccessText: 'Before we proceed, we need some of your data',
    buttonRegister: 'Register with Iqvia',
    disclaimer: 'Use Iqvia credentials to login',
    doctorDisclaimer: `Si precisa che il questionario analizza
    unicamente le abitudini di stile di vita del paziente e non dati di natura clinica:
    non è pertanto da intendersi come uno strumento di diagnosi.
    Il medico curante dovrà valutare secondo il proprio giudizio professionale
    l'opportunità di effettuare eventuali approfondimenti clinico-diagnostici.`,
    email: {
      label: 'Email',
      placeholder: 'Insert your email',
    },
    password: {
      label: 'Password',
      placeholder: 'Insert your password',
    },
    forgotPassword: 'Forgot your password?',
    button: 'LOGIN',
    signUp: 'Not registered yet?',
    name: 'Name',
    lastName: 'Last name',
    phone: 'Phone number',
    city: 'City',
    province: 'Province',
    completeRegistration: 'Complete registration',
    registrationCompleted: 'Registration is complete, login to continue',
    registrationError: 'There was an error during the registration process',
    checkboxDataTreatment:
      'I declare that I have read and understood the information and I give my consent to the processing by AstraZeneca SpA: ',
    checkboxDataTreatmentLink: 'Privacy information.',
    checkboxPromo:
      'The sending of electronic communications by e-mail for the sending of information relating to the initiatives, including promotional ones, of AstraZeneca SpA indicated in the information.',
    checkboxInfoPromo:
      'The use of automated call systems without the intervention of an operator via text message or mobile phone, to send information relating to initiatives, including promotional ones, of AstraZeneca SpA indicated in the disclosure.',
    checkboxInterest:
      'To the treatment by AstraZeneca SpA of my personal data for the definition of my professional profile in order to identify the main sections and areas of interest, according to the methods described in the disclosure',
  },
  search: {
    labelCF: 'Research for fiscal code',
    placeholder: 'Search...',
  },
  Alert: {
    success: {
      passwordDescription: 'Password changed successfully',
    },
    error: {
      credentialsDescription: 'Email or password not found',
      forgotPassword: 'Try again',
      resetPassword: 'Try again',
      questionnaireError: 'Questionnaire could not be loaded. Please try again',
      genericLoginError: 'Authentication error. Please, retry later on.',
    },
    info: {
      surveyDescription:
        'You have saved the questionnaire as a draft, you can continue it later.',
      activeSurveyButton: 'Okay, go back to the patient',
      cancelSurveyButton: 'Resume the survey',
      forgotPassword: 'Check your email to reset your password',
      resetPassword: 'Your password has been changed successfully',
    },
  },
  SignUp: {
    title: 'Sign up',
    fc: {
      label: 'Fiscal Code',
      placeholder: 'Insert your fiscal code',
    },
    email: {
      label: 'Email',
      placeholder: 'Insert your email',
    },
    password: {
      label: 'Password',
      placeholder: 'Insert your password',
    },
    confirmPassword: {
      label: 'Confirm password',
      placeholder: 'Insert your password again',
    },
    hintPassword:
      '8 char, with at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special char',
    privacy: {
      text: 'I read the ',
      link: 'privacy and data processing conditions',
    },
    conditions: {
      text: 'I agree to the ',
      link: 'Terms and Conditions',
    },
    button: 'SIGN UP',
  },
  ForgotPassword: {
    title: 'Forgot Password',
    previousPage: 'User area',
    subtitle:
      'To proceed write your email. You will receive a link to reset your password.',
    email: {
      label: 'Email',
      placeholder: 'Insert your email',
    },
    button: 'SEND',
  },
  ResetPassword: {
    title: 'Reset Password',
    password: 'Insert new password',
    confirmPassword: 'Confirm new password',
    button: 'SAVE',
  },
  Sidebar: {
    label: {
      surveys: 'Surveys',
      medicalCenters: 'Medical centers',
      contents: 'Contents',
      mmg: 'MMG enabled',
      admin: 'Admin management',
      logout: 'Log out',
      role: 'Admin',
      profile: 'Profile',
      kpi: 'KPI',
      downloadMMGList: 'Download MMG list',
      downloadModalTitle: 'Download enabled MMG list',
      downloadModalBody:
        'You are about to download the list of enabled MMG, do you want to proceed?',
      download: 'Download',
    },
    altImage: 'Profile image',
  },
  QuestionnaireCard: {
    title: {
      lungCancer: 'Lung cancer mutation EGFR',
    },
    buttonPrimary: 'DETAIL',
    deleteSurvey: 'delete survey',
    publishSurvey: 'Publish',
  },
  SurveyResult: {
    risk: 'Risk',
    riskLevel: {
      LOW: 'low',
      MEDIUM_LOW: 'medium/low',
      MEDIUM_HIGH: 'medium/high',
      HIGH: 'high',
    },
  },
  AdminQuestionnaires: {
    title: 'Questionnaires',
    searchLabel: 'Research for pathologies',
    error:
      'Error while trying to fetch questionnaires structure data. Please, retry.',
    alert: {
      publish: {
        success: 'Questionnaire published successfully',
        error: 'It is not possible to publish this questionnaire',
      },
      delete: {
        success: 'Questionnaire deleted successfully',
        error: 'It is not possible to delete this questionnaire',
      },
      cantDeleteAnswer:
        'It is not possible to delete the answer, every question must have at least 2 answers.',
    },
  },
  AdminMMG: {
    title: 'MMG enabled',
    button: 'DOWNLOAD MMG ENABLED',
  },
  Accordion: {
    newTitle: 'Question {{questionOrder}}',
    deleteQuestion: 'Delete question',
    title: {
      label: 'Question {{questionOrder}} title',
      placeholder: 'Insert title',
    },
    description: {
      label: 'Short description (optional)',
      placeholder: 'Insert description',
    },
    reference: {
      label: 'Reference (optional)',
      placeholder: '--',
    },
    button: 'ADD NEW ANSWER',
  },
  AnswerChoice: {
    newTitle: 'Answer option - {{answerCounter}}',
    deleteAnswer: 'Delete answer',
    title: {
      label: 'Answer title',
      placeholder: 'Insert title',
    },
    description: {
      label: 'Short description (optional)',
      placeholder: 'Insert description',
    },
    reference: {
      label: 'Reference (optional)',
      placeholder: '--',
    },
    score: {
      label: 'Option score',
      placeholder: 'Insert score',
    },
    answerJump: {
      label: 'Jump to question',
      placeholder: 'Choose question',
      endOfQuestionnaire: 'End the questionnaire',
    },
    riskColor: {
      label: 'Risk category',
      placeholder: 'Choose a category',
      colors: {
        red: 'RED - high risk',
        orange: 'ORANGE - medium-high risk',
        green: 'GREEN - low risk',
        yellow: 'YELLOW - medium-low risk',
      },
    },
  },
  Patients: {
    heading: 'Your patients',
    searchText: {
      label: 'Search Social Security Number / Email',
      placeholder: 'Search...',
    },
    noPatientsFound: 'No patients found',
    table: {
      heading: {
        socialSecurity: 'Social security number',
        email: 'Email',
        status: 'Status',
      },
    },
    cta: {
      label: 'New Patient',
    },
    alert: {
      resendRegistrationMail: {
        success: 'Email successfully sent',
        error: 'Error while sending email. Please, retry.',
      },
    },
    description:
      "Register patients to question them and assess their cancer risk level. To register a patient just enter his tax code and his email. * Remember to collect your patient's privacy consent by flagging the corresponding box.",
  },
  AddPatient: {
    fiscalCode: {
      label: 'Fiscal code',
      placeholder: 'Insert fiscal code',
    },
    email: {
      label: 'Email',
      placeholder: 'Insert email',
    },
    cta: {
      submit: 'CREATE',
      delete: 'CANCEL',
    },
    title: 'New patient',
  },
  Patient: {
    breadCrumb: {
      parentPage: {
        label: 'Your patients',
      },
      currentPage: {
        label: 'Patients detail',
      },
    },
    heading: 'Patients detail',
    questionnaires: {
      heading: 'Questionnaire history',
      cta: 'NEW QUESTIONNAIRE',
      error: 'Error while trying to fetch questionnaires data. Please, retry.',
    },
    description:
      'Submit the questionnaire to your patient to assess the level of disease risk and monitor it periodically.',
  },
  StatusLabel: {
    enabled: 'Registered',
    disabled: 'Waiting',
    draft: 'Draft',
    completed: 'Completed',
  },
  PatientData: {
    label: {
      email: 'Email',
      fiscalCode: 'Fiscal code',
      status: 'Status',
      pathologies: 'Positive questionnaire:',
    },
    error: {
      generic: 'Data is not available. Retry.',
    },
  },
  AdminQuestionnaireDetail: {
    headerButton: {
      save: 'SAVE',
      edit: 'EDIT',
      cancel: 'Cancel changes',
      deleteSurvey: 'Delete survey',
    },
    title: 'Title',
    subtitle: {
      label: 'Subtitle',
      placeholder: 'Insert subtitle',
    },
    instructions: {
      label: 'Instructions',
      placeholder: 'Insert instructions',
    },
    addQuestion: 'ADD QUESTION',
    alert: {
      cancel: {
        message: 'Do you want to cancel the applied changes?',
        activeButtonLabel: 'Yes, Proceed.',
        cancelButtonLabel: 'Dismiss.',
      },
    },
    deleteQuestion: {
      message: 'Do you want to delete this question?',
    },
  },
  ScoreThreshold: {
    title: 'Publish',
    labelTotalScore: 'Total score',
    labelMinScore: 'Minimum score',
    threshold: {
      label: 'Threshold low risk',
      placeholder: 'Insert low threshold',
    },
    threshold2: {
      label: 'Threshold high risk',
      placeholder: 'Insert high threshold',
    },
    buttonDraft: 'Save draft',
    buttonPublish: 'Publish',
    error: {
      generic: 'Error while processing data. Check the required fields!',
      equalScore: 'The maximum and minimum score cannot be the same!',
    },
    alert: {
      success: 'Questionnaire saved successfully!',
    },
  },
  QuestionnairesTable: {
    heading: {
      questionnaire: 'Questionnaire',
      date: 'Date',
      status: 'Status',
      result: 'Result',
    },
    filters: {
      searchByTitle: 'Search questionnaire by title',
      questionnaireStatus: 'Search by status',
      questionnaireResult: 'Search by result',
      applyFilters: 'Apply filters',
      resetFilters: 'Reset filters',
      placeholder: 'Show all',
      noResults: 'There are no questionnaires to be shown',
    },
    data: {
      result: {
        positive: 'Positive',
        negative: 'Negative',
      },
    },
    delete: {
      confirm: 'Are you sure you want to delete the questionnaire?',
      error: 'Operation failed',
      success: 'Questionnaire removed successfully',
    },
  },
  NewQuestionnaireModal: {
    title: 'New questionnaire',
    noQuestionnairesAvailable: 'No questionnaires available',
    cta: {
      cancel: 'Cancel',
      proceed: 'Proceed',
    },
    forcedPlaceholder:
      'Lung cancer risk questionnaire developed by clinicians at the University of Turin',
  },
  NewQuestionnaire: {
    title: 'New questionnaire',
    instructionsTitle: 'Instructions for compilation:',
    buttons: {
      start: 'start',
      cancel: 'cancel',
    },
    refreshAlert:
      'It is not possible to reload the page while completing the questionnaire. You will be redirected to your "Questionnaire" section',
    breadCrumb: {
      parentPage: {
        label: 'Questionnaire',
      },
      currentPage: {
        label: 'New questionnaire',
      },
    },
    disclaimer: `This questionnaire was developed by a working group
    of oncologists of the University of Turin and does not intend to replace the medical judgement`,
    bottomDisclaimer: 'Start the digitized questionnaire on lung cancer risk',
  },
  ActiveQuestionnaire: {
    buttonDraft: 'save in draft',
    errorCreateQuestionnaire: 'Questionnaire error',
    buttonNext: 'next',
    buttonFinish: 'finish',
    buttonBack: 'back',
    buttonClose: 'close',
    references: 'References',
    copyId: 'AstraZeneca - Codice IT-9799 - Data di approvazione: 5/06/2023',
  },
  ResultQuestionnaire: {
    bookAnAppointment: 'Book an appointment',
    result: {
      POSITIVE: 'positive',
      NEGATIVE: 'negative',
      STRONG_POS: `The patient is at risk, further diagnostic investigation is recommended
      a qualified center for the diagnosis of lung cancer.`,
      WEAK_POS: `The patient is at low risk. Consider a screening program
      for lung cancer if appropriate.`,
    },
    title: 'Questionnaire result',
    resultIs: 'The result is ',
    button: 'Restart the questionnaire',
    generalError: 'Error! Something went wrong...',
    bottomDisclaimer: ` The result is purely a reproduction of a questionnaire developed by clinicians of the University of Turin.\n
    This does not constitute medical advice and is purely advice.`,
    evaluationDisclaimer: `*Subject to evaluation of criteria for eligibility for a screening program\n
      (aged 55-75, heavy smoker or ex-smoker - stopped less than 15 years ago -
      with an average consumption of 20 cigarettes a day for 30 years)`,
  },
  DeleteModal: {
    title: 'Warning!',
    bodyQuestionnaire:
      'You chose to delete the questionnaire {{questionnaire}}; if you proceed data will be deleted.',
    bodyQuestion:
      'You chose to delete a question: the question could be related to another one',
    subtitle: 'Are you sure you want to proceed?',
    cta: {
      proceed: 'Yes, go ahead',
      undo: 'Cancel',
      ok: 'Ok',
    },
  },
  TermsAndConditions: {
    title: 'Terms and conditions',
  },
  MedicalCenters: {
    title: 'Medical centers',
    newCenter: 'New center',
    massiveImport: 'Massive import from XLSX',
    templateDownload: 'Download XLSX template',
    errorFileExt: 'File extension is not valid',
    uploadError: 'There was an error uploading the file',
    uploadErrorNotEmpty: " Error! This field couldn't be  empty: ",
    uploadErrorNotFound: 'Error! Not found :  ',
    uploadErrorInvalid: 'Error! This field is not valid :  ',
    uploadErrorAlreadyExist: 'Error! This medical center is already exist:  ',
    uploadSuccess: 'File imported successfully',
    uploadBadFormat: 'There is a format error in the file, please retry',
    delete: {
      confirm: 'Are you sure you want to delete this medical center?',
      error: 'It is not possible to delete this medical center',
      success: 'Medical center deleted successfully',
    },
  },
  ErrorPages: {
    notFound: '404',
    pageNotFound: 'Page not found',
  },
  CentersMap: {
    heading: 'Centers map',
    selectAPathology: 'Select a pathology',
    noSelectionError:
      'Geolocation is not enabled, search for an address and chose a pathology to view the map. Else enable geolocation.',
    noPathologySelected:
      'No pathology selected, please choose one to view the centers.',
    noPathologies:
      'There are no pathologies, it is not possible to view the centers map.',
    yourLocation: 'Your location',
    description:
      'Refer your patient to the nearest center based on his needs. You can filter the results by entering a place.',
  },
  AdminPathologies: {
    heading: 'Pathologies',
    newPathology: 'New pathology',
    delete: {
      confirm: 'Are you sure you want to delete this pathology?',
      error: 'It is not possible to delete this pathology',
      success: 'Pathology deleted successfully',
    },
  },
  AdminPathologyCreate: {
    cta: 'Save',
    cancel: 'Cancel',
    label: 'Name',
    placeholder: 'Insert pathology name',
    alertCreate: {
      success: 'Pathology created successfully',
      error: 'This pathology already exists in a database',
    },
  },
  AdminContents: {
    heading: 'Contents',
    newContent: 'New content',
    contentDetail: 'Content detail',
    delete: {
      confirm: 'Are you sure you want to delete this content?',
      error: 'It is not possible to delete this content',
      success: 'Content deleted successfully',
    },
  },
  AdminContentsDetail: {
    cancel: 'Delete content',
    title: {
      label: 'Title',
      placeholder: 'Insert a title',
    },
    image: {
      label: 'Image',
      placeholder: 'No image ais selected',
    },
    url: {
      label: 'URL to external resource',
      placeholder: 'Insert a url',
    },
    selectFile: 'Select file',
    contentType: {
      type: 'Content type',
      isPublic: 'Public',
      isPrivate: 'Private',
    },
    pathology: 'Pathology',
    description: {
      label: 'Description',
      placeholder: 'Insert a description',
    },
    type: 'Type',
    createSuccess: 'Content successfully created!',
    updateSuccess: 'Content successfully updated!',
    createError: 'There was an error creating the content',
    updateError: 'There was an error updating the content',
  },
  AdminManage: {
    heading: 'manage admin',
    newAdmin: 'New admin',
    tableHeading: {
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      status: 'Status',
    },
    deleteAdmin: 'Elimina',
    delete: {
      confirm: 'Are you sure you want to delete this admin?',
      error: 'It is not possible to delete this admin',
      success: 'Admin deleted successfully',
    },
  },
  AdminManageDetail: {
    heading: 'Admin detail',
    cancel: 'Delete admin',
    deleteAlert: 'Do you really want to delete this Admin?',
    save: 'Save',
    changePassword: 'Do you want to change password?',
    name: {
      label: 'Name',
      placeholder: 'Insert name',
    },
    surname: {
      label: 'Lastname',
      placeholder: 'Insert lastname',
    },
    email: {
      label: 'Email',
      placeholder: 'Insert email',
    },
    status: {
      label: 'Status admin',
      disable: 'Disabled',
      enable: 'Enabled',
    },
    image: {
      label: 'Profile image',
      button: 'Choose',
      errorTooBig: 'File is too big. Max size allowed is 2mb.',
      errorNotSupported: 'Is not a supported file extension.',
    },
    selectFile: 'Choose file',
    password: {
      label: 'Password',
      placeholder: 'Insert password',
    },
    alertCreate: {
      success: 'Admin created successfully',
      error: 'It is not possible to create this admin',
    },
    alertUpdate: {
      success: 'Admin updated successfully',
      error: 'It is not possible to update this admin',
    },
  },
  WeekDays: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  MedicalCentersDetail: {
    title: 'Center detail',
    save: 'Save',
    deleteCenter: 'Delete center',
    search: {
      label: 'Search for a place',
      placeholder: 'Eg. Istituto Nazionale dei Tumori',
      button: 'Autofill',
    },
    businessName: {
      label: 'Business name',
      placeholder: 'Insert the business name',
    },
    pathologiesLabel: 'Pathologies',
    streetName: {
      label: 'Street',
      placeholder: 'Eg: Via Torino',
    },
    houseNumber: {
      label: 'House number',
      placeholder: 'Eg: 1',
    },
    zipCode: {
      label: 'Zip code',
      placeholder: 'Eg: 20123',
    },
    city: {
      label: 'City',
      placeholder: 'Eg: Milano',
    },
    province: {
      label: 'Province',
      placeholder: 'Eg: Milano',
    },
    email: {
      label: 'Email',
      placeholder: 'Eg: mail@mail.it',
    },
    phoneNumber: {
      label: 'Phone number',
      placeholder: 'Eg: 02 58584511',
    },
    description: {
      label: 'Description',
      placeholder: 'Insert the description',
    },
    address: 'Address',
    closed: 'Closed',
    openingHours: 'Opening hours',
    open: 'Open',
    from: 'From',
    to: 'To',
    alertCreate: {
      success: 'Medical center created successfully',
      error: 'It is not possible to create this medical center',
    },
    alertUpdate: {
      success: 'Medical center updated successfully',
      error: 'It is not possible to update this medical center',
      errorOpeningHours:
        'There is an error in opening hours, check the fields and try again',
    },
    addressError: 'Address not found',
    noMedicalCentersFound:
      'No medical centers found near the searched address, try again with a different address',
  },
  Tooltips: {
    resendEmail: 'Resend activation email to patient',
  },
  EditPassword: {
    title: 'Change password',
  },
  ErrorStrings: {
    email: 'Email',
    fiscalCode: 'Fiscal code',
    AlreadyUsed: 'already used.',
    Invalid: 'is invalid',
    pathologiesId: 'Pathologies',
    NotEmpty: 'Cannot be empty',
    url: 'Url',
    URL: 'must be a valid url',
    phone: 'Phone number',
    Phone: 'is invalid',
  },
  Profile: {
    title: 'Profile',
    fiscalCode: 'Fiscal code',
    email: 'Email',
    editPassword: 'Do you want to edit the password?',
    view: 'View ',
    termsAndConditions: 'Terms and conditions',
    deleteProfile: 'Delete profile',
    deleteProfileText:
      'You chose to delete your profile; if you proceed all your personal data will be deleted and lost. Are you sure you want to go ahead?',
    deleteProfileDoctorText:
      'You chose to delete your profile; if you proceed all your personal data will be deleted and lost. You will also be removed as a reference from your questionnaires. Are you sure you want to go ahead?',
    unlinkMMG: 'Remove MMG linking',
    unlinkMMGText: '',
    genericError: 'There was an error, please try again later',
    editPasswordTitle: 'Edit password',
    edit: 'Edit',
    save: 'Save',
  },
  MMGProfile: {
    updateError: 'There was an error updating the profile',
    updateSuccess: 'Profile updated successfully',
  },
  KPI: {
    totalQuestionnaires: 'Collected questionnaires:',
    result: 'Result',
    highRisk: 'Strongly Positive',
    mediumHighRisk: 'Positive',
    lowRisk: 'Negative',
    dateLabel: {
      from: 'Date from',
      to: 'Date to',
    },
  },
  Privacy: {
    title: 'Privacy',
  },
  MedicalCenterType: {
    placeholderDropdown: 'Insert center type',
    label: 'Center type',
    RISP: 'Italian Network Lung Screening Center',
    generic: 'Generic',
  },
  Questionnaire: {
    heading: 'Questionnaire',
    cta: {
      label: 'New questionnaire',
    },
    disclaimers: [
      `This questionnaire was developed by a working group
      of oncologists of the University of Turin and does not intend to replace medical judgment`,
      'The use of the questionnaire and its results are offered on the basis of scientific literature.',
      "È responsabilità del medico curante l'indirizzamento verso terapie e decisioni riguardo i loro pazienti sulla base del loro giudizio clinico.",
      'The use of this tool should not be the sole basis of clinical decisions and is provided for digital media only.',
    ],
    bibliography: 'Bibliography',
  },
  LeavingPagePrompt: {
    message: 'Do you want to leave the site?',
  },
};
