import * as Sentry from '@sentry/react';

/**
 * @description Generic synchronous storage.
 * @todo implements Storage interface
 */
export const AZStorage = {
  getItem(key: string): string | null {
    return localStorage.getItem(key);
  },
  removeItem(key: string) {
    try {
      return localStorage.removeItem(key);
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  },
  setItem(key: string, value: string): void {
    try {
      return localStorage.setItem(key, value);
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  },
};
