import React from 'react';
import classNames from 'classnames';

// Types
import {IProps} from './interface';

// Style
import './Link.scss';

const Link = ({
  children,
  href = '/',
  className,
  target = '_self',
}: IProps): JSX.Element => {
  return (
    <a
      href={href}
      className={classNames('az-link', className)}
      target={target}
      rel="noreferrer noopener">
      {children}
    </a>
  );
};

export default React.memo(Link);
