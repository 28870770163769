import React from 'react';
import {useTranslations} from '@vidiemme/react-i18n';

//Other components
import {Base} from '../../templates/Base';

//Component namespace
import './PageNotFound.scss';

const PageNotFound = () => {
  const {t} = useTranslations();

  return (
    <Base isAuth={false}>
      <div className="az-page-not-found">
        <h1 className="az-page-not-found__title">{t('ErrorPages.notFound')}</h1>
        <h2 className="az-page-not-found__subtitle">
          {t('ErrorPages.pageNotFound')}
        </h2>
      </div>
    </Base>
  );
};

export default React.memo(PageNotFound);
