import React, {useCallback, useContext, useMemo} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';

// Others
import {useNavigation} from '../../../navigation';
import {useAuthentication} from '../../../store/authentication';
import {useGetMedicalCenters} from '../../../store/medicalCenters/hooks';
import {AlertContext} from '../../../store/alert/context';

// Components
import {Admin} from '../../templates/Admin';
import {Button} from '../../atoms/Button';
import {ButtonText} from '../../atoms/ButtonText';
import {DataTableMedicalCenters} from '../../organisms/DataTable/DataTableMedicalCenters';
import {Spinner} from '../../atoms/Spinner';

// Types
import {IProps as IconProps} from '../../atoms/IconSVG/interfaces';
import {MedicalCenterUploadErrorType} from '../../../store/medicalCenters/interfaces';

// Style
import './MedicalCenters.scss';

const MedicalCenters = () => {
  const {t} = useTranslations();
  const {goToMedicalCentersDetail} = useNavigation();
  const {token} = useAuthentication();
  const {setValue: setAlert} = useContext(AlertContext);

  const {
    data: medicalCentersData,
    loading,
    refetch,
  } = useGetMedicalCenters({}, 5);

  const addIcon = useMemo<IconProps>(
    () => ({
      icon: 'icon-add',
      size: 23,
      className: 'text-white',
    }),
    [],
  );

  const downloadImportTemplate = useCallback(() => {
    fetch(
      `${process.env.REACT_APP_BASE_URL_REST}/medicalcenters/excel/download-template`,
      {
        method: 'get',
        headers: new Headers({
          authorization: `Bearer ${token}`,
        }),
      },
    )
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'medical-centers-import-template.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        setAlert({
          type: 'ERROR',
          message: 'Qualcosa è andato storto',
        });
      });
  }, [token, setAlert]);

  const uploadImportFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // add file to FormData object
      let fd = new FormData();
      if (e?.target?.files && e?.target?.files.length > 0) {
        fd.append('file', e.target.files[0]);

        // Check file ext
        if (
          ![
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
          ].includes(e.target.files[0].type)
        ) {
          setAlert({
            type: 'ERROR',
            message: t('MedicalCenters.errorFileExt'),
          });
          return;
        }

        // If needed in the future, check file size
        // if (e.target.files[0].size > 2 * 1024 * 1024) {
        //   return;
        // }

        fetch(
          `${process.env.REACT_APP_BASE_URL_REST}/medicalcenters/excel/upload`,
          {
            method: 'post',
            headers: new Headers({
              authorization: `Bearer ${token}`,
            }),
            body: fd,
          },
        )
          .then(res => {
            if (res.status === 201) {
              // Upload success
              setAlert({
                type: 'SUCCESS',
                message: t('MedicalCenters.uploadSuccess'),
              });
            } else if (res.status === 406) {
              // File is badly formatted or missing params
              setAlert({
                type: 'ERROR',
                message: t('MedicalCenters.uploadBadFormat'),
              });
            } else if (res.status === 422) {
              // File is badly formatted or missing params
              res.json().then(res => {
                let errorMsgText = '';
                res.forEach(
                  (item: {
                    medicalCenter: string;
                    errorType: string;
                    field: string;
                  }) => {
                    switch (item.errorType) {
                      case MedicalCenterUploadErrorType.NOT_EMPTY:
                        errorMsgText +=
                          t('MedicalCenters.uploadErrorNotEmpty') + item.field;
                        break;
                      case MedicalCenterUploadErrorType.NOT_FOUND:
                        errorMsgText +=
                          t('MedicalCenters.uploadErrorNotFound') + item.field;
                        break;
                      case MedicalCenterUploadErrorType.INVALID:
                        errorMsgText += `${t(
                          'MedicalCenters.uploadErrorInvalid',
                        )} ${item.field}`;
                        break;
                      case MedicalCenterUploadErrorType.ALREDY_USED:
                        errorMsgText +=
                          t('MedicalCenters.uploadErrorAlreadyExist') +
                          item.medicalCenter;
                        break;
                      default:
                        errorMsgText = '';
                    }
                  },
                );
                setAlert({
                  type: 'ERROR',
                  message: errorMsgText,
                });
              });
            } else {
              // Generic error
              setAlert({
                type: 'ERROR',
                message: t('MedicalCenters.uploadError'),
              });
            }
          })
          .catch(err => {
            setAlert({
              type: 'ERROR',
              message: t('MedicalCenters.uploadError'),
            });
          });
      }
    },
    [setAlert, t, token],
  );

  return (
    <Admin title={t('MedicalCenters.title')}>
      <div className="az-admin-medical-centers">
        <div className="flex justify-end">
          <div className="az-admin-medical-centers__button-container">
            <ButtonText
              label={t('MedicalCenters.templateDownload')}
              className="az-admin-medical-centers__button-text"
              onClick={downloadImportTemplate}
            />
          </div>
          <div className="az-admin-medical-centers__button-container">
            <label className="az-admin-medical-centers__upload-button">
              <input type="file" onChange={e => uploadImportFile(e)} />
              {t('MedicalCenters.massiveImport')}
            </label>
          </div>
          <div className="az-admin-medical-centers__button-container">
            <Button
              label={t('MedicalCenters.newCenter')}
              rightIcon={addIcon}
              onClick={goToMedicalCentersDetail}
            />
          </div>
        </div>
        {loading && (
          <Spinner color="gray" className="az-admin-medical-centers__spinner" />
        )}
        {medicalCentersData?.medicalCenters.items && (
          <DataTableMedicalCenters
            medicalCentersData={medicalCentersData?.medicalCenters?.items}
            totalMedicalCenters={medicalCentersData?.medicalCenters?.info.total}
            refetchData={refetch}
          />
        )}
      </div>
    </Admin>
  );
};

export default React.memo(MedicalCenters);
