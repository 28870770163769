import React, {useMemo} from 'react';
import classNames from 'classnames';

//Others
import {useBEMClass} from '../../../hooks/use-bem-class';

//Component namespace
import {IProps} from './interfaces';
import './DataTableHead.scss';

const DataTableHead = ({label, boxed = true, className}: IProps) => {
  const BEMClass = useBEMClass();

  //Internal state
  const boxedModifierClass = useMemo(
    () => (boxed ? BEMClass('az-data-table-head', '', 'boxed') : ''),
    [boxed, BEMClass],
  );

  return (
    <th
      className={classNames(
        'az-data-table-head',
        boxedModifierClass,
        className,
      )}>
      {label}
    </th>
  );
};

export default DataTableHead;
