import {useTranslations} from '@vidiemme/react-i18n';
import {useState} from 'react';
import {useGetStructureQuestionnairePublished} from '../../store/questionnaire';
import {AnswerType, QuestionType} from '../../store/questionnaire/types';

export const useQuestionnaire = (id: number) => {
  const {t} = useTranslations();

  try {
    JSON.parse(
      JSON.stringify(
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useGetStructureQuestionnairePublished(id).data
          ?.structureQuestionnairePublished,
      ),
    );
  } catch {
    alert(t('NewQuestionnaire.refreshAlert'));
    window.location.href = '/questionnaire';
    // throw new Error('This is an error to abort javascript execution');
  }

  let firstQuestionnaire = JSON.parse(
    JSON.stringify(
      useGetStructureQuestionnairePublished(id).data
        ?.structureQuestionnairePublished,
    ),
  );

  const [questionnaire, setQuestionnaire] = useState(firstQuestionnaire);
  let questions: QuestionType[] = questionnaire.questions;

  //helper
  const getQuestionById = (questionId: string): QuestionType | undefined => {
    const search = questions?.find(item => item.id === questionId);
    if (search) return search;
  };

  const getAnswerById = (
    questionId: string,
    answerId: string,
  ): AnswerType | undefined => {
    const search = getQuestionById(questionId)?.answers.find(
      item => item.id === answerId,
    );
    if (search) return search;
  };

  //Backward and forward arrows
  const getFirstQuestionId = (): string => {
    const firstQuestion = questions.find(item => item.start);
    return firstQuestion!.id;
  };

  const getPreviousQuestionId = (questionId: string): number | null => {
    const question = getQuestionById(questionId);
    if (!question?.start) {
      return question!.previousQuestionId;
    }
    return null;
  };

  const getNextQuestionId = (
    questionId: string,
    answerId: string,
  ): string | null => {
    return getAnswerById(questionId, answerId)!.nextQuestionId!.toString();
  };

  //Update questionnaire values
  const checkAnswerByQuestionId = (questionId: string, answerId: string) => {
    // reset all choice to false
    getQuestionById(questionId)?.answers.forEach(item => (item.choice = false));
    getAnswerById(questionId, answerId)!.choice = true;
    setQuestionnaire({...questionnaire, questions: questions});
  };

  const goToNextQuestionId = (questionId: string, answerId: string) => {
    // checkAnswerByQuestionId(questionId, answerId);
    const nextQuestion = getQuestionById(
      getNextQuestionId(questionId, answerId)!,
    );
    nextQuestion!.previousQuestionId = Number(questionId);
  };

  return {
    questionnaire,
    questions,
    getQuestionById,
    getPreviousQuestionId,
    getNextQuestionId,
    goToNextQuestionId,
    getFirstQuestionId,
    getAnswerById,
    checkAnswerByQuestionId,
  };
};
