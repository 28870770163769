// React api
import React from 'react';
import classNames from 'classnames';

// Other
import {noop} from '../../../utils/noop';

// Components
import {IconSVG} from '../IconSVG';
import {Spinner} from '../Spinner';

// Interface
import {IProps} from './interfaces';

// Style
import './Button.scss';

const Button = ({
  loading = false,
  onClick = noop,
  label,
  styleType = 'primary',
  width = '100%',
  height = 43,
  rightIcon,
  className,
  disabled = false,
  type = 'button',
  hideLabel = false,
}: IProps): JSX.Element => (
  <button
    style={{width, height}}
    className={classNames('az-button', className, {
      [`az-button--${styleType}`]: styleType,
      'az-button__content': !!rightIcon,
    })}
    disabled={disabled}
    onClick={onClick}
    type={type}>
    {loading ? (
      <Spinner className="az-button__spinner" />
    ) : (
      <>
        <div
          className={classNames('az-button__label', {
            'az-button__label--add-mobile': hideLabel,
          })}>
          {label}
        </div>
        {rightIcon && (
          <IconSVG
            className={classNames(
              'az-button__icon',
              rightIcon?.className ?? '',
              {'az-button__icon--add-mobile': hideLabel},
            )}
            icon={rightIcon.icon}
            size={rightIcon.size}
          />
        )}
      </>
    )}
  </button>
);

export default React.memo(Button);
