import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import classNames from 'classnames';

//Others
import {useMediaQuery} from '../../../hooks';
import {MEDIA_QUERIES} from '../../../hooks/mediaQuery/contants';
import {useAuthentication} from '../../../store/authentication';
import {useNavigation} from '../../../navigation';
import {Role} from '../../../store/api';

// Components
import {Logo} from '../../atoms/Logo';
import {Link} from '../../atoms/Link';
import {BurgerButton} from '../../atoms/BurgerButton';
import {ButtonText} from '../../atoms/ButtonText';
import {IconButton} from '../../molecules/iconButton';
import {BurgerMenu} from '../BurgerMenu';

// Other types
import {IProps as IconType} from '../../atoms/IconSVG/interfaces';

// Types
import {IProps} from './interface';

// Style
import './Header.scss';

const Header = ({
  className,
  isDoctor = true,
  isAuth,
  isTransparent = false,
  isHomepage = false,
}: IProps): JSX.Element => {
  const {t} = useTranslations();
  const {
    // @REMOVE_PATIENTS_DATA
    // goToPatients,
    // goToPatientProfile,
    goToQuestionnaire,

    goToUserLogin,
    goToHealthProfessionalLogin,
    goToMain,
    goToCentersMap,
    goToAdminPathologies,
    goToMMGProfile,
    goToResources,
  } = useNavigation();
  const {logOut, role, id} = useAuthentication();

  const iconProfile: IconType = {
    icon: 'icon-profilo',
    size: 30,
    className: isHomepage ? 'text-white' : 'text-dark',
  };

  // State to open burger menu
  const [isOpen, setOpen] = useState<boolean>(false);

  const {mediaQueryList} = useMediaQuery(MEDIA_QUERIES.get('LG')!);
  const headerRef = useRef<HTMLDivElement>(null);

  const logoStyle = useMemo(() => {
    if (mediaQueryList.matches || !isOpen) {
      return 'az-header__logo-link';
    }

    return 'az-header__logo-link az-header__logo-link--disabled';
  }, [isOpen, mediaQueryList]);

  // Toggle burger menu
  const toggleOpen = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  // Close menu if screen size is large
  useEffect(() => {
    if (mediaQueryList.matches) {
      setOpen(false);
    }
  }, [mediaQueryList]);

  const bgClassName = useMemo<string>(
    () => `az-header--${isTransparent ? 'transparent' : 'colored'}`,
    [isTransparent],
  );

  const logoType = useMemo(
    () => (isHomepage ? 'white-yellow' : 'full'),
    [isHomepage],
  );

  const handleGoToProfile = useCallback(() => {
    if (role === Role.ROLE_ADMIN) {
      goToAdminPathologies();
    }
    // @REMOVE_PATIENTS_DATA
    // else if (role === Role.ROLE_PATIENT) {
    //   goToPatientProfile();
    // }
    else if (role === Role.ROLE_DOCTOR) {
      goToMMGProfile();
    }
  }, [
    goToAdminPathologies,
    goToMMGProfile,
    // @REMOVE_PATIENTS_DATA
    // goToPatientProfile,
    role,
  ]);

  // Header for anonymous users
  if (!isAuth) {
    return (
      <header className={classNames('az-header', bgClassName, className)}>
        <div className={isTransparent ? '' : 'az-header__gradient-bar'} />
        <div className="az-header__container" ref={headerRef}>
          <div className="az-header__desktop-menu">
            <Link href="/" className={logoStyle}>
              <Logo className="az-header__logo" type={logoType} />
            </Link>
          </div>
          {isTransparent && (
            <>
              <div className="az-header__login">
                {/*<ButtonText
                  className="az-header__link"
                  label={t('Header.user')}
                  onClick={goToUserLogin}
                />*/}
                <div className="az-header__access az-header__access--light">
                  {t('Header.access')}
                </div>
                <ButtonText
                  className="az-header__link az-header__link--light az-header__link--border"
                  label={t('Header.healthPro')}
                  onClick={goToHealthProfessionalLogin}
                />
              </div>
              <BurgerButton
                className="az-header__homepage-mobile-menu"
                isHomepage={isHomepage}
                isOpen={isOpen}
                onClick={toggleOpen}
              />
            </>
          )}
        </div>
        <BurgerMenu
          isAuth={false}
          className="az-header__burger-menu"
          isDoctor={isDoctor}
          isOpen={isOpen}
        />
      </header>
    );
  }

  return (
    <header className={classNames('az-header', bgClassName, className)}>
      <div className={isHomepage ? '' : 'az-header__gradient-bar'} />
      <div className="az-header__container" ref={headerRef}>
        <BurgerButton
          className="az-header__mobile-menu"
          isOpen={isOpen}
          onClick={toggleOpen}
          isHomepage={isHomepage}
        />
        <div className="az-header__desktop-menu">
          <Link href="/" className={logoStyle}>
            <Logo className="az-header__logo" type={logoType} />
          </Link>
          {id !== -1 && (
            <ButtonText
              label={t('Header.home')}
              className={classNames(
                'az-header__link',
                'az-header__link--homepage',
                {'az-header__link--light': isHomepage},
              )}
              onClick={goToMain}
            />
          )}
          {isDoctor && id !== -1 && (
            <>
              {/* @REMOVE_PATIENTS_DATA */}
              {/* <ButtonText
                label={t('Header.patients')}
                className={classNames(
                  'az-header__link',
                  'az-header__link--az-header__link--patients',
                  {'az-header__link--light': isHomepage},
                )}
                onClick={goToPatients}
              /> */}
              <ButtonText
                label={t('Header.questionnaire')}
                className={classNames(
                  'az-header__link',
                  'az-header__link--az-header__link--patients',
                  {'az-header__link--light': isHomepage},
                )}
                onClick={goToQuestionnaire}
              />
            </>
          )}
          {role !== Role.ROLE_ADMIN && id !== -1 && (
            <ButtonText
              label={t('Header.centersMap')}
              className={classNames(
                'az-header__link',
                'az-header__link--homepage',
                {'az-header__link--light': isHomepage},
              )}
              onClick={goToCentersMap}
            />
          )}
          {role !== Role.ROLE_ADMIN && id !== -1 && (
            <ButtonText
              label={t('Header.resources')}
              className={classNames(
                'az-header__link',
                'az-header__link--homepage',
                {'az-header__link--light': isHomepage},
              )}
              onClick={goToResources}
            />
          )}
        </div>
        <div className="az-header__profile">
          {id !== -1 && (
            <IconButton icon={iconProfile} onClick={handleGoToProfile} />
          )}
          <ButtonText
            className={classNames('az-header__logout', {
              'az-header__logout--light': isHomepage,
            })}
            label={t('Header.logout')}
            onClick={logOut}
          />
        </div>
      </div>
      <BurgerMenu
        isAuth
        className={`az-header__burger-menu`}
        isDoctor={isDoctor}
        isOpen={isOpen}
      />
    </header>
  );
};

export default React.memo(Header);
