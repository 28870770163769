export interface QuestionnaireResultState {
  data: any;
}

export enum QuestionnaireResultActions {
  FILL_QUESTIONNAIRE_RESULT = 'fillQuestionnaireResult',
  CLEAN_QUESTIONNAIRE_RESULT = 'cleanQuestionnaireResult',
}

export type QuestionnaireResultFillAction = {
  type: QuestionnaireResultActions.FILL_QUESTIONNAIRE_RESULT;
  payload: any;
};

export type QuestionnaireResultCleanAction = {
  type: QuestionnaireResultActions.CLEAN_QUESTIONNAIRE_RESULT;
};
