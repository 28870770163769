import React from 'react';
import classNames from 'classnames';

// Components
import {CheckableInput} from '../../atoms/CheckableInput';

// Interface
import {IProps} from './interface';

// Style
import './RadioAnswer.scss';

const RadioAnswer = ({
  value,
  isChecked = false,
  answer,
  onChange = undefined,
  selectOption = undefined,
  isDisabled = false,
  riskColor,
}: IProps) => {
  return (
    <div
      className={classNames('az-radio-answer', {
        'az-radio-answer--checked': isChecked,
        'az-radio-answer--disabled': isDisabled,
        [`az-radio-answer--${riskColor}`]: isDisabled && isChecked,
      })}
      onClick={() => selectOption!(value)}>
      {!isDisabled && (
        <CheckableInput
          type="radio"
          name="answer"
          value={value}
          disabled={isDisabled}
          isChecked={isChecked}
          onChange={onChange!}
        />
      )}
      <p className="az-radio-answer__answer">{answer}</p>
    </div>
  );
};

export default React.memo(RadioAnswer);
