import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import L from 'leaflet';
import {MapContainer, Marker, TileLayer, Tooltip} from 'react-leaflet';
import {IProps as IconProps} from '../../atoms/IconSVG/interfaces';
import {useLazyQuery} from '@apollo/client';

//Others
import {
  GET_LOGGED_USER,
  useAuthentication,
} from '../../../store/authentication';
import {useGetMedicalCenters} from '../../../store/medicalCenters/hooks';
import {
  MedicalCenterResponse,
  MedicalCenterType,
} from '../../../store/medicalCenters/interfaces';
import {Role} from '../../../store/api';
import {GET_PATHOLOGIES} from '../../../store/pathologies';
import truncateText from '../../../utils/truncateText';
import {useBreadCrumb} from '../../../store/breadCrumb';
import {IconNames} from '../../../composable';

// Context
import {AlertContext} from '../../../store/alert/context';

//Other components
import {AuthBase} from '../../templates/AuthBase';
import {Spinner} from '../../atoms/Spinner';
import {LabeledInput} from '../../molecules/LabeledInput';
import {IconButton} from '../../molecules/iconButton';
import {Dropdown} from '../../molecules/Dropdown';
import {Marker as MarkerDescription} from '../../atoms/Marker';
import {IconSVG} from '../../atoms/IconSVG';
import {InputLabel} from '../../atoms/InputLabel';

//Component namespace
import './CentersMap.scss';

const CentersMap = () => {
  //Helpers
  const {t} = useTranslations();
  const {isLoggedIn, role} = useAuthentication();
  const {setValue: setAlert} = useContext(AlertContext);
  const {updateBreadCrumbItems} = useBreadCrumb();

  // Reset to empty breadcrumbs
  useEffect(() => updateBreadCrumbItems([]), [updateBreadCrumbItems]);

  // Queries
  // Get medical centers
  const {
    data: medicalCentersData,
    loading,
    refetch,
  } = useGetMedicalCenters({latitude: 41.9102415, longitude: 12.3959131});
  // Get pathologies
  const [
    getPathologies,
    {
      data: pathologiesData,
      loading: pathologiesLoading,
      // error: pathologiesError,
    },
  ] = useLazyQuery(GET_PATHOLOGIES, {
    variables: {},
    fetchPolicy: 'cache-and-network',
  });
  // Get logged user to get pathologies for patient
  const [
    getPatientPathologies,
    {
      data: patientPathologiesData,
      loading: patientPathologiesLoading,
      // error: pathologiesError,
    },
  ] = useLazyQuery(GET_LOGGED_USER, {
    variables: {},
    fetchPolicy: 'cache-and-network',
  });

  // Days translated
  const weekDays = useMemo(
    () => [
      t('WeekDays.monday'),
      t('WeekDays.tuesday'),
      t('WeekDays.wednesday'),
      t('WeekDays.thursday'),
      t('WeekDays.friday'),
      t('WeekDays.saturday'),
      t('WeekDays.sunday'),
    ],
    [t],
  );

  // State input
  const [searchAddress, setSearchAddress] = useState<string>('');
  const [loadCoordinates, setLoadCoordinates] = useState<boolean>(false);
  const [searchedLatLon, setSearchedLatLon] = useState<{
    lat: number | null;
    lon: number | null;
  }>({lat: null, lon: null});
  const [geolocatedLatLon, setGeolocatedLatLon] = useState<{
    lat: number | null;
    lon: number | null;
  }>({lat: null, lon: null});
  const [toggleInfoBox, setToggleInfoBox] = useState<boolean>(false);
  const [selectedMedicalCenter, setSelectedMedicalCenter] =
    useState<MedicalCenterResponse | null>(null);
  const [pathologiesList, setPathologiesList] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);
  const [selectedPathology, setSelectedPathology] = useState<string>('');
  const [patientReady, setPatientReady] = useState<boolean>(false);
  const [searchDisabled, setSearchDisabled] = useState<boolean>(false);

  // If search button is disabled because of a recent button press, Re-enable it after 2 seconds
  // This is to rate limit fetching our reverse geocoder api
  useEffect(() => {
    if (searchDisabled) {
      setTimeout(() => {
        setSearchDisabled(false);
      }, 2000);
    }
  }, [searchDisabled]);
  const searchCoordinates = useCallback(() => {
    setLoadCoordinates(true);
    // Lock search button
    setSearchDisabled(true);
    let url = `https://nominatim.openstreetmap.org/search?q=${searchAddress}&format=json&addressdetails=1`;
    fetch(url.replace(/ /g, '+'))
      .then(res => res.json())
      .then(
        result => {
          if (result.length > 0) {
            setSearchedLatLon({
              lat: result[0].lat,
              lon: result[0].lon,
            });
            if (role === Role.ROLE_PATIENT && !selectedPathology) {
              setAlert({
                type: 'ERROR',
                message: t('CentersMap.noPathologySelected'),
              });
              setLoadCoordinates(false);
              return;
            }
            refetch({
              input: {
                latitude: result[0].lat,
                longitude: result[0].lon,
                pathologyId:
                  pathologiesList.find(
                    pathology => selectedPathology === pathology.name,
                  )?.id || null,
              },
            }).then(() => setLoadCoordinates(false));
          } else {
            setLoadCoordinates(false);
            setAlert({
              type: 'ERROR',
              message: t('MedicalCentersDetail.addressError'),
            });
          }
        },
        error => {
          setLoadCoordinates(false);
          setAlert({
            type: 'ERROR',
            message: t('ResultQuestionnaire.generalError'),
          });
        },
      );
  }, [
    pathologiesList,
    refetch,
    role,
    searchAddress,
    selectedPathology,
    setAlert,
    t,
  ]);
  // MMG only
  // Get a list of all pathologies to fill dropdown
  useEffect(() => {
    if (role === Role.ROLE_DOCTOR) {
      getPathologies();
    }
    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Patient only
  // Get a list of patient's pathologies to fill dropdown
  useEffect(() => {
    // Query to get pathologies of patient
    if (role === Role.ROLE_PATIENT) {
      getPatientPathologies();
    }
    return () => {
      //
    };
    // Run this useEffect only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Set our list of pathologies to fill dropdown
  useEffect(() => {
    // Fill pathologies list with all pathologies
    if (pathologiesData) {
      setPathologiesList(pathologiesData?.pathologies?.items);
    }
    // Fill pathologies list with patient's pathologies
    if (patientPathologiesData) {
      setPathologiesList(
        patientPathologiesData?.loggedUser?.positivePathologies,
      );
      setSelectedPathology(
        patientPathologiesData?.loggedUser?.positivePathologies?.[0]?.name,
      );
    }
    return () => {
      //
    };
    // Run this useEffect only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathologiesData, patientPathologiesData]);

  useEffect(() => {
    if (
      !patientReady &&
      role === Role.ROLE_PATIENT &&
      pathologiesList.length > 0 &&
      selectedPathology
    ) {
      setPatientReady(true);
    }
  }, [pathologiesList, selectedPathology, patientReady, role]);

  // Set alert if no medical centers are found near the pointed address
  useEffect(() => {
    if (
      !loading &&
      !loadCoordinates &&
      medicalCentersData?.medicalCenters?.items?.length < 1 &&
      pathologiesList.length > 0 &&
      !pathologiesLoading &&
      !patientPathologiesLoading
    ) {
      // searchedLatLon,
      // geolocatedLatLon,
      if (role === Role.ROLE_DOCTOR) {
        setAlert({
          type: 'ERROR',
          message: t('MedicalCentersDetail.noMedicalCentersFound'),
        });
      } else if (patientReady && role === Role.ROLE_PATIENT) {
        if (
          (searchedLatLon.lat && searchedLatLon.lon) ||
          (geolocatedLatLon.lat && geolocatedLatLon.lon)
        ) {
          setAlert({
            type: 'ERROR',
            message: t('MedicalCentersDetail.noMedicalCentersFound'),
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadCoordinates,
    loading,
    medicalCentersData,
    setAlert,
    t,
    pathologiesList,
    patientReady,
    role,
    searchedLatLon,
    geolocatedLatLon,
  ]);

  // Set geolocation if present in the browser
  useEffect(() => {
    if (
      'geolocation' in navigator &&
      role === Role.ROLE_PATIENT &&
      patientReady
    ) {
      navigator.geolocation.getCurrentPosition(function (position) {
        if (position?.coords?.latitude && position?.coords?.longitude) {
          setLoadCoordinates(true);
          setSearchedLatLon({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
          setGeolocatedLatLon({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
          if (
            role === Role.ROLE_PATIENT &&
            !selectedPathology &&
            pathologiesList.length > 0
          ) {
            setAlert({
              type: 'ERROR',
              message: t('CentersMap.noPathologySelected'),
            });
            setLoadCoordinates(false);
            return;
          }
          if (refetch) {
            try {
              refetch({
                input: {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                  pathologyId: pathologiesList.find(
                    pathology => selectedPathology === pathology.name,
                  )?.id,
                },
              }).then(() => setLoadCoordinates(false));
            } catch {
              setLoadCoordinates(false);
            }
          } else {
            setLoadCoordinates(false);
          }
        }
      });
    } else {
      // Geolocation isn't available in the browser
    }
    // Only dependant on patient ready state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientReady]);

  // Icons
  // Icon needed to close the medical center info box
  const iconClose = useMemo<IconProps>(
    () => ({
      icon: 'icon-close',
      size: 14,
      className: 'az-medical-centers__icon-close',
    }),
    [],
  );
  // Icon needed to close the medical center info box
  const iconSearch = useMemo<IconProps>(
    () => ({
      icon: 'icon-search',
      size: 21,
      className: 'az-medical-centers__icon-search',
      onClick:
        searchAddress.length > 0 && !searchDisabled ? searchCoordinates : null,
    }),
    [searchAddress.length, searchCoordinates, searchDisabled],
  );

  const patientNoGeoNoSearch = useMemo(() => {
    return (
      role === Role.ROLE_PATIENT &&
      !searchedLatLon.lat &&
      !searchedLatLon.lon &&
      !geolocatedLatLon.lat &&
      !geolocatedLatLon.lon &&
      !selectedPathology
    );
  }, [geolocatedLatLon, role, searchedLatLon, selectedPathology]);

  const openInfoBox = useCallback((medicalCenter: MedicalCenterResponse) => {
    setToggleInfoBox(true);
    setSelectedMedicalCenter(medicalCenter);
  }, []);

  const closeInfoBox = useCallback(() => {
    setToggleInfoBox(false);
    setSelectedMedicalCenter(null);
  }, []);

  // RISP marker icon
  const rispMarker = new L.Icon({
    iconUrl: require('../../../themes/base/assets/risp_mappa.svg').default,
    iconSize: new L.Point(40, 40),
    className: 'leaflet-div-icon',
  });

  // T1 marker icon
  const genericMarker = new L.Icon({
    iconUrl: require('../../../themes/base/assets/t1_mappa.svg').default,
    iconSize: new L.Point(40, 40),
    className: 'leaflet-div-icon',
  });

  // COMMENTED for cr 6/7/23 remove marker types
  // T2 marker icon
  /*const t2Marker = new L.Icon({
    iconUrl: require('../../../themes/base/assets/t2_mappa.svg').default,
    iconSize: new L.Point(40, 40),
    className: 'leaflet-div-icon',
  });

  // T3 marker icon
  const t3Marker = new L.Icon({
    iconUrl: require('../../../themes/base/assets/t3_mappa.svg').default,
    iconSize: new L.Point(30, 30),
    className: 'leaflet-div-icon',
  });

  // Default marker
  const defaultMarker = new L.Icon({
    iconUrl: require('../../../themes/base/assets/marker.png').default,
    iconSize: new L.Point(40, 40),
    className: 'leaflet-div-icon',
  });*/

  // Get marker by type
  const customMarker = useCallback((type: MedicalCenterType) => {
    switch (type) {
      case MedicalCenterType.RISP:
        return rispMarker;
      case MedicalCenterType.GENERIC:
        return genericMarker;
      default:
        return genericMarker;
    }
  }, []);

  const parsingCenterType = useCallback((type: MedicalCenterType) => {
    switch (type) {
      case MedicalCenterType.RISP:
        return t('MedicalCenterType.RISP');
      case MedicalCenterType.GENERIC:
        return t('MedicalCenterType.generic');
      default:
        return t('MedicalCenterType.generic');
    }
  }, []);

  const renderInfoBox = useMemo(() => {
    return (
      <div className="az-medical-centers__info-box">
        <div className="az-medical-centers__heading-box">
          <div className="az-medical-centers__heading">
            {truncateText(selectedMedicalCenter?.name || '', 26)}
          </div>
          <IconButton icon={iconClose} onClick={closeInfoBox} />
        </div>
        <div className="az-medical-centers__info-table">
          {/* Center typology */}
          <span className="az-medical-centers__info-label">
            {t('MedicalCenterType.label')}
          </span>
          <span className="az-medical-centers__info-text">
            {selectedMedicalCenter && (
              <span className="whitespace-nowrap">
                <IconSVG
                  icon={selectedMedicalCenter.type.toLowerCase() as IconNames}
                  size={18}
                  color
                  className="text-current mb-2 mr-2"
                />
                {parsingCenterType(selectedMedicalCenter.type)}
              </span>
            )}
          </span>
          {/* Business name */}
          <span className="az-medical-centers__info-label">
            {t('MedicalCentersDetail.businessName.label')}
          </span>
          <span className="az-medical-centers__info-text">
            {selectedMedicalCenter?.companyName}
          </span>
          {/* Address */}
          <span className="az-medical-centers__info-label">
            {t('MedicalCentersDetail.address')}
          </span>
          <span className="az-medical-centers__info-text">
            {selectedMedicalCenter?.street +
              ' ' +
              selectedMedicalCenter?.streetNumber}
            <br />
            {selectedMedicalCenter?.city} ({selectedMedicalCenter?.province}) -{' '}
            {selectedMedicalCenter?.postalCode}
            <br />
            {selectedMedicalCenter?.email}
            <br />
            {t('MedicalCentersDetail.phoneNumber.label')}:{' '}
            {selectedMedicalCenter?.phone}
          </span>
        </div>
        <div className="az-medical-centers__info-table-opening-hours">
          {/* Opening hours */}
          <span className="az-medical-centers__info-label">
            {t('MedicalCentersDetail.openingHours')}
          </span>
          <div className="az-medical-centers__info-text">
            {selectedMedicalCenter?.openingHours.map((day, i) => {
              return (
                <div key={i} className="az-medical-centers__days-grid">
                  <span>{weekDays[i]}</span>
                  {day.open ? (
                    <>
                      <span>{day.fromHour}</span>
                      <span>{day.toHour}</span>
                      <span>{day.fromHour2}</span>
                      <span>{day.toHour2}</span>
                    </>
                  ) : (
                    <span>{t('MedicalCentersDetail.closed')}</span>
                  )}
                </div>
              );
            })}
          </div>
          <div className="az-medical-centers__info-text">
            {selectedMedicalCenter?.description}
          </div>
        </div>
      </div>
    );
  }, [closeInfoBox, iconClose, selectedMedicalCenter, t, weekDays]);

  // Return an error message if the user is a patient with no pathologies, he cannot view the map
  if (pathologiesList?.length < 1 && !loading && !loadCoordinates) {
    return (
      <AuthBase isAuth={isLoggedIn} pageHeading={t('CentersMap.heading')}>
        <div className="mb-24 font-helvetica-az text-base text-dark">
          {t('CentersMap.noPathologies')}
        </div>
      </AuthBase>
    );
  }

  return (
    <AuthBase isAuth={isLoggedIn} pageHeading={t('CentersMap.heading')}>
      <div className="mb-24">
        <div className="mt-10">
          <MarkerDescription
            color="tertiary"
            className="az-patient__section-marker"
            description={t('CentersMap.description')}
          />
        </div>
        <div className="az-medical-centers__action-container">
          <div className="w-full lg:w-6/12">
            <div className="az-medical-centers__actions-row">
              <LabeledInput
                label={t('MedicalCentersDetail.search.label')}
                placeholder={t('MedicalCentersDetail.search.placeholder')}
                onChange={setSearchAddress}
                className="w-full lg:w-6/12"
                maxLength={120}
                rightIcon={iconSearch}
                onKeyPress={e => {
                  if (
                    e.key === 'Enter' &&
                    searchAddress.length > 0 &&
                    !searchDisabled
                  ) {
                    searchCoordinates();
                  }
                }}
                disabled={searchDisabled}
              />
              <div className="az-medical-centers__dropdown">
                <Dropdown
                  label={t('CentersMap.selectAPathology')}
                  options={pathologiesList.map(item => {
                    return {
                      label: item.name,
                      selected: item.name === selectedPathology,
                      value: item.name,
                    };
                  })}
                  onChange={option => {
                    setSelectedPathology(option.value);
                    setLoadCoordinates(true);
                    refetch({
                      input: {
                        pathologyId: pathologiesList.find(
                          pathology => option.value === pathology.name,
                        )?.id,
                        latitude: searchedLatLon.lat,
                        longitude: searchedLatLon.lon,
                      },
                    }).then(res => {
                      if (res?.data?.medicalCenters?.items?.length > 0) {
                        setSearchedLatLon({
                          lat: res?.data?.medicalCenters?.items?.[0].latitude,
                          lon: res?.data?.medicalCenters?.items?.[0].longitude,
                        });
                      }
                      setLoadCoordinates(false);
                    });
                  }}
                  dropdownPlaceholder={t('CentersMap.selectAPathology')}
                />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-6/12 lg:ml-32">
            <div className="az-medical-centers__type-container">
              <InputLabel
                className="az-medical-centers__type-label"
                label={t('MedicalCenterType.label')}
                color="dark"
              />
              <div className="flex flex-col lg:flex-row lg:mt-1">
                <div className="flex flex-col lg:mr-3">
                  <div className="text-xs">
                    <IconSVG
                      icon="risp"
                      size={18}
                      color
                      className="text-current mb-2 mr-2"
                    />
                    {t('MedicalCenterType.RISP')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading || loadCoordinates ? (
          <Spinner color="gray" className="az-medical-centers__spinner" />
        ) : (
          <>
            {patientNoGeoNoSearch &&
            !geolocatedLatLon.lat &&
            !geolocatedLatLon.lon ? (
              <div>{t('CentersMap.noSelectionError')}</div>
            ) : (
              <div>
                <MapContainer
                  center={[
                    searchedLatLon.lat ||
                      medicalCentersData?.medicalCenters?.items?.[0]
                        ?.latitude ||
                      41.9102415,
                    searchedLatLon.lon ||
                      medicalCentersData?.medicalCenters?.items?.[0]
                        ?.longitude ||
                      12.3959131,
                  ]}
                  zoom={13}
                  scrollWheelZoom={false}
                  id="map">
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {toggleInfoBox ? renderInfoBox : <></>}
                  {medicalCentersData?.medicalCenters?.items.map(
                    (medicalCenter: any) => {
                      return (
                        <Marker
                          key={medicalCenter.id}
                          position={[
                            medicalCenter.latitude,
                            medicalCenter.longitude,
                          ]}
                          icon={customMarker(medicalCenter.type)}
                          eventHandlers={{
                            click: () => {
                              openInfoBox(medicalCenter);
                            },
                          }}>
                          <Tooltip>
                            {truncateText(medicalCenter.name || '', 20)}
                          </Tooltip>
                        </Marker>
                      );
                    },
                  )}
                  {role === Role.ROLE_PATIENT &&
                    geolocatedLatLon.lat &&
                    geolocatedLatLon.lon && (
                      <Marker
                        position={[geolocatedLatLon.lat, geolocatedLatLon.lon]}>
                        <Tooltip>{t('CentersMap.yourLocation')}</Tooltip>
                      </Marker>
                    )}
                </MapContainer>
              </div>
            )}
          </>
        )}
      </div>
    </AuthBase>
  );
};

export default React.memo(CentersMap);
