import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useParams} from 'react-router-dom';
import {useTranslations} from '@vidiemme/react-i18n';

// Others
import {noop} from '../../../utils/noop';
import {useNavigation} from '../../../navigation';
import {ModalContext} from '../../../store/modal/context';
import {ModalContent} from '../../../store/modal/interfaces';
import {useBreadCrumb} from '../../../store/breadCrumb';
import {
  useStructureQuestionnaire,
  QuestionInput,
  useGetStructureQuestionnaireLazy,
} from '../../../store/structureQuestionnaire';
import {AlertContext} from '../../../store/alert/context';
import {useStructureQuestionnaireInputReducer} from './hooks';
import {pipe} from '../../../utils/pipe';
import {PathologyResponse, useGetPathologies} from '../../../store/pathologies';

// Template
import {Admin} from '../../templates/Admin';

// Components
import {Dropdown} from '../../molecules/Dropdown';
import {QuestionAccordion} from '../../organisms/QuestionAccordion';
import {Button} from '../../atoms/Button';
import {LabeledTextarea} from '../../molecules/LabeledTextarea';

// Types
import {IProps as ButtonProp} from '../../atoms/Button/interfaces';
import {IProps as ButtonTextProp} from '../../atoms/ButtonText/interface';
import {IProps as IconProps} from '../../atoms/IconSVG/interfaces';

// Style
import './AdminQuestionnaireDetail.scss';

const AdminQuestionnaireDetail = () => {
  const {t} = useTranslations();
  const {goToAdminSurveys} = useNavigation();
  const {updateBreadCrumbItems} = useBreadCrumb();
  const {setValue: setAlert} = useContext(AlertContext);
  const {setValue: setModal} = useContext(ModalContext);
  const {id}: {id: ''} = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedPathology, setSelectedPathology] = useState<string>('');
  const [globalError, setGlobalError] = useState(false);

  const {
    data: pathologiesData,
    // loading: pathologiesLoading,
    error: pathologiesError,
  } = useGetPathologies({availableQuestionnairesOnly: false}, 100);

  const pathologies = useMemo<PathologyResponse[]>(
    () => pathologiesData?.pathologies.items ?? [],
    [pathologiesData],
  );

  useEffect(() => {
    if (pathologiesError) {
      setGlobalError(true);
    }
    if (!id && pathologies.length > 0) {
      setSelectedPathology(pathologies[0].name);
      dispatch({
        type: 'UPDATE_QUESTIONNAIRE',
        payload: {
          ...questionnaire,
          pathologyId: pathologies[0].id,
        },
      });
    }
  }, [id, pathologies, pathologiesError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!id) {
      setIsEditing(true);
    }
  }, [id]);

  const [
    getStructure,
    {
      data: {structureQuestionnaire: questionnaireQueryData} = {
        structureQuestionnaire: undefined,
      },
      // loading: questionnaireQueryLoading,
      // error: questionnaireQueryError,
    },
  ] = useGetStructureQuestionnaireLazy();
  const {
    createStructureQuestionnaire,
    createQuestion,
    findQuestionIdx,
    nextQuestionId,
    nextQuestionOrder,
    sanitizedQuestionnaire,
  } = useStructureQuestionnaire();
  const {questionnaire, dispatch} = useStructureQuestionnaireInputReducer();

  useEffect(() => {
    const questionnaireId = Number(id);

    if (!isNaN(questionnaireId)) {
      getStructure({variables: {id: questionnaireId}});
    }
  }, [id, getStructure]);

  useEffect(() => {
    if (questionnaireQueryData) {
      const {pathology, ...otherProps} = questionnaireQueryData;
      setSelectedPathology(pathology.name);

      const nextQuestionnaire = pipe(
        createStructureQuestionnaire,
        sanitizedQuestionnaire,
      )(otherProps);

      dispatch({type: 'UPDATE_QUESTIONNAIRE', payload: nextQuestionnaire});
    }
  }, [
    questionnaireQueryData,
    createStructureQuestionnaire,
    sanitizedQuestionnaire,
    dispatch,
  ]);

  const openModal = useCallback(() => {
    // Remove empty fields subTitle and instructions
    const {subTitle, instructions, ...fields} = questionnaire;
    const cleanedQuestionnaire = {
      ...(subTitle && {subTitle}),
      ...(instructions && {instructions}),
      ...fields,
    };

    setModal({
      content: ModalContent.SCORE_THRESHOLD,
      props: {questionnaireStructure: cleanedQuestionnaire},
    });
  }, [setModal, questionnaire]);

  // State for eval questions and answers validation when click save
  const [tryPublish, setTryPublish] = useState<boolean>(false);

  const cta: ButtonProp = isEditing
    ? {
        label: t('AdminQuestionnaireDetail.headerButton.save'),
        onClick: () => {
          setTryPublish(!tryPublish);
          openModal();
        },
      }
    : {
        label: t('AdminQuestionnaireDetail.headerButton.edit'),
        onClick: () => {
          // On entering edit mode update the questionnaire in reducer state with the current selected pathology
          setIsEditing(true);
          dispatch({
            type: 'UPDATE_QUESTIONNAIRE',
            payload: {
              ...questionnaire,
              pathologyId: pathologies.find(
                item => item.name === selectedPathology,
              )!.id,
            },
          });
        },
      };

  const cancel: ButtonTextProp | null = isEditing
    ? {
        label: t('AdminQuestionnaireDetail.headerButton.cancel'),
        onClick: () =>
          setAlert({
            type: 'INFO',
            message: t('AdminQuestionnaireDetail.alert.cancel.message'),
            activeButtonLabel: t(
              'AdminQuestionnaireDetail.alert.cancel.activeButtonLabel',
            ),
            cancelButtonLabel: t(
              'AdminQuestionnaireDetail.alert.cancel.cancelButtonLabel',
            ),
            onClick: () => {
              window.location.reload();
              // dispatch({
              //   type: 'UPDATE_QUESTIONNAIRE',
              //   payload: createStructureQuestionnaire(),
              // });
              // setAlert(DEFAULT_ALERT_STATE);
            },
          }),
      }
    : null;

  const addIcon = useMemo<IconProps>(
    () => ({
      icon: 'icon-add',
      size: 23,
      className: 'text-white',
    }),
    [],
  );

  // Add breadcrumb
  useEffect(() => {
    updateBreadCrumbItems([
      {
        label: t('AdminQuestionnaires.title'),
        onClick: goToAdminSurveys,
      },
      {
        label: selectedPathology,
        onClick: noop,
      },
    ]);
  }, [selectedPathology, updateBreadCrumbItems, t, goToAdminSurveys]);

  const addQuestionToQuestionnaire = useCallback(() => {
    setTryPublish(false);
    const id = nextQuestionId(questionnaire.questions);

    const nextQuestion = createQuestion({
      id,
      order: nextQuestionOrder(questionnaire.questions),
      start: id === 0,
    });

    questionnaire.questions.push(nextQuestion);

    dispatch({
      type: 'UPDATE_QUESTIONNAIRE',
      payload: {
        ...questionnaire,
      },
    });
  }, [
    questionnaire,
    nextQuestionOrder,
    nextQuestionId,
    createQuestion,
    dispatch,
  ]);

  const removeQuestionFromQuestionnaire = useCallback(
    (question: QuestionInput) => () => {
      const questionIdx = findQuestionIdx(question, questionnaire.questions);

      if (questionIdx >= 0) {
        //Remove question from questions list
        questionnaire.questions.splice(questionIdx, 1);

        //Create new question list
        const nextQuestions = questionnaire.questions.map(
          (currQuestion, idx) => ({
            ...currQuestion,
            order: idx + 1,
            start: idx === 0,
          }),
        );

        //Reset linked answers
        nextQuestions.forEach(compQuestion => {
          compQuestion.answers.forEach(answer => {
            if (answer.nextQuestionId === question.id) {
              answer.nextQuestionId = undefined;
            }
          });
        });

        dispatch({
          type: 'UPDATE_QUESTIONNAIRE',
          payload: {
            ...questionnaire,
            questions: nextQuestions,
          },
        });
      }
    },
    [findQuestionIdx, questionnaire, dispatch],
  );

  const deleteQuestion = useCallback(
    (question: QuestionInput) => {
      setModal({
        content: ModalContent.DELETE_MODAL,
        props: {
          modalTitle: 'DeleteModal.title',
          body: 'DeleteModal.bodyQuestion',
          subtitle: 'DeleteModal.subtitle',
          callback: removeQuestionFromQuestionnaire(question),
        },
      });
    },
    [removeQuestionFromQuestionnaire, setModal],
  );

  const updateQuestionnaireQuestions = useCallback(
    (question: QuestionInput) => {
      const questionIdx = findQuestionIdx(question, questionnaire.questions);

      if (questionIdx >= 0) {
        //Replace question with new one
        questionnaire.questions.splice(questionIdx, 1, question);

        //Update question answer ids
        let answerIdx = 0;
        questionnaire.questions.forEach(question =>
          question.answers.forEach(answer => (answer.id = answerIdx++)),
        );

        dispatch({
          type: 'UPDATE_QUESTIONNAIRE',
          payload: {
            ...questionnaire,
          },
        });
      }
    },
    [findQuestionIdx, questionnaire, dispatch],
  );

  const nextQuestions = useCallback(
    (question: QuestionInput) =>
      questionnaire?.questions.filter(
        compQuestion => compQuestion.order > question.order,
      ),
    [questionnaire?.questions],
  );

  // Subtitle error state
  const [hasSubtitleError, setHasSubtitleError] = useState<boolean>(false);

  const updateSubTitle = useCallback(
    (value: string) => {
      dispatch({type: 'UPDATE_SUB_TITLE', payload: value});
    },
    [dispatch],
  );

  const updateInstruction = useCallback(
    (value: string) => dispatch({type: 'UPDATE_INSTRUCTIONS', payload: value}),
    [dispatch],
  );

  if (globalError) {
    return (
      <Admin title={t('ResultQuestionnaire.generalError')}>
        <></>
      </Admin>
    );
  }

  return (
    <Admin
      title={selectedPathology}
      breadcrumb
      ctaPrimary={cta}
      buttonText1={cancel}>
      <div className="az-admin-questionnaire-detail">
        <div className="az-admin-questionnaire-detail__intro">
          <div className="az-admin-questionnaire-detail__titles">
            <div className="az-admin-questionnaire-detail__drop">
              <Dropdown
                label={t('AdminQuestionnaireDetail.title')}
                options={pathologies.map(item => {
                  return item.name === selectedPathology
                    ? {label: item.name, selected: true, value: item.name}
                    : {label: item.name, selected: false, value: item.name};
                })}
                onChange={option => {
                  setSelectedPathology(option.value);
                  dispatch({
                    type: 'UPDATE_QUESTIONNAIRE',
                    payload: {
                      ...questionnaire,
                      pathologyId: pathologies.find(
                        item => item.name === option.value,
                      )!.id,
                    },
                  });
                }}
                className="az-admin-questionnaire-detail__title-dropdown"
                isDisabled={!isEditing}
              />
            </div>
            <LabeledTextarea
              className="az-admin-questionnaire-detail__input"
              label={t('AdminQuestionnaireDetail.subtitle.label')}
              onChange={updateSubTitle}
              nRows={1}
              placeholder={t('AdminQuestionnaireDetail.subtitle.placeholder')}
              value={questionnaire.subTitle}
              error={hasSubtitleError}
              disabled={!isEditing}
              maxLength={140}
            />
          </div>
          <LabeledTextarea
            className="mt-6"
            label={t('AdminQuestionnaireDetail.instructions.label')}
            onChange={updateInstruction}
            placeholder={t('AdminQuestionnaireDetail.instructions.placeholder')}
            value={questionnaire.instructions}
            disabled={!isEditing}
            maxLength={300}
          />
        </div>
        <div className="az-admin-questionnaire-detail__questions-wrapper">
          {questionnaire.questions.map(question => (
            <QuestionAccordion
              question={question}
              key={question.id}
              nextQuestions={nextQuestions(question)}
              onDelete={() => deleteQuestion(question)}
              onUpdate={updateQuestionnaireQuestions}
              publishing={tryPublish}
              className="az-admin-questionnaire-detail__question"
              disabled={!isEditing}
            />
          ))}
        </div>
        <div className="az-admin-questionnaire-detail__new-question">
          {isEditing ? (
            <Button
              label={t('AdminQuestionnaireDetail.addQuestion')}
              rightIcon={addIcon}
              onClick={addQuestionToQuestionnaire}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </Admin>
  );
};

export default React.memo(AdminQuestionnaireDetail);
