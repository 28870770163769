import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import {useParams} from 'react-router-dom';
import ImageUploader from 'react-images-upload';
import Select from 'react-select';
import {useLazyQuery, useMutation} from '@apollo/client';

// Others
import {useNavigation} from '../../../navigation';
import {useBreadCrumb} from '../../../store/breadCrumb';
import {noop} from '../../../utils/noop';
import {AlertContext} from '../../../store/alert/context';
import {PathologyResponse, useGetPathologies} from '../../../store/pathologies';
import {emptyFieldConstraint, useValidator} from '../../../hooks/validator';
import {
  CREATE_CONTENT,
  GET_CONTENT,
  UPDATE_CONTENT,
} from '../../../store/resources/queries';

// Components
import {Admin} from '../../templates/Admin';
import {LabeledInput} from '../../molecules/LabeledInput';
import {LabeledTextarea} from '../../molecules/LabeledTextarea';
import {InputLabel} from '../../atoms/InputLabel';
import {Spinner} from '../../atoms/Spinner';

// Types
import {IProps as ButtonProp} from '../../atoms/Button/interfaces';

// Style
import './AdminContentsDetail.scss';

// Enum for radio status
enum ResourceStatus {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

// Action for reducer
enum StateActionType {
  UPDATE_ERROR = 'UPDATE_ERROR',
}

type StateAction = {
  type: StateActionType.UPDATE_ERROR;
  payload: any;
};

// Reducer
const reducer = (state: any, action: StateAction) => {
  switch (action.type) {
    case StateActionType.UPDATE_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.input]: action.payload.value,
        },
      };
    default:
      throw new Error();
  }
};

const AdminContentsDetail = () => {
  const {t} = useTranslations();
  const {goToAdminContents} = useNavigation();
  const {updateBreadCrumbItems} = useBreadCrumb();
  const {setValue: setAlert} = useContext(AlertContext);
  const {validateField} = useValidator();
  const {id: contentId}: {id: string} = useParams();

  // Query to create content
  const [createContent] = useMutation(CREATE_CONTENT);

  // Query to update content
  const [updateContent] = useMutation(UPDATE_CONTENT);

  const [
    getContent,
    {
      data: contentData,
      loading: contentLoading,
      // error: adminError,
    },
  ] = useLazyQuery(GET_CONTENT, {fetchPolicy: 'no-cache'});

  // State input
  const [title, setTitle] = useState<string>('');
  const isTitleValid = useMemo(
    () => validateField(title, emptyFieldConstraint),
    [title, validateField],
  );

  const [resourceUrl, setResourceUrl] = useState<string>('');
  const isResourceUrlValid = useMemo(
    () => validateField(resourceUrl, emptyFieldConstraint),
    [resourceUrl, validateField],
  );

  const [resourceStatus, setResourceStatus] = useState<string>(
    ResourceStatus.PUBLIC,
  );

  const [imageUploaded, setImageUploaded] = useState('');
  const onDrop = useCallback((picture: any, base64: any) => {
    setImageUploaded(base64[0]);
  }, []);

  const [selectedPathologies, setSelectedPathologies] = useState<
    {value: number; label: string}[]
  >([]);

  const [description, setDescription] = useState<string>('');

  // State error
  const [globalError, setGlobalError] = useState<boolean>(false);

  const {
    data: pathologiesData,
    // loading: pathologiesLoading,
    error: pathologiesError,
  } = useGetPathologies({availableQuestionnairesOnly: false}, 100);

  const pathologies = useMemo<PathologyResponse[]>(
    () => pathologiesData?.pathologies.items ?? [],
    [pathologiesData],
  );

  // State for manage errors
  const initialState = {
    errors: {
      title: false,
      resourceUrl: false,
      pathologiesLength: false,
    },
  };

  // Init reducer
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (pathologiesError) {
      setGlobalError(true);
    }
  }, [pathologies, pathologiesError]);

  // Check if is editing
  useEffect(() => {
    if (contentId) {
      getContent({
        variables: {id: contentId},
      });
    }
  }, [getContent, contentId]);

  // If there is the content id populate the fields
  useEffect(() => {
    if (contentData?.resource) {
      setTitle(contentData?.resource?.title);
      setResourceUrl(contentData?.resource?.url);
      setDescription(contentData?.resource?.description);
      setSelectedPathologies(
        contentData?.resource?.pathologies?.map((pathology: any) => {
          return {
            value: pathology.id,
            label: pathology.name,
          };
        }) || [],
      );
      setResourceStatus(
        contentData?.resource?.isPublic
          ? ResourceStatus.PUBLIC
          : ResourceStatus.PRIVATE,
      );
    }
  }, [contentData]);

  // Create a new resource to server or update it
  const createOrUpdateContent = useCallback(() => {
    let filter: any = {
      title,
      description,
      url: resourceUrl,
      imageBase64: imageUploaded ? imageUploaded : undefined,
      pathologiesId:
        resourceStatus === ResourceStatus.PUBLIC
          ? []
          : selectedPathologies.map(pathology => pathology.value),
      isPublic: resourceStatus === ResourceStatus.PUBLIC ? true : false,
    };

    if (contentId) {
      filter.id = Number(contentId);
      updateContent({variables: {input: filter}, errorPolicy: 'all'})
        .then(response => {
          if (response.errors) {
            setAlert({
              type: 'ERROR',
              message:
                t('AdminContentsDetail.updateError') +
                ': ' +
                t(
                  `ErrorStrings.${response?.errors?.[0].extensions?.extra?.[0].field}`,
                ) +
                ' ' +
                t(
                  `ErrorStrings.${response?.errors?.[0].extensions?.extra?.[0].errorType}`,
                ),
            });
          } else {
            setAlert({
              type: 'SUCCESS',
              message: t('AdminContentsDetail.updateSuccess'),
            });
            goToAdminContents();
          }
        })
        .catch(() => {
          setAlert({
            type: 'ERROR',
            message: t('AdminContentsDetail.updateError'),
          });
        });
    } else {
      createContent({variables: {input: filter}, errorPolicy: 'all'})
        .then(response => {
          if (response.errors) {
            setAlert({
              type: 'ERROR',
              message:
                t('AdminContentsDetail.createError') +
                ': ' +
                t(
                  `ErrorStrings.${response?.errors?.[0].extensions?.extra?.[0].field}`,
                ) +
                ' ' +
                t(
                  `ErrorStrings.${response?.errors?.[0].extensions?.extra?.[0].errorType}`,
                ),
            });
          } else {
            setAlert({
              type: 'SUCCESS',
              message: t('AdminContentsDetail.createSuccess'),
            });
            goToAdminContents();
          }
        })
        .catch(() => {
          setAlert({
            type: 'ERROR',
            message: t('AdminContentsDetail.createError'),
          });
        });
    }
  }, [
    title,
    description,
    resourceUrl,
    imageUploaded,
    selectedPathologies,
    resourceStatus,
    contentId,
    updateContent,
    setAlert,
    t,
    createContent,
    goToAdminContents,
  ]);

  const isFormComplete = useMemo(() => {
    if (!contentId && !imageUploaded) {
      return false;
    }
    return !!(title && resourceUrl);
  }, [contentId, imageUploaded, title, resourceUrl]);

  // Save button
  const cta: ButtonProp = {
    label: t('AdminQuestionnaireDetail.headerButton.save'),
    disabled:
      !!Object.values(state.errors).find(value => value) || !isFormComplete,
    onClick: createOrUpdateContent,
  };

  // Add breadcrumb
  useEffect(() => {
    updateBreadCrumbItems([
      {
        label: t('AdminContents.heading'),
        onClick: goToAdminContents,
      },
      {
        label: t('AdminContents.contentDetail'),
        onClick: noop,
      },
    ]);
  }, [updateBreadCrumbItems, t, goToAdminContents]);

  if (globalError) {
    return (
      <Admin title={t('ResultQuestionnaire.generalError')}>
        <></>
      </Admin>
    );
  }

  // Style for multi select component
  const multiSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      borderColor: state.errors.pathologiesLength ? '#E01433' : '#DDDEDE',
      backgroundColor: state.errors.pathologiesLength ? '#E014332B' : '#FFFFFF',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #DDDEDE',
      },
    }),
    input: (styles: any) => ({
      ...styles,
      height: 39.04,
      margin: 0,
      fontFamily: 'Helvetica-AZ',
      fontSize: 14,
      fontWeight: 400,
    }),
    placeholder: (styles: any) => ({
      ...styles,
      fontFamily: 'Helvetica-AZ',
      fontSize: 14,
      color: '#A9A9A9',
      fontWeight: 400,
    }),
    multiValue: (styles: any) => ({
      ...styles,
      fontFamily: 'Helvetica-AZ',
      fontSize: 14,
      color: '#FFFFFF',
      fontWeight: 400,
      backgroundColor: '#A15D84',
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      fontFamily: 'Helvetica-AZ',
      fontSize: 14,
      color: '#FFFFFF',
      fontWeight: 400,
    }),
    menuList: (styles: any) => ({
      ...styles,
      fontFamily: 'Helvetica-AZ',
      fontSize: 14,
      color: '#3F4444',
      fontWeight: 400,
    }),
    option: (styles: any) => ({
      ...styles,
      backgroundColor: 'none',
      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#A15D84',
      },
    }),
  };

  return (
    <Admin title={t('AdminContents.contentDetail')} breadcrumb ctaPrimary={cta}>
      <div className="az-admin-content-detail">
        {contentLoading && (
          <Spinner color="gray" className="az-admin-contents__spinner" />
        )}
        {!contentLoading && (
          <>
            <div className="az-admin-content-detail__row-wrapper">
              <LabeledInput
                label={t('AdminContentsDetail.title.label')}
                placeholder={t('AdminContentsDetail.title.placeholder')}
                value={title}
                onChange={setTitle}
                className="w-4/12"
                maxLength={120}
                error={state.errors.title}
                onBlur={() => {
                  dispatch({
                    type: StateActionType.UPDATE_ERROR,
                    payload: {input: 'title', value: !isTitleValid},
                  });
                }}
              />
              <LabeledInput
                label={t('AdminContentsDetail.url.label')}
                placeholder={t('AdminContentsDetail.url.placeholder')}
                value={resourceUrl}
                onChange={setResourceUrl}
                className="w-4/12"
                error={state.errors.resourceUrl}
                onBlur={() => {
                  dispatch({
                    type: StateActionType.UPDATE_ERROR,
                    payload: {input: 'resourceUrl', value: !isResourceUrlValid},
                  });
                }}
              />
              <div className="w-4/12 self-end mb-2">
                <InputLabel
                  label={t('AdminContentsDetail.contentType.type')}
                  className="az-admin-content-detail__radio-title"
                />
                <div className="flex">
                  <div className="mr-20">
                    <input
                      type="radio"
                      value={ResourceStatus.PUBLIC}
                      name="privacy"
                      checked={resourceStatus === ResourceStatus.PUBLIC}
                      onChange={e => {
                        setResourceStatus(e.target.value);
                      }}
                    />
                    <span className="az-admin-content-detail__radio-label">
                      {t('AdminContentsDetail.contentType.isPublic')}
                    </span>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value={ResourceStatus.PRIVATE}
                      name="privacy"
                      checked={resourceStatus === ResourceStatus.PRIVATE}
                      onChange={e => {
                        setResourceStatus(e.target.value);
                      }}
                    />
                    <span className="az-admin-content-detail__radio-label">
                      {t('AdminContentsDetail.contentType.isPrivate')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="az-admin-content-detail__row-wrapper">
              <div className="w-4/12 flex-col">
                <InputLabel label={t('AdminContentsDetail.image.label')} />
                <ImageUploader
                  withIcon={true}
                  buttonText={t('AdminManageDetail.image.button')}
                  onChange={onDrop}
                  imgExtension={['.jpg', '.png']}
                  maxFileSize={2097152}
                  fileSizeError={t('AdminManageDetail.image.errorTooBig')}
                  fileTypeError={t('AdminManageDetail.image.errorNotSupported')}
                  singleImage
                  withLabel={false}
                  withPreview
                  buttonClassName="az-admin-manage-detail__button"
                />
              </div>
              <div className="w-4/12 flex-col">
                <InputLabel
                  label={t('MedicalCentersDetail.pathologiesLabel')}
                  className="mb-2"
                  color={'dark'}
                  error={state.errors.pathologiesLength}
                />
                <Select
                  placeholder={'Seleziona una o più patologie'}
                  isMulti
                  name="pathologies"
                  options={pathologies.map(item => {
                    return {label: item.name, value: item.id};
                  })}
                  styles={multiSelectStyles}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={
                    resourceStatus === ResourceStatus.PRIVATE
                      ? selectedPathologies
                      : []
                  }
                  isDisabled={resourceStatus === ResourceStatus.PUBLIC}
                  onChange={inputPathologies =>
                    setSelectedPathologies(
                      inputPathologies.map(item => {
                        return {label: item.label, value: item.value};
                      }),
                    )
                  }
                />
              </div>
              <div className="w-4/12 flex-col"></div>
            </div>
            <div className="az-admin-content-detail__row-wrapper">
              <div className="w-8/12 flex-col">
                <LabeledTextarea
                  labelClassName="mb-2"
                  label={t('AdminContentsDetail.description.label')}
                  onChange={setDescription}
                  placeholder={t('AdminContentsDetail.description.placeholder')}
                  value={description}
                  nRows={4}
                  maxLength={300}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Admin>
  );
};

export default React.memo(AdminContentsDetail);
