import {combineReducers} from 'redux';

import {AuthenticationActions, authenticationReducer} from '../authentication';
import {breadCrumbReducer} from '../breadCrumb';
import {questionnaireResultReducer} from '../questionnaireResult';

export const rootReducer = combineReducers({
  authentication: authenticationReducer,
  breadCrumb: breadCrumbReducer,
  questionnaireResult: questionnaireResultReducer,
});

export const appReducer = (state: any, action: any) => {
  if (action.type === AuthenticationActions.loginSuccess) {
    return rootReducer(
      {
        ...state,
        authentication: state.authentication,
      },
      action,
    );
  }
  return rootReducer(state, action);
};
