import React, {useMemo} from 'react';
import classNames from 'classnames';
import {useTranslations} from '@vidiemme/react-i18n';

//Others
import {UserStatus} from '../../../store/api';
import {QuestionnaireStatus} from '../../../store/questionnaire';
import {useBEMClass} from '../../../hooks/use-bem-class';

//Component namespace
import {IProps} from './interfaces';
import './StatusLabel.scss';

const StatusLabel = ({status, className, isAdmin = false}: IProps) => {
  //Helpers
  const {t} = useTranslations();
  const BEMClass = useBEMClass();

  const statusColorModifiers = useMemo(
    () => ({
      [BEMClass('az-status-label', '', 'active')]:
        status === UserStatus.ENABLED ||
        status === QuestionnaireStatus.COMPLETED,
      [BEMClass('az-status-label', '', 'inactive')]:
        status === UserStatus.DISABLED || status === QuestionnaireStatus.DRAFT,
    }),
    [status, BEMClass],
  );

  const statusTextMap = useMemo(
    () =>
      new Map<UserStatus | QuestionnaireStatus, string>()
        .set(
          UserStatus.DISABLED,
          isAdmin
            ? t('AdminManageDetail.status.disable')
            : t(`StatusLabel.${UserStatus.DISABLED.toLowerCase()}`),
        )
        .set(
          UserStatus.ENABLED,
          isAdmin
            ? t('AdminManageDetail.status.enable')
            : t(`StatusLabel.${UserStatus.ENABLED.toLowerCase()}`),
        )
        .set(QuestionnaireStatus.DRAFT, t(`StatusLabel.draft`))
        .set(QuestionnaireStatus.COMPLETED, t(`StatusLabel.completed`)),
    [t, isAdmin],
  );

  const statusText = useMemo(
    () => statusTextMap.get(status),
    [status, statusTextMap],
  );

  return (
    <div
      className={classNames(
        'az-status-label',
        statusColorModifiers,
        className,
      )}>
      <span>{statusText}</span>
    </div>
  );
};

export default StatusLabel;
