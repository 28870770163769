import {useMutation, useQuery} from '@apollo/client';
import {UserCreateInput, UserUpdateInput} from '../api';
import {AdminFilter, AdminPageResponse} from './interface';
import {
  CREATE_ADMIN,
  DELETE_USER,
  GET_ADMIN,
  GET_ADMINS,
  UPDATE_ADMIN,
} from './queries';

export const useGetAdmins = (
  input: AdminFilter | object,
  limit = 5,
  offset = 0,
) =>
  useQuery<AdminPageResponse>(GET_ADMINS, {
    variables: {
      input,
      limit,
      offset,
    },
  });

export const useGetAdmin = (id: number) =>
  useQuery(GET_ADMIN, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

export const useCreateAdmin = (input: UserCreateInput) =>
  useMutation(CREATE_ADMIN, {
    variables: {
      input,
    },
    errorPolicy: 'all',
  });

export const useUpdateAdmin = (admin: UserUpdateInput) =>
  useMutation(UPDATE_ADMIN, {
    variables: {
      input: admin,
    },
  });

export const useDeleteUser = () =>
  useMutation<{deleteUser: boolean}>(DELETE_USER);
