import React from 'react';
import {useTranslations} from '@vidiemme/react-i18n';

// Components
import {Base} from '../../templates/Base';
import {Link} from '../../atoms/Link';

// Style
import './TermsAndConditions.scss';

const TermsAndConditions = () => {
  const {t} = useTranslations();

  return (
    <Base className="az-terms-and-conditions">
      <h1 className="az-terms-and-conditions__title">
        {t('TermsAndConditions.title')}
      </h1>
      <p className="az-terms-and-conditions__content">
        Dichiaro di aver letto e compreso l'informativa e do il consenso al
        trattamento da parte di AstraZeneca SpA: Informativa policy.
        <br />
        1. All’invio di comunicazioni elettroniche mediante posta elettronica
        per l'invio di informazioni inerenti alle iniziative, anche
        promozionali, di AstraZeneca indicate nell’informativa
        <br />
        2. All’uso di sistemi automatizzati di chiamata senza l’intervento di un
        operatore mediante sms o telefono cellulare, per l'invio di informazioni
        inerenti alle iniziative, anche promozionali, di AstraZeneca indicate
        nell’informativa
        <br />
        3. Al trattamento da parte di AstraZeneca SpA dei miei dati personali
        per la definizione del mio profilo professionale al fine di individuare
        le principali sezioni ed aree di interesse, secondo le modalità
        descritte nell’informativa
        <br />
        4. Al trattamento da parte di AstraZeneca SpA dei miei dati personali
        (ad esempio: dati anagrafici, codice fiscale, indirizzo, recapiti
        telefonici, indirizzo e-mail e dati inerenti la Mia attività
        professionale), per lo svolgimento delle attività di informazione
        scientifica o di informazione di natura farmaco-economica e commerciale,
        nonché per iniziative di collaborazione, ricerca, divulgazione, ricerche
        di mercato, formazione o promozione, secondo le modalità descritte
        nell’informativa in questione, ivi inclusa la comunicazione a terzi e il
        trasferimento da parte di AstraZeneca S.p.A., con qualsiasi forma o
        mezzo, dei miei dati personali anche verso paesi non appartenenti
        all’Unione Europea per tali finalità. Il consenso è completamente
        volontario e facoltativo e può essere revocato in qualsiasi momento
        su&nbsp;
        <Link
          className="az-terms-and-conditions__link"
          href="http://subjectrequest.astrazeneca.com">
          http://subjectrequest.astrazeneca.com.
        </Link>
        &nbsp;In caso di mancato consenso o sua revoca, AstraZeneca non potrà
        avviare alcuna collaborazione o fornire informazioni sui propri prodotti
        e attività.
      </p>
    </Base>
  );
};

export default React.memo(TermsAndConditions);
