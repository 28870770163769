import React from 'react';
import classNames from 'classnames';

// Components
import {IconSVG} from '../IconSVG';

// Interface
import {IProps} from './interface';

// Style
import './ButtonText';

const ButtonText = ({
  label,
  className,
  onClick,
  iconLeft = null,
  iconRight = null,
  disabled = false,
}: IProps): JSX.Element => (
  <button
    className={classNames('az-button-text', className)}
    onClick={onClick}
    disabled={disabled}>
    {iconLeft && <IconSVG {...iconLeft} />}
    {label}
    {iconRight && <IconSVG {...iconRight} />}
  </button>
);

export default React.memo(ButtonText);
