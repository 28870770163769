import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import {useTranslations} from '@vidiemme/react-i18n';
import Collapse from '@kunukn/react-collapse';

// Others
import {noop} from '../../../utils/noop';
import truncateText from '../../../utils/truncateText';
import {emptyFieldConstraint, useValidator} from '../../../hooks/validator';
import {
  AnswerInput,
  useStructureQuestionnaire,
} from '../../../store/structureQuestionnaire';
import {ModalContext} from '../../../store/modal/context';
import {ModalContent} from '../../../store/modal/interfaces';

// Components
import {ButtonText} from '../../atoms/ButtonText';
import {AnswerChoice} from '../../molecules/AnswerChoice';
import {Button} from '../../atoms/Button';
import {IconButton} from '../../molecules/iconButton';
import {LabeledInput} from '../../molecules/LabeledInput';

// Types
import {IProps as IconProps} from '../../atoms/IconSVG/interfaces';

// Interface
import {IProps} from './interface';

// Style
import './QuestionAccordion.scss';

const QuestionAccordion = ({
  question,
  className,
  nextQuestions = [],
  onDelete = noop,
  onUpdate = noop,
  publishing = false,
  disabled,
}: IProps) => {
  const {t} = useTranslations();
  const {validateField} = useValidator();
  const {createAnswer, findAnswerIdx} = useStructureQuestionnaire();
  const {setValue: setModal} = useContext(ModalContext);

  // State
  const [title, setTitle] = useState<string>(question.title);
  const [description, setDescription] = useState<string>(question.description);
  const [reference, setReference] = useState<string>(question.reference);
  const [toggle, setToggle] = useState<boolean>(false);

  // State for maintain the arrays of answers
  const [answers, setAnswers] = useState<Partial<AnswerInput>[]>(
    question.answers,
  );

  // Error state
  const [hasTitleError, setHasTitleError] = useState<boolean>(false);
  const [hasAnswerError, setHasAnswerError] = useState<boolean>(false);

  const handleSetAnswerError = useCallback((value: boolean) => {
    setHasAnswerError(value);
  }, []);

  // Return true if title input is valid
  const isTitleValid = useMemo(
    () => validateField(title, emptyFieldConstraint),
    [title, validateField],
  );

  const addIcon = useMemo<IconProps>(
    () => ({
      icon: 'icon-add',
      size: 23,
      className: 'text-white',
    }),
    [],
  );
  const accordionIcon = useMemo<IconProps>(
    () => ({
      icon: 'icon-arrow',
      size: 13,
      className: 'text-gray-dark mb-1 mr-2',
    }),
    [],
  );

  // Give animation to accordion icon
  const accordionIconAnimationStyle = useMemo(
    () =>
      toggle
        ? 'transform -rotate-180 duration-500 -translate-x-1.5 ease-in-out'
        : '',
    [toggle],
  );

  // Set question title from user input
  const questionTitle = useMemo(
    () =>
      title
        ? title
        : `${t('Accordion.newTitle', {questionOrder: question.order})}`,
    [title, t, question],
  );

  // Append new answer option on click
  const addNewAnswerToQuestions = useCallback(() => {
    const nextAnswer1 = createAnswer({});
    const nextAnswer2 = createAnswer({});

    if (answers.length > 0) {
      setAnswers([...answers, nextAnswer1]);
    } else {
      setAnswers([...answers, nextAnswer1, nextAnswer2]);
    }
  }, [createAnswer, answers]);

  // Show / hide accordion content
  const toggleAccordion = useCallback(() => {
    setToggle(!toggle);
  }, [toggle]);

  //Notify parent that question data are changed
  //Notification should be executed ONLY when values are different,
  useEffect(() => {
    //If you remove this checks, an infinite loop will occur
    //cause question object instance will always be different from the previous one
    if (
      question.title !== title ||
      question.description !== description ||
      question.reference !== reference
    ) {
      onUpdate({
        ...question,
        description,
        reference,
        title,
      });
    }
  }, [onUpdate, question, title, description, reference, answers]);

  const updateAnswers = useCallback(
    (answer: Partial<AnswerInput>, replace: boolean = false) => {
      const answerIdx = findAnswerIdx(answer, answers);

      if (answerIdx >= 0) {
        replace
          ? answers.splice(answerIdx, 1, answer)
          : answers.splice(answerIdx, 1);

        onUpdate({
          ...question,
          answers: [...answers],
        });
      }
    },
    [answers, findAnswerIdx, onUpdate, question],
  );

  const updateAnswer = useCallback(
    (answer: Partial<AnswerInput>) => updateAnswers(answer, true),
    [updateAnswers],
  );

  const removeAnswerFromQuestion = useCallback(
    (answer: Partial<AnswerInput>) => {
      if (answers.length < 3) {
        // alert(t('AdminQuestionnaires.alert.cantDeleteAnswer'));
        setModal({
          content: ModalContent.DELETE_MODAL,
          props: {
            modalTitle: 'DeleteModal.title',
            body: 'AdminQuestionnaires.alert.cantDeleteAnswer',
            isAlert: true,
            callback: noop,
          },
        });
        return;
      }
      updateAnswers(answer);
    },
    [answers.length, updateAnswers, setModal],
  );

  // Validate input
  useEffect(() => {
    if (publishing) {
      setHasTitleError(!isTitleValid);
    }
  }, [publishing, isTitleValid]);

  return (
    <Collapse
      isOpen={toggle}
      collapseHeight="50px"
      className={classNames('az-question-accordion', className, {
        'az-question-accordion--error': hasTitleError || hasAnswerError,
      })}>
      <div className="az-question-accordion__header">
        <h2 className="az-question-accordion__title">
          {truncateText(questionTitle, 100)}
        </h2>
        <div className="az-question-accordion__header-actions">
          {disabled ? (
            <></>
          ) : (
            <ButtonText
              label={t('Accordion.deleteQuestion')}
              onClick={onDelete}
              className="az-question-accordion__button-text"
            />
          )}
          <IconButton
            icon={accordionIcon}
            onClick={toggleAccordion}
            className={accordionIconAnimationStyle}
          />
        </div>
      </div>
      <div className="az-question-accordion__inputs">
        <div className="az-answer-choice__input">
          <LabeledInput
            label={t('Accordion.title.label', {questionOrder: question.order})}
            placeholder={t('Accordion.title.placeholder')}
            value={title}
            error={hasTitleError}
            onChange={setTitle}
            onFocus={() => setHasTitleError(false)}
            disabled={disabled}
            maxLength={140}
          />
        </div>
        <div className="az-answer-choice__input">
          <LabeledInput
            label={t('Accordion.description.label')}
            placeholder={t('Accordion.description.placeholder')}
            value={description}
            onChange={setDescription}
            disabled={disabled}
          />
        </div>
        <div className="az-answer-choice__input">
          <LabeledInput
            label={t('Accordion.reference.label')}
            placeholder={t('Accordion.reference.placeholder')}
            value={reference}
            onChange={setReference}
            disabled={disabled}
          />
        </div>
      </div>
      {answers.map((answer, idx) => (
        <AnswerChoice
          answer={answer}
          counter={idx + 1}
          key={idx}
          nextQuestions={nextQuestions}
          onDelete={removeAnswerFromQuestion}
          onUpdate={updateAnswer}
          publishing={publishing}
          className="az-question-accordion__answer"
          handleParentError={handleSetAnswerError}
          disabled={disabled}
        />
      ))}
      <div className="az-question-accordion__add-new-answer">
        {disabled || answers.length > 3 ? (
          <></>
        ) : (
          <Button
            label={t('Accordion.button')}
            rightIcon={addIcon}
            onClick={addNewAnswerToQuestions}
          />
        )}
      </div>
    </Collapse>
  );
};

export default React.memo(QuestionAccordion);
