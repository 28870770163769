import React, {useCallback, useContext, useMemo} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import classNames from 'classnames';
import {useHistory} from 'react-router-dom';

// Others
import {useAuthentication} from '../../../store/authentication';
import {ModalContext} from '../../../store/modal/context';
import {ModalContent} from '../../../store/modal/interfaces';
import truncateText from '../../../utils/truncateText';

// Navigation
import {AnonymousRoutes, useNavigation} from '../../../navigation';
import {AdminRoutes} from '../../../navigation/routes';

// Components
import {ButtonText} from '../../atoms/ButtonText';
import {Button} from '../../atoms/Button';

// Types
import {IProps as IconProp} from '../../atoms/IconSVG/interfaces';

// Interface
import {IProps} from './interface';

// Style
import './Sidebar.scss';

const Sidebar = ({src, adminFirstName, adminLastName}: IProps) => {
  const {t} = useTranslations();
  const history = useHistory();
  const {logOut, token, id: adminId} = useAuthentication();
  const {
    isMatchRoute,
    goToAdminSurveys,
    // goToAdminMMG,
    goToMedicalCenters,
    goToAdminPathologies,
    goToAdminContents,
    goToAdminManage,
    goToAdminProfile,
    // @REMOVE_PATIENTS_DATA
    // goToAdminKpi,
  } = useNavigation();

  const {setValue: setModal} = useContext(ModalContext);

  const initials = useMemo(
    () => adminFirstName.charAt(0) + adminLastName.charAt(0),
    [adminFirstName, adminLastName],
  );

  // Icons for sidebar buttons
  const iconSurveys = useMemo<IconProp>(
    () => ({
      icon: 'menu-icon-questionari',
      size: 17,
      className: 'az-sidebar__icon',
    }),
    [],
  );
  const iconMedicalCenters = useMemo<IconProp>(
    () => ({
      icon: 'menu-icon-centri',
      size: 17,
      className: 'az-sidebar__icon',
    }),
    [],
  );
  const iconContents = useMemo<IconProp>(
    () => ({
      icon: 'menu-icon-contenuti',
      size: 17,
      className: 'az-sidebar__icon',
    }),
    [],
  );
  // const iconMMG = useMemo<IconProp>(
  //   () => ({
  //     icon: 'menu-icon-mmg',
  //     size: 17,
  //     className: 'az-sidebar__icon',
  //   }),
  //   [],
  // );
  const iconAdmin = useMemo<IconProp>(
    () => ({
      icon: 'menu-icon-admin',
      size: 17,
      className: 'az-sidebar__icon',
    }),
    [],
  );
  const iconPathologies = useMemo<IconProp>(
    () => ({
      icon: 'home-icon-3',
      size: 17,
      className: 'az-sidebar__icon',
    }),
    [],
  );
  const iconKpi = useMemo<IconProp>(
    () => ({
      icon: 'icon_menu_kpi',
      size: 17,
      className: 'az-sidebar__icon',
    }),
    [],
  );

  const downloadList = useCallback(() => {
    fetch(`${process.env.REACT_APP_BASE_URL_REST}/doctors/excel/export`, {
      method: 'get',
      headers: new Headers({
        authorization: `Bearer ${token}`,
      }),
    })
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'MMG-abilitati.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert('Qualcosa è andato storto'));
  }, [token]);

  const onListDownload = useCallback(() => {
    setModal({
      content: ModalContent.DELETE_MODAL,
      props: {
        modalTitle: 'Sidebar.label.downloadModalTitle',
        body: 'Sidebar.label.downloadModalBody',
        isDownload: true,
        callback: downloadList,
      },
    });
  }, [setModal, downloadList]);

  // Logout and redirect to admin login
  const goToAdminLogin = () => {
    logOut();
    history.push(AnonymousRoutes.ADMIN_LOGIN);
  };

  return (
    <aside className="az-sidebar">
      <div
        className="az-sidebar__image-container"
        onClick={() => goToAdminProfile(adminId!)}>
        {!src ? (
          <div className="az-sidebar__image-wrapper">
            <div className="az-sidebar__admin-name-image">{initials}</div>
          </div>
        ) : (
          <img
            src={src}
            className="az-sidebar__profile-pic-wrapper"
            alt={t('Sidebar.altImage')}
          />
        )}
      </div>
      <h2 className="az-sidebar__admin-name">
        {truncateText(`${adminFirstName} ${adminLastName}`, 16)}
      </h2>
      <p className="az-sidebar__label">{t('Sidebar.label.role')}</p>
      <div className="az-sidebar__buttons-container">
        <ButtonText
          iconLeft={iconPathologies}
          label={t('AdminPathologies.heading')}
          onClick={goToAdminPathologies}
          className={classNames('az-sidebar__button', {
            'az-sidebar__button--selected': isMatchRoute(
              AdminRoutes.PATHOLOGIES,
            ),
          })}
        />
        <ButtonText
          iconLeft={iconSurveys}
          label={t('Sidebar.label.surveys')}
          onClick={goToAdminSurveys}
          className={classNames('az-sidebar__button', {
            'az-sidebar__button--selected': isMatchRoute(
              AdminRoutes.QUESTIONNAIRES,
            ),
          })}
        />
        <ButtonText
          iconLeft={iconMedicalCenters}
          label={t('Sidebar.label.medicalCenters')}
          onClick={goToMedicalCenters}
          className={classNames('az-sidebar__button', {
            'az-sidebar__button--selected': isMatchRoute(
              AdminRoutes.MEDICAL_CENTERS,
            ),
          })}
        />
        <ButtonText
          iconLeft={iconContents}
          label={t('Sidebar.label.contents')}
          onClick={goToAdminContents}
          className={classNames('az-sidebar__button', {
            'az-sidebar__button--selected': isMatchRoute(AdminRoutes.CONTENTS),
          })}
        />
        {/* <ButtonText
          iconLeft={iconMMG}
          label={t('Sidebar.label.mmg')}
          onClick={goToAdminMMG}
          className={classNames('az-sidebar__button', {
            'az-sidebar__button--selected': isMatchRoute(AdminRoutes.MMG),
          })}
        /> */}
        <ButtonText
          iconLeft={iconAdmin}
          label={t('Sidebar.label.admin')}
          onClick={goToAdminManage}
          className={classNames('az-sidebar__button', {
            'az-sidebar__button--selected': isMatchRoute(
              AdminRoutes.MANAGE_ADMIN,
            ),
          })}
        />
        {/* @REMOVE_PATIENTS_DATA */}
        {/* <ButtonText
          iconLeft={iconKpi}
          label={t('Sidebar.label.kpi')}
          onClick={goToAdminKpi}
          className={classNames('az-sidebar__button', {
            'az-sidebar__button--selected': isMatchRoute(AdminRoutes.ADMIN_KPI),
          })}
        /> */}
        <Button
          label={t('Sidebar.label.downloadMMGList')}
          onClick={onListDownload}
          className="az-sidebar__button-download"
        />
      </div>
      <div className="mx-10 flex">
        <ButtonText
          label={t('Sidebar.label.profile')}
          onClick={() => goToAdminProfile(adminId!)}
          className="az-sidebar__profile"
        />
        <ButtonText
          label={t('Sidebar.label.logout')}
          onClick={goToAdminLogin}
          className="az-sidebar__logout"
        />
      </div>
    </aside>
  );
};

export default React.memo(Sidebar);
