import React, {useCallback} from 'react';
import {toast, Slide} from 'react-toastify';
import {IProps} from './interfaces';
import {IconSVG} from '../../components/atoms/IconSVG';

const commonProps = {
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  transition: Slide,
};

export const useToast = () => {
  /**
   * @brief Show error
   */
  const showErrorToast = useCallback((message: IProps) => {
    toast.error(
      <span className="toastify__icon-container">
        <IconSVG icon="icon-error" size={17} className="toastify__icon" />
        {message}
      </span>,
      {
        position: 'top-center',
        ...commonProps,
      },
    );
  }, []);

  /**
   * @brief Show message
   */
  const showSuccessToast = useCallback((message: IProps) => {
    toast.success(
      <span className="toastify__icon-container">
        <IconSVG icon="icon-checked" size={20} className="toastify__icon" />
        {message}
      </span>,
      {
        position: 'top-center',
        ...commonProps,
      },
    );
  }, []);

  return {
    showErrorToast,
    showSuccessToast,
  };
};
