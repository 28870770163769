import React, {useCallback, useMemo} from 'react';
import {matchPath} from 'react-router';
import {
  AnonymousRoutes,
  MMGRoutes,
  AuthenticatedRoutes,
} from '../../navigation/routes';

// Id script
const TEALIUM_SCRIPT_ID = 'az-tealium-script';

export const useTealium = () => {
  const addTealiumScript = useCallback(() => {
    let a: any = `//tags.tiqcdn.com/utag/astrazeneca/it-eazyref/${process.env.REACT_APP_TEALIUM_ENV}/utag.js`;
    let b: any = document;
    let c: any = 'script';
    let d: any = b.createElement(c);
    d.id = TEALIUM_SCRIPT_ID;
    d.src = a;
    d.type = 'text/java' + c;
    d.async = true;
    a = b.getElementsByTagName(c)[0];
    a.parentNode.insertBefore(d, a);
  }, []);

  const getTealiumScript = useCallback(() => {
    return document.getElementById(TEALIUM_SCRIPT_ID);
  }, []);

  const tealiumScriptIsMounted = useCallback(() => {
    const script = getTealiumScript();
    return script && script.parentNode;
  }, []);

  const removeTealiumScript = useCallback(() => {
    if (tealiumScriptIsMounted()) {
      const script = getTealiumScript();
      script!.parentNode!.removeChild(script!);
    }
  }, []);

  const sendPageViewEvent = useCallback((utagData: {[k: string]: string}) => {
    if (tealiumScriptIsMounted()) {
      removeTealiumScript();
    }

    (window as any).utag_data = {
      page_section: '',
      page_subsection: '',
      visitor_time: '',
      visitor_azid: '',
      core_target_country: 'it',
      ...utagData,
    };

    addTealiumScript();
  }, []);

  const pathNameToPageName: {[k: string]: string} = useMemo(
    () => ({
      [AnonymousRoutes.LOGIN_MMG]: 'MMG Login',
      [AnonymousRoutes.MAIN]: 'Homepage',
      [AnonymousRoutes.TERMS_AND_CONDITIONS]: 'Terms and conditions',
      [AuthenticatedRoutes.MMG_FIRST_ACCESS]: 'MMG Complete registration',
      [MMGRoutes.QUESTIONNAIRE]: 'Questionnaire',
      [MMGRoutes.NEW_QUESTIONNAIRE]: 'Questionnaire New',
      [MMGRoutes.ACTIVE_QUESTIONNAIRE]: 'Questionnaire Active',
      [MMGRoutes.RESULT_QUESTIONNAIRE]: 'Questionnaire Result',
      [MMGRoutes.CENTERS_MAP]: 'Centers Map',
      [MMGRoutes.RESOURCES]: 'Resources',
      [MMGRoutes.MMG_PROFILE]: 'MMG Profile',
      [MMGRoutes.PRIVACY]: 'Privacy',
    }),
    [],
  );

  const resolvePathNameToPageName = useCallback(
    (pathname: string): string | false => {
      const matchedPath = Object.keys(pathNameToPageName).find(path => {
        const match = matchPath(pathname, {
          path: path,
          exact: true,
          strict: false,
        });

        if (match === null) {
          return false;
        }

        return true;
      });

      if (matchedPath) {
        return pathNameToPageName[matchedPath];
      }

      return false;
    },
    [],
  );

  return {
    sendPageViewEvent,
    resolvePathNameToPageName,
  };
};
