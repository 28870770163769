import React from 'react';
import {Route, RouteProps} from 'react-router-dom';

import {useAuthentication} from '../../store/authentication';
import {Authenticated} from '../index';

const Guest = (props: RouteProps) => {
  const {isLoggedIn} = useAuthentication();

  if (isLoggedIn) {
    return <Authenticated {...props} />;
  } else {
    return <Route {...props} />;
  }
};

export default React.memo(Guest);
