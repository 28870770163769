export type BreadCrumbItem = {
  label: string;
  onClick: () => void;
};

export interface BreadCrumbState {
  items: BreadCrumbItem[];
}

export enum BreadCrumbActions {
  UPDATE_BREAD_CRUMB = 'updateBreadCrumb',
}

export type BreadCrumbAction = {
  type: BreadCrumbActions.UPDATE_BREAD_CRUMB;
  payload: BreadCrumbItem[];
};
