import {AuthenticationState} from './types.d';

export const selectorIsLoggedIn = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => Boolean(authentication.token) && Boolean(authentication.role);

export const selectorToken = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication.token;

export const selectorError = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication.error;

export const selectorIsFetching = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication.isFetching;

export const selectorRole = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication.role;

export const selectorId = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication.id;

export const selectorOwaId = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication.owaId;
