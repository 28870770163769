import {PageInfoResponse, UserResponse} from '../api';
import {PathologyResponse} from '../pathologies';
import {QuestionType} from './types';

export enum QuestionnaireResult {
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE',
}

export enum QuestionnaireStatus {
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
}

export type QuestionnaireFilter = {
  id?: number;
  pathologyId?: number;
  status?: QuestionnaireStatus[];
  result?: QuestionnaireResult;
  patientId?: number;
};

export type ResultDetailResponse = {
  totalAnswers: number;
  highRiskAnswers: number;
  mediumHighRiskAnswers: number;
  mediumLowRiskAnswers: number;
  lowRiskAnswers: number;
};

export interface QuestionnaireResponse {
  id: string;
  pathology?: PathologyResponse;
  subTitle?: string;
  instructions?: string;
  maxScore?: number;
  limitScore?: number;
  limitScore2?: number;
  score?: number;
  questions: QuestionType[];
  status: QuestionnaireStatus;
  result?: QuestionnaireResult;
  resultDetail?: ResultDetailResponse;
  patient: UserResponse;
  doctor: UserResponse;
  createdAt?: string;
}

export interface QuestionnairePageResponse {
  items: QuestionnaireResponse[];
  info: PageInfoResponse;
}

//Interface for questionnaire as mmg
export interface QuestionnaireStructureResponse {
  pathology?: PathologyResponse;
  subTitle?: string;
  instructions?: string;
  maxScore?: number;
  limitScore?: number;
  limitScore2?: number;
  questions: QuestionType[];
}

export interface QuestionnaireInput {
  id?: number;
  pathologyId: number;
  subTitle: string;
  instructions: string;
  maxScore?: number;
  limitScore: number;
  limitScore2: number;
  score: number;
  questions: QuestionType[];
  status: QuestionnaireStatus;
  doctorId: number;
}
