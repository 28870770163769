import React from 'react';

// Others
import {useAuthentication} from '../../../store/authentication';
import {Role} from '../../../store/api';

// Components
import {NikeLogo} from '../../atoms/NikeLogo';
import {Header} from '../../organisms/Header';
import {Footer} from '../../molecules/Footer';

//Component namespace
import {IProps} from './interfaces';
import './Homepage.scss';

const Homepage = ({children, isAuth}: IProps): JSX.Element => {
  const {role} = useAuthentication();
  const heroBannerSlot = children?.filter(
    child => child?.props?.slot === 'hero-banner',
  );
  const defaultSlot = children?.filter(child => !child?.props?.slot);

  return (
    <div className="az-page az-page--homepage">
      <div className="az-page__heading">
        <Header
          className="az-page__header"
          isAuth={isAuth}
          isHomepage
          isTransparent={true}
          isDoctor={role === Role.ROLE_DOCTOR}
        />
        {heroBannerSlot && (
          <div className="az-page__container az-page__container--heading">
            {heroBannerSlot}
          </div>
        )}
        <NikeLogo className="az-page__heading-nike-logo" color="white" />
      </div>
      <main className="az-page__container az-page--main az-page__main">
        {defaultSlot}
      </main>
      <Footer className={'az-page__footer'} />
    </div>
  );
};

export default React.memo(Homepage);
