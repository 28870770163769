import React, {useCallback, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import Collapse from '@kunukn/react-collapse';
import {noop} from '../../../utils/noop';

// Components
import {ButtonText} from '../../atoms/ButtonText';
import {IconButton} from '../../molecules/iconButton';
import {InputLabel} from '../../atoms/InputLabel';

// Types
import {IProps as IconProps} from '../../atoms/IconSVG/interfaces';

// Interface
import {DropDownOption, IProps} from './interface';

// Style
import './Dropdown.scss';

const Dropdown = ({
  options = [],
  label,
  className,
  dropdownPlaceholder = '',
  isDisabled = false,
  collapseHeight = '45px',
  onChange = noop,
  error = false,
  handleParentError,
}: IProps) => {
  // State
  const [toggle, setToggle] = useState<boolean>(false);
  const [notSelected, setNotSelected] = useState<boolean>(false);

  const selectedOption = useMemo(
    () => options.find(option => option.selected),
    [options],
  );

  const dropdownIcon = useMemo<IconProps>(
    () => ({
      icon: 'icon-arrow',
      size: 13,
      className: 'text-gray-dark mt-1 mb-1 mr-3',
    }),
    [],
  );

  // Give animation to dropdown icon
  const dropdownIconAnimationStyle = useMemo(
    () =>
      toggle
        ? 'transform -rotate-180 duration-500 -translate-x-3 ease-in-out'
        : '',
    [toggle],
  );

  // Show / hide dropdown content
  const toggleDropdown = useCallback(() => {
    setToggle(!toggle);
    setNotSelected(false);
  }, [toggle]);

  // Close dropdown
  const closeDropdown = useCallback(() => setToggle(false), []);

  const onChangeHandler = useCallback(
    (option: DropDownOption<any>) => {
      setToggle(false);
      onChange({...option, selected: true});
    },
    [setToggle, onChange],
  );

  useEffect(() => {
    if (!selectedOption?.selected && error && handleParentError) {
      setNotSelected(true);
    }
  }, [handleParentError, error, selectedOption?.selected]);

  // Spread error to parent component
  useEffect(() => {
    if (error && handleParentError) {
      if (notSelected) {
        handleParentError(true);
      } else if (handleParentError) {
        handleParentError(false);
      }
    }
  }, [error, handleParentError, notSelected]);

  return (
    <>
      <InputLabel className="az-dropdown__label" label={label} color={'dark'} />
      <Collapse
        isOpen={toggle}
        collapseHeight={collapseHeight}
        className={classNames('az-dropdown', className, {
          'az-dropdown--disabled': isDisabled,
          'az-dropdown--error': notSelected && !isDisabled,
        })}>
        <div
          className="az-dropdown__select"
          onBlur={closeDropdown}
          onClick={toggleDropdown}>
          <h3 className="az-dropdown__value">
            {selectedOption?.label ?? dropdownPlaceholder}
          </h3>
          <IconButton
            icon={dropdownIcon}
            onClick={toggleDropdown}
            className={dropdownIconAnimationStyle}
          />
        </div>
        <div className="az-dropdown__option-list">
          {options.map((option, idx) => (
            <ButtonText
              key={idx}
              label={option.label}
              onClick={() => onChangeHandler(option)}
              className="az-dropdown__option"
            />
          ))}
        </div>
      </Collapse>
    </>
  );
};

export default React.memo(Dropdown);
