import {useMutation, useQuery} from '@apollo/client';

import {CREATE_PATHOLOGY, GET_PATHOLOGIES} from './queries';
import {PathologyFilter, PathologyInput} from './interfaces';

export const useGetPathologies = (
  input: PathologyFilter | object,
  limit = 5,
  offset = 0,
) =>
  useQuery(GET_PATHOLOGIES, {
    variables: {
      input,
      limit,
      offset,
    },
    fetchPolicy: 'no-cache',
  });

export const useCreatePathology = (input: PathologyInput) =>
  useMutation(CREATE_PATHOLOGY, {
    variables: {
      input,
    },
    errorPolicy: 'all',
  });
