import React from 'react';
import {RadioAnswer} from '../../molecules/RadioAnswer';
import './QuestionWithAnswers.scss';
import {IProps} from './interface';

const QuestionWithAnswers = ({
  question,
  onChange,
  selectOption,
  selectedOption,
  isDisabled = false,
}: IProps) => {
  return (
    <div className="az-active-questionnaire__titles-container">
      <h3 className="az-active-questionnaire__title">{question.title}</h3>
      <p className="az-active-questionnaire__subtitle">
        {question.description}
      </p>
      {question.answers.map(item => (
        <RadioAnswer
          key={item.id}
          value={item.id.toString()}
          answer={item.title}
          onChange={onChange}
          selectOption={selectOption}
          isChecked={selectedOption === item.id.toString()}
          isDisabled={isDisabled}
          riskColor={item.riskLevel}
        />
      ))}
    </div>
  );
};

export default React.memo(QuestionWithAnswers);
