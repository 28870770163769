import React, {useContext} from 'react';
import classNames from 'classnames';

//Alert Context
import {AlertContext} from '../../../store/alert/context';

// Others
import {useAuthentication} from '../../../store/authentication';
import {Role} from '../../../store/api';

// Components
import {Header} from '../../organisms/Header';
import {Footer} from '../../molecules/Footer';
import {Alert} from '../../molecules/Alert';

//Component namespace
import {IProps} from './interfaces';

// Style
import './Base.scss';

const Base = ({
  children,
  isAuth = false,
  isAdmin,
  className,
}: IProps): JSX.Element => {
  const {value: alert} = useContext(AlertContext);
  const {role} = useAuthentication();

  return (
    <div className="az-page-template az-page-template--base">
      {isAdmin ? null : (
        <Header
          isAuth={isAuth}
          className="az-page-template__header az-header--colored"
          isDoctor={role === Role.ROLE_DOCTOR}
        />
      )}
      <main className="az-page-template__main az-page-template__main--gray-light">
        <div className="az-page-template__container">
          {alert && (
            <div
              className={classNames('az-page-template__alert', {
                'az-page-template__admin-alert': isAdmin,
              })}>
              <Alert />
            </div>
          )}
          {children}
        </div>
      </main>
      <Footer className="az-page-template__footer" />
    </div>
  );
};

export default React.memo(Base);
