import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import {useCallback, useMemo} from 'react';
import {onError} from '@apollo/client/link/error';

import {AZStorage, STORAGE_KEY} from '../../utils/storage';
import {useAuthentication} from '../authentication';

const httpLink = new HttpLink({uri: process.env.REACT_APP_BASE_URL});

/**
 * @see https://www.apollographql.com/docs/react/networking/advanced-http-networking/
 */
const useAuthMiddleware = new ApolloLink((operation, forward) => {
  const token = AZStorage.getItem(STORAGE_KEY.TOKEN);
  // add the authorization to the headers
  operation.setContext(({headers = {}}) => ({
    headers: {
      ...headers,
      ...(token && token !== 'no-token'
        ? {authorization: `Bearer ${token}`}
        : {}),
    },
  }));

  return forward(operation);
});

// Function that return an ApolloLink to handle errors
// TODO best ways??
function createErrorLink(errorCallback: any) {
  return onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({message, locations, path}) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      errorCallback();
    }
  });
}

// Create Apollo client for the provider
function createApolloClient(errorCallback: any) {
  return new ApolloClient({
    link: useAuthMiddleware
      .concat(createErrorLink(errorCallback))
      .concat(httpLink),
    cache: new InMemoryCache({addTypename: false}),
    credentials: 'include',
  });
}

// Created a hook to use logout function
export const useApollo = () => {
  const {logOut} = useAuthentication();

  const handleError = useCallback(() => logOut(), [logOut]);

  const store = useMemo(() => createApolloClient(handleError), [handleError]);

  return store;
};

export * from './interfaces';
