import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import classNames from 'classnames';

// Others
import {useNavigation} from '../../../navigation';
import {
  QuestionnaireStructureStatus,
  useDeleteStructureQuestionnaire,
  useGetStructureQuestionnaires,
  usePublishStructureQuestionnaire,
} from '../../../store/structureQuestionnaire';
import {GET_STRUCTURE_QUESTIONNAIRES} from '../../../store/structureQuestionnaire/queries';
import {ModalContext} from '../../../store/modal/context';
import {ModalContent} from '../../../store/modal/interfaces';

// Components
import {Button} from '../../atoms/Button';
import {Spinner} from '../../atoms/Spinner';
import {LabeledInput} from '../../molecules/LabeledInput';
import {QuestionnaireCard} from '../../molecules/QuestionnaireCard';
import {Admin} from '../../templates/Admin';
// Types
import {IProps as IconProps} from '../../atoms/IconSVG/interfaces';
// Style
import './AdminQuestionnaires.scss';
import {AlertContext} from '../../../store/alert/context';

const AdminQuestionnaires = () => {
  const [searchValue, setSearchValue] = useState('');
  const {setValue: setModal} = useContext(ModalContext);
  const {t} = useTranslations();
  const {goToAdminSurveyDetail} = useNavigation();
  const {setValue: setAlert} = useContext(AlertContext);
  const {
    data: {structureQuestionnaires} = {structureQuestionnaires: {items: []}},
    error,
    loading,
    refetch,
  } = useGetStructureQuestionnaires();
  const [
    deleteQuestionnaire,
    {data: deleteData, loading: deleteLoading, error: deleteError},
  ] = useDeleteStructureQuestionnaire();
  const [
    publishQuestionnaire,
    {data: publishData, loading: publishLoading, error: publishError},
  ] = usePublishStructureQuestionnaire();

  const searchIcon = useMemo<IconProps>(
    () => ({
      icon: 'icon-search',
      size: 21,
      className: 'text-primary self-center mb-1',
    }),
    [],
  );

  const addIcon = useMemo<IconProps>(
    () => ({
      icon: 'icon-add',
      size: 23,
      className: 'text-white',
    }),
    [],
  );

  const goToAdminSurveyDetailHandler = useCallback(
    (questionnaireId?: number) => () => goToAdminSurveyDetail(questionnaireId),
    [goToAdminSurveyDetail],
  );

  const onDeleteQuestionnaire = useCallback(
    (id: number) => () => {
      deleteQuestionnaire({
        variables: {id},
        refetchQueries: [GET_STRUCTURE_QUESTIONNAIRES],
      })
        .catch(() => {
          setAlert({type: 'ERROR', message: 'Operazione fallita'});
        })
        .then(data => {
          const result =
            (data as any)?.data?.deleteStructureQuestionnaire ?? false;

          setAlert({
            type: result ? 'SUCCESS' : 'ERROR',
            message: result
              ? t('AdminQuestionnaires.alert.delete.success')
              : t('AdminQuestionnaires.alert.delete.error'),
          });

          refetch();
        });
    },
    [deleteQuestionnaire, refetch, setAlert, t],
  );

  const deleteQuestionnaireOnClick = useCallback(
    (id: number, name: string) => {
      setModal({
        content: ModalContent.DELETE_MODAL,
        props: {
          modalTitle: 'DeleteModal.title',
          body: 'DeleteModal.bodyQuestionnaire',
          subtitle: 'DeleteModal.subtitle',
          questionnaireName: name,
          callback: onDeleteQuestionnaire(id),
        },
      });
    },
    [onDeleteQuestionnaire, setModal],
  );

  const onPublishQuestionnaire = useCallback(
    (id: number) => () => {
      publishQuestionnaire({
        variables: {id},
        refetchQueries: [GET_STRUCTURE_QUESTIONNAIRES],
      })
        .catch(() => {
          setAlert({type: 'ERROR', message: 'Operazione non eseguita'});
        })
        .then(data => {
          const result =
            (data as any)?.data?.publishStructureQuestionnaire ?? false;

          setAlert({
            type: result ? 'SUCCESS' : 'ERROR',
            message: result
              ? t('AdminQuestionnaires.alert.publish.success')
              : t('AdminQuestionnaires.alert.publish.error'),
          });

          refetch();
        });
    },
    [publishQuestionnaire, setAlert, t, refetch],
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Admin title={t('AdminQuestionnaires.title')}>
      <div className="az-admin-questionnaires">
        <div className="az-admin-questionnaires__row-wrapper">
          <LabeledInput
            className="az-admin-questionnaires__search"
            label={t('AdminQuestionnaires.searchLabel')}
            onChange={setSearchValue}
            placeholder={t('search.placeholder')}
            rightIcon={searchIcon}
          />
          <div className="az-admin-questionnaires__new-survey">
            <Button
              label={t('Button.newSurvey')}
              onClick={goToAdminSurveyDetailHandler()}
              rightIcon={addIcon}
            />
          </div>
        </div>
        <div
          className={classNames(
            'az-admin-questionnaires__questionnaires-container',
            {
              'az-admin-questionnaires__questionnaires-container--loading':
                loading || error,
            },
          )}>
          {loading && (
            <Spinner
              color="gray"
              className="az-admin-questionnaires__spinner"
            />
          )}
          {error && (
            <p className="az-admin-questionnaires__error-message">
              {t('AdminQuestionnaires.error')}
            </p>
          )}
          {structureQuestionnaires.items
            .filter(item =>
              item.pathology.name
                .toUpperCase()
                .includes(searchValue.toUpperCase()),
            )
            .map((structureQuestionnaire, idx) => (
              <QuestionnaireCard
                key={idx}
                title={structureQuestionnaire.pathology.name}
                subtitle={structureQuestionnaire.subTitle}
                onClick={goToAdminSurveyDetailHandler(
                  structureQuestionnaire.id,
                )}
                onDelete={() =>
                  deleteQuestionnaireOnClick(
                    structureQuestionnaire.id,
                    structureQuestionnaire.pathology.name,
                  )
                }
                onPublish={onPublishQuestionnaire(structureQuestionnaire.id)}
                publish={
                  structureQuestionnaire.status !==
                  QuestionnaireStructureStatus.PUBLISHED
                }
                isPathologyDeleted={structureQuestionnaire.pathology.isDeleted}
              />
            ))}
        </div>
      </div>
    </Admin>
  );
};

export default React.memo(AdminQuestionnaires);
