import React, {useCallback, useContext, useState} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import classNames from 'classnames';

//Others
import {useNavigation} from '../../../../navigation';
import {AlertContext} from '../../../../store/alert/context';
import {ButtonText} from '../../../atoms/ButtonText';
import {useDeleteContent} from '../../../../store/resources/hooks';

//Other components
import {DataTableHead} from '../../../atoms/DataTableHead';
import {DataTableData} from '../../../molecules/DataTableData';
import {Pagination} from '../../../molecules/Pagination';

//Component namespace
import {IProps} from './interface';
import '../DataTable.scss';
import './DataTableContents.scss';

const DataTableContents = ({
  contentsData,
  className,
  totalContents,
  refetchData,
}: IProps) => {
  // Helpers
  const {setValue: setAlert} = useContext(AlertContext);
  const {t} = useTranslations();
  const {goToAdminContentUpdate} = useNavigation();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemPerPage = 5;

  // Pagination
  const setPage = useCallback(
    page => {
      setCurrentPage(page);
      // refetch data
      refetchData({offset: itemPerPage * (page - 1), limit: 5});
    },
    [refetchData],
  );

  const [deleteContent, {loading: deleteLoading, error: deleteError}] =
    useDeleteContent();

  const getOnClickHandler = useCallback(
    (contentId: number) => {
      goToAdminContentUpdate(contentId);
    },
    [goToAdminContentUpdate],
  );

  const onDelete = useCallback(
    (id: number, e: any) => {
      e.stopPropagation();

      setAlert({
        type: 'INFO',
        message: t('AdminContents.delete.confirm'),
        activeButtonLabel: t(
          'AdminQuestionnaireDetail.alert.cancel.activeButtonLabel',
        ),
        cancelButtonLabel: t(
          'AdminQuestionnaireDetail.alert.cancel.cancelButtonLabel',
        ),
        onClick: () => {
          deleteContent({
            variables: {id},
          })
            .catch(() => {
              setAlert({
                type: 'ERROR',
                message: t('AdminContents.delete.error'),
              });
            })
            .then((data: any) => {
              if (
                !deleteLoading &&
                !deleteError &&
                data?.data?.deleteResource
              ) {
                setAlert({
                  type: 'SUCCESS',
                  message: t('AdminContents.delete.success'),
                });
                setPage(1);
              }
            });
        },
      });
    },
    [deleteError, deleteLoading, deleteContent, setAlert, setPage, t],
  );

  return (
    <div className="w-full mb-16">
      <div className="mb-16 az-data-table__table-container">
        <table
          className={classNames(
            'az-data-table',
            'az-data-table--contents',
            className,
          )}>
          <thead className="az-data-table__head">
            <tr>
              <DataTableHead label={t('AdminContentsDetail.title.label')} />
              <DataTableHead label={t('AdminContentsDetail.type')} />
              <DataTableHead label="" />
            </tr>
          </thead>
          <tbody>
            {contentsData.map((content, idx) => (
              <tr
                className={classNames('az-data-table__contents-data-row')}
                key={`patient-row-${idx}`}>
                <DataTableData
                  label={content.title}
                  onClick={() => getOnClickHandler(content.id)}
                  className={'az-data-table__data-cell w-4/12'}
                />
                <DataTableData
                  label={
                    content.isPublic
                      ? t('AdminContentsDetail.contentType.isPublic')
                      : t('AdminContentsDetail.contentType.isPrivate')
                  }
                  onClick={() => getOnClickHandler(content.id)}
                  className={'az-data-table__data-cell w-6/12'}
                />
                <DataTableData
                  onClick={() => getOnClickHandler(content.id)}
                  className={'az-data-table__data-cell w-2/12'}>
                  <ButtonText
                    label={t('AdminContentsDetail.cancel')}
                    className="underline font-bold"
                    onClick={e => onDelete(content.id, e)}
                  />
                </DataTableData>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalContents ? Math.ceil(totalContents / itemPerPage) : 0}
        setPage={setPage}
      />
    </div>
  );
};

export default React.memo(DataTableContents);
