import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import {useParams} from 'react-router-dom';
import {useTranslations} from '@vidiemme/react-i18n';
import ImageUploader from 'react-images-upload';
import {useLazyQuery, useMutation} from '@apollo/client';
import {useNavigation} from '../../../navigation';

// Others
import {noop} from '../../../utils/noop';
import {AlertContext} from '../../../store/alert/context';
import {useBreadCrumb} from '../../../store/breadCrumb';
import {
  CREATE_ADMIN,
  GET_ADMIN,
  UPDATE_ADMIN,
} from '../../../store/admin/queries';
import {UserCreateInput, UserStatus} from '../../../store/api';
import {
  emailConstraint,
  emptyFieldConstraint,
  useValidator,
} from '../../../hooks/validator';

// Components
import {Admin} from '../../templates/Admin';
import {LabeledInput} from '../../molecules/LabeledInput';
import {InputLabel} from '../../atoms/InputLabel';

// Types
import {IProps as ButtonProp} from '../../atoms/Button/interfaces';

// Style
import './AdminManageDetail.scss';

enum StateActionType {
  UPDATE_ERROR = 'UPDATE_ERROR',
}

type StateAction = {
  type: StateActionType.UPDATE_ERROR;

  payload: any;
};

// Reducer
const reducer = (state: any, action: StateAction) => {
  switch (action.type) {
    case StateActionType.UPDATE_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.input]: action.payload.value,
        },
      };
    default:
      throw new Error();
  }
};

const AdminContentsDetail = () => {
  const {t} = useTranslations();
  const {goToAdminManage} = useNavigation();
  const {updateBreadCrumbItems} = useBreadCrumb();
  const {setValue: setAlert} = useContext(AlertContext);
  const {validateField} = useValidator();
  const {id: adminId}: {id: string} = useParams();

  // State input and validation
  // Name
  const [name, setName] = useState<string>('');
  const isNameValid = useMemo(
    () => validateField(name, emptyFieldConstraint),
    [name, validateField],
  );

  // Surname
  const [surname, setSurname] = useState<string>('');
  const isSurnameValid = useMemo(
    () => validateField(surname, emptyFieldConstraint),
    [surname, validateField],
  );

  // Email
  const [email, setEmail] = useState<string>('');
  const isEmailValid = useMemo(
    () => validateField(email, emailConstraint),
    [email, validateField],
  );

  // Admin status
  const [radio, setRadio] = useState<string>(UserStatus.DISABLED);

  // Upload image
  const [imageUploaded, setImageUploaded] = useState('');
  const onDrop = useCallback((picture: any, base64: any) => {
    setImageUploaded(base64[0]);
  }, []);

  // Query for create admin
  const [createAdmin /*, {error: createError}*/] = useMutation(CREATE_ADMIN);

  // Query for update admin
  const [updateAdmin /*, {error: updateError}*/] = useMutation(UPDATE_ADMIN);

  const [
    getAdmin,
    {
      data: adminData,
      // loading: adminLoading,
      // error: adminError,
    },
  ] = useLazyQuery(GET_ADMIN, {fetchPolicy: 'no-cache'});

  // State for manage errors
  const initialState = {
    errors: {
      name: '',
      surname: '',
      email: '',
    },
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  // Add breadcrumb
  useEffect(() => {
    updateBreadCrumbItems([
      {
        label: t('AdminManage.heading'),
        onClick: () => {
          goToAdminManage();
          window.location.reload();
        },
      },
      {
        label: t('AdminManageDetail.heading'),
        onClick: noop,
      },
    ]);
  }, [updateBreadCrumbItems, t, goToAdminManage]);

  // Check if is editing
  useEffect(() => {
    if (adminId) {
      getAdmin({
        variables: {id: adminId},
      });
    }
  }, [getAdmin, adminId]);

  // If there is the admin id populate the fields
  useEffect(() => {
    if (adminData?.admin) {
      setName(adminData?.admin?.firstName);
      setSurname(adminData?.admin?.lastName);
      setEmail(adminData?.admin?.email);
      setRadio(adminData?.admin?.status);
    }
  }, [adminData]);

  // Send a new admin to server
  const createOrUpdateAdmin = useCallback(() => {
    let filter: UserCreateInput = {
      firstName: name,
      lastName: surname,
      email,
      status:
        radio === UserStatus.ENABLED ? UserStatus.ENABLED : UserStatus.DISABLED,
      imageBase64: imageUploaded ? imageUploaded : undefined,
    };
    if (adminId) {
      filter.id = Number(adminId);
      updateAdmin({variables: {input: filter}, errorPolicy: 'all'})
        .then(response => {
          if (response.errors) {
            setAlert({
              type: 'ERROR',
              message:
                t('AdminManageDetail.alertUpdate.error') +
                ': ' +
                t(
                  `ErrorStrings.${response?.errors?.[0].extensions?.extra?.[0].field}`,
                ) +
                ' ' +
                t(
                  `ErrorStrings.${response?.errors?.[0].extensions?.extra?.[0].errorType}`,
                ),
            });
          } else {
            setAlert({
              type: 'SUCCESS',
              message: t('AdminManageDetail.alertUpdate.success'),
            });
            goToAdminManage();
          }
        })
        .catch(() => {
          setAlert({
            type: 'ERROR',
            message: t('AdminManageDetail.alertUpdate.error'),
          });
        });
    } else {
      createAdmin({variables: {input: filter}, errorPolicy: 'all'})
        .then(response => {
          if (response.errors) {
            setAlert({
              type: 'ERROR',
              message:
                t('AdminManageDetail.alertCreate.error') +
                ': ' +
                t(
                  `ErrorStrings.${response?.errors?.[0].extensions?.extra?.[0].field}`,
                ) +
                ' ' +
                t(
                  `ErrorStrings.${response?.errors?.[0].extensions?.extra?.[0].errorType}`,
                ),
            });
          } else {
            setAlert({
              type: 'SUCCESS',
              message: t('AdminManageDetail.alertCreate.success'),
            });
            goToAdminManage();
          }
        })
        .catch(() => {
          setAlert({
            type: 'ERROR',
            message: t('AdminManageDetail.alertCreate.error'),
          });
        });
    }
  }, [
    name,
    surname,
    email,
    radio,
    imageUploaded,
    adminId,
    updateAdmin,
    setAlert,
    t,
    goToAdminManage,
    createAdmin,
  ]);

  const isFormComplete = useMemo(() => {
    return !!(name && surname && email);
  }, [name, surname, email]);

  // Save button
  const cta: ButtonProp = {
    label: t('AdminQuestionnaireDetail.headerButton.save'),
    disabled:
      !!Object.values(state.errors).find(value => value) || !isFormComplete,
    onClick: createOrUpdateAdmin,
  };

  return (
    <Admin title={t('AdminManageDetail.heading')} breadcrumb ctaPrimary={cta}>
      <div className="az-admin-manage-detail">
        <div className="az-admin-manage-detail__row-wrapper">
          <LabeledInput
            label={t('AdminManageDetail.name.label')}
            placeholder={t('AdminManageDetail.name.placeholder')}
            value={name}
            onChange={setName}
            className="w-4/12"
            maxLength={120}
            error={state.errors.name}
            onBlur={() => {
              dispatch({
                type: StateActionType.UPDATE_ERROR,
                payload: {input: 'name', value: !isNameValid},
              });
            }}
          />
          <LabeledInput
            label={t('AdminManageDetail.surname.label')}
            placeholder={t('AdminManageDetail.surname.placeholder')}
            value={surname}
            onChange={setSurname}
            className="w-4/12"
            maxLength={120}
            error={state.errors.surname}
            onBlur={() => {
              dispatch({
                type: StateActionType.UPDATE_ERROR,
                payload: {input: 'surname', value: !isSurnameValid},
              });
            }}
          />
          <LabeledInput
            label={t('AdminManageDetail.email.label')}
            placeholder={t('AdminManageDetail.email.placeholder')}
            value={email}
            onChange={setEmail}
            className="w-4/12"
            maxLength={120}
            error={state.errors.email}
            onBlur={() => {
              dispatch({
                type: StateActionType.UPDATE_ERROR,
                payload: {input: 'email', value: !isEmailValid},
              });
            }}
          />
        </div>
        <div className="az-admin-manage-detail__row-wrapper">
          <div className="w-4/12 flex-col pr-3">
            <InputLabel
              label={t('AdminManageDetail.image.label')}
              className="az-admin-manage-detail__radio-title"
            />
            <ImageUploader
              withIcon={true}
              buttonText={t('AdminManageDetail.image.button')}
              onChange={onDrop}
              imgExtension={['.jpg', '.png']}
              maxFileSize={2097152}
              fileSizeError={t('AdminManageDetail.image.errorTooBig')}
              fileTypeError={t('AdminManageDetail.image.errorNotSupported')}
              singleImage
              withLabel={false}
              withPreview
              buttonClassName="az-admin-manage-detail__button"
            />
          </div>
          <div className="w-8/12 mb-2">
            <InputLabel
              label={t('AdminManageDetail.status.label')}
              className="az-admin-manage-detail__radio-title"
            />
            <div onChange={noop} className="flex">
              <div className="mr-14">
                <input
                  type="radio"
                  value={UserStatus.DISABLED}
                  name="status"
                  checked={radio === UserStatus.DISABLED}
                  onChange={e => {
                    setRadio(e.target.value);
                  }}
                />
                <span className="az-admin-manage-detail__radio-label">
                  {t('AdminManageDetail.status.disable')}
                </span>
              </div>
              <div>
                <input
                  type="radio"
                  value={UserStatus.ENABLED}
                  name="status"
                  checked={radio === UserStatus.ENABLED}
                  onChange={e => {
                    setRadio(e.target.value);
                  }}
                />
                <span className="az-admin-manage-detail__radio-label">
                  {t('AdminManageDetail.status.enable')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Admin>
  );
};

export default React.memo(AdminContentsDetail);
