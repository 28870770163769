import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

// Context
import {AlertContext, DEFAULT_ALERT_STATE} from '../../../store/alert/context';

// Others
import {useBEMClass} from '../../../hooks/use-bem-class';

// Components
import {IconButton} from '../../molecules/iconButton';
import {IconSVG} from '../../atoms/IconSVG';
import {ButtonText} from '../../atoms/ButtonText';

// Types
import {IProps as IconProps} from '../../atoms/IconSVG/interfaces';

// Interface
import {IProps} from './interface';

// Style
import './Alert.scss';

const Alert = ({className}: IProps): JSX.Element => {
  //Internal state
  const [isOpaque, setIsOpaque] = useState<boolean>(false);

  //Helpers
  const {value: alert, setValue: setAlert} = useContext(AlertContext);

  const alertRef = useRef<HTMLDivElement>(null);
  const closeIcon = useMemo<IconProps>(
    () => ({
      icon: 'icon-close',
      size: 14,
      className: 'text-gray',
    }),
    [],
  );
  const BEMClass = useBEMClass();

  const alertIcon = useMemo(
    () => (alert?.type === 'SUCCESS' ? 'icon-confirmation' : 'icon-error'),
    [alert?.type],
  );

  const closeAlert = useCallback(() => {
    alertRef.current?.addEventListener('transitionend', () => {
      if (alert?.onDismiss) {
        alert.onDismiss();
      }

      setAlert(DEFAULT_ALERT_STATE);
    });

    setIsOpaque(false);
  }, [alert, setAlert]);

  const typeClassNameModifier = useMemo<string>(
    () =>
      BEMClass('az-alert__icon-description', '', alert?.type?.toLowerCase()),
    [alert, BEMClass],
  );

  const opacityClassNameModifier = useMemo(
    () => (isOpaque ? BEMClass('az-alert', '', 'opaque') : ''),
    [isOpaque, BEMClass],
  );

  useEffect(() => {
    const setOpacityAtNextTick = setTimeout(() => {
      setIsOpaque(true);
    }, 0);

    return () => clearTimeout(setOpacityAtNextTick);
  }, []);

  useEffect(() => {
    if (alert?.type !== 'INFO') {
      setTimeout(() => {
        closeAlert();
      }, 2500);
    }
  }, [alert, closeAlert]);

  return (
    <div
      className={classNames('az-alert', opacityClassNameModifier, className)}
      ref={alertRef}>
      <div className="az-alert__close-button">
        <IconButton icon={closeIcon} onClick={closeAlert} />
      </div>
      <div
        className={classNames(
          'az-alert__icon-description',
          typeClassNameModifier,
        )}>
        {alert?.type !== 'INFO' && (
          <IconSVG icon={alertIcon} size={30} className="az-alert__icon" />
        )}
        <p className="az-alert__description">{alert?.message ?? ''}</p>
        {alert?.type === 'INFO' && (
          <>
            <ButtonText
              className="az-alert__link az-alert__link--primary"
              onClick={e => {
                try {
                  closeAlert();
                } catch {
                  console.error('Errore');
                } finally {
                  setTimeout(() => {
                    if (alert?.onClick) {
                      alert?.onClick(e);
                    }
                  }, 250);
                }
              }}
              label={alert?.activeButtonLabel ?? ''}
            />
            <ButtonText
              className="az-alert__link"
              onClick={closeAlert}
              label={alert?.cancelButtonLabel ?? ''}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(Alert);
