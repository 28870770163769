import React, {FunctionComponent, useEffect} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';

import {
  AdminRoutes,
  AnonymousRoutes,
  AuthenticatedRoutes,
  MMGRoutes,
  PatientRoutes,
} from './routes';

//Components
import {Homepage} from '../components/pages/Homepage';
import {Components} from '../components/pages/Components';
import {LoginMMG} from '../components/pages/LoginMMG';
import {SignUp} from '../components/pages/SignUp';
import {AdminLogin} from '../components/pages/AdminLogin';
import {AdminQuestionnaires} from '../components/pages/AdminQuestionnaires';
// @REMOVE_PATIENTS_DATA
// import {Patients} from '../components/pages/Patients';
import ForgotPassword from '../components/pages/ForgotPassword';
import ResetPassword from '../components/pages/ResetPassword';
import {AdminQuestionnaireDetail} from '../components/pages/AdminQuestionnaireDetail';
// @REMOVE_PATIENTS_DATA
// import {Patient} from '../components/pages/Patient';
import {NewQuestionnaire} from '../components/pages/NewQuestionnaire';
import {ActiveQuestionnaire} from '../components/pages/ActiveQuestionnaire';
import {ResultQuestionnaire} from '../components/pages/ResultQuestionnaire';
import {TermsAndConditions} from '../components/pages/TermsAndConditions';
import {MedicalCenters} from '../components/pages/MedicalCenters';
import {PageNotFound} from '../components/pages/PageNotFound';
import {CentersMap} from '../components/pages/CentersMap';
import {Resources} from '../components/pages/Resources';
import {AdminPathologies} from '../components/pages/AdminPathologies';
import {LoginMMGFirstAccess} from '../components/pages/LoginMMGFirstAccess';
import {AdminPathologyCreate} from '../components/pages/AdminPathologyCreate';
import {AdminContentsDetail} from '../components/pages/AdminContentsDetail';
import {AdminContents} from '../components/pages/AdminContents';
import {AdminManage} from '../components/pages/AdminManage';
import {AdminManageDetail} from '../components/pages/AdminManageDetail';
import {MedicalCentersDetail} from '../components/pages/MedicalCentersDetail';
import {AdminProfile} from '../components/pages/AdminProfile';
import {AdminSetNewPassword} from '../components/pages/AdminSetNewPassword';
import {MMGProfile} from '../components/pages/MMGProfile';
import {Privacy} from '../components/pages/Privacy';

//Component namespace
import Guest from './middleware/Guest';
import Authenticated from './middleware/Authenticated';
import {useTealium} from '../hooks/tealium/useTealium';
import {useTranslations} from '@vidiemme/react-i18n';
import {Questionnaire} from '../components/pages/Questionnaire';

export const NavigationSwitch: FunctionComponent = () => {
  const {pathname} = useLocation();
  const {sendPageViewEvent, resolvePathNameToPageName} = useTealium();
  const {currentLanguage} = useTranslations();

  useEffect(() => {
    const pageName: string | false = resolvePathNameToPageName(pathname);

    if (pageName !== false) {
      const utagData = {
        page_name: pageName,
        core_language: currentLanguage!.substring(0, 2),
      };

      sendPageViewEvent(utagData);
    }
  }, [pathname, currentLanguage]);

  return (
    <Switch>
      <Guest exact path={AnonymousRoutes.ADMIN_LOGIN} component={AdminLogin} />
      {/* Login patient */}
      {/*<Guest exact path={AnonymousRoutes.LOGIN} component={Login} />*/}
      {/* Login MMG */}
      <Guest exact path={AnonymousRoutes.LOGIN_MMG} component={LoginMMG} />
      <Guest exact path={AnonymousRoutes.SIGNUP} component={SignUp} />
      <Guest
        exact
        path={AnonymousRoutes.FORGOT_PASSWORD}
        component={ForgotPassword}
      />
      <Guest
        exact
        path={AnonymousRoutes.RESET_PASSWORD}
        component={ResetPassword}
      />
      <Authenticated
        exact
        path={AnonymousRoutes.RESET_PASSWORD}
        component={ResetPassword}
      />
      <Guest
        exact
        path={AnonymousRoutes.ADMIN_NEW_PASSWORD}
        component={ResetPassword}
      />
      <Guest exact path={AnonymousRoutes.COMPONENTS} component={Components} />
      <Authenticated
        exact
        path={MMGRoutes.TERMS_AND_CONDITIONS}
        component={TermsAndConditions}
      />
      <Authenticated exact path={MMGRoutes.PRIVACY} component={Privacy} />

      {/* @REMOVE_PATIENTS_DATA */}
      {/* <Authenticated exact path={MMGRoutes.PATIENTS} component={Patients} />
      <Authenticated exact path={MMGRoutes.PATIENT} component={Patient} /> */}
      <Authenticated
        exact
        path={MMGRoutes.QUESTIONNAIRE}
        component={Questionnaire}
      />

      <Authenticated
        exact
        path={MMGRoutes.NEW_QUESTIONNAIRE}
        component={NewQuestionnaire}
      />
      <Authenticated
        exact
        path={AdminRoutes.QUESTIONNAIRES}
        component={AdminQuestionnaires}
      />

      {/* @REMOVE_PATIENTS_DATA */}
      {/*
      <Authenticated
        exact
        path={MMGRoutes.DRAFT_QUESTIONNAIRE}
        component={DraftQuestionnaire}
      /> */}
      {/* <Authenticated
        exact
        path={MMGRoutes.REVIEW_QUESTIONNAIRE}
        component={DraftQuestionnaire}
      /> */}

      <Authenticated
        exact
        path={MMGRoutes.ACTIVE_QUESTIONNAIRE}
        component={ActiveQuestionnaire}
      />

      <Authenticated
        exact
        path={MMGRoutes.RESULT_QUESTIONNAIRE}
        component={ResultQuestionnaire}
      />
      <Authenticated
        exact
        path={MMGRoutes.CENTERS_MAP}
        component={CentersMap}
      />
      <Authenticated exact path={MMGRoutes.RESOURCES} component={Resources} />

      {/* @REMOVE_PATIENTS_DATA */}
      {/* <Authenticated
        exact
        path={PatientRoutes.PATIENT_PROFILE}
        component={PatientProfile}
      /> */}

      <Authenticated
        exact
        path={MMGRoutes.MMG_PROFILE}
        component={MMGProfile}
      />

      {/* @REMOVE_PATIENTS_DATA */}
      {/* <Authenticated
        exact
        path={PatientRoutes.PATIENT_EDIT_PASSWORD}
        component={PatientEditPassword}
      /> */}

      <Authenticated
        exact
        path={AdminRoutes.QUESTIONNAIRES}
        component={AdminQuestionnaires}
      />
      <Authenticated
        exact
        path={AdminRoutes.QUESTIONNAIRE_DETAIL}
        component={AdminQuestionnaireDetail}
      />
      <Authenticated
        exact
        path={AdminRoutes.QUESTIONNAIRE_DETAIL_UPDATE}
        component={AdminQuestionnaireDetail}
      />
      <Authenticated
        exact
        path={AdminRoutes.PATHOLOGIES}
        component={AdminPathologies}
      />
      <Authenticated
        exact
        path={AdminRoutes.CREATE_PATHOLOGY}
        component={AdminPathologyCreate}
      />
      <Authenticated
        exact
        path={AdminRoutes.CONTENTS}
        component={AdminContents}
      />
      <Authenticated
        exact
        path={AdminRoutes.CREATE_CONTENTS}
        component={AdminContentsDetail}
      />
      <Authenticated
        exact
        path={AdminRoutes.UPDATE_CONTENTS}
        component={AdminContentsDetail}
      />
      {/* <Authenticated exact path={AdminRoutes.MMG} component={AdminMMG} /> */}
      <Authenticated
        exact
        path={AdminRoutes.MEDICAL_CENTERS}
        component={MedicalCenters}
      />
      <Authenticated
        exact
        path={AdminRoutes.MEDICAL_CENTERS_DETAIL}
        component={MedicalCentersDetail}
      />
      <Authenticated
        exact
        path={AdminRoutes.MEDICAL_CENTERS_DETAIL_UPDATE}
        component={MedicalCentersDetail}
      />
      <Authenticated
        exact
        path={AdminRoutes.MANAGE_ADMIN}
        component={AdminManage}
      />
      <Authenticated
        exact
        path={AdminRoutes.CREATE_ADMIN}
        component={AdminManageDetail}
      />
      <Authenticated
        exact
        path={AdminRoutes.MANAGE_ADMIN_DETAIL}
        component={AdminManageDetail}
      />
      <Authenticated
        exact
        path={AdminRoutes.PROFILE}
        component={AdminProfile}
      />
      <Authenticated
        exact
        path={AdminRoutes.ADMIN_EDIT_PASSWORD}
        component={AdminSetNewPassword}
      />

      {/* @REMOVE_PATIENTS_DATA */}
      {/* <Authenticated exact path={AdminRoutes.ADMIN_KPI} component={AdminKpi} /> */}

      <Route
        exact
        path={AuthenticatedRoutes.MMG_FIRST_ACCESS}
        component={LoginMMGFirstAccess}
      />
      <Route exact path={AuthenticatedRoutes.MAIN} component={Homepage} />
      <Route component={PageNotFound} />
    </Switch>
  );
};
