import {useEffect, useState} from 'react';

/**
 * @description Provide a reactive MediaQueryList object that can be used to check over time
 * whether the current device is matching a specific media query.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
 * @see MEDIA_QUERIES, MEDIA_QUERY_LABELS (constants.ts, interfaces.ts)
 *
 * @example const {mediaQueryList} = useMediaQuery(MEDIA_QUERIES.get('LG'));
 */
export const useMediaQuery = (mediaQueryString: string) => {
  //Used internally to attach
  const _mql = window.matchMedia(mediaQueryString);
  const [mediaQueryList, setMediaQueryList] = useState<MediaQueryList>(_mql);

  useEffect(() => {
    const changeListener = (ev: MediaQueryListEvent) => {
      setMediaQueryList(window.matchMedia(ev.media));
    };

    try {
      _mql.addEventListener('change', changeListener);
    } catch {
      //
    }

    return () => {
      try {
        _mql.removeEventListener('change', changeListener);
      } catch {
        //
      }
    };
  }, [_mql]);

  return {
    mediaQueryList: mediaQueryList,
  };
};
