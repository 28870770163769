import React, {useMemo} from 'react';
import classNames from 'classnames';

//Component namespace
import {IProps} from './interfaces';
import './NikeLogo.scss';
import {ReactComponent as NikeSVG} from './nike.svg';
import {useBEMClass} from '../../../hooks/use-bem-class';

/**
 * This is an ironic name for a component that inject an svg tag whose layout it's DEFINITELY similar to nike's logo
 */
const NikeLogo = (props: IProps) => {
  const {className, color = ''} = props;

  //Helpers
  const BEMClass = useBEMClass();
  const colorClassName = useMemo(
    () => BEMClass('az-nike-logo', '', color),
    [color, BEMClass],
  );

  return (
    <NikeSVG
      className={classNames('az-nike-logo', colorClassName, className)}
    />
  );
};

export default NikeLogo;
