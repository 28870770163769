import {createContext} from 'react';

// Others
import {noop} from '../../utils/noop';

// Interface
import {IModalContext} from './interfaces';

export const DEFAULT_MODAL_STATE = null;

export const ModalContext = createContext<IModalContext>({
  value: DEFAULT_MODAL_STATE,
  setValue: noop,
});
