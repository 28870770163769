import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {Provider} from 'react-redux';
import 'reset-css';
import {TranslationsProvider} from '@vidiemme/react-i18n';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ApolloProvider} from '@apollo/client';

import {ThemeProvider} from './themes';
import {Navigation} from './navigation';
import {en, it} from './translations';
import {initializeStore} from './store/app';
import './hooks/toast/Toast.scss';
import reportWebVitals from './reportWebVitals';
import {useApollo as getGraphQLClient} from './store/api';
import AlertProvider from './store/alert/Provider';
import {DEFAULT_ALERT_STATE} from './store/alert/context';
import ModalProvider from './store/modal/Provider';
import {DEFAULT_MODAL_STATE} from './store/modal/context';

if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
  Sentry.init({
    release: process.env.npm_package_version,
    environment: process.env.REACT_APP_ENVIRONMENT,

    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const store = initializeStore();

const App = () => {
  const graphQLClient = getGraphQLClient();

  return (
    <ApolloProvider client={graphQLClient}>
      <ThemeProvider theme="light">
        <TranslationsProvider
          params={{
            resources: {
              en: {
                translation: en,
              },
              it: {
                translation: it,
              },
            },
          }}>
          <AlertProvider defaultValue={DEFAULT_ALERT_STATE}>
            <ModalProvider defaultValue={DEFAULT_MODAL_STATE}>
              <Navigation />
              <ToastContainer />
            </ModalProvider>
          </AlertProvider>
        </TranslationsProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

if (process.env.REACT_APP_WEB_VITALS === 'true') {
  reportWebVitals();
}
