import {createContext} from 'react';

// Others
import {noop} from '../../utils/noop';

// Interface
import {IAlertContext} from './interfaces';

export const DEFAULT_ALERT_STATE = null;

export const AlertContext = createContext<IAlertContext>({
  value: DEFAULT_ALERT_STATE,
  setValue: noop,
});
