import {useDispatch, useSelector} from 'react-redux';

import {selectorItems} from './selectors';
import {BreadCrumbItem, BreadCrumbState} from './interfaces';
import {useCallback} from 'react';
import {BreadCrumbUpdate} from './actions';
import * as Sentry from '@sentry/react';

export const useBreadCrumb = () => {
  const dispatch = useDispatch();

  const breadCrumbItems = useSelector<
    {breadCrumb: BreadCrumbState},
    BreadCrumbState['items']
  >(selectorItems);

  const updateBreadCrumbItems = useCallback(
    (items: BreadCrumbItem[]) => {
      try {
        dispatch(BreadCrumbUpdate({items}));
      } catch (e) {
        Sentry.captureException(e);
        throw e;
      }
    },
    [dispatch],
  );

  return {
    breadCrumbItems,
    updateBreadCrumbItems,
  };
};
