import React, {useCallback, useMemo, useState} from 'react';

// Context
import {AlertContext} from './context';

// Interface
import {IAlertContext, IAlertState, IProps} from './interfaces';

/**
 * Provides an AlertState as Context for children
 */
const AlertProvider = ({defaultValue, children}: IProps): JSX.Element => {
  const [alert, setAlert] = useState<IAlertState | null>(defaultValue);
  const updateAlert = useCallback(
    (value: IAlertState | null) => setAlert(value),
    [],
  );
  const providedAlertContext = useMemo<IAlertContext>(
    () => ({value: alert, setValue: updateAlert}),
    [alert, updateAlert],
  );

  return (
    <AlertContext.Provider value={providedAlertContext}>
      {children}
    </AlertContext.Provider>
  );
};

export default React.memo(AlertProvider);
