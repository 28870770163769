import {Role} from '../api';

export type AuthenticationState = {
  isFetched: boolean;
  isFetching: boolean;
  error?: string;
  token?: string;
  role?: Role;
  id?: number;
  owaId?: string;
};

export enum AuthenticationActions {
  loginLoading = 'login loading',
  loginSuccess = 'login success',
  loginFail = 'login fail',
  logout = 'logout',
}

export type AuthenticationResponse = {
  token: string;
  role: Role;
  id: number;
};

export type AuthenticationAction =
  | {
      type: AuthenticationActions.loginLoading | AuthenticationActions.logout;
    }
  | {
      type: AuthenticationActions.loginSuccess;
      payload: AuthenticationResponse;
    }
  | {
      type: AuthenticationActions.loginFail;
      payload: Error;
    };
