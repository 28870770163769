import React from 'react';
import {useTranslations} from '@vidiemme/react-i18n';

// Components
import {Logo} from '../../atoms/Logo';

// Interface
import {IProps} from './interface';

// Style
import './AdminFooter.scss';

const AdminFooter = (_props: IProps) => {
  const {t} = useTranslations();
  return (
    <footer className="az-admin-footer">
      <div className="az-admin-footer__logo-wrapper">
        <Logo type="white" className="az-admin-footer__logo" />
      </div>
      <p className="az-admin-footer__id-text">{t('Footer.infoId')}</p>
    </footer>
  );
};

export default React.memo(AdminFooter);
