import {
  BreadCrumbAction,
  BreadCrumbActions,
  BreadCrumbState,
} from './interfaces';

export const BreadCrumbUpdate = (data: {
  items: BreadCrumbState['items'];
}): BreadCrumbAction => ({
  type: BreadCrumbActions.UPDATE_BREAD_CRUMB,
  payload: data.items,
});
