import {
  BreadCrumbAction,
  BreadCrumbActions,
  BreadCrumbState,
} from './interfaces';

export const getInitialState = (): BreadCrumbState => ({
  items: [],
});

export const breadCrumbReducer = (
  state: BreadCrumbState = getInitialState(),
  action: BreadCrumbAction,
) => {
  if (action.type === BreadCrumbActions.UPDATE_BREAD_CRUMB) {
    return {...state, items: action.payload};
  }

  return state;
};
