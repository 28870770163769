import React from 'react';

// Components
import {IconSVG} from '../../atoms/IconSVG';

// Type
import {IProps} from './interface';

const IconButton = ({className, icon, onClick}: IProps): JSX.Element => {
  return (
    <button className={className} onClick={onClick}>
      <IconSVG icon={icon.icon} size={icon.size} className={icon.className} />
    </button>
  );
};

export default React.memo(IconButton);
