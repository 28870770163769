import React from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';

import {AuthenticatedRoutes, PatientRoutes} from '../routes';

const Patient = (props: RouteProps) => {
  if (Object.values(PatientRoutes).includes(props.path as PatientRoutes)) {
    return <Route {...props} />;
  }

  return <Redirect to={AuthenticatedRoutes.MAIN} />;
};

export default React.memo(Patient);
