import React, {useCallback, useContext} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';

//Others
import {DEFAULT_MODAL_STATE, ModalContext} from '../../../store/modal/context';
import {useNavigation} from '../../../navigation';

//Other components

import {Button} from '../../atoms/Button';

//Component namespace
import {IProps} from './interfaces';
import './BookAnAppointmentModal.scss';

const NewQuestionnaireModal = (_props: IProps) => {
  const {goToQuestionnaire} = useNavigation();

  //Helpers
  const {t} = useTranslations();
  const {setValue: setModal} = useContext(ModalContext);

  const closeModal = useCallback(
    () => setModal(DEFAULT_MODAL_STATE),
    [setModal],
  );

  const proceed = useCallback(() => {
    closeModal();
    // @TODO: add related path/link when available
    goToQuestionnaire();
  }, [closeModal, goToQuestionnaire]);

  return (
    <div>
      <h1 className="az-book-an-appointment-modal__title">
        {t('LeavingPagePrompt.message')}
      </h1>

      <div className="h-20"></div>

      <div className="az-book-an-appointment-modal__cta-container">
        <Button
          label={t('NewQuestionnaireModal.cta.cancel')}
          onClick={closeModal}
          styleType="secondary"
          className="az-book-an-appointment-modal__cta az-book-an-appointment-modal__cta--delete"
        />
        <Button
          label={t('NewQuestionnaireModal.cta.proceed')}
          styleType="primary"
          type="submit"
          className="az-book-an-appointment-modal__cta az-book-an-appointment-modal__cta--submit"
          onClick={proceed}
        />
      </div>
    </div>
  );
};

export default React.memo(NewQuestionnaireModal);
