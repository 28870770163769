import React from 'react';
import {useTranslations} from '@vidiemme/react-i18n';

// Others
import {noop} from '../../../utils/noop';
import truncateText from '../../../utils/truncateText';

// Components
import {Button} from '../../atoms/Button';
import {ButtonText} from '../../atoms/ButtonText';

// Interface
import {IProps} from './interface';

// Style
import './QuestionnaireCard.scss';
import classNames from 'classnames';

const QuestionnaireCard = ({
  title,
  subtitle,
  onClick,
  onDelete,
  onPublish = noop,
  publish = false,
  isPathologyDeleted = false,
}: IProps) => {
  const {t} = useTranslations();

  return (
    <div className="az-questionnaire-card">
      <h1 className="az-questionnaire-card__title">{title}</h1>
      <h2 className="az-questionnaire-card__subtitle">
        {truncateText(subtitle || '', 26)}
      </h2>
      <div className="az-questionnaire-card__button-wrapper">
        <Button
          styleType="primary"
          label={t('QuestionnaireCard.buttonPrimary')}
          onClick={onClick}
          disabled={isPathologyDeleted}
        />
      </div>
      <div className="az-questionnaire-card__button-texts">
        {publish && !isPathologyDeleted && (
          <ButtonText
            label={t('QuestionnaireCard.publishSurvey')}
            onClick={onPublish}
            className="az-questionnaire-card__button-text az-questionnaire-card__button-text--primary"
          />
        )}
        <ButtonText
          label={t('QuestionnaireCard.deleteSurvey')}
          onClick={onDelete}
          className={classNames(
            'az-questionnaire-card__button-text az-questionnaire-card__button-text--gray',
            {'pl-2': publish},
          )}
        />
      </div>
    </div>
  );
};

export default React.memo(QuestionnaireCard);
