import {useDispatch, useSelector} from 'react-redux';

import {selectorQuestionnaireResult} from './selectors';
import {QuestionnaireResultState} from './interfaces';
import {useCallback} from 'react';
import {QuestionnaireResultFill, QuestionnaireResulClean} from './actions';
import * as Sentry from '@sentry/react';

export const useQuestionnaireResult = () => {
  const dispatch = useDispatch();

  const questionnaireData = useSelector<
    {questionnaireResult: QuestionnaireResultState},
    QuestionnaireResultState['data']
  >(selectorQuestionnaireResult);

  const fillQuestionnaireResult = useCallback(
    (data: any) => {
      try {
        dispatch(QuestionnaireResultFill(data));
      } catch (e) {
        Sentry.captureException(e);
        throw e;
      }
    },
    [dispatch],
  );

  const cleanQuestionnaireResult = useCallback(() => {
    try {
      dispatch(QuestionnaireResulClean());
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  }, [dispatch]);

  return {
    fillQuestionnaireResult,
    cleanQuestionnaireResult,
    questionnaireData,
  };
};
