import React, {useContext} from 'react';
import {useQuery} from '@apollo/client';

//Alert Context
import {AlertContext} from '../../../store/alert/context';

// Others
import {STORAGE_KEY} from '../../../utils/storage';
import {GET_PROFILE_PICTURE} from '../../../store/authentication';

// Components
import {Sidebar} from '../../molecules/Sidebar';
import {AdminFooter} from '../../molecules/AdminFooter';
import {Alert} from '../../molecules/Alert';
import {Button} from '../../atoms/Button';
import {ButtonText} from '../../atoms/ButtonText';
import {BreadCrumb} from '../../molecules/BreadCrumb';
import {Modal} from '../../organisms/Modal';

//Component namespace
import {IProps} from './interface';

// Style
import './Admin.scss';

const Admin = ({
  title,
  children,
  ctaPrimary = null,
  buttonText1 = null,
  buttonText2 = null,
  breadcrumb = false,
}: IProps): JSX.Element => {
  const {value: alert} = useContext(AlertContext);

  // @REMOVE_LOGGED_USER_IMG
  // const {
  //   data: profilePic,
  //   loading: profilePicLoading,
  //   // error: pathologiesError,
  // } = useQuery(GET_PROFILE_PICTURE);

  return (
    <div className="az-admin-page">
      <div className="az-admin-page__sidebar">
        <Sidebar
          adminFirstName={localStorage.getItem(STORAGE_KEY.FIRST_NAME) || ''}
          adminLastName={localStorage.getItem(STORAGE_KEY.LAST_NAME) || ''}
          // @REMOVE_LOGGED_USER_IMG
          // src={profilePic?.loggedUser?.imageBase64 || undefined}
        />
      </div>
      <main className="az-admin-page__main">
        {alert && (
          <div className="az-admin-page__alert">
            <Alert />
          </div>
        )}
        <div className="az-admin-page__header">
          <div className="az-admin-page__header-info">
            <h1 className="az-admin-page__title">{title}</h1>
            {breadcrumb && (
              <div className="az-admin-page__breadcrumb">
                <BreadCrumb />
              </div>
            )}
          </div>
          <div className="az-admin-page__header-actions">
            {buttonText2 && (
              <ButtonText
                className="az-admin-page__button-text"
                {...buttonText2}
              />
            )}
            {buttonText1 && (
              <ButtonText
                className="az-admin-page__button-text"
                {...buttonText1}
              />
            )}
            {ctaPrimary && (
              <div className="az-admin-page__cta">
                <Button {...ctaPrimary} />
              </div>
            )}
          </div>
        </div>
        <div className="az-admin-page__container">{children}</div>
      </main>
      <div className="az-admin-page__footer">
        <AdminFooter />
      </div>
      <Modal />
    </div>
  );
};

export default React.memo(Admin);
