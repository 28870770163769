import {
  passwordConstraint,
  confirmPasswordConstraint,
  checkboxConstraint,
} from './genericConstraints';

export const signUpConstraints = {
  password: passwordConstraint,
  confirmPassword: confirmPasswordConstraint,
  privacy: checkboxConstraint,
  conditions: checkboxConstraint,
};
