import {
  QuestionnaireResultActions,
  QuestionnaireResultCleanAction,
  QuestionnaireResultFillAction,
} from './interfaces';

export const QuestionnaireResultFill = (
  data: any,
): QuestionnaireResultFillAction => ({
  type: QuestionnaireResultActions.FILL_QUESTIONNAIRE_RESULT,
  payload: data,
});

export const QuestionnaireResulClean = (): QuestionnaireResultCleanAction => ({
  type: QuestionnaireResultActions.CLEAN_QUESTIONNAIRE_RESULT,
});
