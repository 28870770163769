export const it = {
  Footer: {
    infoTitle: 'Global sites',
    infoDescription:
      "This website is intended for people seeking information on AstraZeneca's worldwide business. " +
      "We also have country sites; click the 'Global sites' link for the directory.",
    altLogo: 'astraZeneca logo',
    relatedToHealth: 'Connessi alla salute',
    azWebsite: 'AstraZeneca Italy',
    legalInfo: 'Informazioni legali',
    privacy: 'Privacy policy',
    contacts: 'Contattaci',
    cookies: 'Informativa cookies',
    reports: 'Segnala eventi avversi',
    altFb: 'link FaceBook',
    altIn: 'link Linkedin',
    infoId: '© AstraZeneca - Codice IT-9556 - Data di approvazione: 24/05/2023',
  },
  Button: {
    next: 'PROCEDI',
    cancel: 'ANNULLA',
    new: 'CREA NUOVO',
    newSurvey: 'NUOVO QUESTIONARIO',
  },
  Homepage: {
    goToQuestionnaire: 'Vai al questionario',
    goToMap: 'Vai alla mappa',
    resources: {
      errorMessage: 'Risorse non disponibili',
      label: 'Risorse',
      showAll: 'Vedi tutte',
    },
  },
  Resources: {
    title: 'Risorse',
    home: 'Home',
    filters: {
      all: 'Tutte',
    },
    noResources: 'Nessuna risorsa presente',
  },
  Header: {
    home: 'HOME',
    questionnaire: 'QUESTIONARIO',
    patients: 'I TUOI PAZIENTI',
    profile: 'PROFILO',
    logout: 'Logout',
    user: 'UTENTE',
    access: 'ACCEDI COME',
    healthPro: 'PROFESSIONISTA DELLA SALUTE',
    centersMap: 'MAPPA DEI CENTRI',
    resources: 'RISORSE',
  },
  Login: {
    title: 'Accedi Area Utente',
    titleMMG: 'Accedi Area MMG',
    mmgFirstAccessText:
      'Per poter procedere, abbiamo bisogno di alcuni tuoi dati',
    buttonRegister: 'Registrati su Iqvia',
    disclaimer: 'Per procedere utilizza le credenziali Iqvia',
    doctorDisclaimer: `Si precisa che il questionario analizza
    unicamente le abitudini di stile di vita del paziente e non dati di natura clinica:
    non è pertanto da intendersi come uno strumento di diagnosi.
    Il medico curante dovrà valutare secondo il proprio giudizio professionale
    l'opportunità di effettuare eventuali approfondimenti clinico-diagnostici.`,
    email: {
      label: 'Email',
      placeholder: 'Inserisci la tua email',
    },
    password: {
      label: 'Password',
      placeholder: 'Inserisci la tua password',
    },
    forgotPassword: 'Hai dimenticato la tua password?',
    button: 'ACCEDI',
    signUp: 'Non sei ancora registrato?',
    name: 'Nome',
    lastName: 'Cognome',
    phone: 'Telefono',
    city: 'Città',
    province: 'Provincia',
    completeRegistration: 'Completa registrazione',
    registrationCompleted:
      'Registrazione completata, procedi con il login per continuare',
    registrationError: 'Qualcosa è andato storto durante la registrazione',
    checkboxDataTreatment:
      "Dichiaro di aver letto e compreso l'informativa e do il consenso al trattamento da parte di AstraZeneca SpA: ",
    checkboxDataTreatmentLink: 'Informativa privacy.',
    checkboxPromo:
      "All'invio di comunicazioni elettroniche mediante posta elettronica per l'invio di informazioni inerenti alle iniziative, anche promozionali, di AstraZeneca SpA indicate nell’informativa.",
    checkboxInfoPromo:
      "All'uso di sistemi automatizzati di chiamata senza l'intervento di un operatore mediante sms o telefono cellulare, per l'invio di informazioni inerenti alle iniziative, anche promozionali, di AstraZeneca SpA indicate nell'informativa.",
    checkboxInterest:
      "Al trattamento da parte di AstraZeneca SpA dei miei dati personali per la definizione del mio profilo professionale al fine di individuare le principali sezioni ed aree di interesse, secondo le modalità descritte nell'informativa",
  },
  search: {
    labelCF: 'Ricerca per codice fiscale',
    placeholder: 'Cerca...',
  },
  Alert: {
    success: {
      passwordDescription: 'Password modificata con successo!',
    },
    error: {
      credentialsDescription: 'Email o password non riconosciute',
      forgotPassword:
        'Non è stato possibile inviare la mail. Riprova più tardi.',
      resetPassword:
        'Non è stato possibile impostare una nuova password. Riprova più tardi.',
      questionnaireError:
        'Non è stato possibile avviare il questionario. Riprova più tardi',
      genericLoginError:
        "Non è stato possibile eseguire l'autenticazione. Riprova più tardi.",
    },
    info: {
      surveyDescription:
        'Hai salvato il questionario come bozza, potrai continuarlo più tardi.',
      activeSurveyButton: 'Ok, torna al paziente',
      cancelSurveyButton: 'Riprendi il questionario',
      forgotPassword: 'Controlla la tua mail per resettare la password',
      resetPassword: 'La password è stata aggiornata correttamente',
    },
  },
  SignUp: {
    title: 'Registrazione',
    fc: {
      label: 'Codice Fiscale',
      placeholder: 'Inserisci il tuo codice fiscale',
    },
    email: {
      label: 'Email',
      placeholder: 'Inserisci la tua email',
    },
    password: {
      label: 'Password',
      placeholder: 'Inserisci la tua password',
    },
    confirmPassword: {
      label: 'Conferma password',
      placeholder: 'Inserisci di nuovo la password',
    },
    hintPassword:
      '8 caratteri, con almeno 1 lettera maiuscola, 1 minuscola, 1 numero e 1 carattere speciale',
    privacy: {
      text: 'Ho letto le ',
      link: 'condizioni di privacy e trattamento dati',
    },
    conditions: {
      text: 'Accetto ',
      link: 'termini e condizioni',
    },
    button: 'REGISTRATI',
  },
  ForgotPassword: {
    title: 'Password dimenticata',
    previousPage: 'Area utente',
    subtitle:
      'Per poter procedere, inserisci la tua email. ' +
      'Riceverai un link per resettare la password.',
    email: {
      label: 'Email',
      placeholder: 'Inserisci la tua email',
    },
    button: 'INVIA',
  },
  ResetPassword: {
    title: 'Reset Password',
    password: 'Inserisci nuova password',
    confirmPassword: 'Conferma nuova password',
    button: 'SALVA',
  },
  Sidebar: {
    label: {
      surveys: 'Questionari',
      medicalCenters: 'Centri medici',
      contents: 'Contenuti',
      mmg: 'MMG abilitati',
      admin: 'Gestione admin',
      logout: 'Log out',
      role: 'Admin',
      profile: 'Profilo',
      kpi: 'KPI',
      downloadMMGList: 'Scarica mmg abilitati',
      downloadModalTitle: 'Scarica lista MMG abilitati',
      downloadModalBody:
        'Stai per scaricare la lista degli MMG abilitati, desideri proseguire?',
      download: 'Scarica',
    },
    altImage: 'Immagine del profilo',
  },
  QuestionnaireCard: {
    title: {
      lungCancer: 'Tumore del polmone mutazione EGFR',
    },
    buttonPrimary: 'DETTAGLIO',
    deleteSurvey: 'Elimina il questionario',
    publishSurvey: 'Pubblica',
  },
  SurveyResult: {
    risk: 'Rischio',
    riskLevel: {
      LOW: 'basso',
      MEDIUM_LOW: 'medio/basso',
      MEDIUM_HIGH: 'medio/alto',
      HIGH: 'alto',
    },
  },
  AdminQuestionnaires: {
    title: 'Questionari',
    searchLabel: 'Ricerca per patologia',
    error:
      'Impossibile recuperare i dati relativi alle strutture dei questionari. Riprova.',
    alert: {
      publish: {
        success: 'Questionario pubblicato con successo',
        error: 'Non è possibile pubblicare il questionario',
      },
      delete: {
        success: 'Questionario eliminato con successo',
        error: 'Non è possibile eliminare il questionario',
      },
      cantDeleteAnswer:
        'Non è possibile eliminare la risposta, ogni domanda deve avere almeno 2 possibili risposte.',
    },
  },
  AdminMMG: {
    title: 'MMG abilitati',
    button: 'SCARICA LISTA MMG ABILITATI',
  },
  Accordion: {
    newTitle: 'Domanda {{questionOrder}}',
    deleteQuestion: 'Elimina domanda',
    title: {
      label: 'Titolo domanda {{questionOrder}}',
      placeholder: 'Inserisci il titolo della domanda',
    },
    description: {
      label: 'Breve descrizione (opzionale)',
      placeholder: 'Inserisci la descrizione',
    },
    reference: {
      label: 'Referenza (opzionale)',
      placeholder: '--',
    },
    button: 'AGGIUNGI OPZIONE DI RISPOSTA',
  },
  AnswerChoice: {
    newTitle: 'Opzione di risposta - {{answerCounter}}',
    deleteAnswer: 'Elimina risposta',
    title: {
      label: 'Titolo opzione',
      placeholder: 'Inserisci il titolo opzione',
    },
    description: {
      label: 'Breve descrizione (opzionale)',
      placeholder: 'Inserisci la descrizione',
    },
    reference: {
      label: 'Referenza (opzionale)',
      placeholder: '--',
    },
    score: {
      label: 'Score opzione',
      placeholder: 'Inserisci lo score',
    },
    answerJump: {
      label: 'Salta a domanda',
      placeholder: 'Scegli una domanda',
      endOfQuestionnaire: 'Fine questionario',
    },
    riskColor: {
      label: 'Categoria di rischio',
      placeholder: 'Scegli una categoria',
      colors: {
        red: 'ROSSO - rischio alto',
        orange: 'ARANCIONE - rischio medio-alto',
        green: 'VERDE - rischio basso',
        yellow: 'GIALLO - rischio medio-basso',
      },
    },
  },
  Patients: {
    heading: 'I tuoi pazienti',
    searchText: {
      label: 'Ricerca per codice fiscale / email',
      placeholder: 'Cerca...',
    },
    noPatientsFound: 'Nessun paziente trovato',
    table: {
      heading: {
        socialSecurity: 'Codice fiscale',
        email: 'Email',
        status: 'Stato',
      },
    },
    cta: {
      label: 'Nuovo paziente',
    },
    alert: {
      resendRegistrationMail: {
        success: 'Email inviata con successo',
        error: 'Impossibile inviare email. Riprova.',
      },
    },
    description:
      'Registra i pazienti per sottoporre loro il questionario e valutare il livello di rischio di tumore. Per registrare un paziente basterà inserire il suo codice fiscale e la sua email. *Ricorda di raccogliere il consenso privacy del tuo paziente flaggando la casella corrispondente.',
  },
  AddPatient: {
    fiscalCode: {
      label: 'Codice fiscale',
      placeholder: 'Inserisci il codice fiscale',
    },
    email: {
      label: 'Email',
      placeholder: "Inserisci l'email",
    },
    cta: {
      submit: 'CREA',
      delete: 'ANNULLA',
    },
    title: 'Nuovo paziente',
  },
  Patient: {
    breadCrumb: {
      parentPage: {
        label: 'I tuoi pazienti',
      },
      currentPage: {
        label: 'Dettaglio paziente',
      },
    },
    heading: 'Dettaglio paziente',
    questionnaires: {
      heading: 'Cronologia questionari',
      cta: 'NUOVO QUESTIONARIO',
      error: 'Impossibile recuperare i dati relativi ai questionari. Riprova.',
    },
    description:
      'Sottoponi il questionario al tuo paziente per valutare il livello di rischio di malattia e monitorarlo periodicamente.',
  },
  StatusLabel: {
    enabled: 'Registrato',
    disabled: 'In attesa',
    draft: 'Bozza',
    completed: 'Completato',
  },
  PatientData: {
    label: {
      email: 'Email',
      fiscalCode: 'Codice fiscale',
      status: 'Stato',
      pathologies: 'Esito questionario POSITIVO:',
    },
    error: {
      generic: 'Failed to retrieve data. Please, retry.',
    },
  },
  AdminQuestionnaireDetail: {
    headerButton: {
      save: 'SALVA',
      edit: 'MODIFICA',
      cancel: 'Annulla modifiche',
      deleteSurvey: 'Elimina questionario',
    },
    title: 'Titolo',
    subtitle: {
      label: 'Sottotitolo',
      placeholder: 'Inserisci sottotitolo',
    },
    instructions: {
      label: 'Istruzioni',
      placeholder: 'Inserisci istruzioni',
    },
    addQuestion: 'AGGIUNGI DOMANDA',
    alert: {
      cancel: {
        message: 'Vuoi cancellare le modifiche fatte finora?',
        activeButtonLabel: 'Sì, procedi.',
        cancelButtonLabel: 'Annulla.',
      },
    },
    deleteQuestion: {
      message: 'Vuoi eliminare questa domanda?',
    },
  },
  ScoreThreshold: {
    title: 'Pubblica',
    labelTotalScore: 'Score totale',
    labelMinScore: 'Score minimo',
    threshold: {
      label: 'Soglia di rischio basso',
      placeholder: 'Inserisci soglia',
    },
    threshold2: {
      label: 'Soglia di rischio alto',
      placeholder: 'Inserisci soglia',
    },
    buttonDraft: 'Salva Bozza',
    buttonPublish: 'Pubblica',
    error: {
      generic:
        'Errore durante il salvataggio. Controlla che tutti i campi obbligatori siano inseriti correttamente!',
      equalScore:
        'Lo score totale e lo score minimo non possono essere uguali!',
    },
    alert: {
      success: 'Questionario salvato con successo!',
    },
  },
  QuestionnairesTable: {
    heading: {
      questionnaire: 'Questionario',
      date: 'Data',
      status: 'Stato',
      result: 'Esito',
    },
    filters: {
      searchByTitle: 'Ricerca per titolo questionario',
      questionnaireStatus: 'Stato questionario',
      questionnaireResult: 'Esito questionario',
      applyFilters: 'Applica filtri',
      resetFilters: 'Resetta filtri',
      placeholder: 'Vedi tutto',
      noResults: 'Nessun questionario da mostrare',
    },
    data: {
      result: {
        positive: 'Positivo',
        negative: 'Negativo',
      },
    },
    delete: {
      confirm: 'Vuoi davvero eliminare il questionario?',
      error: 'Operazione fallita',
      success: 'Questionario rimosso correttamente',
    },
  },
  NewQuestionnaireModal: {
    title: 'Nuovo questionario',
    noQuestionnairesAvailable: 'Nessun questionario disponibile',
    cta: {
      cancel: 'Annulla',
      proceed: 'Procedi',
    },
    forcedPlaceholder:
      "Questionario sul rischio di tumore al polmone sviluppato da clinici dell'Università di Torino",
  },
  NewQuestionnaire: {
    title: 'Nuovo questionario',
    instructionsTitle: 'Istruzioni per la compilazione:',
    buttons: {
      start: 'inizia',
      cancel: 'annulla',
    },
    refreshAlert:
      'Non è possibile aggiornare la pagina durante la compilazione di un questionario. Verrai portato alla sezione "Questionario".',
    breadCrumb: {
      parentPage: {
        label: 'Questionario',
      },
      currentPage: {
        label: 'Nuovo questionario',
      },
    },
    disclaimer: `Il presente questionario è stato sviluppato da un gruppo di lavoro
    di oncologi dell'università di Torino e non intende sostituirsi al giudizio medico`,
    bottomDisclaimer:
      'Inizia il questionario digitalizzato sul rischio di tumore al polmone',
  },
  ActiveQuestionnaire: {
    buttonDraft: 'salva come bozza',
    errorCreateQuestionnaire: "Errore nell'invio del questionario",
    buttonNext: 'avanti',
    buttonFinish: 'concludi',
    buttonBack: 'indietro',
    buttonClose: 'chiudi',
    references: 'Referenze',
    copyId: 'AstraZeneca - Codice IT-9799 - Data di approvazione: 5/06/2023',
  },
  ResultQuestionnaire: {
    bookAnAppointment: 'Prenota la visita',
    result: {
      POSITIVE: 'positivo',
      NEGATIVE: 'negativo',
      STRONG_POS: `Il paziente è a rischio, si consiglia un approfondimento diagnostico verso
      un centro qualificato per la diagnosi del tumore al polmone.`,
      WEAK_POS: `Il paziente è a basso rischio. Considerare un programma di screening
      per il tumore del polmone se appropiato.`,
    },
    title: 'Risultato questionario',
    resultIs: "L'esito è ",
    button: 'Ripeti il questionario',
    generalError: 'Errore! Qualcosa è andato storto...',
    bottomDisclaimer: `Il risultato è puramente una riproduzione di questionario sviluppato da clinici dell'Università di Torino.\n
    Questo non costituisce un parere medico ed è puramente un consiglio.`,
    evaluationDisclaimer: `*Previa valutazione dei criteri per l'eleggibilità ad un programma di screening\n
    (età compresa tra 55 e 75 anni, forte fumatore o ex fumatore - ha smesso da meno di 15 anni -
    con un consumo medio di 20 sigarette al giorno per 30 anni)`,
  },
  DeleteModal: {
    title: 'Attenzione!',
    bodyQuestionnaire:
      'Hai scelto di eliminare il questionario {{questionnaire}}; se procedi tutti i dati verranno cancellati.',
    bodyQuestion:
      'Hai scelto di eliminare una domanda: la domanda potrebbe essere collegata ad un’altra.',
    subtitle: 'Sei sicuro di voler proseguire?',
    cta: {
      proceed: 'Sì, procedi',
      undo: 'Annulla',
      ok: 'Ok',
    },
  },
  TermsAndConditions: {
    title: 'Termini e condizioni',
  },
  MedicalCenters: {
    title: 'Centri medici',
    newCenter: 'Nuovo centro',
    massiveImport: 'Import massivo da XLSX',
    templateDownload: 'Download template XLSX',
    errorFileExt: 'Estensione del file non valida',
    uploadError: "Errore durante l'upload del file",
    uploadErrorNotEmpty: ' Errore! Compila il campo : ',
    uploadErrorNotFound: 'Errore! Not trovato :  ',
    uploadErrorInvalid: 'Errore! Questo campo non è valido :  ',
    uploadErrorAlreadyExist: 'Errore! Questo Centro Medico già esiste:  ',
    uploadSuccess: 'Import massivo effettuato con successo',
    uploadBadFormat:
      "È presente un errore di formattazione all'interno del file, riprova",
    delete: {
      confirm: 'Vuoi davvero eliminare il centro medico?',
      error: 'Operazione fallita',
      success: 'Centro medico rimosso correttamente',
    },
  },
  ErrorPages: {
    notFound: '404',
    pageNotFound: 'La pagina non è stata trovata',
  },
  CentersMap: {
    heading: 'Mappa dei centri',
    selectAPathology: 'Seleziona una patologia',
    noSelectionError:
      'Gelocalizzazione non abilitata, cerca un indirizzo e scegli una patologia per visualizzare la mappa. Oppure abilita la geolocalizzazione.',
    noPathologySelected:
      'Nessuna patologia selezionata, selezionane una per visualizzare i centri.',
    noPathologies:
      'Non è presente alcuna patologia, impossibile visualizzare la mappa dei centri.',
    yourLocation: 'La tua posizione',
    description:
      'Indirizza il tuo paziente al centro più vicino in base alle sue esigenze. Puoi filtrare i risultati inserendo un luogo.',
  },
  AdminPathologies: {
    heading: 'Patologie',
    newPathology: 'Nuova patologia',
    delete: {
      confirm: 'Vuoi davvero eliminare la patologia?',
      error: 'Operazione fallita',
      success: 'Patologia rimossa correttamente',
    },
  },
  AdminPathologyCreate: {
    cta: 'Salva',
    cancel: 'Cancella',
    label: 'Nome',
    placeholder: 'Inserisci nome patologia',
    alertCreate: {
      success: 'Patologia aggiunta con successo!',
      error: 'La patologia esiste già a database',
    },
  },
  AdminContents: {
    heading: 'Contenuti',
    newContent: 'Nuovo contenuto',
    contentDetail: 'Dettaglio contenuto',
    delete: {
      confirm: 'Vuoi davvero eliminare questo contenuto?',
      error: 'Operazione fallita',
      success: 'Contenuto rimosso correttamente',
    },
  },
  AdminContentsDetail: {
    cancel: 'Elimina contenuto',
    title: {
      label: 'Titolo',
      placeholder: 'Inserisci un titolo',
    },
    image: {
      label: 'Immagine',
      placeholder: 'Nessuna immagine selezioanta',
    },
    url: {
      label: 'URL a risorsa esterna',
      placeholder: 'Inserisci un URL di una risorsa',
    },
    selectFile: 'Scegli file',
    contentType: {
      type: 'Tipo di contenuto',
      isPublic: 'Pubblico',
      isPrivate: 'Privato',
    },
    pathology: 'Patologia',
    description: {
      label: 'Descrizione',
      placeholder: 'Inserisci una descrizione',
    },
    type: 'Tipo',
    createSuccess: 'Contenuto creato con successo!',
    updateSuccess: 'Contenuto aggiornato con successo!',
    createError: 'Non è stato possibile creare il contenuto',
    updateError: 'Non è stato possibile aggiornare il contenuto',
  },
  AdminManage: {
    heading: 'Gestione admin',
    newAdmin: 'Nuovo admin',
    tableHeading: {
      firstName: 'Nome',
      lastName: 'Cognome',
      email: 'Email',
      status: 'Stato',
    },
    deleteAdmin: 'Elimina',
    delete: {
      confirm: 'Vuoi davvero eliminare l admin?',
      error: 'Operazione fallita',
      success: 'Admin rimosso correttamente',
    },
  },
  AdminManageDetail: {
    heading: 'Dettaglio admin',
    cancel: 'Elimina admin',
    deleteAlert: 'Vuoi davvero eliminare questo Admin?',
    save: 'Salva',
    changePassword: 'Vuoi modificare la password?',
    name: {
      label: 'Nome',
      placeholder: 'Inserisci nome',
    },
    surname: {
      label: 'Cognome',
      placeholder: 'Inserisci cognome',
    },
    email: {
      label: 'Email',
      placeholder: 'Inserisci email',
    },
    status: {
      label: 'Status admin',
      disable: 'Disabilitato',
      enable: 'Abilitato',
    },
    image: {
      label: 'Immagine del profilo',
      button: 'Scegli',
      errorTooBig: 'Il file è troppo grande. Massimo 2mb.',
      errorNotSupported: 'Non è una estensione di file supportata.',
    },
    selectFile: 'Scegli file',
    password: {
      label: 'Password',
      placeholder: 'Inserisci password',
    },
    alertCreate: {
      success: 'Admin aggiunto con successo!',
      error: "Non è stato possibile aggiungere l'admin",
    },
    alertUpdate: {
      success: 'Admin aggiornato con successo!',
      error: "Non è stato possibile modificare l'admin",
    },
  },
  WeekDays: {
    monday: 'Lunedì',
    tuesday: 'Martedì',
    wednesday: 'Mercoledì',
    thursday: 'Giovedì',
    friday: 'Venerdì',
    saturday: 'Sabato',
    sunday: 'Domenica',
  },
  MedicalCentersDetail: {
    title: 'Dettaglio centro',
    save: 'Salva',
    deleteCenter: 'Elimina centro',
    search: {
      label: 'Cerca un luogo',
      placeholder: 'Es. Istituto Nazionale dei Tumori',
      button: 'Riempi campi',
    },
    businessName: {
      label: 'Ragione sociale',
      placeholder: 'Inserisci la ragione sociale',
    },
    pathologiesLabel: 'Patologia/e',
    streetName: {
      label: 'Via',
      placeholder: 'Es: Via Torino',
    },
    houseNumber: {
      label: 'N. civico',
      placeholder: 'Es: 1',
    },
    zipCode: {
      label: 'CAP',
      placeholder: 'Es: 20123',
    },
    city: {
      label: 'Città',
      placeholder: 'Es: Milano',
    },
    province: {
      label: 'Provincia',
      placeholder: 'Es: Milano',
    },
    email: {
      label: 'Email',
      placeholder: 'Es: mail@mail.it',
    },
    phoneNumber: {
      label: 'Telefono',
      placeholder: 'Es: 02 58584511',
    },
    description: {
      label: 'Descrizione',
      placeholder: 'Inserisci la descrizione',
    },
    address: 'Indirizzo',
    closed: 'Chiuso',
    openingHours: 'Orari',
    open: 'Aperto',
    from: 'Dalle ore',
    to: 'Alle ore',
    alertCreate: {
      success: 'Centro medico aggiunto con successo!',
      error: 'Non è stato possibile aggiungere il centro medico',
    },
    alertUpdate: {
      success: 'Centro medico aggiornato con successo!',
      error: 'Non è stato possibile modificare il centro medico',
      errorOpeningHours:
        'È presente un errore negli orari di apertura, ricontrolla i campi e riprova',
    },
    addressError: 'Indirizzo non trovato',
    noMedicalCentersFound:
      "Nessun centro medico trovato presso l'indirizzo impostato, prova con un altro indirizzo",
  },
  Tooltips: {
    resendEmail: 'Reinvia la mail di attivazione profilo paziente',
  },
  EditPassword: {
    title: 'Modifica password',
  },
  ErrorStrings: {
    email: 'Email',
    fiscalCode: 'Codice fiscale',
    AlreadyUsed: 'già esistente.',
    Invalid: 'non valido/a',
    pathologiesId: 'Patologie',
    NotEmpty: 'non può essere vuoto',
    url: 'Url',
    URL: 'deve essere un url valido',
    phone: 'Numero di telefono',
    Phone: 'non valido',
  },
  Profile: {
    title: 'Profilo',
    fiscalCode: 'Codice fiscale',
    email: 'Email',
    editPassword: 'Vuoi modificare la password?',
    view: 'Visualizza ',
    termsAndConditions: 'Termini e condizioni',
    deleteProfile: 'Elimina profilo',
    deleteProfileText:
      'Hai scelto di eliminare il tuo profilo; se procedi tutti i tuoi dati personali verranno cancellati. Sei sicuro di voler proseguire?',
    deleteProfileDoctorText:
      'Hai scelto di eliminare il tuo profilo; se procedi tutti i tuoi dati personali verranno cancellati. Verrà inoltre eliminato ogni tuo riferimento dai tuoi questionari. Sei sicuro di voler proseguire?',
    unlinkMMG: 'Rimuovi collegamento MMG',
    unlinkMMGText: '',
    genericError: 'Qualcosa è andato storto, riprova più tardi',
    editPasswordTitle: 'Modifica password',
    edit: 'Modifica',
    save: 'Salva',
  },
  MMGProfile: {
    updateError: 'Non è stato possibile aggiornare il profilo',
    updateSuccess: 'Il profilo è stato aggiornato correttamente',
  },
  KPI: {
    totalQuestionnaires: 'Questionari somministrati:',
    result: 'Esito',
    highRisk: 'Fortemente Positivo',
    mediumHighRisk: 'Positivo',
    lowRisk: 'Negativo',
    dateLabel: {
      from: 'Data da',
      to: 'Data a',
    },
  },
  Privacy: {
    title: 'Privacy',
  },
  MedicalCenterType: {
    placeholderDropdown: 'Inserisci la tipologia centro',
    label: 'Tipologia centro',
    RISP: 'Centro Rete Italiana Screening Polmonare',
    generic: 'Generico',
  },
  Questionnaire: {
    heading: 'Questionario',
    cta: {
      label: 'Nuovo questionario',
    },
    disclaimers: [
      `Il presente questionario è stato sviluppato da un gruppo di lavoro
    di oncologi dell'università di Torino e non intende sostituirsi al giudizio medico`,
      "L'uso del questionario ed i suoi risultati sono offerti sulla base di letteratura scientifica.",
      "È responsabilità del medico curante l'indirizzamento verso terapie e decisioni riguardo i loro pazienti sulla base del loro giudizio clinico.",
      "L'uso di questo strumento non dovrebbe essere l'unica base di decisioni cliniche ed è fornito solo per supporto digitale.",
    ],
    bibliography: 'Bibliografia',
  },
  LeavingPagePrompt: {
    message: 'Vuoi uscire dal sito?',
  },
};
