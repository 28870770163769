import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

//Others
import {DEFAULT_MODAL_STATE, ModalContext} from '../../../store/modal/context';
import {useBEMClass} from '../../../hooks/use-bem-class';
import {getModalContent} from './utils';

//Other components
import {ButtonText} from '../../atoms/ButtonText';
import {IProps as IconProps} from '../../atoms/IconSVG/interfaces';

//Component namespace
import {IProps} from './interfaces';
import './Modal.scss';

const Modal = ({className}: IProps) => {
  //Internal state
  const [isTransparent, setTransparency] = useState<boolean>(true);
  const iconLeft = useMemo<IconProps>(
    () => ({
      icon: 'icon-close',
      size: 14,
    }),
    [],
  );

  //Helpers
  const BEMClass = useBEMClass();
  const rootRef = useRef<HTMLDivElement>(null);
  const {value: modalContent, setValue: setModalContent} =
    useContext(ModalContext);

  const closeModal = useCallback(() => {
    rootRef.current?.addEventListener('transitionend', () => {
      setModalContent(DEFAULT_MODAL_STATE);
    });

    setTransparency(true);
  }, [setModalContent]);

  const ModalContent: any = useMemo(
    () =>
      modalContent?.content ? getModalContent(modalContent?.content) : null,
    [modalContent],
  );

  const transparencyClassModifier = useMemo<string>(
    () => (isTransparent ? BEMClass('az-modal', '', 'transparent') : ''),
    [isTransparent, BEMClass],
  );

  useEffect(() => {
    setTimeout(() => setTransparency(!ModalContent), 0);
  }, [ModalContent]);

  return modalContent ? (
    <div
      className={classNames('az-modal', transparencyClassModifier, className)}
      ref={rootRef}>
      <div className="az-modal__content">
        <ButtonText
          className="az-modal__close"
          iconLeft={iconLeft}
          label=""
          onClick={closeModal}
        />
        <ModalContent {...(modalContent?.props ?? {})} />
      </div>
    </div>
  ) : null;
};

export default React.memo(Modal);
