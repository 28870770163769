import {gql} from '@apollo/client';

export const CREATE_MEDICAL_CENTER = gql(
  `mutation CreateMedicalCenter($input: MedicalCenterInput!) {
    createMedicalCenter(input: $input) {
      id
    }
  }`,
);

export const GET_MEDICAL_CENTERS = gql`
  query GetMedicalCenters(
    $input: MedicalCenterFilter
    $limit: Int
    $offset: Int
  ) {
    medicalCenters(input: $input, limit: $limit, offset: $offset) {
      info {
        total
      }
      items {
        id
        name
        companyName
        type
        description
        street
        streetNumber
        city
        province
        postalCode
        region
        latitude
        longitude
        phone
        email
        openingHours {
          day
          open
          fromHour
          toHour
          fromHour2
          toHour2
        }
        pathologies {
          id
          name
        }
      }
    }
  }
`;

export const GET_MEDICAL_CENTER = gql`
  query GetMedicalCenter($input: Int!) {
    medicalCenter(id: $input) {
      id
      name
      companyName
      description
      street
      type
      streetNumber
      city
      province
      postalCode
      region
      latitude
      longitude
      phone
      email
      openingHours {
        day
        open
        fromHour
        toHour
        fromHour2
        toHour2
      }
      pathologies {
        id
        name
      }
    }
  }
`;

export const UPDATE_MEDICAL_CENTER = gql(
  `mutation UpdateMedicalCenter($input: MedicalCenterInput!) {
    updateMedicalCenter(input: $input) {
      id
    }
  }`,
);

export const DELETE_MEDICAL_CENTER = gql`
  mutation DeleteMedicalCenter($id: Int!) {
    deleteMedicalCenter(id: $id)
  }
`;
