/**
 * @format
 */

import React, {useMemo} from 'react';
import classNames from 'classnames';

//Component namespace
import {IProps} from './interfaces';
import './Marker.scss';

const Marker = (props: IProps) => {
  const {color, className, description = null} = props;
  const colorClassName = useMemo(() => `bg-${color}`, [color]);

  return (
    <>
      {description && <p className="az-marker__description">{description}</p>}
      <div className={classNames('az-marker', colorClassName, className)} />
    </>
  );
};

export default React.memo(Marker);
