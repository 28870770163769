import React, {useCallback, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import {useTranslations} from '@vidiemme/react-i18n';

// Others
import {noop} from '../../../utils/noop';
import {
  emptyFieldConstraint,
  numericalityConstraint,
  useValidator,
} from '../../../hooks/validator';
import {RiskLevel} from '../../../utils/riskLevelEnum';

// Components
import {ButtonText} from '../../atoms/ButtonText';
import {LabeledInput} from '../LabeledInput';
import {Dropdown} from '../Dropdown';

// Types
import {DropDownOption} from '../Dropdown/interface';

// Interface
import {IProps} from './interface';

// Style
import './AnswerChoice.scss';

const AnswerChoice = ({
  answer,
  className,
  counter = 1,
  nextQuestions = [],
  onDelete = noop,
  onUpdate = noop,
  publishing = false,
  handleParentError,
  disabled,
}: IProps) => {
  const {t} = useTranslations();
  const {validateField} = useValidator();

  // State
  const [title, setTitle] = useState<string>(answer.title!);
  const [description, setDescription] = useState<string>(answer.description!);
  const [reference, setReference] = useState<string>(answer.reference!);
  const [score, setScore] = useState<number>(answer.score ?? 0);
  const [riskLevel, setRiskLevel] = useState<RiskLevel | undefined>(
    answer.riskLevel ?? RiskLevel.LOW_RISK,
  );
  const [nextQuestionId, setNextQuestionId] = useState<number | undefined>(
    answer.nextQuestionId,
  );

  // Error state
  const [hasTitleError, setHasTitleError] = useState<boolean>(false);
  const [hasScoreError, setHasScoreError] = useState<boolean>(false);

  // Return true if title input is valid
  const isTitleValid = useMemo(
    () => validateField(title, emptyFieldConstraint),
    [title, validateField],
  );

  // Return true if score input is valid
  const isScoreValid = useMemo(
    () => validateField(score, numericalityConstraint),
    [score, validateField],
  );

  const scoreOptions = useMemo<DropDownOption<number>[]>(
    () => [
      {
        label: '-1',
        selected: score === -1,
        value: -1,
      },
      {
        label: '0',
        selected: score === 0,
        value: 0,
      },
      {
        label: '0.5',
        selected: score === 0.5,
        value: 0.5,
      },
      {
        label: '1',
        selected: score === 1,
        value: 1,
      },
      {
        label: '1.5',
        selected: score === 1.5,
        value: 1.5,
      },
      {
        label: '2',
        selected: score === 2,
        value: 2,
      },
      {
        label: '2.5',
        selected: score === 2.5,
        value: 2.5,
      },
    ],
    [score],
  );

  const getRiskLevelByScore = (score: number) => {
    switch (score) {
      case 2.5:
      case 2: {
        return {
          label: t('AnswerChoice.riskColor.colors.red'),
          value: RiskLevel.HIGH_RISK,
        };
      }
      case 1.5:
      case 1: {
        return {
          label: t('AnswerChoice.riskColor.colors.orange'),
          value: RiskLevel.MEDIUM_HIGH_RISK,
        };
      }
      case 0.5: {
        return {
          label: t('AnswerChoice.riskColor.colors.yellow'),
          value: RiskLevel.MEDIUM_LOW_RISK,
        };
      }
      case 0:
      case -1: {
        return {
          label: t('AnswerChoice.riskColor.colors.green'),
          value: RiskLevel.LOW_RISK,
        };
      }
    }
  };

  // Array of risk color options dropdown

  //Go to question options
  const goToNextQuestions = useMemo<DropDownOption<number>[]>(() => {
    let newQuestions = nextQuestions?.map(question => ({
      label: question.title || `Question ${question.order}`,
      selected: nextQuestionId === question.id,
      value: question.id,
    }));
    newQuestions.push({
      label: t('AnswerChoice.answerJump.endOfQuestionnaire'),
      selected: false,
      value: NaN,
    });
    return newQuestions;
  }, [nextQuestions, t, nextQuestionId]);

  const onDeleteHandler = useCallback(
    () => onDelete(answer),
    [onDelete, answer],
  );
  const onRiskLevelChange = useCallback((option: DropDownOption<RiskLevel>) => {
    setRiskLevel(option.value);
  }, []);

  const onNextQuestionIdChange = useCallback(
    (option: DropDownOption<number>) => {
      setNextQuestionId(option.value);
    },
    [],
  );

  //Force reset nextQuestionId when a question is deleted
  useEffect(() => {
    if (!nextQuestions.find(question => question.id === nextQuestionId)) {
      setNextQuestionId(undefined);
    }
  }, [nextQuestions, nextQuestionId]);

  //Notify parents of data changing
  useEffect(() => {
    if (
      answer.reference !== reference ||
      answer.title !== title ||
      answer.score !== score ||
      answer.description !== description ||
      answer.riskLevel !== riskLevel ||
      answer.nextQuestionId !== nextQuestionId
    ) {
      onUpdate({
        ...answer,
        description,
        nextQuestionId,
        reference,
        riskLevel,
        score,
        title,
      });
    }
  }, [
    answer,
    description,
    nextQuestionId,
    reference,
    riskLevel,
    score,
    title,
    onUpdate,
  ]);

  // Validate filed when admin try to publish a questionnaire
  useEffect(() => {
    if (publishing) {
      setHasTitleError(!isTitleValid);
      setHasScoreError(!isScoreValid);
      if (handleParentError && (hasTitleError || hasScoreError)) {
        handleParentError(true);
      } else if (handleParentError) {
        handleParentError(false);
      }
    }
  }, [
    isTitleValid,
    isScoreValid,
    publishing,
    handleParentError,
    hasTitleError,
    hasScoreError,
  ]);

  return (
    <div className={classNames('az-answer-choice', className)}>
      <div className="az-answer-choice__header">
        <h2 className="az-answer-choice__title">
          {`${t('AnswerChoice.newTitle', {answerCounter: counter})}`}
        </h2>
        {disabled ? (
          <></>
        ) : (
          <ButtonText
            label={t('AnswerChoice.deleteAnswer')}
            onClick={onDeleteHandler}
            className="az-answer-choice__button-text"
          />
        )}
      </div>
      <div className="az-answer-choice__form-container">
        <div className="az-answer-choice__inputs-row">
          <div className="az-answer-choice__input">
            <LabeledInput
              label={t('AnswerChoice.title.label')}
              placeholder={t('AnswerChoice.title.placeholder')}
              value={title}
              error={hasTitleError}
              onChange={setTitle}
              onFocus={() => setHasTitleError(false)}
              disabled={disabled}
            />
          </div>
          <div className="az-answer-choice__input">
            <LabeledInput
              label={t('AnswerChoice.description.label')}
              placeholder={t('AnswerChoice.description.placeholder')}
              value={description}
              onChange={setDescription}
              disabled={disabled}
            />
          </div>
          <div className="az-answer-choice__input">
            <LabeledInput
              label={t('AnswerChoice.reference.label')}
              placeholder={t('AnswerChoice.reference.placeholder')}
              value={reference}
              onChange={setReference}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="az-answer-choice__inputs-row">
          <div className="az-answer-choice__input">
            <Dropdown
              dropdownPlaceholder={t('AnswerChoice.score.placeholder')}
              className="az-score-modal__element-container"
              isDisabled={disabled}
              label={t('AnswerChoice.score.label')}
              onChange={option => {
                setScore(option.value);
                setRiskLevel(getRiskLevelByScore(option.value)!.value);
              }}
              options={scoreOptions}
            />
          </div>
          <div className="az-answer-choice__input">
            <Dropdown
              dropdownPlaceholder={t(
                'AnswerChoice.answerJump.endOfQuestionnaire',
              )}
              isDisabled={
                (nextQuestions && nextQuestions.length === 0) || disabled
              }
              label={t('AnswerChoice.answerJump.label')}
              onChange={onNextQuestionIdChange}
              options={goToNextQuestions}
              error={publishing}
            />
          </div>
          <div className="az-answer-choice__input">
            <LabeledInput
              placeholder={t('AnswerChoice.riskColor.placeholder')}
              disabled={true}
              label={t('AnswerChoice.riskColor.label')}
              error={publishing}
              value={getRiskLevelByScore(score)!.label}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AnswerChoice);
