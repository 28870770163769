import {QuestionnaireStructureCreateInput} from '../../../store/structureQuestionnaire';
import {useMemo} from 'react';

/**
 * @description Calculates questionnaire max and min scores.
 *
 * Max scores = sum of all the answers with higher score for each question
 * Min score = sum of all the answers with minimum score for each question
 */
export const useQuestionnaireScores = (
  questionnaire: QuestionnaireStructureCreateInput,
) => {
  const maxScore = useMemo<number>(
    () =>
      questionnaire.questions.reduce((currentScore, question) => {
        const maxQuestionScore = question.answers.reduce(
          (questionScore, answer) =>
            (answer.score ?? 0) > questionScore
              ? (answer.score as number)
              : questionScore,
          0,
        );

        return currentScore + maxQuestionScore;
      }, 0),
    [questionnaire],
  );

  //Sum every minimum score for each answer
  const minScore = useMemo<number>(
    () =>
      //For each question
      questionnaire.questions.reduce((currentScore, question) => {
        //Find minimum answer score, 0 if nothing is found
        const currentQuestionMinimumScore = question.answers.reduce(
          (questionScore, answer, idx) => {
            if (idx === 0) {
              return answer?.score ?? 0;
            }

            if (answer?.score || answer?.score === 0) {
              return (answer.score as number) < questionScore
                ? (answer.score as number)
                : questionScore;
            }

            return questionScore;
          },
          0,
        );

        return currentScore + currentQuestionMinimumScore;
      }, 0),
    [questionnaire],
  );

  return {
    maxScore,
    minScore,
  };
};
