import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import {useLocation} from 'react-router-dom';

// Others
import {
  useValidator,
  passwordConstraint,
  signUpConstraints,
  confirmPasswordConstraint,
} from '../../../hooks/validator';
import {useNavigation} from '../../../navigation';

//Templates
import {Base} from '../../templates/Base';

// Components
import {Button} from '../../atoms/Button';
import {LabeledInput} from '../../molecules/LabeledInput';
import {CheckableInput} from '../../atoms/CheckableInput';
import {Link} from '../../atoms/Link';
import {
  useConfirmRegistration,
  useGetUserData,
} from '../../../store/authentication';
import {AlertContext} from '../../../store/alert/context';

// Types
import {InputType} from '../../molecules/LabeledInput/enum';
import {UserResponse} from '../../../store/api';
import {UserRegistrationInput} from '../../../store/authentication/interfaces';

// Style
import './SignUp.scss';

const SignUp = (): JSX.Element => {
  const {t} = useTranslations();
  const params = new URLSearchParams(useLocation().search);
  const token = params.get('token') ?? '';

  const [userData, setUserData] = useState<UserResponse>();
  const {setValue: setAlert} = useContext(AlertContext);

  const {validateField, validateForm} = useValidator();
  const {data: dataQuery, error: errorQuery} = useGetUserData({token});
  const [confirmRegistration, {error: errorMutate}] = useConfirmRegistration();
  const {goToMain} = useNavigation();

  useEffect(() => {
    if (!token) {
      setAlert({
        type: 'ERROR',
        message: t('Alert.error.missingToken'),
      });
    }
    if (errorQuery) {
      setAlert({
        type: 'ERROR',
        message: t('Alert.error.missingUser'),
      });
    }
    setUserData(dataQuery?.userByToken);
  }, [dataQuery, errorQuery, setAlert, t, token]);

  // Inputs state
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  // Input error state
  const [hasPasswordError, setHasPasswordError] = useState<boolean>(false);
  const [hasConfirmPasswordError, setHasConfirmPasswordError] =
    useState<boolean>(false);

  // Checkbox state
  const [isCheckedPrivacy, setIsCheckedPrivacy] = useState<boolean>(false);
  const [isCheckedConditions, setIsCheckedConditions] =
    useState<boolean>(false);

  const onPrivacyCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsCheckedPrivacy(event.target.checked);
    },
    [],
  );

  const onConditionCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsCheckedConditions(event.target.checked);
    },
    [],
  );

  // Return true if password input is valid
  const isPasswordValid = useMemo(
    () => validateField(password, passwordConstraint),
    [password, validateField],
  );

  // Return true if confirm password input is valid
  const isConfirmPasswordValid = useMemo(
    () =>
      validateForm(
        {password, confirmPassword},
        {confirmPassword: confirmPasswordConstraint},
      ),
    [validateForm, password, confirmPassword],
  );

  const isFormValid = useMemo(() => {
    return validateForm(
      {
        password,
        confirmPassword,
        privacy: isCheckedPrivacy,
        conditions: isCheckedConditions,
      },
      signUpConstraints,
    );
  }, [
    confirmPassword,
    isCheckedConditions,
    isCheckedPrivacy,
    password,
    validateForm,
  ]);

  const onSubmit = () => {
    const dataToSend: UserRegistrationInput = {
      token,
      password,
      confirmPassword,
      termsCondition: isCheckedConditions,
      privacy: isCheckedPrivacy,
    };
    confirmRegistration({
      variables: {
        input: dataToSend,
      },
    }).then((res: any) => {
      if (res?.data?.confirmRegistration?.email) {
        goToMain();
      }
    });
    if (errorMutate) {
      setAlert({
        type: 'ERROR',
        message: t('Alert.error.credentialsDescription'),
      });
    }
  };

  return (
    <Base isAuth={false}>
      <div className="az-signup">
        <h1 className="az-signup__title">{t('SignUp.title')}</h1>
        <div className="az-signup__content">
          <div className="az-signup__inputs-container">
            <div className="az-signup__inputs-wrapper">
              <LabeledInput
                disabled
                className="az-signup__input"
                value={userData?.fiscalCode}
                type={InputType.TEXT}
                label={t('SignUp.fc.label')}
              />
              <LabeledInput
                disabled
                className="az-signup__input"
                value={userData?.email}
                type={InputType.EMAIL}
                label={t('SignUp.email.label')}
              />
            </div>
            <div className="az-signup__inputs-wrapper">
              <div className="az-signup__input">
                <LabeledInput
                  type={InputType.PASSWORD}
                  label={t('SignUp.password.label')}
                  placeholder={t('SignUp.password.placeholder')}
                  error={hasPasswordError}
                  onChange={setPassword}
                  onFocus={() => setHasPasswordError(false)}
                  onBlur={() => setHasPasswordError(!isPasswordValid)}
                />
              </div>
              <div className="az-signup__input">
                <LabeledInput
                  type={InputType.PASSWORD}
                  label={t('SignUp.confirmPassword.label')}
                  placeholder={t('SignUp.confirmPassword.placeholder')}
                  error={hasConfirmPasswordError}
                  onChange={setConfirmPassword}
                  onFocus={() => setHasConfirmPasswordError(false)}
                  onBlur={() =>
                    setHasConfirmPasswordError(!isConfirmPasswordValid)
                  }
                />
              </div>
            </div>
            <p className="az-signup__hint-password">
              {t('SignUp.hintPassword')}
            </p>
          </div>
          <div className="az-signup__checkboxes">
            <CheckableInput
              inputName="privacy"
              isChecked={isCheckedPrivacy}
              onChange={onPrivacyCheckboxChange}>
              <span>
                {t('SignUp.privacy.text')}
                <Link className="underline">{t('SignUp.privacy.link')}</Link>
              </span>
            </CheckableInput>
            <CheckableInput
              inputName="conditions"
              isChecked={isCheckedConditions}
              onChange={onConditionCheckboxChange}>
              <span>
                {t('SignUp.conditions.text')}
                <Link className="underline" href={'/terms-and-conditions'}>
                  {t('SignUp.conditions.link')}
                </Link>
              </span>
            </CheckableInput>
          </div>
          <div className="az-signup__button-wrapper">
            <Button
              disabled={!isFormValid}
              label={t('SignUp.button')}
              className="az-signup__button"
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </Base>
  );
};

export default React.memo(SignUp);
