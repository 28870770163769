import React from 'react';
import classNames from 'classnames';

//Component namespace
import {IProps} from './interface';
import './Logo.scss';

const Logo = ({className = '', type = 'full'}: IProps): JSX.Element => (
  <div className={classNames(`az-logo az-logo--${type}`, className)} />
);

export default React.memo(Logo);
