import {useCallback} from 'react';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';

import {
  AnswerInput,
  CreateQuestionnaireStructureResponse,
  DeleteQuestionnaireStructureResponse,
  GetQuestionnairesStructureResponse,
  GetQuestionnaireStructureResponse,
  PublishQuestionnaireStructureResponse,
  QuestionInput,
  QuestionnaireStructureCreateInput,
  QuestionnaireStructureFilter,
  QuestionnaireStructureResponse,
  QuestionnaireStructureStatus,
  TypeQuestion,
  UpdateQuestionnaireStructureResponse,
} from './interfaces';
import {
  CREATE_STRUCTURE_QUESTIONNAIRE,
  DELETE_STRUCTURE_QUESTIONNAIRE,
  GET_STRUCTURE_QUESTIONNAIRE,
  GET_STRUCTURE_QUESTIONNAIRES,
  PUBLISH_STRUCTURE_QUESTIONNAIRE,
  UPDATE_STRUCTURE_QUESTIONNAIRE,
} from './queries';

export const useStructureQuestionnaire = () => {
  const structureQuestionnaire = useCallback(
    (): QuestionnaireStructureCreateInput => ({
      instructions: '',
      limitScore: 0,
      limitScore2: 0,
      questions: [],
      status: QuestionnaireStructureStatus.DRAFT,
      pathologyId: 1,
      subTitle: '',
    }),
    [],
  );

  const createStructureQuestionnaire = useCallback(
    (
      structureQuestionnaireScaffold: Partial<QuestionnaireStructureCreateInput> = {},
    ) => ({
      ...structureQuestionnaire(),
      ...structureQuestionnaireScaffold,
    }),
    [structureQuestionnaire],
  );

  const createQuestion = useCallback(
    (scaffold: Partial<QuestionInput>): QuestionInput => ({
      answers: [],
      id: 0,
      description: '',
      order: 0,
      reference: '',
      start: false,
      title: '',
      typeQuestion: TypeQuestion.SELECT,
      ...scaffold,
    }),
    [],
  );

  const createAnswer = useCallback(
    (scaffold: Partial<AnswerInput>): AnswerInput => ({
      choice: false,
      description: '',
      id: undefined,
      nextQuestionId: undefined,
      reference: '',
      riskLevel: undefined,
      score: undefined,
      text: '',
      title: '',
      ...scaffold,
    }),
    [],
  );

  const findAnswerIdx = useCallback(
    (answer: Partial<AnswerInput>, answers: Partial<AnswerInput>[]) =>
      answers.findIndex(compAnswer => answer.id! === compAnswer!.id!),
    [],
  );

  const findQuestionIdx = useCallback(
    (question: QuestionInput, questions: QuestionInput[] = []) =>
      questions.findIndex(compQuestion => question.id === compQuestion.id),
    [],
  );

  /**
   * @description Return the next answer expected index.
   * So far it returns the biggest answer index + 1, otherwise 0.
   */
  const nextAnswerId = useCallback(
    (questions: QuestionInput[] = []): number =>
      questions.reduce(
        (nextId, answer) =>
          (answer?.id && answer.id > nextId ? answer.id : nextId) + 1,
        0,
      ),
    [],
  );

  /**
   * @description Return the next question expected index.
   * So far it returns the biggest question index + 1, 0 otherwise.
   */
  const nextQuestionId = useCallback(
    (structureQuestionnaireQuestions: QuestionInput[]) =>
      structureQuestionnaireQuestions.reduce(
        (nextId, question) => (question.id > nextId ? question.id : nextId) + 1,
        0,
      ),
    [],
  );

  /**
   * @description Return the next question expected order.
   * So far it returns the biggest question order + 1, 1 otherwise.
   */
  const nextQuestionOrder = useCallback(
    (structureQuestionnaireQuestions: QuestionInput[]) =>
      structureQuestionnaireQuestions.reduce(
        (nextOrder, question) =>
          (question.order > nextOrder ? question.order : nextOrder) + 1,
        1,
      ),
    [],
  );

  const sanitizedQuestionnaire = useCallback(
    (
      structureQuestionnaire:
        | QuestionnaireStructureCreateInput
        | QuestionnaireStructureResponse,
    ) => {
      const {pathology, createdAt, ...questionnaire} =
        structureQuestionnaire as QuestionnaireStructureResponse;

      const questions: QuestionInput[] = questionnaire?.questions.map(
        question => {
          const answers = question.answers.map(answer => ({
            ...answer,
            id: Number(answer.id),
            nextQuestionId: answer.nextQuestionId ?? undefined,
          }));

          return {
            ...question,
            answers,
            id: Number(question.id),
          };
        },
      );

      return {
        ...questionnaire,
        questions,
        id: Number(questionnaire?.id),
      };
    },
    [],
  );

  return {
    createAnswer,
    createQuestion,
    createStructureQuestionnaire,
    findAnswerIdx,
    findQuestionIdx,
    nextAnswerId,
    nextQuestionId,
    nextQuestionOrder,
    sanitizedQuestionnaire,
    structureQuestionnaire,
  };
};

export const useCreateStructureQuestionnaire = () =>
  useMutation<CreateQuestionnaireStructureResponse>(
    CREATE_STRUCTURE_QUESTIONNAIRE,
  );

export const useUpdateStructureQuestionnaire = () =>
  useMutation<UpdateQuestionnaireStructureResponse>(
    UPDATE_STRUCTURE_QUESTIONNAIRE,
  );

export const useGetStructureQuestionnaires = (
  input: Partial<QuestionnaireStructureFilter> = {},
  limit: number = 30,
  offset: number = 0,
) =>
  useQuery<GetQuestionnairesStructureResponse>(GET_STRUCTURE_QUESTIONNAIRES, {
    variables: {input, limit, offset},
  });

export const useGetStructureQuestionnaire = (id?: number) =>
  useQuery<GetQuestionnaireStructureResponse>(GET_STRUCTURE_QUESTIONNAIRE, {
    variables: {id},
  });

export const useGetStructureQuestionnaireLazy = (id?: number) =>
  useLazyQuery<GetQuestionnaireStructureResponse>(GET_STRUCTURE_QUESTIONNAIRE, {
    variables: {id},
  });

export const useDeleteStructureQuestionnaire = () =>
  useMutation<DeleteQuestionnaireStructureResponse>(
    DELETE_STRUCTURE_QUESTIONNAIRE,
  );

export const usePublishStructureQuestionnaire = () =>
  useMutation<PublishQuestionnaireStructureResponse>(
    PUBLISH_STRUCTURE_QUESTIONNAIRE,
  );
