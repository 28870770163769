// Generic interfaces or namespace missing interfaces

import {QuestionnaireResponse} from '../questionnaire';
import {PathologyResponse} from '../pathologies';

export enum UserStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum Role {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_DOCTOR = 'ROLE_DOCTOR',
  ROLE_PATIENT = 'ROLE_PATIENT',
}

export interface UserResponse {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  fiscalCode: string;
  phone: string;
  city: string;
  province: string;
  termsCondition: boolean;
  privacy: boolean;
  doctor: UserResponse;
  status: UserStatus;
  role: Role;
  pathologies: PathologyResponse[];
  questionnaires: QuestionnaireResponse[];
  questionnairesCount: number;
  lastPositiveQuestionnaire: QuestionnaireResponse;
  owaId: string;
}

export interface PageInfoResponse {
  total: number;
}

export interface UserFilter {
  fiscalCode: string;
  skip: number;
  take: number;
}

export interface UserCreateInput {
  id?: number;
  email: string;
  firstName?: string;
  lastName?: string;
  fiscalCode?: string;
  pathologiesId?: number;
  imageBase64?: string;
  status?: UserStatus;
  termsCondition?: boolean;
  privacy?: boolean;
}

export interface UserUpdateInput {
  id: number;
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  termsCondition?: boolean;
  privacy?: boolean;
  imageBase64?: string;
  status?: UserStatus;
}
