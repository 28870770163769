import {useLazyQuery, useMutation, useQuery} from '@apollo/client';

import {MedicalCenterInput, MedicalCenterFilter} from './interfaces';
import {
  CREATE_MEDICAL_CENTER,
  DELETE_MEDICAL_CENTER,
  GET_MEDICAL_CENTERS,
  UPDATE_MEDICAL_CENTER,
} from './queries';

export const useCreateMedicalCenter = (medicalCenter?: MedicalCenterInput) =>
  useMutation(CREATE_MEDICAL_CENTER, {
    variables: {
      input: medicalCenter,
    },
  });

export const useLazyGetMedicalCenters = (
  input: MedicalCenterFilter | object,
  limit: number | null = null,
  offset = 0,
) =>
  useLazyQuery(GET_MEDICAL_CENTERS, {
    variables: {
      input,
      limit,
      offset,
    },
    fetchPolicy: 'no-cache',
  });

export const useGetMedicalCenters = (
  input: MedicalCenterFilter | object,
  limit: number | null = null,
  offset = 0,
) =>
  useQuery(GET_MEDICAL_CENTERS, {
    variables: {
      input,
      limit,
      offset,
    },
    fetchPolicy: 'no-cache',
  });

export const useGetMedicalCenter = (id: number) =>
  useQuery(GET_MEDICAL_CENTERS, {
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  });

export const useUpdateMedicalCenter = (medicalCenter: MedicalCenterInput) =>
  useMutation(UPDATE_MEDICAL_CENTER, {
    variables: {
      input: medicalCenter,
    },
  });

export const useDeleteMedicalCenter = () =>
  useMutation<{deleteMedicalCenter: boolean}>(DELETE_MEDICAL_CENTER);
