import React, {useCallback, useMemo} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

//Other Components
import {Marker} from '../../atoms/Marker';
import {HeroBanner} from '../../molecules/HeroBanner';
import {ResourceCard} from '../../organisms/ResourceCard';
import {HighlightSection} from '../../organisms/HighlightSection';
import {HighlightBlock} from '../../organisms/HighlightSection/interfaces';
import {HomepageTemplate} from '../../templates/Homepage';
import {Spinner} from '../../atoms/Spinner';
import {Button} from '../../atoms/Button';

// Components
import {Link} from '../../atoms/Link';

//Others
import {useResources} from '../../../store/resources/hooks';
import {useAuthentication} from '../../../store/authentication';
import {useNavigation} from '../../../navigation';
import useWindowDimensions from '../../../hooks/mediaQuery/useWindowDimensions';

//Component namespace
import {IProps} from './interfaces';
import './Homepage.scss';

const Homepage = (_props: IProps) => {
  const {isLoggedIn, token} = useAuthentication();

  if (token === 'no-token') {
    window.location.href = '/complete-registration';
  }

  const {width: windowWidth} = useWindowDimensions();
  const {
    goToResources,

    // @REMOVE_PATIENTS_DATA
    // goToPatients,
    goToQuestionnaire,

    goToCentersMap,
    goToHealthProfessionalLogin,
  } = useNavigation();
  //Internal state
  const {
    loading,
    error,
    data: {resources} = {resources: {items: []}},
  } = useResources({}, 6);
  //Here cause in the future it will be possible these values will come from BE
  const highlightBlocks = useMemo<HighlightBlock[]>(
    () => [
      {
        className: 'az-highlight-blocks__block',
        color: 'primary',
        highlightText: '+ 32',
        icon: 'home-icon-1',
        title: 'Lorem Ipsum Dolor Sit Amet',
      },
      {
        className: 'az-highlight-blocks__block',
        color: 'primary',
        highlightText: '76%',
        icon: 'home-icon-2',
        title: 'Lorem Ipsum Dolor Sit Amet',
      },
      {
        className: 'az-highlight-blocks__block',
        color: 'primary',
        highlightText: '+ 44',
        icon: 'home-icon-3',
        title: 'Lorem Ipsum Dolor Sit Amet',
      },
    ],
    [],
  );
  const highlightHeadingQuestionnaireText = useMemo<string>(
    () =>
      'Il percorso del paziente oncologico è unico e spesso complesso.\n ' +
      'Il medico di medicina generale ha un ruolo fondamentale nel facilitare la diagnosi precoce e l’indirizzamento alla medicina personalizzata.\n\n' +
      'Utilizza la piattaforma digitale eAZyREF ed il questionario che, con poche domande,\n ti permette di identificare il livello di rischio di insorgenza del tumore al polmone.\n\n',
    [],
  );

  const highlightHeadingMapText = useMemo<string>(
    () =>
      'Consulta la mappa dei centri oncologici per indirizzare il tuo paziente a quello più vicino.\n',
    [],
  );

  const heroBannerTitle = useMemo<string>(
    () => 'Accanto al medico,\nper il paziente',
    [],
  );
  const heroBannerSubtitle = useMemo<string>(
    () =>
      'Identificare il rischio e indirizzare i \n' +
      'pazienti a un adeguato percorso di \n' +
      'cura',
    [],
  );

  const onClickGoToQuestionnaire = useCallback(() => {
    // @REMOVE_PATIENTS_DATA
    // isLoggedIn ? goToPatients() : goToHealthProfessionalLogin();
    isLoggedIn ? goToQuestionnaire() : goToHealthProfessionalLogin();
  }, [
    goToHealthProfessionalLogin,
    // @REMOVE_PATIENTS_DATA
    // goToPatients,
    goToQuestionnaire,
    isLoggedIn,
  ]);

  const onClickGoToMap = useCallback(() => {
    isLoggedIn ? goToCentersMap() : goToHealthProfessionalLogin();
  }, [
    goToHealthProfessionalLogin,
    // @REMOVE_PATIENTS_DATA
    // goToPatients,
    goToCentersMap,
    isLoggedIn,
  ]);

  //Helpers
  const {t} = useTranslations();

  const renderResources = () => {
    const dataToRender = [...resources.items];

    const dataToRenderGrouped = [];

    while (dataToRender.length) {
      dataToRenderGrouped.push(
        dataToRender.splice(0, windowWidth > 767 ? 3 : 1),
      );
    }

    return dataToRenderGrouped.map((chunk, i) => {
      // Show max 6 resources
      const showingResources = chunk.length > 6 ? chunk.slice(0, 5) : chunk;
      return (
        <div
          className="grid grid-cols-1 gap-4 mx-auto md:grid-cols-3 md:gap-7"
          key={i}>
          {showingResources.map(resource => {
            return (
              <ResourceCard
                key={resource.id}
                className="az-homepage__resource"
                description={resource.description}
                imageUrl={resource.imageUrl}
                title={resource.title}
                url={resource.url}
                pathologies={resource.pathologies}
              />
            );
          })}
        </div>
      );
    });
  };

  return (
    <HomepageTemplate isAuth={isLoggedIn}>
      <HeroBanner
        slot="hero-banner"
        subtitle={heroBannerSubtitle}
        title={heroBannerTitle}
      />

      <div className="az-homepage__video-container">
        <video
          controls
          className="az-homepage__video"
          width="100%"
          poster="video/hp-astrazeneca-video.jpg">
          <source src="video/hp-astrazeneca-video.mp4" type="video/mp4" />
        </video>
      </div>

      <HighlightSection
        className="az-homepage__highlight-section"
        heading={highlightHeadingQuestionnaireText}
        /*highlightBlocks={highlightBlocks}*/
      />
      <Button
        className="az-homepage__go-to-cta"
        label={t('Homepage.goToQuestionnaire')}
        onClick={onClickGoToQuestionnaire}
      />
      <HighlightSection
        className="az-homepage__highlight-section"
        heading={highlightHeadingMapText}
        /*highlightBlocks={highlightBlocks}*/
      />
      <Button
        className="az-homepage__go-to-cta--map"
        label={t('Homepage.goToMap')}
        onClick={onClickGoToMap}
      />
      <section className="az-homepage__resources">
        <Marker color={'tertiary'} className={'az-homepage__section-marker'} />
        <h2 className="az-homepage__resource-label">
          {t('Homepage.resources.label')}
        </h2>
        <div className="az-homepage__resource-content">
          {error && (
            <p className="az-homepage__resource-error-message">
              {t('Homepage.resources.errorMessage')}
            </p>
          )}
          {loading && <Spinner color="gray" className="az-homepage__spinner" />}
          {!loading && (
            <div className="w-full">
              {renderResources()}
              <div>
                {!loading && !error && isLoggedIn && (
                  <Button
                    className="az-homepage__show-all-btn"
                    label={t('Homepage.resources.showAll')}
                    onClick={goToResources}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </HomepageTemplate>
  );
};

export default Homepage;
