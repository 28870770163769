import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import Select from 'react-select';
import classNames from 'classnames';
import {useLazyQuery, useMutation} from '@apollo/client';
import {useParams} from 'react-router-dom';
import {useNavigation} from '../../../navigation';
import {useBreadCrumb} from '../../../store/breadCrumb';

// Others
import {noop} from '../../../utils/noop';
import {AlertContext} from '../../../store/alert/context';
import {PathologyResponse, useGetPathologies} from '../../../store/pathologies';
import {
  MedicalCenterInput,
  MedicalCenterType,
} from '../../../store/medicalCenters/interfaces';
import {
  CREATE_MEDICAL_CENTER,
  GET_MEDICAL_CENTER,
  UPDATE_MEDICAL_CENTER,
} from '../../../store/medicalCenters/queries';
import {
  emailConstraint,
  emptyFieldConstraint,
  useValidator,
} from '../../../hooks/validator';

// Components
import {Admin} from '../../templates/Admin';
import {LabeledInput} from '../../molecules/LabeledInput';
import {LabeledTextarea} from '../../molecules/LabeledTextarea';
import {CheckableInput} from '../../atoms/CheckableInput';
import {InputLabel} from '../../atoms/InputLabel';
import {ButtonText} from '../../atoms/ButtonText';
import {Dropdown} from '../../molecules/Dropdown';
import {DropDownOption} from '../../molecules/Dropdown/interface';

// Types
import {IProps as ButtonProp} from '../../atoms/Button/interfaces';

// Style
import './MedicalCentersDetail.scss';

enum StateActionType {
  UPDATE_CHECKBOX = 'UPDATE_CHECKBOX',
  UPDATE_TIME = 'UPDATE_TIME',
  UPDATE_ERROR = 'UPDATE_ERROR',
  UPDATE_TIME_BULK = 'UPDATE_TIME_BULK',
}

type StateAction = {
  type:
    | StateActionType.UPDATE_CHECKBOX
    | StateActionType.UPDATE_TIME
    | StateActionType.UPDATE_ERROR
    | StateActionType.UPDATE_TIME_BULK;
  payload: any;
};

// Reducer
const reducer = (state: any, action: StateAction) => {
  switch (action.type) {
    case StateActionType.UPDATE_CHECKBOX:
      return {
        ...state,
        openingHours: state.openingHours.map((day: any) => {
          if (action.payload.item.label === day.label) {
            return {...day, open: action.payload.item.open};
          }

          return day;
        }),
      };
    case StateActionType.UPDATE_TIME:
      return {
        ...state,
        openingHours: state.openingHours.map((day: any) => {
          if (action.payload.label === day.label) {
            return {
              ...day,
              [action.payload.updatedTime.target]: day[
                action.payload.updatedTime.target
              ].map((value: any, index: number) => {
                if (index === action.payload.updatedTime.i) {
                  return action.payload.updatedTime.value;
                }
                return value;
              }),
            };
          }

          return day;
        }),
      };
    case StateActionType.UPDATE_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.input]: action.payload.value,
        },
      };
    case StateActionType.UPDATE_TIME_BULK:
      const newTimeObj = action.payload.updatedTime;
      return {
        ...state,
        openingHours: state.openingHours.map((day: any) => {
          if (action.payload.label === day.label) {
            return {
              ...day,
              openFrom: [newTimeObj.fromHour || '', newTimeObj.fromHour2 || ''],
              openTo: [newTimeObj.toHour || '', newTimeObj.toHour2 || ''],
            };
          }

          return day;
        }),
      };
    default:
      throw new Error();
  }
};

const MedicalCentersDetail = () => {
  const {t} = useTranslations();
  const {goToMedicalCenters} = useNavigation();
  const {updateBreadCrumbItems} = useBreadCrumb();
  const {setValue: setAlert} = useContext(AlertContext);
  const {validateField} = useValidator();
  const {id: medicalCenterId}: {id: string} = useParams();

  const [
    getMedicalCenter,
    {
      data: medicalCenterData,
      // loading: medicalCenterloading,
      // error: medicalCentererror,
    },
  ] = useLazyQuery(GET_MEDICAL_CENTER, {fetchPolicy: 'no-cache'});

  const [createMedicalCenter /*, {error: createError}*/] = useMutation(
    CREATE_MEDICAL_CENTER,
  );

  const [updateMedicalCenter /*, {error: updateError}*/] = useMutation(
    UPDATE_MEDICAL_CENTER,
  );

  // Weekdays translations
  const weekDays = useMemo(
    () => [
      t('WeekDays.monday'),
      t('WeekDays.tuesday'),
      t('WeekDays.wednesday'),
      t('WeekDays.thursday'),
      t('WeekDays.friday'),
      t('WeekDays.saturday'),
      t('WeekDays.sunday'),
    ],
    [t],
  );

  const enumDays = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY',
  ];

  const initialState = {
    openingHours: weekDays.map((day: string, i) => {
      return {
        label: day,
        open: false,
        openFrom: ['', ''],
        openTo: ['', ''],
        dayEnum: enumDays[i],
      };
    }),
    errors: {
      businessName: false,
      streetName: false,
      houseNumber: false,
      zipCode: false,
      province: false,
      city: false,
      email: false,
      phone: false,
      pathologiesLength: false,
    },
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  // State input
  // Business name + error
  const [businessName, setBusinessName] = useState<string>('');
  const isBusinessNameValid = useMemo(
    () => validateField(businessName, emptyFieldConstraint),
    [businessName, validateField],
  );
  // Street name + error
  const [streetName, setStreetName] = useState<string>('');
  const isStreetNameValid = useMemo(
    () => validateField(streetName, emptyFieldConstraint),
    [streetName, validateField],
  );

  // House number + error
  const [houseNumber, setHouseNumber] = useState<string>('');
  const isHouseNumberValid = useMemo(
    () => validateField(houseNumber, emptyFieldConstraint),
    [houseNumber, validateField],
  );

  // Zip code + error
  const [zipCode, setZipCode] = useState<string>('');
  const isZipCodeValid = useMemo(
    () => validateField(zipCode, emptyFieldConstraint),
    [zipCode, validateField],
  );

  // Province + error
  const [province, setProvince] = useState<string>('');
  const isProvinceValid = useMemo(
    () => validateField(province, emptyFieldConstraint),
    [province, validateField],
  );

  // City + error
  const [city, setCity] = useState<string>('');
  const isCityValid = useMemo(
    () => validateField(city, emptyFieldConstraint),
    [city, validateField],
  );

  // Email + error
  const [email, setEmail] = useState<string>('');
  const isEmailValid = useMemo(
    () => validateField(email, emailConstraint),
    [email, validateField],
  );

  // Phone + error
  const [phone, setPhone] = useState<string>('');
  const isPhoneValid = useMemo(
    () => validateField(phone, emptyFieldConstraint),
    [phone, validateField],
  );

  const [selectedPathologies, setSelectedPathologies] = useState<
    {value: number; label: string}[]
  >([]);
  const [searchAddress, setSearchAddress] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [latLon, setLatLon] = useState<{
    lat: string | null;
    lon: string | null;
  }>({lat: null, lon: null});
  const [region, setRegion] = useState<string>('');
  const [readyToSave, setReadyToSave] = useState<boolean>(false);

  // Selected option for center type dropdown state
  const [selectedTypeCenter, setSelectedTypeCenter] =
    useState<MedicalCenterType>(MedicalCenterType.RISP);

  // State error
  const [globalError, setGlobalError] = useState<boolean>(false);

  const {
    data: pathologiesData,
    // loading: pathologiesLoading,
    error: pathologiesError,
  } = useGetPathologies({availableQuestionnairesOnly: false}, 100);

  const pathologies = useMemo<PathologyResponse[]>(
    () => pathologiesData?.pathologies.items ?? [],
    [pathologiesData],
  );

  // Dropdown options for medical center type
  const centerTypeOptions = useMemo<DropDownOption<MedicalCenterType>[]>(
    () => [
      {
        label: t('MedicalCenterType.RISP'),
        selected: selectedTypeCenter === MedicalCenterType.RISP,
        value: MedicalCenterType.RISP,
      },
      {
        label: t('MedicalCenterType.generic'),
        selected: selectedTypeCenter === MedicalCenterType.GENERIC,
        value: MedicalCenterType.GENERIC,
      },
    ],
    [selectedTypeCenter, t],
  );

  useEffect(() => {
    if (pathologiesError) {
      setGlobalError(true);
    }
  }, [pathologies, pathologiesError]);

  const saveMedicalCenter = useCallback(() => {
    //
    // eslint-disable-next-line prettier/prettier
    let url = `https://nominatim.openstreetmap.org/search?q=${streetName ? streetName + '+' : ''}${houseNumber ? houseNumber + '+' : ''}${zipCode ? zipCode + '+' : ''}${city ? city + '+' : ''}${province ? province + '+' : ''}&format=json&addressdetails=1`;
    fetch(url.replace(/ /g, '+'))
      .then(res => res.json())
      .then(
        result => {
          if (result.length > 0) {
            setRegion(result[0].address.state);
            setLatLon({
              lat: result[0].lat,
              lon: result[0].lon,
            });
          } else {
            setLatLon({lat: null, lon: null});
            setAlert({
              type: 'ERROR',
              message: t('MedicalCentersDetail.addressError'),
            });
          }
        },
        error => {
          setLatLon({lat: null, lon: null});
          setAlert({
            type: 'ERROR',
            message: t('ResultQuestionnaire.generalError'),
          });
        },
      )
      .then(() => {
        setReadyToSave(true);
      });
  }, [city, houseNumber, province, setAlert, streetName, t, zipCode]);

  // When everything is set, this useEffect does the create query
  useEffect(() => {
    if (readyToSave && latLon.lat && latLon.lon) {
      // Prepare filter
      let filter: MedicalCenterInput = {
        name: businessName,
        companyName: businessName,
        description,
        type: selectedTypeCenter,
        street: streetName,
        streetNumber: houseNumber,
        city,
        province,
        postalCode: zipCode,
        region,
        latitude: Number(latLon.lat),
        longitude: Number(latLon.lon),
        phone,
        email,
        openingHours: state.openingHours.map((weekday: any) => {
          return {
            day: weekday.dayEnum,
            open: weekday.open,
            fromHour: weekday.open ? weekday.openFrom[0] : [],
            toHour: weekday.open ? weekday.openTo[0] : [],
            fromHour2: weekday.open ? weekday.openFrom[1] : [],
            toHour2: weekday.open ? weekday.openTo[1] : [],
          };
        }),
        pathologiesId: selectedPathologies.map(pathology => {
          return pathology.value;
        }),
      };

      // Before mutation check if on an open weekday user has set at least an opening and closing hour
      // else throw an error
      let openingHoursFormatError = false;

      state.openingHours.forEach((weekday: any) => {
        if (weekday.open && (!weekday.openFrom[0] || !weekday.openTo[0])) {
          openingHoursFormatError = true;
        }
      });

      if (openingHoursFormatError) {
        setAlert({
          type: 'ERROR',
          message: t('MedicalCentersDetail.alertUpdate.errorOpeningHours'),
        });
        setReadyToSave(false);
        return;
      }

      // If id is present, update. Else create a new one
      if (medicalCenterId) {
        filter.id = Number(medicalCenterId);
        updateMedicalCenter({variables: {input: filter}, errorPolicy: 'all'})
          .then(response => {
            if (response.errors) {
              setAlert({
                type: 'ERROR',
                message: t('MedicalCentersDetail.alertUpdate.error'),
              });
            } else {
              setAlert({
                type: 'SUCCESS',
                message: t('MedicalCentersDetail.alertUpdate.success'),
              });
              goToMedicalCenters();
            }
          })
          .catch(err => {
            setAlert({
              type: 'ERROR',
              message: t('MedicalCentersDetail.alertUpdate.error'),
            });
          });
      } else {
        createMedicalCenter({variables: {input: filter}, errorPolicy: 'all'})
          .then(response => {
            if (response.errors) {
              setAlert({
                type: 'ERROR',
                message: t('MedicalCentersDetail.alertCreate.error'),
              });
            } else {
              setAlert({
                type: 'SUCCESS',
                message: t('MedicalCentersDetail.alertCreate.success'),
              });
              goToMedicalCenters();
            }
          })
          .catch(err => {
            setAlert({
              type: 'ERROR',
              message: t('MedicalCentersDetail.alertCreate.error'),
            });
          });
      }

      // Reset ready to save state to avoid loops
      setReadyToSave(false);
    }
  }, [
    businessName,
    city,
    createMedicalCenter,
    description,
    email,
    latLon,
    houseNumber,
    medicalCenterId,
    phone,
    province,
    readyToSave,
    region,
    selectedPathologies,
    setAlert,
    state.openingHours,
    streetName,
    t,
    updateMedicalCenter,
    zipCode,
    goToMedicalCenters,
  ]);

  const searchAndFill = useCallback(() => {
    //
    // eslint-disable-next-line prettier/prettier
    let url = `https://nominatim.openstreetmap.org/search?q=${searchAddress}&format=json&addressdetails=1`;
    fetch(url.replace(/ /g, '+'))
      .then(res => res.json())
      .then(
        result => {
          setBusinessName('');
          setCity('');
          setProvince('');
          setStreetName('');
          setZipCode('');
          setHouseNumber('');
          setRegion('');
          if (result.length > 0) {
            const addressFound = result[0];
            setBusinessName(addressFound.address.amenity || '');
            setCity(
              addressFound.address.city ||
                addressFound.address.town ||
                addressFound.address.village ||
                '',
            );
            setProvince(addressFound.address.county || '');
            setStreetName(addressFound.address.road || '');
            setZipCode(addressFound.address.postcode || '');
            setHouseNumber(addressFound.address.house_number || '');
            setRegion(addressFound.address.state || '');
          } else {
            // TODO set our error alert
            setLatLon({lat: null, lon: null});
            alert('Nessun indirizzo trovato');
          }
        },
        error => {
          // TODO set our error alert
          setLatLon({lat: null, lon: null});
          alert('Qualcosa è andato storto');
        },
      );
  }, [searchAddress]);

  const isFormComplete = useMemo(() => {
    return !!(
      businessName &&
      streetName &&
      houseNumber &&
      zipCode &&
      province &&
      city &&
      email &&
      phone &&
      selectedPathologies.length > 0
    );
  }, [
    businessName,
    city,
    email,
    houseNumber,
    phone,
    province,
    selectedPathologies.length,
    streetName,
    zipCode,
  ]);

  // Save button
  const cta: ButtonProp = {
    label: t('MedicalCentersDetail.save'),
    disabled:
      !!Object.values(state.errors).find(value => value) || !isFormComplete,
    onClick: saveMedicalCenter,
  };

  // Delete content button
  // const cancel: ButtonTextProp = {
  //   label: t('MedicalCentersDetail.deleteCenter'),
  //   onClick: () =>
  //     setAlert({
  //       type: 'INFO',
  //       message: t('AdminQuestionnaireDetail.alert.cancel.message'),
  //       activeButtonLabel: t(
  //         'AdminQuestionnaireDetail.alert.cancel.activeButtonLabel',
  //       ),
  //       cancelButtonLabel: t(
  //         'AdminQuestionnaireDetail.alert.cancel.cancelButtonLabel',
  //       ),
  //       onClick: () => window.location.reload(),
  //     }),
  // };

  // Add breadcrumb
  useEffect(() => {
    updateBreadCrumbItems([
      {
        label: t('MedicalCenters.title'),
        onClick: goToMedicalCenters,
      },
      {
        label: t('MedicalCentersDetail.title'),
        onClick: noop,
      },
    ]);
  }, [updateBreadCrumbItems, t, goToMedicalCenters]);

  // Opening Hours table row
  const openingDateRow = (item: any) => {
    return (
      <div
        key={item.label}
        className="az-admin-medical-centers-detail__opening-date-row">
        {/* Day label */}
        <div className="w-2/12">
          <span className="az-admin-medical-centers-detail__day">
            {item.label}
          </span>
        </div>
        {/* Opened checkbox */}
        <div className="az-admin-medical-centers-detail__cell">
          <CheckableInput
            isChecked={item.open}
            onChange={e => {
              dispatch({
                type: StateActionType.UPDATE_CHECKBOX,
                payload: {item: {...item, open: e.target.checked}},
              });
            }}>
            <span className="az-admin-medical-centers-detail__checkbox-label">
              {t('MedicalCentersDetail.open')}
            </span>
          </CheckableInput>
        </div>
        <div className="az-admin-medical-centers-detail__inputs-container">
          {/* Opened from input text */}
          <div
            className={classNames(
              'az-admin-medical-centers-detail__input-wrapper',
              {
                'az-admin-medical-centers-detail__input-wrapper--disabled':
                  !item.open,
              },
            )}>
            <input
              type="time"
              className="az-admin-medical-centers-detail__input"
              placeholder={t('MedicalCentersDetail.from')}
              disabled={!item.open}
              value={item.openFrom?.[0]}
              onChange={e => {
                dispatch({
                  type: StateActionType.UPDATE_TIME,
                  payload: {
                    label: item.label,
                    updatedTime: {
                      i: 0,
                      value: e.target?.value,
                      target: 'openFrom',
                    },
                  },
                });
              }}
            />
          </div>
          {/* Opened to input text */}
          <div
            className={classNames(
              'az-admin-medical-centers-detail__input-wrapper',
              {
                'az-admin-medical-centers-detail__input-wrapper--disabled':
                  !item.open,
              },
            )}>
            <input
              type="time"
              className="az-admin-medical-centers-detail__input"
              placeholder={t('MedicalCentersDetail.to')}
              disabled={!item.open}
              value={item.openTo?.[0]}
              onChange={e => {
                dispatch({
                  type: StateActionType.UPDATE_TIME,
                  payload: {
                    label: item.label,
                    updatedTime: {
                      i: 0,
                      value: e.target?.value,
                      target: 'openTo',
                    },
                  },
                });
              }}
            />
          </div>
          <div className="az-admin-medical-centers-detail__separator" />
          {/* Opened from input text */}
          <div
            className={classNames(
              'az-admin-medical-centers-detail__input-wrapper',
              {
                'az-admin-medical-centers-detail__input-wrapper--disabled':
                  !item.open,
              },
            )}>
            <input
              type="time"
              className="az-admin-medical-centers-detail__input"
              placeholder={t('MedicalCentersDetail.from')}
              disabled={!item.open}
              value={item.openFrom?.[1]}
              onChange={e => {
                dispatch({
                  type: StateActionType.UPDATE_TIME,
                  payload: {
                    label: item.label,
                    updatedTime: {
                      i: 1,
                      value: e.target?.value,
                      target: 'openFrom',
                    },
                  },
                });
              }}
            />
          </div>
          {/* Opened to input text */}
          <div
            className={classNames(
              'az-admin-medical-centers-detail__input-wrapper',
              {
                'az-admin-medical-centers-detail__input-wrapper--disabled':
                  !item.open,
              },
            )}>
            <input
              type="time"
              className="az-admin-medical-centers-detail__input"
              placeholder={t('MedicalCentersDetail.to')}
              disabled={!item.open}
              value={item.openTo?.[1]}
              onChange={e => {
                dispatch({
                  type: StateActionType.UPDATE_TIME,
                  payload: {
                    label: item.label,
                    updatedTime: {
                      i: 1,
                      value: e.target?.value,
                      target: 'openTo',
                    },
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  // Check if is editing
  useEffect(() => {
    if (medicalCenterId) {
      getMedicalCenter({
        variables: {input: medicalCenterId},
      });
    }
  }, [getMedicalCenter, medicalCenterId]);

  useEffect(() => {
    if (medicalCenterData?.medicalCenter) {
      setBusinessName(medicalCenterData.medicalCenter.companyName);
      setStreetName(medicalCenterData.medicalCenter.street);
      setHouseNumber(medicalCenterData.medicalCenter.streetNumber);
      setZipCode(medicalCenterData.medicalCenter.postalCode);
      setProvince(medicalCenterData.medicalCenter.province);
      setCity(medicalCenterData.medicalCenter.city);
      setEmail(medicalCenterData.medicalCenter.email);
      setPhone(medicalCenterData.medicalCenter.phone);
      setSelectedTypeCenter(medicalCenterData.medicalCenter.type);
      setSelectedPathologies(
        medicalCenterData.medicalCenter.pathologies.map((pathology: any) => {
          return {
            value: pathology.id,
            label: pathology.name,
          };
        }),
      );
      setDescription(medicalCenterData.medicalCenter.description);
      setLatLon({
        lat: medicalCenterData.medicalCenter.latitude,
        lon: medicalCenterData.medicalCenter.longitude,
      });
      setRegion(medicalCenterData.medicalCenter.region);

      medicalCenterData?.medicalCenter?.openingHours?.forEach(
        (openingHour: any, index: number) => {
          dispatch({
            type: StateActionType.UPDATE_CHECKBOX,
            payload: {
              item: {
                label: weekDays[index],
                open: openingHour.open,
              },
            },
          });
          dispatch({
            type: StateActionType.UPDATE_TIME_BULK,
            payload: {
              label: weekDays[index],
              updatedTime: openingHour,
            },
          });
        },
      );
    }
  }, [medicalCenterData, weekDays]);

  if (globalError) {
    return (
      <Admin title={t('ResultQuestionnaire.generalError')}>
        <></>
      </Admin>
    );
  }

  // Style for multi select component
  const multiSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      borderColor: state.errors.pathologiesLength ? '#E01433' : '#DDDEDE',
      backgroundColor: state.errors.pathologiesLength ? '#E014332B' : '#FFFFFF',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #DDDEDE',
      },
    }),
    input: (styles: any) => ({
      ...styles,
      height: 39.04,
      margin: 0,
      fontFamily: 'Helvetica-AZ',
      fontSize: 14,
      fontWeight: 400,
    }),
    placeholder: (styles: any) => ({
      ...styles,
      fontFamily: 'Helvetica-AZ',
      fontSize: 14,
      color: '#A9A9A9',
      fontWeight: 400,
    }),
    multiValue: (styles: any) => ({
      ...styles,
      fontFamily: 'Helvetica-AZ',
      fontSize: 14,
      color: '#FFFFFF',
      fontWeight: 400,
      backgroundColor: '#A15D84',
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      fontFamily: 'Helvetica-AZ',
      fontSize: 14,
      color: '#FFFFFF',
      fontWeight: 400,
    }),
    menuList: (styles: any) => ({
      ...styles,
      fontFamily: 'Helvetica-AZ',
      fontSize: 14,
      color: '#3F4444',
      fontWeight: 400,
    }),
    option: (styles: any) => ({
      ...styles,
      backgroundColor: 'none',
      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#A15D84',
      },
    }),
  };

  return (
    <Admin title={t('MedicalCentersDetail.title')} breadcrumb ctaPrimary={cta}>
      <div className="az-admin-medical-centers-detail">
        <div className="az-admin-medical-centers-detail__row-wrapper">
          <LabeledInput
            label={t('MedicalCentersDetail.search.label')}
            placeholder={t('MedicalCentersDetail.search.placeholder')}
            onChange={setSearchAddress}
            className="w-5/12"
            maxLength={120}
          />
          <ButtonText
            className="az-admin-medical-centers-detail__autofill"
            onClick={searchAndFill}
            label={t('MedicalCentersDetail.search.button')}
            disabled={searchAddress.length < 1}
          />
          <div className="w-6/12">
            <Dropdown
              dropdownPlaceholder={t('MedicalCenterType.placeholderDropdown')}
              className="w-full"
              label={t('MedicalCenterType.label')}
              onChange={option => {
                setSelectedTypeCenter(option.value);
              }}
              options={centerTypeOptions}
            />
          </div>
        </div>
        <div className="az-admin-medical-centers-detail__row-wrapper">
          <LabeledInput
            label={t('MedicalCentersDetail.businessName.label')}
            placeholder={t('MedicalCentersDetail.businessName.placeholder')}
            value={businessName}
            onChange={setBusinessName}
            className="w-4/12"
            maxLength={120}
            error={state.errors.businessName}
            onBlur={() => {
              dispatch({
                type: StateActionType.UPDATE_ERROR,
                payload: {input: 'businessName', value: !isBusinessNameValid},
              });
            }}
          />
          <div className="az-admin-medical-centers-detail__drop">
            <InputLabel
              label={t('MedicalCentersDetail.pathologiesLabel')}
              className="mb-2"
              color={'dark'}
              error={state.errors.pathologiesLength}
            />
            <Select
              placeholder={'Seleziona una o più patologie'}
              isMulti
              name="pathologies"
              options={pathologies.map(item => {
                return {label: item.name, value: item.id};
              })}
              styles={multiSelectStyles}
              className="basic-multi-select"
              classNamePrefix="select"
              value={selectedPathologies}
              onChange={inputPathologies =>
                setSelectedPathologies(
                  inputPathologies.map(item => {
                    return {label: item.label, value: item.value};
                  }),
                )
              }
              onBlur={() => {
                dispatch({
                  type: StateActionType.UPDATE_ERROR,
                  payload: {
                    input: 'pathologiesLength',
                    value: selectedPathologies.length < 1,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="az-admin-medical-centers-detail__row-wrapper">
          <LabeledInput
            label={t('MedicalCentersDetail.streetName.label')}
            placeholder={t('MedicalCentersDetail.streetName.placeholder')}
            onChange={setStreetName}
            className="w-4/12"
            maxLength={120}
            value={streetName}
            error={state.errors.streetName}
            onBlur={() => {
              dispatch({
                type: StateActionType.UPDATE_ERROR,
                payload: {input: 'streetName', value: !isStreetNameValid},
              });
            }}
          />
          <div className="w-4/12 space-x-4 flex">
            <LabeledInput
              label={t('MedicalCentersDetail.houseNumber.label')}
              placeholder={t('MedicalCentersDetail.houseNumber.placeholder')}
              onChange={setHouseNumber}
              className="w-full"
              maxLength={10}
              value={houseNumber}
              error={state.errors.houseNumber}
              onBlur={() => {
                dispatch({
                  type: StateActionType.UPDATE_ERROR,
                  payload: {input: 'houseNumber', value: !isHouseNumberValid},
                });
              }}
            />
            <LabeledInput
              label={t('MedicalCentersDetail.zipCode.label')}
              placeholder={t('MedicalCentersDetail.zipCode.placeholder')}
              onChange={setZipCode}
              className="w-full"
              maxLength={10}
              value={zipCode}
              error={state.errors.zipCode}
              onBlur={() => {
                dispatch({
                  type: StateActionType.UPDATE_ERROR,
                  payload: {input: 'zipCode', value: !isZipCodeValid},
                });
              }}
            />
          </div>
          <LabeledInput
            label={t('MedicalCentersDetail.city.label')}
            placeholder={t('MedicalCentersDetail.city.placeholder')}
            onChange={setCity}
            className="w-4/12"
            maxLength={40}
            value={city}
            error={state.errors.city}
            onBlur={() => {
              dispatch({
                type: StateActionType.UPDATE_ERROR,
                payload: {input: 'city', value: !isCityValid},
              });
            }}
          />
        </div>
        <div className="az-admin-medical-centers-detail__row-wrapper">
          <LabeledInput
            label={t('MedicalCentersDetail.province.label')}
            placeholder={t('MedicalCentersDetail.province.placeholder')}
            onChange={setProvince}
            className="w-4/12"
            maxLength={40}
            value={province}
            error={state.errors.province}
            onBlur={() => {
              dispatch({
                type: StateActionType.UPDATE_ERROR,
                payload: {input: 'province', value: !isProvinceValid},
              });
            }}
          />
          <LabeledInput
            label={t('MedicalCentersDetail.email.label')}
            placeholder={t('MedicalCentersDetail.email.placeholder')}
            onChange={setEmail}
            className="w-4/12"
            maxLength={120}
            value={email}
            error={state.errors.email}
            onBlur={() => {
              dispatch({
                type: StateActionType.UPDATE_ERROR,
                payload: {input: 'email', value: !isEmailValid},
              });
            }}
          />
          <LabeledInput
            label={t('MedicalCentersDetail.phoneNumber.label')}
            placeholder={t('MedicalCentersDetail.phoneNumber.placeholder')}
            onChange={setPhone}
            className="w-4/12"
            maxLength={20}
            value={phone}
            error={state.errors.phone}
            onBlur={() => {
              dispatch({
                type: StateActionType.UPDATE_ERROR,
                payload: {input: 'phone', value: !isPhoneValid},
              });
            }}
          />
        </div>
        <div className="w-full flex space-x-4">
          <LabeledTextarea
            className="az-admin-medical-centers-detail__description"
            labelClassName="mb-2"
            label={t('MedicalCentersDetail.description.label')}
            onChange={setDescription}
            placeholder={t('MedicalCentersDetail.description.placeholder')}
            nRows={2}
            maxLength={300}
            value={description}
          />
          <div className="w-4/12" />
        </div>
        <h2 className="az-admin-medical-centers-detail__opening-hours">
          {t('MedicalCentersDetail.openingHours')}
        </h2>
        <div className="w-11/12">
          {state.openingHours.map((row: any) => {
            return openingDateRow(row);
          })}
        </div>
      </div>
    </Admin>
  );
};

export default React.memo(MedicalCentersDetail);
