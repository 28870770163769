import React, {useMemo} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import {useQuery} from '@apollo/client';

import {useNavigation} from '../../../navigation';
import {GET_RESOURCES} from '../../../store/resources/queries';
import {ResourceQueryResult} from '../../../store/resources/interfaces';

// Components
import {Admin} from '../../templates/Admin';
import {Button} from '../../atoms/Button';
import {Spinner} from '../../atoms/Spinner';
import {DataTableContents} from '../../organisms/DataTable/DataTableContents';

//Types
import {IProps as IconProps} from '../../atoms/IconSVG/interfaces';

// Style
import './AdminContents.scss';

const AdminContents = () => {
  const {loading, data, refetch} = useQuery<ResourceQueryResult>(
    GET_RESOURCES,
    {
      variables: {input: {pathologiesId: []}, limit: 5, offset: 0},
      fetchPolicy: 'no-cache',
    },
  );

  const {t} = useTranslations();
  const {goToAdminContentCreate} = useNavigation();

  const addIcon = useMemo<IconProps>(
    () => ({
      icon: 'icon-add',
      size: 23,
      className: 'text-white',
    }),
    [],
  );

  return (
    <Admin title={t('AdminContents.heading')}>
      <div className="az-admin-contents">
        <div className="flex justify-end">
          <div className="az-admin-contents__button-container">
            <Button
              label={t('AdminContents.newContent')}
              width={255}
              rightIcon={addIcon}
              onClick={goToAdminContentCreate}
            />
          </div>
        </div>
        {loading && (
          <Spinner color="gray" className="az-admin-contents__spinner" />
        )}
        {data?.resources.items && (
          <DataTableContents
            contentsData={data?.resources?.items}
            totalContents={data?.resources?.info.total}
            refetchData={refetch}
          />
        )}
      </div>
    </Admin>
  );
};

export default React.memo(AdminContents);
