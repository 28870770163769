import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import {useMutation, useQuery} from '@apollo/client';

//Others
import {
  GET_LOGGED_USER,
  useAuthentication,
} from '../../../store/authentication';
import {useBreadCrumb} from '../../../store/breadCrumb';
import {noop} from '../../../utils/noop';
import {MMGRoutes, useNavigation} from '../../../navigation';
import {AlertContext} from '../../../store/alert/context';
import {ModalContent} from '../../../store/modal/interfaces';
import {ModalContext} from '../../../store/modal/context';
import {UPDATE_MMG, REMOVE_DOCTOR} from '../../../store/authentication/queries';

// Template
import {AuthBase} from '../../templates/AuthBase';

//Components
import {Spinner} from '../../atoms/Spinner';
import {LabeledInput} from '../../molecules/LabeledInput';
import {ButtonText} from '../../atoms/ButtonText';
import {Button} from '../../atoms/Button';
import {CheckableInput} from '../../atoms/CheckableInput';
import {Link} from '../../atoms/Link';

//Component namespace
import {IProps} from './interfaces';
import './MMGProfile.scss';

const MMGProfile = (_props: IProps) => {
  // Get data of logged MMG
  const {
    data: mmgData,
    loading: mmgDataLoading,
    // error: mmgDataError,
  } = useQuery(GET_LOGGED_USER, {
    variables: {},
    fetchPolicy: 'cache-and-network',
  });

  // Mutation to delete the logged MMG
  const [removeDoctor] = useMutation(REMOVE_DOCTOR);

  // Mutation to update the logged mmg
  const [
    updateDoctor,
    {loading: updateDoctorLoading} /*, {error: updateError}*/,
  ] = useMutation(UPDATE_MMG);

  // State --------------------
  // Is in edit mode or not
  const [isEditing, setIsEditing] = useState<boolean>(false);
  // MMG data
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [province, setProvince] = useState<string>('');
  const [electronicCommunications, setElectronicCommunications] =
    useState<boolean>(false);
  const [automatedCallSystems, setAutomatedCallSystems] =
    useState<boolean>(false);

  //Helpers --------------------
  const {t} = useTranslations();
  const {isLoggedIn, id, logOut} = useAuthentication();
  const {goToMain} = useNavigation();
  const {updateBreadCrumbItems} = useBreadCrumb();
  const {setValue: setAlert} = useContext(AlertContext);
  const {setValue: setModal} = useContext(ModalContext);

  //Set BreadCrumb items
  useEffect(() => {
    updateBreadCrumbItems([
      {
        label: t('Resources.home'),
        onClick: goToMain,
      },
      {
        label: t('Profile.title'),
        onClick: noop,
      },
    ]);
  }, [updateBreadCrumbItems, t, goToMain]);

  // Load input data when query finishes
  useEffect(() => {
    if (mmgData?.loggedUser) {
      setFirstName(mmgData?.loggedUser.firstName);
      setLastName(mmgData?.loggedUser.lastName);
      setPhone(mmgData?.loggedUser.phone);
      setCity(mmgData?.loggedUser.city);
      setProvince(mmgData?.loggedUser.province);
      setElectronicCommunications(mmgData?.loggedUser.electronicCommunications);
      setAutomatedCallSystems(mmgData?.loggedUser.automatedCallSystems);
    }
  }, [mmgData]);

  // Update the doctor
  const handleUpdateDoctor = useCallback(() => {
    let filter = {
      id,
      phone,
      city,
      province,
      electronicCommunications,
      automatedCallSystems,
    };
    updateDoctor({variables: {input: filter}, errorPolicy: 'all'})
      .then(response => {
        if (response.errors) {
          setAlert({
            type: 'ERROR',
            message:
              t('MMGProfile.updateError') +
              ': ' +
              t(
                `ErrorStrings.${response?.errors?.[0].extensions?.extra?.[0].field}`,
              ) +
              ' ' +
              t(
                `ErrorStrings.${response?.errors?.[0].extensions?.extra?.[0].errorType}`,
              ),
          });
        } else {
          setAlert({
            type: 'SUCCESS',
            message: t('MMGProfile.updateSuccess'),
          });
          setIsEditing(false);
        }
      })
      .catch(() => {
        setAlert({
          type: 'ERROR',
          message: t('MMGProfile.updateError'),
        });
      });
  }, [
    automatedCallSystems,
    city,
    electronicCommunications,
    id,
    phone,
    province,
    setAlert,
    t,
    updateDoctor,
  ]);

  // Delete the doctor
  const deleteDoctor = useCallback(() => {
    removeDoctor({errorPolicy: 'all'})
      .then(response => {
        if (response.errors) {
          setAlert({
            type: 'ERROR',
            message: t('Profile.genericError'),
          });
        } else {
          logOut();
        }
      })
      .catch(() => {
        setAlert({
          type: 'ERROR',
          message: t('Profile.genericError'),
        });
      });
  }, [logOut, removeDoctor, setAlert, t]);

  // Set modal on profile delete action
  const onDeleteDoctor = useCallback(() => {
    setModal({
      content: ModalContent.DELETE_MODAL,
      props: {
        modalTitle: 'DeleteModal.title',
        body: 'Profile.deleteProfileDoctorText',
        subtitle: 'DeleteModal.subtitle',
        callback: deleteDoctor,
      },
    });
  }, [setModal, deleteDoctor]);

  // Check all params completed to enable save button
  const isCompleteButtonDisabled = useMemo(() => {
    if (!firstName || !lastName || !phone || !city || !province) {
      return true;
    }
  }, [city, firstName, lastName, phone, province]);

  return (
    <AuthBase pageHeading={t('Profile.title')} isAuth={isLoggedIn}>
      <div className="az-mmg-profile">
        {mmgDataLoading && (
          <Spinner color="gray" className="az-mmg-profile__spinner" />
        )}
        {mmgData?.loggedUser && !mmgDataLoading && (
          <>
            <div className="az-mmg-profile__container">
              <LabeledInput
                label={t('Login.name')}
                value={firstName}
                onChange={setFirstName}
                disabled
                className="w-full md:w-6/12 mt-4"
              />
              <LabeledInput
                label={t('Login.lastName')}
                value={lastName}
                onChange={setLastName}
                disabled
                className="w-full md:w-6/12 mt-4"
              />
            </div>
            <div className="az-mmg-profile__container">
              <LabeledInput
                label={t('Login.email.label')}
                value={mmgData?.loggedUser.email || ''}
                disabled
                className="w-full md:w-6/12 mt-4"
              />
              <LabeledInput
                label={t('Login.phone')}
                value={phone}
                onChange={setPhone}
                disabled={!isEditing}
                className="w-full md:w-6/12 mt-4"
              />
            </div>
            <div className="az-mmg-profile__container">
              <LabeledInput
                label={t('Login.city')}
                value={city}
                onChange={setCity}
                disabled={!isEditing}
                className="w-full md:w-6/12 mt-4"
              />
              <LabeledInput
                label={t('Login.province')}
                value={province}
                onChange={setProvince}
                disabled={!isEditing}
                className="w-full md:w-6/12 mt-4"
              />
            </div>
            {isEditing && (
              <div className="az-mmg-profile__link-container">
                <span className="text-right">
                  <ButtonText
                    label={t('Profile.deleteProfile')}
                    onClick={onDeleteDoctor}
                    className="az-mmg-profile__link az-mmg-profile__link--edit-password"
                  />
                </span>
              </div>
            )}
            <div className="az-mmg-profile__container-checkboxes">
              <CheckableInput
                className="az-mmg-first-access__checkbox mt-8"
                inputName="checkboxDataTreatment"
                isChecked={true}
                disabled={true}
                onChange={() => {}}>
                <span>
                  {t('Login.checkboxDataTreatment')}
                  <Link href={MMGRoutes.PRIVACY} className="underline">
                    {t('Login.checkboxDataTreatmentLink')}
                  </Link>
                </span>
              </CheckableInput>

              <CheckableInput
                className="az-mmg-profile__checkbox"
                inputName="electronicCommunications"
                isChecked={electronicCommunications}
                disabled={!isEditing}
                onChange={() =>
                  isEditing
                    ? setElectronicCommunications(!electronicCommunications)
                    : null
                }>
                <span>{t('Login.checkboxPromo')}</span>
              </CheckableInput>
            </div>
            {isEditing ? (
              <Button
                className="az-mmg-profile__button"
                styleType="primary"
                label={t('Profile.save')}
                onClick={handleUpdateDoctor}
                disabled={isCompleteButtonDisabled}
                loading={updateDoctorLoading}
              />
            ) : (
              <Button
                className="az-mmg-profile__button"
                styleType="primary"
                label={t('Profile.edit')}
                onClick={() => setIsEditing(true)}
              />
            )}
          </>
        )}
      </div>
    </AuthBase>
  );
};

export default React.memo(MMGProfile);
