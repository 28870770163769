import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import classNames from 'classnames';

// Components
import {Link} from '../../atoms/Link';
import {ButtonText} from '../../atoms/ButtonText';

//Others
import {useBEMClass} from '../../../hooks/use-bem-class';
import {useAuthentication} from '../../../store/authentication';
import {Role} from '../../../store/api';
import {useNavigation} from '../../../navigation';

// Types
import {IProps} from './interface';

// Style
import './BurgerMenu.scss';

const BurgerMenu = ({
  isAuth = false,
  isDoctor = true,
  className,
  isOpen = false,
}: IProps): JSX.Element => {
  //Internal state
  const [isOpaque, setIsOpaque] = useState<boolean>(isOpen);

  //Helpers
  const {t} = useTranslations();
  const {logOut, role, id} = useAuthentication();
  const BEMClass = useBEMClass();
  const {
    // @REMOVE_PATIENTS_DATA
    // goToPatients,
    goToQuestionnaire,

    goToMain,
    goToCentersMap,
    goToAdminPathologies,
    // goToPatientProfile,
    goToMMGProfile,
  } = useNavigation();

  const openClassNameModifier = useMemo(
    () => (isOpen ? BEMClass('az-burger-menu', '', 'open') : ''),
    [isOpen, BEMClass],
  );

  const opacityClassNameModifier = useMemo(
    () => (isOpaque ? BEMClass('az-burger-menu', '', '--opaque') : ''),
    [isOpaque, BEMClass],
  );

  const linksStyle = useMemo(() => {
    return isOpen ? 'az-burger-menu__links' : 'az-burger-menu__links-disabled';
  }, [isOpen]);

  //Wait next tick to set opacity
  useEffect(() => {
    const setOpacityAtNextTick = setTimeout(() => {
      setIsOpaque(isOpen);
    }, 0);

    return () => clearTimeout(setOpacityAtNextTick);
  }, [isOpen]);

  const handleGoToProfile = useCallback(() => {
    if (role === Role.ROLE_ADMIN) {
      goToAdminPathologies();
    }
    // @REMOVE_PATIENTS_DATA
    // else if (role === Role.ROLE_PATIENT) {
    //   goToPatientProfile();
    // }
    else if (role === Role.ROLE_DOCTOR) {
      goToMMGProfile();
    }
  }, [
    goToAdminPathologies,
    goToMMGProfile,
    // @REMOVE_PATIENTS_DATA
    //goToPatientProfile,
    role,
  ]);

  return (
    <div
      className={classNames(
        'az-burger-menu',
        openClassNameModifier,
        opacityClassNameModifier,
        className,
      )}>
      {!isAuth ? (
        <div className={linksStyle}>
          {/*<Link
            href="/login"
            className="az-burger-menu__link az-burger-menu__link--user">
            {t('Header.user')}
          </Link>*/}
          <Link
            href="/mmg-login"
            className="az-burger-menu__link az-burger-menu__link--user--health-pro">
            {t('Header.healthPro')}
          </Link>
        </div>
      ) : (
        <div className={linksStyle}>
          <ButtonText
            className="az-burger-menu__link"
            label={t('Header.home')}
            onClick={goToMain}
          />
          {isDoctor && id !== -1 && (
            <>
              {/* @REMOVE_PATIENTS_DATA */}
              {/* <ButtonText
                label={t('Header.patients')}
                onClick={goToPatients}
                className="az-burger-menu__link"
              /> */}
              <ButtonText
                label={t('Header.questionnaire')}
                onClick={goToQuestionnaire}
                className="az-burger-menu__link"
              />
            </>
          )}
          {role !== Role.ROLE_ADMIN && id !== -1 && (
            <ButtonText
              className="az-burger-menu__link"
              label={t('Header.centersMap')}
              onClick={goToCentersMap}
            />
          )}
          {id !== -1 && (
            <ButtonText
              className="az-burger-menu__link"
              label={t('Header.profile')}
              onClick={handleGoToProfile}
            />
          )}

          <ButtonText
            className="az-burger-menu__logout"
            label={t('Header.logout')}
            onClick={logOut}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(BurgerMenu);
