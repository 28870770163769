import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import {useMutation} from '@apollo/client';

//Others
import {
  DOCTOR_FINISH_REGISTRATION,
  useAuthentication,
} from '../../../store/authentication';
import {useBreadCrumb} from '../../../store/breadCrumb';
import {Role} from '../../../store/api';
import {MMGRoutes, useNavigation} from '../../../navigation';
import {STORAGE_KEY} from '../../../utils/storage';
import {AlertContext} from '../../../store/alert/context';

// Template
import {AuthBase} from '../../templates/AuthBase';

//Components
import {LabeledInput} from '../../molecules/LabeledInput';
import {CheckableInput} from '../../atoms/CheckableInput';
import {Link} from '../../atoms/Link';
import {Button} from '../../atoms/Button';

//Component namespace
import {IProps} from './interfaces';
import './LoginMMGFirstAccess.scss';

const LoginMMGFirstAccess = (_props: IProps) => {
  //Helpers
  const {t} = useTranslations();
  const {isLoggedIn, role, token, logOut} = useAuthentication();
  const {goToMain} = useNavigation();
  const {updateBreadCrumbItems} = useBreadCrumb();

  // Registration vars
  const owaId = localStorage.getItem(STORAGE_KEY.OWA_ID);
  const email = localStorage.getItem(STORAGE_KEY.EMAIL);

  // State
  const [name, setName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [province, setProvince] = useState<string>('');
  // Checkboxes
  const [checkboxDataTreatment, setCheckboxDataTreatment] =
    useState<boolean>(false);
  const [checkboxPromo, setCheckboxPromo] = useState<boolean>(false);
  const [checkboxInfoPromo, setCheckboxInfoPromo] = useState<boolean>(false);
  // const [checkboxInterest, setCheckboxInterest] = useState<boolean>(false);

  const {setValue: setAlert} = useContext(AlertContext);

  // Query
  const [completeDoctorRegistrationQuery] = useMutation(
    DOCTOR_FINISH_REGISTRATION,
  );

  const completeDoctorRegistration = useCallback(() => {
    let filter: any = {
      email,
      firstName: name,
      lastName,
      city,
      province,
      phone,
      owaId,
      treatmentOfPersonalData: checkboxDataTreatment,
      electronicCommunications: checkboxPromo,
      automatedCallSystems: checkboxInfoPromo,
      privacy: checkboxDataTreatment,
    };

    completeDoctorRegistrationQuery({
      variables: {input: filter},
      errorPolicy: 'all',
    })
      .then(response => {
        if (response.errors) {
          let serverErrorMessage =
            response.errors?.[0]?.extensions?.extra?.[0]?.description;
          setAlert({
            type: 'ERROR',
            message: `${t('Login.registrationError')} ${
              serverErrorMessage || ''
            }`,
          });
        } else {
          setAlert({
            type: 'SUCCESS',
            message: t('Login.registrationCompleted'),
          });
          logOut();
        }
      })
      .catch(() => {
        setAlert({
          type: 'ERROR',
          message: t('Login.registrationError'),
        });
      });
  }, [
    checkboxDataTreatment,
    checkboxInfoPromo,
    checkboxPromo,
    city,
    completeDoctorRegistrationQuery,
    email,
    lastName,
    logOut,
    name,
    owaId,
    phone,
    province,
    setAlert,
    t,
  ]);

  //Set BreadCrumb items
  useEffect(() => {
    updateBreadCrumbItems([]);
  }, [updateBreadCrumbItems]);

  // if user isn't logged in or not a doctor redirect to home
  if (!isLoggedIn || role !== Role.ROLE_DOCTOR) {
    goToMain();
  }
  // if user is logged in and has token that means he is registered already; redirect to home
  if (isLoggedIn && token !== 'no-token') {
    goToMain();
  }

  const isCompleteButtonDisabled = useMemo(() => {
    if (
      !owaId ||
      !name ||
      !lastName ||
      !phone ||
      !city ||
      !province ||
      !checkboxDataTreatment ||
      !checkboxInfoPromo
    ) {
      return true;
    }
  }, [
    checkboxDataTreatment,
    checkboxInfoPromo,
    city,
    lastName,
    name,
    owaId,
    phone,
    province,
  ]);

  return (
    <AuthBase pageHeading={t('Login.titleMMG')} isAuth={isLoggedIn}>
      <div className="az-mmg-first-access">
        <div className="az-mmg-first-access__disclaimer-text">
          {t('Login.mmgFirstAccessText')}
        </div>
        <>
          <div className="az-mmg-first-access__container">
            <LabeledInput
              label={t('Login.name')}
              value={name}
              onChange={setName}
              className="w-full md:w-6/12 mt-4"
            />
            <LabeledInput
              label={t('Login.lastName')}
              value={lastName}
              onChange={setLastName}
              className="w-full md:w-6/12 mt-4"
            />
          </div>
          <div className="az-mmg-first-access__container">
            <LabeledInput
              label={t('Login.email.label')}
              value={email || ''}
              disabled
              className="w-full md:w-6/12 mt-4"
            />
            <LabeledInput
              label={t('Login.phone')}
              value={phone}
              onChange={setPhone}
              className="w-full md:w-6/12 mt-4"
            />
          </div>
          <div className="az-mmg-first-access__container">
            <LabeledInput
              label={t('Login.city')}
              value={city}
              onChange={setCity}
              className="w-full md:w-6/12 mt-4"
            />
            <LabeledInput
              label={t('Login.province')}
              value={province}
              onChange={setProvince}
              className="w-full md:w-6/12 mt-4"
            />
          </div>

          <div className="az-mmg-first-access__link-container">
            <CheckableInput
              className="az-mmg-first-access__checkbox mt-8"
              inputName="checkboxDataTreatment"
              isChecked={checkboxDataTreatment}
              onChange={() => setCheckboxDataTreatment(!checkboxDataTreatment)}>
              <span>
                {t('Login.checkboxDataTreatment')}
                <Link href={MMGRoutes.PRIVACY} className="underline">
                  {t('Login.checkboxDataTreatmentLink')}
                </Link>
              </span>
            </CheckableInput>

            <CheckableInput
              className="az-mmg-first-access__checkbox"
              inputName="checkboxPromo"
              isChecked={checkboxPromo}
              onChange={() => setCheckboxPromo(!checkboxPromo)}>
              <span>{t('Login.checkboxPromo')}</span>
            </CheckableInput>

            <CheckableInput
              className="az-mmg-first-access__checkbox"
              inputName="checkboxInfoPromo"
              isChecked={checkboxInfoPromo}
              onChange={() => setCheckboxInfoPromo(!checkboxInfoPromo)}>
              <span>{t('Login.doctorDisclaimer')}</span>
            </CheckableInput>
          </div>

          <div className="az-mmg-first-access__button-wrapper">
            <Button
              className="az-mmg-first-access__complete-button"
              styleType="primary"
              label={t('Login.completeRegistration')}
              onClick={completeDoctorRegistration}
              disabled={isCompleteButtonDisabled}
            />
          </div>
        </>
      </div>
    </AuthBase>
  );
};

export default React.memo(LoginMMGFirstAccess);
