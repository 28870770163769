import React from 'react';

// Components
import {Base} from '../../templates/Base';

// Style
import '../TermsAndConditions/TermsAndConditions.scss';

const Privacy = () => {
  return (
    <Base className="az-terms-and-conditions">
      <h1 className="az-terms-and-conditions__title">
        Informativa ai fini privacy ai sensi dell'art. 13 Reg. UE n. 679/2016
        per l'utente della Piattaforma EazyRef
      </h1>

      <div className="az-terms-and-conditions__content">
        <p>
          AstraZeneca S.p.A., C.F. - P.IVA 00735390155, con sede legale in
          Milano, viale Decumano 39 (cap 20157), in persona del legale
          rappresentante p.t., in qualità di titolare del trattamento (in
          seguito “Titolare”), desidera informarLa che, ai sensi dell'art. 13
          del Reg. UE n. 679/2016 (in seguito “GDPR”), ha necessità di procedere
          al trattamento dei Suoi dati personali nel rispetto delle normative
          vigenti e secondo quanto di seguito meglio specificato.
        </p>

        <br />

        <h2 className="font-bold">
          1. Oggetto del Trattamento e categorie di dati trattati:
        </h2>
        <p>
          Il Titolare tratta i dati personali identificativi (“Dati”) forniti
          dall'Interessato e specificamente i dati anagrafici (nome, cognome,
          indirizzo email) nonché i dati relativi al Suo utilizzo della
          Piattaforma al fine di consentire la registrazione e l'utilizzo del
          sito EazyRef (“Piattaforma”).
        </p>

        <br />

        <h2 className="font-bold">
          2. Finalità, base giuridica del trattamento e conseguenze della
          mancata comunicazione dei Dati
        </h2>
        <p>
          Il trattamento è finalizzato a consentire la creazione di un Account
          individuale che Le consentirà di poter accedere ed utilizzare il sito.
        </p>
        <p>
          Ogni Account avrà un unico ID utente e sarà protetto da una password.
          Sarà esclusiva responsabilità di ogni Utente garantire che ID utente e
          password vengano mantenuti riservati e non vengano utilizzati da
          persone non autorizzate. La Piattaforma non richiede l'inserimento di
          dati dei pazienti. I test effettuati non verranno conservati ma il
          risultato sarà cancellato una volta chiusa la sessione.
        </p>
        <p>
          Occasionalmente, AstraZeneca o altri loro responsabili o incaricati
          potrebbero anche contattarLa per verificare l'accuratezza e
          l'aggiornamento dei Suoi Dati raccolti e conservati nei propri
          database.
        </p>
        <p>
          I Dati sono trattati in modo lecito e secondo correttezza per le
          finalità connesse all'espletamento delle attività necessarie
          all'utilizzo del sito. Il conferimento dei dati è obbligatorio ai fini
          di usufruire delle funzionalità del sito.
        </p>

        <br />

        <h2 className="font-bold">3. Modalità del trattamento:</h2>
        <p>
          Il trattamento dei Dati è realizzato per mezzo delle operazioni
          indicate all'art. 4 n. 2) GDPR e precisamente: raccolta,
          registrazione, conservazione. I Dati sono sottoposti a trattamento sia
          cartaceo che elettronico e/o automatizzato.
        </p>
        <p>
          I Dati acquisiti formano oggetto di trattamento, nel pieno rispetto
          delle norme di legge, nonché dei principi di liceità, correttezza,
          trasparenza, non eccedenza e tutela della Sua riservatezza e dei Suoi
          diritti.
        </p>

        <br />

        <h2 className="font-bold">4. Periodo di conservazione dei Dati:</h2>
        <p>
          Il Titolare conserva i Dati nel rispetto delle leggi locali e delle
          politiche e procedure aziendali interne per il tempo necessario allo
          scopo di adempiere le finalità di cui sopra e soddisfare i propri
          legittimi interessi commerciali, obblighi giuridici ovvero per
          stabilire, esercitare o difendere diritti legali. Esaurita la
          necessità di conservazione dei Dati per detti scopi, i medesimi
          saranno eliminati in maniera sicura.
        </p>
        <p>
          I profili inattivi e i relativi dati sono cancellati dopo 1 anno
          previo invio di un alert che la inviterà a confermare se è interessato
          a mantenere il profilo.
        </p>
        <p>
          Per maggiori informazioni sulla politica sulla conservazione dei
          documenti, è possibile consultare la policy AstraZeneca di ritenzione
          dei dati personali sul sito{' '}
          <a
            className="underline"
            href="https://www.astrazenecapersonaldataretention.com/third-parties.html"
            target="_blank"
            rel="noreferrer">
            {
              'https://www.astrazenecapersonaldataretention.com/third-parties.html'
            }
          </a>
        </p>

        <br />

        <h2 className="font-bold">
          5. Categorie di destinatari dei dati personali:
        </h2>
        <p>
          I Dati potranno essere resi accessibili per le finalità sopra
          descritte:
        </p>
        <ul className="pl-4">
          <li className="list-disc">
            a dipendenti e collaboratori del Titolare in Italia e all'estero,
            nella loro qualità di responsabili del trattamento e/o persone
            autorizzate al trattamento/subresponsabili/incaricati del
            trattamento e/o amministratori di sistema;
          </li>
          <li className="list-disc">
            ad altre Società del Gruppo (controllanti, controllate e/o
            collegate) in Italia e all'estero e ai dipendenti e collaboratori
            delle stesse (ad esempio per finalità amministrative e contabili);
          </li>
          <li className="list-disc">
            ad altre società terze o altri soggetti (a titolo meramente
            indicativo e non esaustivo, società che supportano le attività di
            facility management, studi professionali, consulenti etc.) che
            svolgono attività in outsourcing per conto del Titolare, nella loro
            qualità di responsabili esterni del trattamento, tra cui i fornitori
            o i soggetti incaricati ad eseguire servizi accessori o strumentali
            alle finalità sopra indicate, con cui il Titolare stipuli appositi
            accordi.
          </li>
        </ul>
        <p>
          Il Titolare si riserva inoltre la facoltà di rendere accessibili i
          dati personali ad alcune terze parti, fra cui: provider IT per
          finalità di sviluppo di sistemi e assistenza tecnica; revisori e
          consulenti per accertare la conformità ai requisiti esterni e interni;
          enti legali, agenzie preposte all'applicazione della legge e parti in
          causa conformemente a obblighi giuridici di informativa o pretese;
          eventuali successori o partner commerciali del Titolare o di una
          società del gruppo del Titolare in caso di vendita, cessione o altre
          operazioni straordinarie; forze di polizia, forze armate ed altre
          amministrazioni pubbliche, per l'adempimento di obblighi previsti
          dalla legge, da regolamenti o dalla normativa comunitaria.
        </p>
        <p>
          Nel caso in cui detti soggetti siano stabiliti in Paesi extra-UE il
          Titolare assicura che il trasferimento dei dati extra-UE avverrà in
          conformità alle disposizioni di legge applicabili, previa stipula
          delle clausole contrattuali standard previste dalla Commissione
          Europea, come previsto al successivo articolo.
        </p>

        <br />

        <h2 className="font-bold">6. Trasferimento Dati: </h2>
        <p>
          I Dati sono conservati su server e strumenti di archiviazione ubicati
          all'interno dell'Unione Europea. Resta in ogni caso inteso che il
          Titolare, ove si rendesse necessario, ha la facoltà di trasferire i
          dati anche in Paesi non appartenenti all'Unione Europea o allo Spazio
          Economico Europeo riconosciuti dalla Commissione Europea e che
          garantiscano un livello adeguato di protezione dei dati personali
          ovvero, in caso contrario, solo se sia garantito contrattualmente un
          livello di protezione dei dati personali adeguato rispetto a quello
          dell'Unione Europea e sia assicurato l'esercizio dei diritti degli
          interessati. In tal caso, il Titolare assicura sin d'ora che il
          trasferimento dei Dati extra-UE avverrà in conformità alle
          disposizioni di legge applicabili, previa stipula delle clausole
          contrattuali standard previste dalla Commissione Europea.
        </p>
        <p>
          Il Titolare applicherà ai predetti trasferimenti tutte le tutele
          necessarie ai sensi della vigente normativa in materia di privacy.
        </p>

        <br />

        <h2 className="font-bold">7. Diritti dell'interessato: </h2>
        <p>
          L'Interessato ha i diritti di cui agli artt. 13, comma 2, lettere b),
          c) e d), 15, 16, 17, 18, 19 e 21 GDPR (ove compatibili con riferimento
          a ciascun trattamento di dati rilevante ai fini del GDPR) e
          precisamente i diritti di:
        </p>
        <ul className="pl-4">
          <li className="list-disc">
            ottenere la conferma dell'esistenza o meno di Dati che La
            riguardano, anche se non ancora registrati, e la loro comunicazione
            in forma intelligibile;
          </li>
          <li className="list-disc">
            ottenere l'indicazione: a) dell'origine dei Dati (qualora non siano
            stati ottenuti presso l'interessato); b) delle finalità e modalità
            del trattamento, nonché della relativa base giuridica; c) della
            logica applicata in caso di trattamento effettuato con l'ausilio di
            strumenti elettronici; d) degli estremi identificativi del Titolare,
            del Responsabile della protezione dei dati (ove nominato) e del
            rappresentante eventualmente designato ai sensi dell'art. 13, comma
            1, GDPR; e) dei soggetti o delle categorie di soggetti ai quali i
            Dati possono essere comunicati o che possono venirne a conoscenza in
            qualità di rappresentanti designati nel territorio dello Stato e di
            responsabili;
          </li>
          <li className="list-disc">
            ottenere: a) l'aggiornamento, la rettificazione ovvero, quando vi ha
            interesse, l'integrazione dei Dati; b) la cancellazione, la
            trasformazione in forma anonima o il blocco dei dati personali
            trattati in violazione di legge, compresi quelli di cui non è
            necessaria la conservazione in relazione agli scopi per i quali i
            dati personali sono stati raccolti o successivamente trattati; c)
            l'attestazione che le operazioni di cui alle lettere a) e b) sono
            state portate a conoscenza, anche per quanto riguarda il loro
            contenuto, di coloro ai quali i dati sono stati comunicati o
            diffusi, eccettuato il caso in cui tale adempimento si rivela
            impossibile o comporta un impiego di mezzi manifestamente
            sproporzionato rispetto al diritto tutelato;
          </li>
          <li className="list-disc">
            opporsi, in tutto o in parte: a) per motivi legittimi al trattamento
            dei Dati che La riguardano, ancorché pertinenti allo scopo della
            raccolta; b) al trattamento di Dati che La riguardano a fini di
            invio di materiale pubblicitario o di vendita diretta o per il
            compimento di ricerche di mercato o di comunicazione commerciale,
            mediante l'uso di sistemi automatizzati di chiamata senza
            l'intervento di un operatore mediante e-mail e/o mediante modalità
            di marketing tradizionali mediante telefono e/o posta cartacea. Si
            fa presente che il diritto di opposizione dell'interessato, esposto
            al precedente punto b), per finalità di marketing diretto mediante
            modalità automatizzate si estende a quelle tradizionali e che
            comunque resta salva la possibilità per l'interessato di esercitare
            il diritto di opposizione anche solo in parte. Pertanto,
            l'interessato può decidere di ricevere solo comunicazioni mediante
            modalità tradizionali ovvero solo comunicazioni automatizzate oppure
            nessuna delle due tipologie di comunicazione;
          </li>
          <li className="list-disc">
            ove applicabili, ha altresì i diritti di cui agli artt. 16 - 21 GDPR
            (Diritto di rettifica, diritto all'oblio, diritto di limitazione di
            trattamento, diritto alla portabilità dei dati, diritto di
            opposizione), nonché il diritto di reclamo all'Autorità Garante (i
            contatti del Garante per la Protezione dei Dati Personali sono
            consultabili sul sito{' '}
            <a className="underline" href="privacy@astrazeneca.com">
              www.garanteprivacy.it
            </a>
            );
          </li>
          <li className="list-disc">
            revocare in qualsiasi momento il proprio consenso eventualmente
            prestato.
          </li>
        </ul>
        <p>
          Per quanto attiene al diritto alla portabilità dei dati, l'interessato
          può chiedere di ricevere o trasferire i propri dati personali in
          possesso del Titolare in un formato strutturato di uso comune e
          leggibile, per ulteriori usi personali ovvero per fornirli ad altri
          titolare del trattamento.
        </p>
        <p>
          Con riferimento al rapporto contrattuale, in termini generali i dati
          che possono essere oggetto di portabilità sono i dati anagrafici e di
          contatto.
        </p>

        <br />

        <h2 className="font-bold">8. Modalità di esercizio dei diritti: </h2>
        <p>
          L'Interessato potrà in qualsiasi momento esercitare, tramite i propri
          rappresentanti legali, i diritti o formulare una richiesta inviando:
          una raccomandata a.r all'attenzione del Chief Privacy Officer,
          AstraZeneca, Middlewooe Court, Silk Road, Macclesfield, Cheshire SK10
          2NA ovvero tramite il sito{' '}
          <a className="underline" href="privacy@astrazeneca.com">
            privacy@astrazeneca.com
          </a>
        </p>
        <p>
          Lei può presentare inoltre una segnalazione al Garante per la
          protezione dei dati personali.
        </p>

        <br />

        <h2 className="font-bold">
          9. Titolare e Responsabile e del trattamento:{' '}
        </h2>
        <p>
          Il Titolare è la società AstraZeneca S.p.A., C.F. - P.IVA 00735390155,
          con sede legale in Milano, viale Decumano 39 (cap 20157) in persona
          del legale rappresentante p.t.,
        </p>
        <p>
          L'elenco delle categorie dei Responsabili del trattamento è custodito
          presso la sede legale del Titolare.
        </p>
      </div>
    </Base>
  );
};

export default React.memo(Privacy);
