//Others
import {ModalContent as ModalContentEnum} from '../../../store/modal/interfaces';

//Other components
// @REMOVE_PATIENTS_DATA
// import {AddPatient} from '../AddPatient';
import {ScoreThreshold} from '../ScoreThreshold';
import {NewQuestionnaire} from '../NewQuestionnaireModal';
import {DeleteModal} from '../DeleteModal';
import {BookAnAppointmentModal} from '../BookAnAppointmentModal';

export const getModalContent = (modalContent: ModalContentEnum) => {
  // @REMOVE_PATIENTS_DATA
  // if (modalContent === ModalContentEnum.ADD_PATIENT) {
  //   return AddPatient;
  // }

  if (modalContent === ModalContentEnum.SCORE_THRESHOLD) {
    return ScoreThreshold;
  }

  if (modalContent === ModalContentEnum.NEW_QUESTIONNAIRE) {
    return NewQuestionnaire;
  }

  if (modalContent === ModalContentEnum.DELETE_MODAL) {
    return DeleteModal;
  }

  if (modalContent === ModalContentEnum.BOOK_AN_APPOINTMENT) {
    return BookAnAppointmentModal;
  }

  return null;
};
