export enum AnonymousRoutes {
  LOGIN = '/login',
  LOGIN_MMG = '/mmg-login',
  COMPONENTS = '/components',
  MAIN = '/',
  SIGNUP = '/patient/confirm-registration',
  ADMIN_LOGIN = '/admin-login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/user/set-new-password',
  ADMIN_NEW_PASSWORD = '/admin/confirm-registration',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
}

export enum AdminRoutes {
  PATHOLOGIES = '/admin-pathologies',
  CREATE_PATHOLOGY = '/admin-pathologies/create',
  QUESTIONNAIRES = '/admin-questionnaires',
  // MMG = '/mmg',
  QUESTIONNAIRE_DETAIL = '/admin-questionnaire-detail',
  QUESTIONNAIRE_DETAIL_UPDATE = '/admin-questionnaire-detail/:id',
  MEDICAL_CENTERS = '/admin-medical-centers',
  MEDICAL_CENTERS_DETAIL = '/admin-medical-centers-detail',
  MEDICAL_CENTERS_DETAIL_UPDATE = '/admin-medical-centers-detail/:id',
  CONTENTS = '/admin-contents',
  CREATE_CONTENTS = '/admin-contents/create',
  UPDATE_CONTENTS = '/admin-contents/update/:id',
  MANAGE_ADMIN = '/admins',
  CREATE_ADMIN = '/admin-manage-detail',
  MANAGE_ADMIN_DETAIL = '/admin-manage-detail/:id',
  PROFILE = '/admin-profile/:id',
  ADMIN_EDIT_PASSWORD = '/admin/set-new-password',
  // @REMOVE_PATIENTS_DATA
  // ADMIN_KPI = '/admin-kpi',
}

export enum MMGRoutes {
  // @REMOVE_PATIENTS_DATA
  // PATIENTS = '/patients',
  // PATIENT = '/patients/:id',
  // NEW_QUESTIONNAIRE = '/patients/:patientId/pathology/:id/new-questionnaire/',
  // DRAFT_QUESTIONNAIRE = '/patients/:patientId/pathology/:pathologyId/questionnaire/:questionnaireId/draft',
  // REVIEW_QUESTIONNAIRE = '/patients/:patientId/pathology/:pathologyId/questionnaire/:questionnaireId/review',
  // ACTIVE_QUESTIONNAIRE = '/patients/:patientId/pathology/:id/new-questionnaire/active',

  QUESTIONNAIRE = '/questionnaire',
  NEW_QUESTIONNAIRE = '/pathology/:id/new-questionnaire/',
  ACTIVE_QUESTIONNAIRE = '/pathology/:id/new-questionnaire/active',
  RESULT_QUESTIONNAIRE = '/pathology/:pathologyId/new-questionnaire/result',

  CENTERS_MAP = '/centers-map',
  RESOURCES = '/resources',
  MMG_PROFILE = '/mmg-profile',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  PRIVACY = '/privacy',
}

export enum PatientRoutes {
  // @REMOVE_PATIENTS_DATA
  // PATIENT_PROFILE = '/patient',
  // PATIENT_EDIT_PASSWORD = '/patient/edit-password',
  CENTERS_MAP = '/centers-map',
  RESOURCES = '/resources',
}

export enum AuthenticatedRoutes {
  MAIN = '/',
  MMG_FIRST_ACCESS = '/complete-registration',
}
