import React from 'react';
import classNames from 'classnames';

// Components
import {IconSVG} from '../IconSVG';

// Interface
import {IProps} from './interface';

// Style
import './CheckableInput.scss';

const CheckableInput = ({
  type = 'checkbox',
  name = undefined,
  value = undefined,
  children,
  inputName,
  isChecked,
  onChange,
  className,
  disabled = false,
}: IProps): JSX.Element => {
  return (
    <div className={classNames('az-checkbox', className)}>
      <label htmlFor={inputName} className="az-checkbox__input-wrapper">
        <input
          id={inputName}
          type={type}
          name={name}
          value={value}
          className="az-checkbox__input"
          checked={isChecked}
          onChange={onChange}
          disabled={disabled}
        />
        <span className="az-checkbox__span" />
        {isChecked && (
          <IconSVG
            icon="icon-checked"
            size={12}
            className="az-checkbox__thick"
          />
        )}
      </label>
      <div
        className={classNames('az-checkbox__label', {
          'az-checkbox__label--checked': isChecked,
        })}>
        {children}
      </div>
    </div>
  );
};

export default React.memo(CheckableInput);
