import React, {useMemo} from 'react';
import classNames from 'classnames';

// Others
import truncateText from '../../../utils/truncateText';

//Other Components
import {Image} from '../../atoms/Image';

//Component namespace
import {IProps} from './interfaces';
import './ResourceCard.scss';

const ResourceCard = (props: IProps) => {
  const {className, description, imageUrl, title, url, pathologies} = props;

  return (
    <a
      className={classNames('az-resource', className)}
      href={url}
      target="_blank"
      rel="noreferrer noopener">
      <article className="az-resource__wrapper">
        <div className="az-resource__media">
          <Image ratio="4:3" alt={title} src={imageUrl} />
        </div>
        <div className="az-resource__body">
          <h2 className="az-resource__title">{title}</h2>
          <div className="az-resource__excerpt">
            {truncateText(description || '', 110)}
          </div>
        </div>
        <div>
          {pathologies?.map(pathology => {
            return (
              <div key={pathology.id} className="az-resource__pathology-chip">
                {pathology.name}
              </div>
            );
          })}
        </div>
      </article>
    </a>
  );
};

export default React.memo(ResourceCard);
