import {
  QuestionnaireResultActions,
  QuestionnaireResultCleanAction,
  QuestionnaireResultFillAction,
  QuestionnaireResultState,
} from './interfaces';

export const getInitialState = (): QuestionnaireResultState => ({
  data: null,
});

export const questionnaireResultReducer = (
  state: QuestionnaireResultState = getInitialState(),
  action: QuestionnaireResultFillAction | QuestionnaireResultCleanAction,
) => {
  if (action.type === QuestionnaireResultActions.FILL_QUESTIONNAIRE_RESULT) {
    return {data: action.payload};
  }
  if (action.type === QuestionnaireResultActions.CLEAN_QUESTIONNAIRE_RESULT) {
    return {data: null};
  }

  return state;
};
