import validate from 'validate.js';
import {useCallback} from 'react';

export const useValidator = () => {
  const validateField = useCallback((attrValue, constraints) => {
    const isValid = validate.single(attrValue, constraints);
    return isValid === undefined;
  }, []);

  const validateForm = useCallback((form: {}, constraints: {}) => {
    const isValid = validate(form, constraints);
    return isValid === undefined;
  }, []);

  return {
    validateField,
    validateForm,
  };
};
