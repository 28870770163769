import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';

//Others
import {useAuthentication} from '../../../store/authentication';
import {ModalContext} from '../../../store/modal/context';
import {useBreadCrumb} from '../../../store/breadCrumb';
import {ModalContent} from '../../../store/modal/interfaces';

// Template
import {AuthBase} from '../../templates/AuthBase';

//Components
import {Button} from '../../atoms/Button';
// import {Spinner} from '../../atoms/Spinner';

// Types
import {IProps as IconProps} from '../../atoms/IconSVG/interfaces';

//Component namespace
import {IProps} from './interfaces';
import './Questionnaire.scss';
import Collapse from '@kunukn/react-collapse';

const Questionnaire = (_props: IProps) => {
  //Helpers
  const {t} = useTranslations();
  const {isLoggedIn} = useAuthentication();
  const {setValue: setModal} = useContext(ModalContext);
  const {updateBreadCrumbItems} = useBreadCrumb();

  const addIcon = useMemo<IconProps>(
    () => ({
      icon: 'icon-add',
      size: 23,
      className: 'text-white',
    }),
    [],
  );

  useEffect(() => updateBreadCrumbItems([]), [updateBreadCrumbItems]);

  const openModal = useCallback(() => {
    setModal({content: ModalContent.NEW_QUESTIONNAIRE});
  }, [setModal]);

  const cta = useMemo<ReactElement>(
    () => (
      <div className="az-questionnaire__heading-cta">
        <Button
          className="flex justify-end az-button__add"
          label={t('Questionnaire.cta.label')}
          onClick={openModal}
          styleType="primary"
          rightIcon={addIcon}
          hideLabel
        />
      </div>
    ),
    [addIcon, openModal, t],
  );

  const [opened, setOpened] = React.useState(false);

  return (
    <AuthBase
      pageHeading={t('Questionnaire.heading')}
      isAuth={isLoggedIn}
      cta={cta}
      mobileAddBtn>
      <div className="w-full p-4">
        {Array(4)
          .fill(1)
          .map((_, i) => (
            <p className="w-full">{t(`Questionnaire.disclaimers.${i}`)}</p>
          ))}
      </div>

      <details className="pl-4">
        <summary className="underline cursor-pointer max-w-max">
          {t('Questionnaire.bibliography')}
        </summary>

        <ul className="bg-gray-100 pb-8 pl-5">
          {[
            'Salmani H, Ahmadi M, Shahrokhi N. The Impact of Mobile Health on Cancer Screening: A Systematic Review. Cancer Inform. 2020 Oct 13;19:1176935120954191. doi: 10.1177/1176935120954191',
            'Cassidy A, Duffy SW, Myles JP, Liloglou T, Field JK. Lung cancer risk prediction: a tool for early detection. Int J Cancer. 2007 Jan 1;120(1):1-6. doi: 10.1002/ijc.22331.',
            'Szanto Z, Benko I, Jakab L, Szalai G, Vereczkei A. The use of a smartphone application for fast lung cancer risk assessment†. Eur J Cardiothorac Surg. 2017 Jun 1;51(6):1171-1176. doi: 10.1093/ejcts/ezw444.',
            'Siegel RL, Miller KD, Wagle NS, Jemal A. Cancer statistics, 2023. CA Cancer J Clin. 2023 Jan;73(1):17-48. doi: 10.3322/caac.21763.',
            'Huang Y, Zhu M, Ji M, Fan J, Xie J, Wei X, Jiang X, Xu J, Chen L, Yin R, Wang Y, Dai J, Jin G, Xu L, Hu Z, Ma H, Shen H. Air Pollution, Genetic Factors, and the Risk of Lung Cancer: A Prospective Study in the UK Biobank. Am J Respir Crit Care Med. 2021 Oct 1;204(7):817-825.',
            'Vavalà T, Rigney M, Reale ML, Novello S, King JC. An examination of two dichotomies: Women with lung cancer and living with lung cancer as a chronic disease. Respirology. 2020 Nov;25 Suppl 2:24-36. doi: 10.1111/resp.13965.',
            'Hill W, Lim EL, Weeden CE, Lee C, Augustine M, Chen K, Kuan FC, Marongiu F, Evans EJ Jr, Moore DA, Rodrigues FS, Pich O, Bakker B, Cha H, Myers R, van Maldegem F, Boumelha J, Veeriah S, Rowan A, Naceur-Lombardelli C, Karasaki T, Sivakumar M, De S, Caswell DR, Nagano A, Black JRM, Martínez-Ruiz C, Ryu MH, Huff RD, Li S, Favé MJ, Magness A, Suárez-Bonnet A, Priestnall SL, Lüchtenborg M, Lavelle K, Pethick J, Hardy S, McRonald FE, Lin MH, Troccoli CI, Ghosh M, Miller YE, Merrick DT, Keith RL, Al Bakir M, Bailey C, Hill MS, Saal LH, Chen Y, George AM, Abbosh C, Kanu N, Lee SH, McGranahan N, Berg CD, Sasieni P, Houlston R, Turnbull C, Lam S, Awadalla P, Grönroos E, Downward J, Jacks T, Carlsten C, Malanchi I, Hackshaw A, Litchfield K; TRACERx Consortium; DeGregori J, Jamal-Hanjani M, Swanton C. Lung adenocarcinoma promotion by air pollutants. Nature. 2023 Apr;616(7955):159-167. doi: 10.1038/s41586-023-05874-3.',
            'Passiglia F, Cinquini M, Bertolaccini L, Del Re M, Facchinetti F, Ferrara R, Franchina T, Larici AR, Malapelle U, Menis J, Passaro A, Pilotto S, Ramella S, Rossi G, Trisolini R, Novello S. Benefits and Harms of Lung Cancer Screening by Chest Computed Tomography: A Systematic Review and Meta-Analysis. J Clin Oncol. 2021 Aug 10;39(23):2574-2585. doi: 10.1200/JCO.20.02574.',
          ].map(text => (
            <li className="list-disc">{text}</li>
          ))}
        </ul>
      </details>

      <div className="az-questionnaire__page-min-height" />
    </AuthBase>
  );
};

export default React.memo(Questionnaire);
