import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useParams} from 'react-router-dom';
import {useTranslations} from '@vidiemme/react-i18n';

// Others
import {useAuthentication} from '../../../store/authentication';
import {useBreadCrumb} from '../../../store/breadCrumb';
import {AlertContext} from '../../../store/alert/context';
import {QuestionType} from '../../../store/questionnaire/types';
import {
  QuestionnaireInput,
  QuestionnaireStatus,
  useSaveQuestionnaire,
} from '../../../store/questionnaire';
import {useQuestionnaire} from '../../../hooks/questionnaire';
import {useNavigation} from '../../../navigation';

// Components

import {Button} from '../../atoms/Button';
import {QuestionWithAnswers} from '../../organisms/QuestionWithAnswers';

// Templates
import {AuthBase} from '../../templates/AuthBase';

// Interface
import {IProps} from './interface';

// Style
import './ActiveQuestionnaire.scss';
import {useQuestionnaireResult} from '../../../store/questionnaireResult';
import {LeavingPagePrompt} from '../../organisms/LeavingPagePrompt';

const ActiveQuestionnaire = (_props: IProps) => {
  //Internal state
  const {
    id: pathologyId,
  }: {
    id: string;
  } = useParams();
  const {setValue: setAlert} = useContext(AlertContext);

  const {
    getQuestionById,
    getNextQuestionId,
    goToNextQuestionId,
    getFirstQuestionId,
    getAnswerById,
    questionnaire,
    checkAnswerByQuestionId,
  } = useQuestionnaire(Number(pathologyId));

  const firstQuestion = getQuestionById(getFirstQuestionId());
  const [currentQuestion, setCurrentQuestion] = useState<QuestionType>(
    firstQuestion!,
  );
  const [countScore, setCountScore] = useState<number>(0);

  const [
    saveQuestionnaire,
    {
      error: questionnaireError,
      loading: sendingQuestionnaire,
      data: questionnaireResult,
    },
  ] = useSaveQuestionnaire(questionnaire);

  //Helpers
  const {t} = useTranslations();
  const {isLoggedIn, id: doctorId} = useAuthentication();
  const {goToResultQuestionnaire} = useNavigation();
  const {updateBreadCrumbItems} = useBreadCrumb();

  const [selectedOption, setSelectedOption] = useState<string>('');

  const onValueChange = useCallback(
    (value: any) => {
      setSelectedOption(value);
      checkAnswerByQuestionId(currentQuestion.id, value);
    },
    [checkAnswerByQuestionId, currentQuestion.id],
  );

  const findCheckedAnswerId = useCallback(
    (id: string | number): string => {
      return (
        getQuestionById(id?.toString() || '')!.answers.find(item => item.choice)
          ?.id || ''
      );
    },
    [getQuestionById],
  );

  // Go to next question
  const onClickNext = useCallback(() => {
    goToNextQuestionId(currentQuestion.id, selectedOption);
    const nextQuestionId = getNextQuestionId(
      currentQuestion.id,
      selectedOption,
    );
    setSelectedOption(findCheckedAnswerId(nextQuestionId!));

    const nextQuestion = getQuestionById(nextQuestionId!);
    setCurrentQuestion(nextQuestion!);

    // Increment score
    if (selectedOption) {
      const answer = getAnswerById(currentQuestion.id, selectedOption);
      setCountScore(countScore + answer!.score);
    }
  }, [
    countScore,
    currentQuestion,
    findCheckedAnswerId,
    getAnswerById,
    getNextQuestionId,
    getQuestionById,
    goToNextQuestionId,
    selectedOption,
  ]);

  const onClickPrevious = useCallback(() => {
    const previousQuestionId = currentQuestion.previousQuestionId;

    // Decrement score
    if (selectedOption) {
      const answer = getAnswerById(currentQuestion.id, selectedOption);
      setCountScore(countScore - answer!.score);
    }
    setCurrentQuestion(getQuestionById(previousQuestionId.toString())!);
    setSelectedOption(findCheckedAnswerId(previousQuestionId!));
  }, [
    countScore,
    currentQuestion,
    findCheckedAnswerId,
    getAnswerById,
    getQuestionById,
    selectedOption,
  ]);

  const submitQuestionnaire = useCallback(
    (questionnaireStatus: QuestionnaireStatus) => {
      // Create structure ad hoc for mutation input
      const input: QuestionnaireInput = {
        id: questionnaire.id,
        pathologyId: questionnaire.pathology.id,
        subTitle: questionnaire.subTitle,
        instructions: questionnaire.instructions,
        limitScore: questionnaire.limitScore,
        limitScore2: questionnaire.limitScore2,
        score: questionnaire.questions.reduce(
          (acc: any, question: {answers: any[]}) => {
            question.answers.forEach(answer => {
              if (answer.choice) {
                acc += answer.score;
              }
            });
            return acc;
          },
          0,
        ),
        questions: questionnaire.questions,
        status: questionnaireStatus,
        doctorId: doctorId!,
      };

      saveQuestionnaire({variables: {input: input}});
    },
    [questionnaire, doctorId, saveQuestionnaire],
  );

  const onClickFinish = useCallback(() => {
    submitQuestionnaire(QuestionnaireStatus.COMPLETED);
    //TODO setError
  }, [submitQuestionnaire]);

  const renderNextButton = useMemo(() => {
    if (!selectedOption) {
      return (
        <Button
          label={t('ActiveQuestionnaire.buttonNext')}
          onClick={onClickNext}
          disabled
        />
      );
    } else if (
      selectedOption &&
      getAnswerById(currentQuestion.id, selectedOption)?.nextQuestionId
    ) {
      return (
        <Button
          label={t('ActiveQuestionnaire.buttonNext')}
          onClick={onClickNext}
        />
      );
    } else {
      return (
        <Button
          label={t('ActiveQuestionnaire.buttonFinish')}
          onClick={onClickFinish}
        />
      );
    }
  }, [
    selectedOption,
    getAnswerById,
    currentQuestion.id,
    onClickNext,
    t,
    onClickFinish,
  ]);

  //Set BreadCrumb items
  useEffect(() => {
    updateBreadCrumbItems([]);
  }, [updateBreadCrumbItems, t]);

  const {fillQuestionnaireResult} = useQuestionnaireResult();

  // Send user to ResultQuestionnaire
  useEffect(() => {
    if (!sendingQuestionnaire && questionnaireResult) {
      fillQuestionnaireResult(questionnaireResult);
      goToResultQuestionnaire(Number(pathologyId));
    }
  }, [
    sendingQuestionnaire,
    questionnaireResult,
    goToResultQuestionnaire,
    pathologyId,
    fillQuestionnaireResult,
  ]);

  useEffect(() => {
    if (questionnaireError) {
      setAlert({
        type: 'ERROR',
        message: t('ActiveQuestionnaire.errorCreateQuestionnaire'),
      });
    }
  }, [
    fillQuestionnaireResult,
    goToResultQuestionnaire,
    pathologyId,
    questionnaireError,
    sendingQuestionnaire,
    setAlert,
    t,
  ]);
  return (
    <>
      <AuthBase isAuth={isLoggedIn} pageHeading={t('NewQuestionnaire.title')}>
        <div className="az-new-questionnaire">
          <div className="az-active-questionnaire__questionnaire-data">
            <QuestionWithAnswers
              question={currentQuestion}
              selectOption={onValueChange}
              onChange={onValueChange}
              selectedOption={selectedOption}
            />
          </div>
          <div className="az-active-questionnaire__buttons">
            <div className="az-new-questionnaire__button">
              <Button
                styleType="secondary"
                label={t('ActiveQuestionnaire.buttonBack')}
                onClick={onClickPrevious}
                disabled={currentQuestion.start}
              />
            </div>
            <div className="az-new-questionnaire__button">
              {renderNextButton}
            </div>
          </div>
        </div>
        <p className="az-active-questionnaire__references">
          {(currentQuestion.reference && currentQuestion.reference !== '-') ||
          currentQuestion.answers.find(
            item => item.reference && item.reference !== '-',
          )
            ? `${t('ActiveQuestionnaire.references')}: `
            : null}
          {currentQuestion.reference && currentQuestion.reference !== '-'
            ? currentQuestion.reference
            : null}
          {currentQuestion.answers.map(
            item =>
              item.reference && item.reference !== '-' && ' ' + item.reference,
          )}
        </p>

        <p className="w-full text-center">{t('ActiveQuestionnaire.copyId')}</p>
      </AuthBase>
    </>
  );
};

export default React.memo(ActiveQuestionnaire);
