import {RiskLevel} from '../../utils/riskLevelEnum';
import {PageInfoResponse} from '../api';
import {PathologyResponse} from '../pathologies';

export enum TypeQuestion {
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  MULTI_CHOICE = 'MULTI_CHOICE',
}

export enum QuestionnaireStructureStatus {
  DRAFT = 'DRAFT',
  DELETED = 'DELETED',
  PUBLISHED = 'PUBLISHED',
}

export interface AnswerInput {
  id?: number;
  title: string;
  description: string;
  reference: string;
  score?: number;
  nextQuestionId?: number;
  choice: boolean;
  text: string;
  riskLevel?: RiskLevel;
}

export interface QuestionInput {
  id: number;
  start: boolean;
  title: string;
  description: string;
  reference: string;
  order: number;
  typeQuestion: TypeQuestion;
  answers: Partial<AnswerInput>[];
}

export interface AnswerResponse {
  id: number;
  title: string;
  description: string;
  reference: string;
  score: number;
  nextQuestionId: number;
  choice: boolean;
  text: string;
  riskLevel: RiskLevel;
}

export interface QuestionResponse {
  id: number;
  start: boolean;
  title: string;
  description: string;
  reference: string;
  order: number;
  typeQuestion: TypeQuestion;
  answers: [AnswerResponse];
}

export interface QuestionnaireStructureCreateInput {
  id?: number;
  pathologyId: number;
  subTitle: string;
  instructions: string;
  maxScore?: number;
  limitScore: number;
  limitScore2: number;
  questions: QuestionInput[];
  status: QuestionnaireStructureStatus;
}

//Duplicated just to couple what it is defined in the server ... it is not really necessary though
export interface QuestionnaireStructureUpdateInput
  extends Omit<QuestionnaireStructureCreateInput, 'id'> {}

export interface QuestionnaireStructureResponse {
  id: number;
  pathology: PathologyResponse;
  subTitle?: string;
  instructions?: string;
  limitScore: number;
  limitScore2: number;
  status: QuestionnaireStructureStatus;
  createdAt: string;
  questions: QuestionResponse[];
}

export interface QuestionnairesStructurePageResponse {
  items: QuestionnaireStructureResponse[];
  info: PageInfoResponse;
}

export interface QuestionnaireStructureFilter {
  id: number;
  pathologyId: number;
  status: QuestionnaireStructureStatus[];
}

export interface CreateQuestionnaireStructureResponse {
  createStructureQuestionnaire: QuestionnaireStructureResponse;
}

export interface UpdateQuestionnaireStructureResponse {
  updateStructureQuestionnaire: UpdateQuestionnaireStructureResponse;
}

export interface GetQuestionnairesStructureResponse {
  structureQuestionnaires: QuestionnairesStructurePageResponse;
}

export interface GetQuestionnaireStructureResponse {
  structureQuestionnaire: QuestionnaireStructureResponse;
}

export interface DeleteQuestionnaireStructureResponse {
  deleteStructureQuestionnaire: boolean;
}

export interface PublishQuestionnaireStructureResponse {
  publishStructureQuestionnaire: boolean;
}
