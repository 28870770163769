import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';

// Others
import {numericalityConstraint, useValidator} from '../../../hooks/validator';
import {InputType} from '../../molecules/LabeledInput/enum';
import {
  QuestionnaireStructureCreateInput,
  QuestionnaireStructureStatus,
  useCreateStructureQuestionnaire,
  useUpdateStructureQuestionnaire,
} from '../../../store/structureQuestionnaire';
import {useQuestionnaireScores} from './hooks';
import {noop} from '../../../utils/noop';
import {DEFAULT_MODAL_STATE, ModalContext} from '../../../store/modal/context';
import {AlertContext, DEFAULT_ALERT_STATE} from '../../../store/alert/context';

// Components
import {Button} from '../../atoms/Button';
import {LabeledInput} from '../../molecules/LabeledInput';

//Component namespace
import {IProps} from './interface';
import './ScoreThreshold.scss';
import {
  GET_STRUCTURE_QUESTIONNAIRE,
  GET_STRUCTURE_QUESTIONNAIRES,
} from '../../../store/structureQuestionnaire/queries';
import {useNavigation} from '../../../navigation';

const ScoreThreshold = ({questionnaireStructure}: IProps): JSX.Element => {
  const {t} = useTranslations();
  const {goToAdminSurveys} = useNavigation();
  const {validateField} = useValidator();
  const {setValue: setModalContent} = useContext(ModalContext);
  const {setValue: setAlertContent} = useContext(AlertContext);
  const [questionnaire, setQuestionnaire] =
    useState<QuestionnaireStructureCreateInput>(questionnaireStructure);
  const [limitScore, setLimitScore] = useState<number>(
    questionnaire.limitScore ?? 0,
  );
  const [limitScore2, setLimitScore2] = useState<number>(
    questionnaire.limitScore2 ?? 0,
  );
  const [hasLimitScoreError, setHasLimitScoreError] = useState<boolean>(false);
  const [hasLimitScore2Error, setHasLimitScore2Error] =
    useState<boolean>(false);
  const {maxScore, minScore} = useQuestionnaireScores(questionnaire);
  const [storeQuestionnaire, setStoreQuestionnaire] = useState<boolean>(false);

  // Return true if threshold input is valid
  const isThresholdValid = useMemo(() => {
    const isNumber1 = validateField(limitScore, numericalityConstraint);
    const isNumber2 = validateField(limitScore2, numericalityConstraint);

    return (
      isNumber1 &&
      isNumber2 &&
      limitScore >= minScore &&
      limitScore <= limitScore2 &&
      limitScore2 <= maxScore
    );
  }, [minScore, limitScore, limitScore2, maxScore, validateField]);

  //Questionnaire mutations
  const [
    createStructureQuestionnaire,
    {data: createData, error: createError, loading: createLoading},
  ] = useCreateStructureQuestionnaire();

  const [
    updateStructureQuestionnaire,
    {data: updateData, error: updateError, loading: updateLoading},
  ] = useUpdateStructureQuestionnaire();

  useEffect(() => {
    if (storeQuestionnaire) {
      const {id, ...otherOptions} = questionnaire;
      const mutation = id
        ? updateStructureQuestionnaire
        : createStructureQuestionnaire;

      mutation({
        variables: {
          input: {
            ...otherOptions,
            ...(questionnaire?.id ? {id} : {}),
            limitScore,
            limitScore2,
          },
        },
        refetchQueries: [
          GET_STRUCTURE_QUESTIONNAIRE,
          GET_STRUCTURE_QUESTIONNAIRES,
        ],
      })
        .catch(noop)
        .finally(() => setStoreQuestionnaire(false));
    }
  }, [
    storeQuestionnaire,
    createStructureQuestionnaire,
    updateStructureQuestionnaire,
    questionnaire,
    limitScore,
    limitScore2,
  ]);

  const saveQuestionnaireAsDraft = useCallback(() => {
    if (questionnaire.status !== QuestionnaireStructureStatus.DRAFT) {
      setQuestionnaire({
        ...questionnaire,
        status: QuestionnaireStructureStatus.DRAFT,
      });
    }

    setStoreQuestionnaire(true);
  }, [questionnaire]);

  const publishQuestionnaire = useCallback(() => {
    if (questionnaire.status !== QuestionnaireStructureStatus.PUBLISHED) {
      setQuestionnaire({
        ...questionnaire,
        status: QuestionnaireStructureStatus.PUBLISHED,
      });
    }

    setStoreQuestionnaire(true);
  }, [questionnaire]);

  const updateLimitScore = useCallback(
    (value: string) => setLimitScore(Number(value)),
    [],
  );
  const updateLimitScore2 = useCallback(
    (value: string) => setLimitScore2(Number(value)),
    [],
  );

  useEffect(() => {
    if (limitScore !== questionnaire.limitScore) {
      setQuestionnaire({...questionnaire, limitScore});
    }
    if (limitScore2 !== questionnaire.limitScore2) {
      setQuestionnaire({...questionnaire, limitScore2});
    }
  }, [limitScore, limitScore2, questionnaire]);

  useEffect(() => {
    if (createData || updateData) {
      setAlertContent({
        type: 'SUCCESS',
        message: t('ScoreThreshold.alert.success'),
        onClick: () => setAlertContent(DEFAULT_ALERT_STATE),
      });
      setModalContent(DEFAULT_MODAL_STATE);
      goToAdminSurveys();
    }
  }, [
    createData,
    updateData,
    setAlertContent,
    setModalContent,
    goToAdminSurveys,
    t,
  ]);

  return (
    <div className="az-score-modal">
      <h1 className="az-score-modal__title">{t('ScoreThreshold.title')}</h1>
      {(createError || updateError) && (
        <p className="az-score-modal__error">
          {t('ScoreThreshold.error.generic')}
        </p>
      )}
      {maxScore === minScore && (
        <p className="az-score-modal__error">
          {t('ScoreThreshold.error.equalScore')}
        </p>
      )}
      <div className="az-score-modal__scores">
        <LabeledInput
          label={t('ScoreThreshold.labelTotalScore')}
          disabled
          value={maxScore}
          className="az-score-modal__element-container"
        />
        <LabeledInput
          label={t('ScoreThreshold.labelMinScore')}
          disabled
          value={minScore}
          className="az-score-modal__element-container"
        />
      </div>
      <div className="az-score-modal__scores">
        <LabeledInput
          label={t('ScoreThreshold.threshold.label')}
          className="az-score-modal__element-container"
          placeholder={t('ScoreThreshold.threshold.placeholder')}
          onChange={updateLimitScore}
          error={hasLimitScoreError}
          onFocus={() => setHasLimitScoreError(false)}
          onBlur={e => {
            if (e.target.value === '') {
              e.target.value = '0';
            }
            setHasLimitScoreError(!isThresholdValid);
          }}
          type={InputType.NUMBER}
          defaultValue={limitScore}
          onKeyPress={(e: any) => {
            if (e && !e.key.match(/^[0-9]+$/)) {
              if (e.key === ',' || e.key === '.' || e.key === '-') {
                if (
                  e.target.value.includes(',') ||
                  e.target.value.includes('.')
                ) {
                  e.preventDefault();
                }
              } else {
                e.preventDefault();
              }
            }
            if (e.target.value.split('.')[1]?.length >= 1) {
              e.preventDefault();
            }
            if (e.target.value.length > 6) {
              e.preventDefault();
            }
          }}
        />
        <LabeledInput
          label={t('ScoreThreshold.threshold2.label')}
          className="az-score-modal__element-container"
          placeholder={t('ScoreThreshold.threshold2.placeholder')}
          onChange={updateLimitScore2}
          error={hasLimitScore2Error}
          onFocus={() => setHasLimitScore2Error(false)}
          onBlur={e => {
            if (e.target.value === '') {
              e.target.value = '0';
            }
            setHasLimitScore2Error(!isThresholdValid);
          }}
          type={InputType.NUMBER}
          defaultValue={limitScore2}
          onKeyPress={(e: any) => {
            if (e && !e.key.match(/^[0-9]+$/)) {
              if (e.key === ',' || e.key === '.' || e.key === '-') {
                if (
                  e.target.value.includes(',') ||
                  e.target.value.includes('.')
                ) {
                  e.preventDefault();
                }
              } else {
                e.preventDefault();
              }
            }
            if (e.target.value.split('.')[1]?.length >= 1) {
              e.preventDefault();
            }
            if (e.target.value.length > 6) {
              e.preventDefault();
            }
          }}
        />
      </div>
      <div className="az-score-modal__buttons">
        <div className="az-score-modal__element-container">
          <Button
            styleType="secondary"
            label={t('ScoreThreshold.buttonDraft')}
            loading={createLoading || updateLoading}
            disabled={createLoading || updateLoading}
            onClick={saveQuestionnaireAsDraft}
          />
        </div>
        <div className="az-score-modal__element-container">
          <Button
            label={t('ScoreThreshold.buttonPublish')}
            disabled={
              !isThresholdValid ||
              createLoading ||
              updateLoading ||
              minScore > limitScore ||
              limitScore > limitScore2 ||
              maxScore === limitScore ||
              minScore === maxScore
            }
            loading={createLoading || updateLoading}
            onClick={publishQuestionnaire}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(ScoreThreshold);
