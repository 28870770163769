import React, {useCallback, useContext, useState} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import classNames from 'classnames';

//Others
import {useNavigation} from '../../../../navigation';
import {AlertContext} from '../../../../store/alert/context';
import {useDeleteUser} from '../../../../store/admin/hooks';

//Other components
import {DataTableHead} from '../../../atoms/DataTableHead';
import {DataTableData} from '../../../molecules/DataTableData';
import {Pagination} from '../../../molecules/Pagination';
import {ButtonText} from '../../../atoms/ButtonText';

//Component namespace
import {IProps} from './interface';
import '../DataTable.scss';
import './DataTableAdmins.scss';
import {useAuthentication} from '../../../../store/authentication';

const DataTableAdmins = ({
  adminData,
  className,
  totalAdmins,
  refetchData,
}: IProps) => {
  //Helpers
  const {setValue: setAlert} = useContext(AlertContext);
  const {t} = useTranslations();
  const {goToAdminManageUpdate} = useNavigation();
  const {id: adminId} = useAuthentication();

  // Delete admin
  const [deleteUser, {loading: deleteLoading, error: deleteError}] =
    useDeleteUser();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemPerPage = 5;

  const getOnClickHandler = useCallback(
    (adminId: number) => {
      goToAdminManageUpdate(adminId);
    },
    [goToAdminManageUpdate],
  );

  // Pagination
  const setPage = useCallback(
    page => {
      setCurrentPage(page);
      // refetch data
      refetchData({offset: itemPerPage * (page - 1)});
    },
    [refetchData],
  );

  const onDelete = useCallback(
    (id: number, e: any) => {
      e.stopPropagation();

      setAlert({
        type: 'INFO',
        message: t('AdminManage.delete.confirm'),
        activeButtonLabel: t(
          'AdminQuestionnaireDetail.alert.cancel.activeButtonLabel',
        ),
        cancelButtonLabel: t(
          'AdminQuestionnaireDetail.alert.cancel.cancelButtonLabel',
        ),
        onClick: () => {
          deleteUser({
            variables: {id},
          })
            .catch(() => {
              setAlert({
                type: 'ERROR',
                message: t('AdminManage.delete.error'),
              });
            })
            .then(data => {
              if (!deleteLoading && !deleteError && data?.data?.deleteUser) {
                setAlert({
                  type: 'SUCCESS',
                  message: t('AdminManage.delete.success'),
                });
                setPage(1);
              }
            });
        },
      });
    },
    [deleteError, deleteLoading, deleteUser, setAlert, setPage, t],
  );

  return (
    <div className="w-full mb-16">
      <div className="mb-16 az-data-table__table-container">
        <table
          className={classNames(
            'az-data-table',
            'az-data-table--admins',
            className,
          )}>
          <thead className="az-data-table__head">
            <tr>
              <DataTableHead label={t('AdminManage.tableHeading.firstName')} />
              <DataTableHead label={t('AdminManage.tableHeading.lastName')} />
              <DataTableHead label={t('AdminManage.tableHeading.email')} />
              <DataTableHead label={t('AdminManage.tableHeading.status')} />
              <DataTableHead />
            </tr>
          </thead>
          <tbody>
            {adminData.map((admin, idx) => {
              if (admin.id !== adminId) {
                return (
                  <tr
                    className={classNames('az-data-table__admins-data-row')}
                    key={`admin-row-${idx}`}>
                    <DataTableData
                      label={admin.firstName}
                      onClick={() => getOnClickHandler(admin.id)}
                      className={'az-data-table__data-cell w-3/12'}
                    />
                    <DataTableData
                      label={admin.lastName}
                      onClick={() => getOnClickHandler(admin.id)}
                      className={'az-data-table__data-cell w-3/12'}
                    />
                    <DataTableData
                      label={admin.email}
                      onClick={() => getOnClickHandler(admin.id)}
                      className={'az-data-table__data-cell w-3/12'}
                    />
                    <DataTableData
                      status={admin.status}
                      isAdmin={true}
                      onClick={() => getOnClickHandler(admin.id)}
                      className={'az-data-table__data-cell w-2/12'}
                    />
                    <DataTableData
                      onClick={() => getOnClickHandler(admin.id)}
                      className={'az-data-table__data-cell w-1/12'}>
                      <ButtonText
                        label={t('AdminManage.deleteAdmin')}
                        className="underline font-bold"
                        onClick={e => onDelete(admin.id, e)}
                      />
                    </DataTableData>
                  </tr>
                );
              } else if (admin.id === adminId) {
                return (
                  <tr
                    className={classNames(
                      'az-data-table__admins-data-row',
                      'az-data-table__admins-data-row--disabled',
                    )}
                    key={`admin-row-${idx}`}>
                    <DataTableData
                      label={admin.firstName}
                      className={'az-data-table__data-cell--disabled w-3/12'}
                    />
                    <DataTableData
                      label={admin.lastName}
                      className={'az-data-table__data-cell--disabled w-3/12'}
                    />
                    <DataTableData
                      label={admin.email}
                      className={'az-data-table__data-cell--disabled w-3/12'}
                    />
                    <DataTableData
                      status={admin.status}
                      isAdmin={true}
                      className={'az-data-table__data-cell--disabled w-2/12'}
                    />
                    <DataTableData
                      className={'az-data-table__data-cell--disabled w-2/12'}
                    />
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalAdmins ? Math.ceil(totalAdmins / itemPerPage) : 0}
        setPage={setPage}
      />
    </div>
  );
};

export default React.memo(DataTableAdmins);
