import React, {useMemo} from 'react';
import classNames from 'classnames';

//Component namespace
import {IProps} from './interfaces';
import './Image.scss';

const Image = (props: IProps) => {
  const {alt, ratio, src} = props;

  //Helpers
  const ratioClassesMap = useMemo(
    () =>
      new Map<IProps['ratio'], string>()
        .set('1:1', 'az-image__picture--ratio-1-1')
        .set('4:3', 'az-image__picture--ratio-4-3')
        .set('16:9', 'az-image__picture--ratio-16-9'),
    [],
  );
  const ratioClass = useMemo(
    () => ratioClassesMap.get(ratio),
    [ratioClassesMap, ratio],
  );

  return (
    <figure className="az-image">
      <picture className={classNames('az-image__picture', ratioClass)}>
        <img src={src} className="az-image__img" alt={alt} />
      </picture>
    </figure>
  );
};

export default React.memo(Image);
