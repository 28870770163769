import {useReducer} from 'react';
import {
  QuestionnaireStructureCreateInput,
  useStructureQuestionnaire,
} from '../../../store/structureQuestionnaire';

export const useStructureQuestionnaireInputReducer = () => {
  const {createStructureQuestionnaire} = useStructureQuestionnaire();

  const questionnaireReducer = (
    state: QuestionnaireStructureCreateInput,
    action: {type: string; payload?: any},
  ) => {
    if (action.type === 'UPDATE_SUB_TITLE') {
      return {
        ...state,
        subTitle: action.payload,
      };
    }

    if (action.type === 'UPDATE_INSTRUCTIONS') {
      return {
        ...state,
        instructions: action.payload,
      };
    }

    if (action.type === 'UPDATE_QUESTIONNAIRE') {
      return {
        ...(action.payload as QuestionnaireStructureCreateInput),
      };
    }

    return state;
  };

  const [questionnaire, dispatch] = useReducer(
    questionnaireReducer,
    createStructureQuestionnaire(),
  );

  return {
    questionnaire,
    dispatch,
  };
};
