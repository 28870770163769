import React, {useCallback, useContext, useState} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import classNames from 'classnames';

//Others
import {useNavigation} from '../../../../navigation';
import {AlertContext} from '../../../../store/alert/context';
import {ButtonText} from '../../../atoms/ButtonText';
import {useDeleteMedicalCenter} from '../../../../store/medicalCenters/hooks';

//Other components
import {DataTableHead} from '../../../atoms/DataTableHead';
import {DataTableData} from '../../../molecules/DataTableData';
import {Pagination} from '../../../molecules/Pagination';

//Component namespace
import {IProps} from './interface';
import '../DataTable.scss';
import './DataTableMedicalCenters.scss';

const DataTableMedicalCenters = ({
  medicalCentersData,
  className,
  totalMedicalCenters,
  refetchData,
}: IProps) => {
  //Helpers
  const {setValue: setAlert} = useContext(AlertContext);
  const {t} = useTranslations();
  const {goToMedicalCenterUpdate} = useNavigation();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemPerPage = 5;

  const [deleteMedicalCenter, {loading: deleteLoading, error: deleteError}] =
    useDeleteMedicalCenter();

  // Pagination
  const setPage = useCallback(
    page => {
      setCurrentPage(page);
      // refetch data
      refetchData({offset: itemPerPage * (page - 1), limit: 5});
    },
    [refetchData],
  );

  const getOnClickHandler = useCallback(
    (medicalCenterId: number) => {
      goToMedicalCenterUpdate(medicalCenterId);
    },
    [goToMedicalCenterUpdate],
  );

  const onDelete = useCallback(
    (id: number, e: any) => {
      e.stopPropagation();

      setAlert({
        type: 'INFO',
        message: t('MedicalCenters.delete.confirm'),
        activeButtonLabel: t(
          'AdminQuestionnaireDetail.alert.cancel.activeButtonLabel',
        ),
        cancelButtonLabel: t(
          'AdminQuestionnaireDetail.alert.cancel.cancelButtonLabel',
        ),
        onClick: () => {
          deleteMedicalCenter({
            variables: {id},
          })
            .catch(() => {
              setAlert({
                type: 'ERROR',
                message: t('MedicalCenters.delete.error'),
              });
            })
            .then(data => {
              if (
                !deleteLoading &&
                !deleteError &&
                data?.data?.deleteMedicalCenter
              ) {
                setAlert({
                  type: 'SUCCESS',
                  message: t('MedicalCenters.delete.success'),
                });
                setPage(1);
              }
            });
        },
      });
    },
    [deleteError, deleteLoading, deleteMedicalCenter, setAlert, setPage, t],
  );

  return (
    <div className="w-full mb-16">
      <div className="mb-16 az-data-table__table-container">
        <table
          className={classNames(
            'az-data-table',
            'az-data-table--medical-centers',
            className,
          )}>
          <thead className="az-data-table__head">
            <tr>
              <DataTableHead
                label={t('MedicalCentersDetail.businessName.label')}
              />
              <DataTableHead label="" />
            </tr>
          </thead>
          <tbody>
            {medicalCentersData.map((medicalCenter, idx) => (
              <tr
                className={classNames(
                  'az-data-table__medical-centers-data-row',
                )}
                key={`patient-row-${idx}`}>
                <DataTableData
                  label={medicalCenter.name}
                  onClick={() => getOnClickHandler(medicalCenter.id)}
                  className={'az-data-table__data-cell w-10/12'}
                />
                <DataTableData
                  onClick={() => getOnClickHandler(medicalCenter.id)}
                  className={'az-data-table__data-cell w-2/12'}>
                  <ButtonText
                    label={t('MedicalCentersDetail.deleteCenter')}
                    className="underline font-bold"
                    onClick={e => onDelete(medicalCenter.id, e)}
                  />
                </DataTableData>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={
          totalMedicalCenters ? Math.ceil(totalMedicalCenters / itemPerPage) : 0
        }
        setPage={setPage}
      />
    </div>
  );
};

export default React.memo(DataTableMedicalCenters);
