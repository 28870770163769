import React, {useMemo} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import {Prompt} from 'react-router-dom';

// Others
import {useNavigation} from '../../../navigation';
import {useAuthentication} from '../../../store/authentication';
import {ModalContext} from '../../../store/modal/context';

// Types
import {RiskLevel} from '../../../utils/riskLevelEnum';
import {RiskColor} from '../../../utils/riskColorEnum';
import {ModalContent} from '../../../store/modal/interfaces';

// Components
import {AuthBase} from '../../templates/AuthBase';
import {Button} from '../../atoms/Button';
import ResultBox from '../../molecules/ResultBox/ResultBox';

// Style
import './ResultQuestionnaire.scss';
import {useQuestionnaireResult} from '../../../store/questionnaireResult';

const ResultQuestionnaire = () => {
  //Helpers
  const {t} = useTranslations();
  const {isLoggedIn} = useAuthentication();
  const {goToQuestionnaire} = useNavigation();
  const {questionnaireData, cleanQuestionnaireResult} =
    useQuestionnaireResult();
  const {setValue: setModal} = React.useContext(ModalContext);

  // Prevent user to refresh page without data
  if (!questionnaireData) {
    goToQuestionnaire();
  }

  const goToQuestionnairePage = () => {
    goToQuestionnaire();
    cleanQuestionnaireResult();
  };

  const bookAnAppointment = React.useCallback(() => {
    setModal({content: ModalContent.BOOK_AN_APPOINTMENT});
  }, [setModal]);

  const isPositiveResult = useMemo(
    () =>
      questionnaireData?.questionnaireResult?.result.toLowerCase() ===
      'positive',
    [questionnaireData],
  );

  return (
    <>
      <AuthBase isAuth={isLoggedIn} pageHeading={t('NewQuestionnaire.title')}>
        <div className="az-result-questionnaire">
          <h2 className="az-result-questionnaire__title">
            {t('ResultQuestionnaire.title')}
          </h2>
          <h3 className="az-result-questionnaire__result">
            {t('ResultQuestionnaire.resultIs')}
            <span className="az-result-questionnaire__result--primary">
              {t(
                `ResultQuestionnaire.result.${questionnaireData?.questionnaireResult?.result}`,
              )}
              <br />
              {questionnaireData?.questionnaireResult?.result.toLowerCase() ===
                'positive' && t(`ResultQuestionnaire.result.STRONG_POS`)}
            </span>
          </h3>

          <p className="w-full">{t('ResultQuestionnaire.bottomDisclaimer')}</p>

          {/*           <div className="az-result-questionnaire__result-box">
            <ResultBox
              score={
                questionnaireData?.questionnaireResult?.resultDetail
                  ?.highRiskAnswers
              }
              totalScore={
                questionnaireData?.questionnaireResult?.resultDetail
                  ?.totalAnswers
              }
              riskLevel={RiskLevel.HIGH_RISK}
              riskColor={RiskColor.HIGH_RISK}
            />
            <ResultBox
              score={
                questionnaireData?.questionnaireResult?.resultDetail
                  ?.mediumHighRiskAnswers
              }
              totalScore={
                questionnaireData?.questionnaireResult?.resultDetail
                  ?.totalAnswers
              }
              riskLevel={RiskLevel.MEDIUM_HIGH_RISK}
              riskColor={RiskColor.MEDIUM_HIGH_RISK}
            />
            <ResultBox
              score={
                questionnaireData?.questionnaireResult?.resultDetail
                  ?.mediumLowRiskAnswers
              }
              totalScore={
                questionnaireData?.questionnaireResult?.resultDetail
                  ?.totalAnswers
              }
              riskLevel={RiskLevel.MEDIUM_LOW_RISK}
              riskColor={RiskColor.MEDIUM_LOW_RISK}
            />
            <ResultBox
              score={
                questionnaireData?.questionnaireResult?.resultDetail
                  ?.lowRiskAnswers
              }
              totalScore={
                questionnaireData?.questionnaireResult?.resultDetail
                  ?.totalAnswers
              }
              riskLevel={RiskLevel.LOW_RISK}
              riskColor={RiskColor.LOW_RISK}
            />
          </div> */}

          <div className="flex items-center justify-center w-full mb-8 pt-6">
            <div className="flex flex-col md:flex-row justify-content items-center  ">
              <Button
                label={t('ResultQuestionnaire.button')}
                onClick={goToQuestionnairePage}
                className=" max-w-max mb-4 md:mb-0 md:mr-6"
              />

              {/*isPositiveResult && (
                <Button
                  label={t('ResultQuestionnaire.bookAnAppointment')}
                  onClick={bookAnAppointment}
                  className="max-w-max"
                />
              )*/}
            </div>
          </div>

          {isPositiveResult && (
            <p className="w-full mb-28">
              {t('ResultQuestionnaire.evaluationDisclaimer')}
            </p>
          )}
        </div>
      </AuthBase>
    </>
  );
};

export default React.memo(ResultQuestionnaire);
