import {gql} from '@apollo/client';

export const GET_RESOURCES = gql`
  query GetResources($input: ResourceFilter, $limit: Int, $offset: Int) {
    resources(input: $input, limit: $limit, offset: $offset) {
      items {
        id
        title
        description
        imageUrl
        url
        pathologies {
          id
          name
          isDeleted
        }
        isPublic
      }
      info {
        total
      }
    }
  }
`;

export const GET_CONTENT = gql`
  query GetResource($id: Int) {
    resource(id: $id) {
      id
      title
      description
      url
      imageUrl
      pathologies {
        id
        name
        isDeleted
      }
      isPublic
    }
  }
`;

export const CREATE_CONTENT = gql`
  mutation CreateResource($input: ResourceCreateInput!) {
    createResource(input: $input) {
      id
    }
  }
`;

export const UPDATE_CONTENT = gql`
  mutation UpdateResource($input: ResourceUpdateInput!) {
    updateResource(input: $input) {
      id
    }
  }
`;

export const DELETE_CONTENT = gql`
  mutation DeleteResource($id: Int!) {
    deleteResource(id: $id)
  }
`;
