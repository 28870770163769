import React, {useCallback} from 'react';
import classNames from 'classnames';

// Components
import {InputLabel} from '../../atoms/InputLabel';

// Interface
import {IProps} from './interface';

// Style
import './LabeledTextarea.scss';

const LabeledTextarea = ({
  label,
  error = false,
  nRows = 2,
  placeholder = '',
  onChange,
  disabled = false,
  value = '',
  className,
  labelClassName,
  ...props
}: IProps): JSX.Element => {
  const handleChange = useCallback(
    e => {
      if (onChange) {
        onChange!(e.target.value);
      }
    },
    [onChange],
  );

  return (
    <div className={classNames('az-textarea', className)}>
      <InputLabel
        label={label}
        error={error}
        color={'dark'}
        className={labelClassName}
      />
      <div
        className={classNames('az-textarea__wrapper', {
          'az-textarea__wrapper--error': error && !disabled,
          'az-textarea__wrapper--disabled': disabled,
        })}>
        <textarea
          className={classNames('az-textarea__input')}
          disabled={disabled}
          name="body"
          onChange={handleChange}
          placeholder={placeholder}
          rows={nRows}
          value={value}
          {...props}
        />
      </div>
    </div>
  );
};

export default React.memo(LabeledTextarea);
