/**
 * @description Returns an utility proper formatted BEM class
 */
export const useBEMClass: () => (
  block: string,
  element: string,
  modifier?: string,
) => string =
  () =>
  (block, element, modifier = '') => {
    const sanitizedBlock = block?.trim();
    const sanitizedElement = element?.trim();
    const sanitizedModifier = modifier?.trim();
    const classTokens: string[] = [];

    if (!sanitizedBlock.length) {
      return '';
    }

    if (sanitizedModifier.length) {
      classTokens.push(sanitizedBlock);
    }

    if (sanitizedElement.length) {
      classTokens.push(`__${sanitizedElement}`);
    }

    if (sanitizedModifier.length) {
      classTokens.push(`--${sanitizedModifier}`);
    }

    return classTokens.join('');
  };
