import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import {useMutation} from '@apollo/client';

import {
  confirmPasswordConstraint,
  passwordConstraint,
  useValidator,
} from '../../../hooks/validator';

// Queries
import {useAuthentication} from '../../../store/authentication';

//Contexts
import {AlertContext} from '../../../store/alert/context';
import {UPDATE_ADMIN} from '../../../store/admin/queries';

//Templates
import Admin from '../../templates/Admin/Admin';

// Components
import {LabeledInput} from '../../molecules/LabeledInput';

// Types
import {InputType} from '../../molecules/LabeledInput/enum';
import {IProps as ButtonProp} from '../../atoms/Button/interfaces';

// Style
import './AdminSetNewPassword.scss';
import {noop} from '../../../utils/noop';
import {useBreadCrumb} from '../../../store/breadCrumb';
import {useNavigation} from '../../../navigation';

const AdminSetNewPassword = (): JSX.Element => {
  //Helpers
  const {setValue: setAlert} = useContext(AlertContext);
  const {t} = useTranslations();
  const {goToAdminProfile} = useNavigation();
  const [updateAdmin /*, {error: updateError}*/] = useMutation(UPDATE_ADMIN);
  const {validateField, validateForm} = useValidator();
  const {updateBreadCrumbItems} = useBreadCrumb();
  const {id} = useAuthentication();

  //Internal state
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [hasPasswordError, setHasPasswordError] = useState<boolean>(false);
  const [hasConfirmPasswordError, setHasConfirmPasswordError] =
    useState<boolean>(false);

  // Return true if password input is valid
  const isPasswordValid = useMemo(
    () => validateField(password, passwordConstraint),
    [password, validateField],
  );

  // Return true if confirm password input is valid
  const isConfirmPasswordValid = useMemo(
    () =>
      validateForm(
        {password, confirmPassword},
        {confirmPassword: confirmPasswordConstraint},
      ),
    [validateForm, password, confirmPassword],
  );

  const onSubmit = useCallback(() => {
    updateAdmin({
      variables: {
        input: {
          id,
          password,
          confirmPassword,
        },
      },
    })
      .then((adminResponse: any) => {
        if (adminResponse?.data?.updateAdmin?.id) {
          setAlert({
            type: 'SUCCESS',
            message: t('Alert.info.resetPassword'),
          });
          window.history.back();
        }
      })
      .catch(e => {
        console.log(e);
        setAlert({
          type: 'ERROR',
          message: t('Alert.error.resetPassword'),
        });
      });
  }, [updateAdmin, id, password, confirmPassword, setAlert, t]);

  // Save button
  const cta: ButtonProp = {
    label: t('ResetPassword.button'),
    disabled:
      hasPasswordError ||
      hasConfirmPasswordError ||
      !isConfirmPasswordValid ||
      password.length < 1 ||
      confirmPassword.length < 1,
    onClick: onSubmit,
  };

  // Add breadcrumb
  useEffect(() => {
    updateBreadCrumbItems([
      {
        label: t('Sidebar.label.profile'),
        onClick: () => goToAdminProfile(id!),
      },
      {
        label: t('EditPassword.title'),
        onClick: noop,
      },
    ]);
  }, [updateBreadCrumbItems, t, goToAdminProfile, id]);

  return (
    <Admin title={t('EditPassword.title')} breadcrumb ctaPrimary={cta}>
      <div className="az-admin-edit-password">
        <div className="az-login__content">
          <div className="az-admin-edit-password__container">
            <div className="az-signup__inputs-wrapper az-admin-edit-password__inputs-wrapper">
              <div className="az-signup__input az-admin-edit-password__input">
                <LabeledInput
                  type={InputType.PASSWORD}
                  label={t('ResetPassword.password')}
                  placeholder={t('ResetPassword.password')}
                  onChange={setPassword}
                  error={hasPasswordError}
                  onFocus={() => setHasPasswordError(false)}
                  onBlur={() => setHasPasswordError(!isPasswordValid)}
                />
              </div>
              <div className="az-signup__input az-admin-edit-password__input">
                <LabeledInput
                  type={InputType.PASSWORD}
                  label={t('ResetPassword.confirmPassword')}
                  placeholder={t('ResetPassword.confirmPassword')}
                  error={hasConfirmPasswordError}
                  onChange={setConfirmPassword}
                  onFocus={() => setHasConfirmPasswordError(false)}
                  onBlur={() =>
                    setHasConfirmPasswordError(!isConfirmPasswordValid)
                  }
                />
              </div>
            </div>
            <p className="az-signup__hint-password">
              {t('SignUp.hintPassword')}
            </p>
          </div>
        </div>
      </div>
    </Admin>
  );
};

export default React.memo(AdminSetNewPassword);
