import React from 'react';
import {LoadingComponentProps} from 'react-loadable';
import {useTranslations} from '@vidiemme/react-i18n';

// react-loadable requires this specific loading component structure
const LoadableLoading = (props: LoadingComponentProps): JSX.Element => {
  const {t} = useTranslations();
  if (props.error) {
    return (
      <div>
        {t('loadable_loading.error', 'Error')}{' '}
        <button onClick={props.retry}>
          {t('loadable_loading.retry', 'Retry')}
        </button>
      </div>
    );
  } else if (props.pastDelay) {
    return <div>{t('loadable_loading.loading', 'Loading')}</div>;
  } else {
    return <></>;
  }
};

export default React.memo(LoadableLoading);
