import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import {useMutation} from '@apollo/client';
import {useNavigation} from '../../../navigation';
import {useBreadCrumb} from '../../../store/breadCrumb';
import {emptyFieldConstraint, useValidator} from '../../../hooks/validator';
import {CREATE_PATHOLOGY, PathologyInput} from '../../../store/pathologies';

// Others
import {noop} from '../../../utils/noop';
import {AlertContext} from '../../../store/alert/context';

// Components
import {Admin} from '../../templates/Admin';
import {LabeledInput} from '../../molecules/LabeledInput';

// Types
import {IProps as ButtonProp} from '../../atoms/Button/interfaces';
// import {IProps as ButtonTextProp} from '../../atoms/ButtonText/interface';

// Style
import './AdminPathologyCreate.scss';

enum StateActionType {
  UPDATE_ERROR = 'UPDATE_ERROR',
}

type StateAction = {
  type: StateActionType.UPDATE_ERROR;

  payload: any;
};

// Reducer
const reducer = (state: any, action: StateAction) => {
  switch (action.type) {
    case StateActionType.UPDATE_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.input]: action.payload.value,
        },
      };
    default:
      throw new Error();
  }
};

const AdminPathologyCreate = () => {
  const {t} = useTranslations();
  const {goToAdminPathologies} = useNavigation();
  const {updateBreadCrumbItems} = useBreadCrumb();
  const {setValue: setAlert} = useContext(AlertContext);
  const {validateField} = useValidator();

  // State input
  const [name, setName] = useState<string>('');
  const isNameValid = useMemo(
    () => validateField(name, emptyFieldConstraint),
    [name, validateField],
  );

  // Add breadcrumb
  useEffect(() => {
    updateBreadCrumbItems([
      {
        label: t('AdminPathologies.heading'),
        onClick: goToAdminPathologies,
      },
      {
        label: t('AdminPathologies.newPathology'),
        onClick: noop,
      },
    ]);
  }, [updateBreadCrumbItems, t, goToAdminPathologies]);

  // Query for create pathology
  const [createPathology /*, {error: createError}*/] =
    useMutation(CREATE_PATHOLOGY);

  // State for manage errors
  const initialState = {
    errors: {
      name: '',
      surname: '',
      email: '',
    },
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const savePathology = useCallback(() => {
    let filter: PathologyInput = {
      name,
    };

    createPathology({variables: {input: filter}, errorPolicy: 'all'})
      .then(response => {
        if (response.errors) {
          setAlert({
            type: 'ERROR',
            message: t('AdminPathologyCreate.alertCreate.error'),
          });
        } else {
          setAlert({
            type: 'SUCCESS',
            message: t('AdminPathologyCreate.alertCreate.success'),
          });
          goToAdminPathologies();
        }
      })
      .catch(() => {
        setAlert({
          type: 'ERROR',
          message: t('AdminPathologyCreate.alertCreate.error'),
        });
      });
  }, [createPathology, goToAdminPathologies, name, setAlert, t]);

  // Save button
  const cta: ButtonProp = {
    label: t('AdminQuestionnaireDetail.headerButton.save'),
    disabled: !!Object.values(state.errors).find(value => value) || !name,
    onClick: savePathology,
  };

  // const cancel: ButtonTextProp = {
  //   label: t('AdminQuestionnaireDetail.headerButton.cancel'),
  //   onClick: () =>
  //     setAlert({
  //       type: 'INFO',
  //       message: t('AdminQuestionnaireDetail.alert.cancel.message'),
  //       activeButtonLabel: t(
  //         'AdminQuestionnaireDetail.alert.cancel.activeButtonLabel',
  //       ),
  //       cancelButtonLabel: t(
  //         'AdminQuestionnaireDetail.alert.cancel.cancelButtonLabel',
  //       ),
  //       onClick: () => window.location.reload(),
  //     }),
  // };

  return (
    <Admin
      title={t('AdminPathologies.newPathology')}
      breadcrumb
      ctaPrimary={cta}>
      <LabeledInput
        label={t('AdminPathologyCreate.label')}
        placeholder={t('AdminPathologyCreate.placeholder')}
        value={name}
        onChange={setName}
        className="w-3/12"
        maxLength={40}
        error={state.errors.name}
        onBlur={() => {
          dispatch({
            type: StateActionType.UPDATE_ERROR,
            payload: {input: 'name', value: !isNameValid},
          });
        }}
      />
    </Admin>
  );
};

export default React.memo(AdminPathologyCreate);
