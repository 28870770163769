import {useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {
  AdminRoutes,
  AnonymousRoutes,
  AuthenticatedRoutes,
  MMGRoutes,
  // @REMOVE_PATIENTS_DATA
  // PatientRoutes,
} from './routes';

export const useNavigation = () => {
  const history = useHistory();
  const {pathname} = useLocation();

  const isMatchRoute = useCallback(
    (cmpRoute: string) => pathname === cmpRoute,
    [pathname],
  );

  const goToPath = useCallback(
    (path: MMGRoutes | AnonymousRoutes | string) => {
      history.push(path);
    },
    [history],
  );

  const goToMain = useCallback(() => {
    history.push(AuthenticatedRoutes.MAIN);
  }, [history]);

  const goToSignUp = useCallback(() => {
    history.push(AnonymousRoutes.SIGNUP);
  }, [history]);

  const goToForgotPassword = useCallback(() => {
    history.push(AnonymousRoutes.FORGOT_PASSWORD);
  }, [history]);

  const goToResetPassword = useCallback(() => {
    history.push(AnonymousRoutes.RESET_PASSWORD);
  }, [history]);

  const goToAdminSurveys = useCallback(() => {
    goToPath(AdminRoutes.QUESTIONNAIRES);
  }, [goToPath]);

  // const goToAdminMMG = useCallback(() => {
  //   goToPath(AdminRoutes.MMG);
  // }, [goToPath]);

  const goToAdminSurveyDetail = useCallback(
    (surveyId?: number) => {
      goToPath(
        AdminRoutes.QUESTIONNAIRE_DETAIL_UPDATE.replace(
          ':id',
          `${surveyId ?? ''}`,
        ),
      );
    },
    [goToPath],
  );

  const goToUserLogin = useCallback(
    () => goToPath(AnonymousRoutes.LOGIN),
    [goToPath],
  );

  const goToHealthProfessionalLogin = useCallback(
    () => goToPath(AnonymousRoutes.LOGIN_MMG),
    [goToPath],
  );

  const goToQuestionnaire = useCallback(
    () => goToPath(MMGRoutes.QUESTIONNAIRE),
    [goToPath],
  );

  const goToAdminLogin = useCallback(() => {
    goToPath(AnonymousRoutes.ADMIN_LOGIN);
  }, [goToPath]);

  const goToNewQuestionnaire = useCallback(
    (questionnaireId: number) => {
      goToPath(
        MMGRoutes.NEW_QUESTIONNAIRE.replace(':id', `${questionnaireId}`),
      );
    },
    [goToPath],
  );

  const goToActiveQuestionnaire = useCallback(
    (questionnaireId: number) => {
      goToPath(
        MMGRoutes.ACTIVE_QUESTIONNAIRE.replace(':id', `${questionnaireId}`),
      );
    },
    [goToPath],
  );

  const goToResultQuestionnaire = useCallback(
    (pathologyId: number) => {
      goToPath(
        MMGRoutes.RESULT_QUESTIONNAIRE.replace(
          ':pathologyId',
          `${pathologyId}`,
        ),
      );
    },
    [goToPath],
  );

  const goToTermsAndConditions = useCallback(
    () => goToPath(AnonymousRoutes.TERMS_AND_CONDITIONS),
    [goToPath],
  );

  const goToMedicalCenters = useCallback(
    () => goToPath(AdminRoutes.MEDICAL_CENTERS),
    [goToPath],
  );

  const goToMedicalCentersDetail = useCallback(
    () => goToPath(AdminRoutes.MEDICAL_CENTERS_DETAIL),
    [goToPath],
  );

  const goToAdminPathologies = useCallback(
    () => goToPath(AdminRoutes.PATHOLOGIES),
    [goToPath],
  );

  const goToAdminPathologyCreate = useCallback(
    () => goToPath(AdminRoutes.CREATE_PATHOLOGY),
    [goToPath],
  );

  const goToAdminContents = useCallback(
    () => goToPath(AdminRoutes.CONTENTS),
    [goToPath],
  );

  const goToAdminContentCreate = useCallback(
    () => goToPath(AdminRoutes.CREATE_CONTENTS),
    [goToPath],
  );

  const goToAdminContentUpdate = useCallback(
    (contentId: number) =>
      goToPath(AdminRoutes.UPDATE_CONTENTS.replace(':id', `${contentId}`)),
    [goToPath],
  );

  const goToAdminManage = useCallback(
    () => goToPath(AdminRoutes.MANAGE_ADMIN),
    [goToPath],
  );

  const goToAdminManageDetail = useCallback(
    () => goToPath(AdminRoutes.CREATE_ADMIN),
    [goToPath],
  );

  const goToAdminManageUpdate = useCallback(
    (adminId: number) =>
      goToPath(AdminRoutes.MANAGE_ADMIN_DETAIL.replace(':id', `${adminId}`)),
    [goToPath],
  );

  const goToCentersMap = useCallback(
    () => goToPath(MMGRoutes.CENTERS_MAP),
    [goToPath],
  );

  const goToResources = useCallback(
    () => goToPath(MMGRoutes.RESOURCES),
    [goToPath],
  );

  // @REMOVE_PATIENTS_DATA
  // const goToPatientProfile = useCallback(
  //   () => goToPath(PatientRoutes.PATIENT_PROFILE),
  //   [goToPath],
  // );

  const goToMMGProfile = useCallback(
    () => goToPath(MMGRoutes.MMG_PROFILE),
    [goToPath],
  );

  // @REMOVE_PATIENTS_DATA
  // const goToPatientEditPassword = useCallback(
  //   () => goToPath(PatientRoutes.PATIENT_EDIT_PASSWORD),
  //   [goToPath],
  // );

  const goToMedicalCenterUpdate = useCallback(
    (medicalCenterId: number) =>
      goToPath(
        AdminRoutes.MEDICAL_CENTERS_DETAIL_UPDATE.replace(
          ':id',
          `${medicalCenterId}`,
        ),
      ),
    [goToPath],
  );

  const goToAdminProfile = useCallback(
    (adminId: number) =>
      goToPath(AdminRoutes.PROFILE.replace(':id', `${adminId}`)),
    [goToPath],
  );

  const goToAdminSetNewPassword = useCallback(
    () => goToPath(AdminRoutes.ADMIN_EDIT_PASSWORD),
    [goToPath],
  );

  return {
    isMatchRoute,
    goToPath,
    goToMain,
    goToSignUp,
    goToAdminSurveys,
    // goToAdminMMG,
    goToAdminSurveyDetail,
    goToForgotPassword,
    goToResetPassword,
    goToUserLogin,
    goToHealthProfessionalLogin,
    goToQuestionnaire,
    goToActiveQuestionnaire,
    goToAdminLogin,
    goToNewQuestionnaire,
    goToResultQuestionnaire,
    goToTermsAndConditions,
    goToMedicalCenters,
    goToAdminPathologies,
    goToAdminPathologyCreate,
    goToAdminContents,
    goToAdminContentCreate,
    goToAdminContentUpdate,
    goToAdminManage,
    goToAdminManageDetail,
    goToAdminManageUpdate,
    goToMedicalCentersDetail,
    goToCentersMap,
    goToResources,
    goToMedicalCenterUpdate,
    goToAdminProfile,
    goToAdminSetNewPassword,
    goToMMGProfile,

    // @REMOVE_PATIENTS_DATA
    // goToPatients,
    // goToPatient,
    // goToDraftQuestionnaire,
    // goToReviewQuestionnaire,
    // goToAdminKpi,
    // goToPatientProfile,
    // goToPatientEditPassword,
  };
};
