import {gql} from '@apollo/client';

export const GET_QUESTIONNAIRES = gql`
  query GetQuestionnaires(
    $input: QuestionnaireFilter
    $limit: Int
    $offset: Int
  ) {
    questionnaires(input: $input, limit: $limit, offset: $offset) {
      info {
        total
      }
      items {
        id
        createdAt
        pathology {
          id
          name
          isDeleted
        }
        status
        result
      }
    }
  }
`;

export const GET_QUESTIONNAIRE = gql`
  query GetQuestionnaire($id: Int!) {
    questionnaire(id: $id) {
      id
      pathology {
        id
        name
      }
      subTitle
      instructions
      limitScore
      limitScore2
      score
      status
      result
      resultDetail {
        totalAnswers
        highRiskAnswers
        mediumHighRiskAnswers
        mediumLowRiskAnswers
        lowRiskAnswers
      }
      createdAt
      questions {
        id
        start
        title
        description
        reference
        order
        typeQuestion
        previousQuestionId
        answers {
          id
          title
          description
          reference
          score
          nextQuestionId
          choice
          text
          riskLevel
        }
      }
    }
  }
`;

export const GET_STRUCTURE_QUESTIONNAIRE_PUBLISHED = gql`
  query GetStructureQuestionnairePublished($pathologyId: Int!) {
    structureQuestionnairePublished(pathologyId: $pathologyId) {
      subTitle
      instructions
      limitScore
      limitScore2
      status
      questions {
        id
        start
        title
        description
        reference
        order
        typeQuestion
        previousQuestionId
        answers {
          id
          nextQuestionId
          title
          description
          reference
          score
          choice
          text
          riskLevel
        }
      }
      pathology {
        id
        name
      }
      createdAt
    }
  }
`;

export const POST_SAVE_QUESTIONNAIRE = gql`
  mutation PostSaveQuestionnaire($input: QuestionnaireInput!) {
    saveQuestionnaire(input: $input) {
      id
    }
  }
`;

export const DELETE_QUESTIONNAIRE = gql`
  mutation DeleteQuestionnaire($id: Int!) {
    deleteQuestionnaire(id: $id)
  }
`;

export const QUESTIONNAIRE_RESULT = gql`
  query questionnaireResult($input: QuestionnaireInput!) {
    questionnaireResult(input: $input) {
      pathology {
        id
        name
      }
      subTitle
      instructions
      limitScore
      limitScore2
      score
      status
      result
      resultDetail {
        totalAnswers
        highRiskAnswers
        mediumHighRiskAnswers
        mediumLowRiskAnswers
        lowRiskAnswers
      }
      questions {
        id
        start
        title
        description
        reference
        order
        typeQuestion
        previousQuestionId
        answers {
          id
          title
          description
          reference
          score
          nextQuestionId
          choice
          text
          riskLevel
        }
      }
    }
  }
`;
