import React, {useCallback, useMemo} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';

// Types
import {RiskLevel} from '../../../utils/riskLevelEnum';

//Component namespace
import {IProps} from './interfaces';
import './ResultBox.scss';

const ResultBox = ({
  score,
  totalScore,
  riskLevel,
  riskColor,
  isKpi = false,
  descriptionKpi,
}: IProps) => {
  const {t} = useTranslations();

  const description = useMemo(
    () => (isKpi ? descriptionKpi : t('SurveyResult.risk')),
    [descriptionKpi, isKpi, t],
  );
  const resultColoredString = useCallback(() => {
    if (isKpi) {
      switch (riskLevel) {
        case RiskLevel.HIGH_RISK:
          return t('KPI.highRisk');
        case RiskLevel.MEDIUM_HIGH_RISK:
          return t('KPI.mediumHighRisk');
        case RiskLevel.MEDIUM_LOW_RISK:
        case RiskLevel.LOW_RISK:
          return t('KPI.lowRisk');
      }
    } else {
      return t(`SurveyResult.riskLevel.${riskLevel}`);
    }
  }, [isKpi, riskLevel, t]);

  return (
    <div className="az-result-box">
      <div className="az-result-box__score">
        <span
          className={`az-result-box__score-highlight az-result-box__score-highlight--${riskColor}`}>
          {score}
        </span>
        {` / `}
        {totalScore}
      </div>
      <div className="az-result-box__description">
        {description}{' '}
        <span
          className={`az-result-box__description-highlight az-result-box__description-highlight--${riskColor}`}>
          {resultColoredString()}
        </span>
      </div>
    </div>
  );
};

export default React.memo(ResultBox);
