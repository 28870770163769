import React, {useCallback, useMemo, useState} from 'react';

// Context
import {ModalContext} from './context';

// Interface
import {IModalContext, IModalState, IProps} from './interfaces';

/**
 * Provides an AlertState as Context for children
 */
const ModalProvider = ({defaultValue, children}: IProps): JSX.Element => {
  const [modal, setModal] = useState<IModalState | null>(defaultValue);
  const updateModal = useCallback(
    (value: IModalState | null) => setModal(value),
    [],
  );
  const providedAlertContext = useMemo<IModalContext>(
    () => ({value: modal, setValue: updateModal}),
    [modal, updateModal],
  );

  return (
    <ModalContext.Provider value={providedAlertContext}>
      {children}
    </ModalContext.Provider>
  );
};

export default React.memo(ModalProvider);
