import {PageInfoResponse} from '../api';

export interface OpeningHours {
  day: string;
  open: boolean;
  fromHour: string;
  toHour: string;
  fromHour2: string;
  toHour2: string;
}

export enum MedicalCenterType {
  RISP = 'RISP',
  GENERIC = 'GENERIC',
}

export enum MedicalCenterUploadErrorType {
  NOT_EMPTY = 'NotEmpty',
  NOT_FOUND = 'NotFound',
  INVALID = 'Invalid',
  ALREDY_USED = 'AlreadyUsed',
}

export interface MedicalCenterInput {
  id?: number;
  name: string;
  type?: MedicalCenterType;
  companyName: string;
  description: string;
  street: string;
  streetNumber: string;
  city: string;
  province: string;
  postalCode: string;
  region: string;
  latitude: number;
  longitude: number;
  phone: string;
  email: string;
  openingHours: OpeningHours[];
  pathologiesId: number[];
}

export interface MedicalCenterResponse {
  id: number;
  name: string;
  type: MedicalCenterType;
  companyName: string;
  description: string;
  street: string;
  streetNumber: string;
  city: string;
  province: string;
  postalCode: string;
  region: string;
  latitude: number;
  longitude: number;
  phone: string;
  email: string;
  openingHours: OpeningHours[];
  pathologiesId: number[];
}

export interface MedicalCenterFilter {
  pathologyId: number;
  latitude: number;
  longitude: number;
}

export interface MedicalCenterPageResponse {
  patients: {
    items: MedicalCenterResponse[];
    info: PageInfoResponse;
  };
}
