import React from 'react';

//Others
import {useBreadCrumb} from '../../../store/breadCrumb';

//Other components
import {ButtonText} from '../../atoms/ButtonText';

//Component namespace
import {IProps} from './interfaces';
import './BreadCrumb.scss';

const BreadCrumb = (_props: IProps) => {
  const {breadCrumbItems} = useBreadCrumb();

  return breadCrumbItems.length ? (
    <div className="az-bread-crumb">
      {breadCrumbItems.map((breadCrumbItem, idx) => (
        <React.Fragment key={`bread-crumb-item-${idx}`}>
          <ButtonText
            label={breadCrumbItem.label}
            onClick={breadCrumbItem.onClick}
            className="az-bread-crumb__item"
          />
          {idx < breadCrumbItems.length - 1 && (
            <div className="az-bread-crumb__item-separator">&gt;</div>
          )}
        </React.Fragment>
      ))}
    </div>
  ) : null;
};

export default React.memo(BreadCrumb);
