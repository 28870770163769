import classNames from 'classnames';
import React from 'react';

// Interface
import {IProps} from './interface';

// Style
import './Pagination.scss';

const Pagination = ({
  currentPage,
  totalPages,
  setPage,
}: IProps): JSX.Element => {
  // render a button for every page passed as prop
  const renderPageNumbers = () => {
    const elements = [];
    if (totalPages <= 3) {
      for (let page = 1; page <= totalPages; page++) {
        elements.push(
          <button
            className={classNames('az-pagination__page-button', {
              'az-pagination__page-button--primary': page === currentPage,
            })}
            key={page}
            onClick={() => setPage(page)}>
            {page}
          </button>,
        );
      }
    } else {
      if (currentPage < 3) {
        for (let page = 1; page <= 3; page++) {
          elements.push(
            <button
              className={classNames('az-pagination__page-button', {
                'az-pagination__page-button--primary': page === currentPage,
              })}
              key={page}
              onClick={() => setPage(page)}>
              {page}
            </button>,
          );
        }
        elements.push(
          <button
            className="az-pagination__page-button"
            key={`dots${currentPage}`}
            onClick={() => {}}>
            . . .
          </button>,
        );
        elements.push(
          <button
            className={classNames('az-pagination__page-button', {
              'az-pagination__page-button--primary': totalPages === currentPage,
            })}
            key={totalPages}
            onClick={() => setPage(totalPages)}>
            {totalPages}
          </button>,
        );
      } else {
        if (currentPage - 3 <= 1) {
          for (let i = 1; i <= currentPage; i++) {
            elements.push(
              <button
                className={classNames('az-pagination__page-button', {
                  'az-pagination__page-button--primary': i === currentPage,
                })}
                key={i}
                onClick={() => setPage(i)}>
                {i}
              </button>,
            );
          }
        } else {
          elements.push(
            <button
              className={classNames('az-pagination__page-button', {
                'az-pagination__page-button--primary': 1 === currentPage,
              })}
              key={1}
              onClick={() => setPage(1)}>
              1
            </button>,
          );
          elements.push(
            <button
              className="az-pagination__page-button"
              key={`dots${currentPage}`}
              onClick={() => {}}>
              . . .
            </button>,
          );
          if (totalPages - currentPage >= 3) {
            for (let j = currentPage - 2; j <= currentPage; j++) {
              elements.push(
                <button
                  className={classNames('az-pagination__page-button', {
                    'az-pagination__page-button--primary': j === currentPage,
                  })}
                  key={j}
                  onClick={() => setPage(j)}>
                  {j}
                </button>,
              );
            }
          } else {
            for (
              let j = currentPage - (2 - (totalPages - currentPage));
              j <= currentPage;
              j++
            ) {
              elements.push(
                <button
                  className={classNames('az-pagination__page-button', {
                    'az-pagination__page-button--primary': j === currentPage,
                  })}
                  key={j}
                  onClick={() => setPage(j)}>
                  {j}
                </button>,
              );
            }
          }
        }
        if (currentPage + 3 >= totalPages) {
          for (let m = currentPage + 1; m <= totalPages; m++) {
            elements.push(
              <button
                className={classNames('az-pagination__page-button', {
                  'az-pagination__page-button--primary': m === currentPage,
                })}
                key={m}
                onClick={() => setPage(m)}>
                {m}
              </button>,
            );
          }
        } else {
          for (let n = currentPage + 1; n <= currentPage + 2; n++) {
            elements.push(
              <button
                className={classNames('az-pagination__page-button', {
                  'az-pagination__page-button--primary': n === currentPage,
                })}
                key={n}
                onClick={() => setPage(n)}>
                {n}
              </button>,
            );
          }
          elements.push(
            <button
              className="az-pagination__page-button"
              key={`dots${totalPages}`}
              onClick={() => {}}>
              . . .
            </button>,
          );
          elements.push(
            <button
              className={classNames('az-pagination__page-button', {
                'az-pagination__page-button--primary':
                  totalPages === currentPage,
              })}
              key={totalPages}
              onClick={() => setPage(totalPages)}>
              {totalPages}
            </button>,
          );
        }
      }
    }
    return elements;
  };

  return (
    <div>
      <div className="az-pagination">{renderPageNumbers()}</div>
    </div>
  );
};

export default React.memo(Pagination);
