import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as Sentry from '@sentry/react';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';

import {
  selectorError,
  selectorIsFetching,
  selectorIsLoggedIn,
  selectorRole,
  selectorToken,
  selectorId,
  selectorOwaId,
} from './selectors';
import {AuthenticationState} from './types.d';
import {LoginSuccess, Logout} from './actions';
import {
  ForgotPasswordData,
  LoginAdminResponse,
  LoginCredentials,
  LoginDoctorResponse,
  LoginResponse,
  ResendRegistrationMailResponse,
  ResetPasswordData,
  UserByToken,
  UserTokenQuery,
} from './interfaces';
import {
  GET_LOGIN,
  GET_LOGIN_ADMIN,
  GET_USER_BY_TOKEN,
  POST_CONFIRM_REGISTRATION,
  POST_FORGOT_PASSWORD,
  POST_RESET_PASSWORD,
  RESEND_REGISTRATION_EMAIL,
  GET_LOGIN_DOCTOR,
} from './queries';
import {AZStorage, STORAGE_KEY} from '../../utils/storage';
import {Role, UserResponse} from '../api';

export const useAuthentication = () => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector<
    {authentication: AuthenticationState},
    boolean
  >(selectorIsLoggedIn);

  const token = useSelector<
    {authentication: AuthenticationState},
    string | undefined
  >(selectorToken);

  const owaId = useSelector<
    {authentication: AuthenticationState},
    string | undefined
  >(selectorOwaId);

  const error = useSelector<
    {authentication: AuthenticationState},
    string | undefined
  >(selectorError);

  const isFetching = useSelector<
    {authentication: AuthenticationState},
    boolean
  >(selectorIsFetching);

  const role = useSelector<
    {authentication: AuthenticationState},
    Role | undefined
  >(selectorRole);

  const id = useSelector<
    {authentication: AuthenticationState},
    number | undefined
  >(selectorId);

  const restoreAuth = useCallback(() => {
    try {
      const token = AZStorage.getItem(STORAGE_KEY.TOKEN);
      const role = AZStorage.getItem(STORAGE_KEY.ROLE) as Role;
      const id = AZStorage.getItem(STORAGE_KEY.ID);

      if (token !== null && token !== undefined && id !== undefined) {
        dispatch(LoginSuccess({token, role, id: Number(id)}));
      }
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  }, [dispatch]);

  const saveAuth = useCallback(
    (
      token: string,
      role: Role,
      id: string,
      firstName?: string,
      lastName?: string,
      owaId?: string,
      email?: string,
    ) => {
      try {
        if (token) {
          AZStorage.setItem(STORAGE_KEY.TOKEN, token);
        } else {
          AZStorage.setItem(STORAGE_KEY.TOKEN, 'no-token');
        }
        AZStorage.setItem(STORAGE_KEY.ROLE, role);
        AZStorage.setItem(STORAGE_KEY.ID, id);
        if (firstName) {
          AZStorage.setItem(STORAGE_KEY.FIRST_NAME, firstName);
        }
        if (lastName) {
          AZStorage.setItem(STORAGE_KEY.LAST_NAME, lastName);
        }
        if (owaId) {
          AZStorage.setItem(STORAGE_KEY.OWA_ID, owaId);
        }
        if (email) {
          AZStorage.setItem(STORAGE_KEY.EMAIL, email);
        }
      } catch (e) {
        Sentry.captureException(e);
        throw e;
      }
    },
    [],
  );

  const removeAuth = useCallback(() => {
    try {
      AZStorage.removeItem(STORAGE_KEY.TOKEN);
      AZStorage.removeItem(STORAGE_KEY.ROLE);
      AZStorage.removeItem(STORAGE_KEY.ID);
      if (AZStorage.getItem(STORAGE_KEY.FIRST_NAME)) {
        AZStorage.removeItem(STORAGE_KEY.FIRST_NAME);
      }
      if (AZStorage.getItem(STORAGE_KEY.LAST_NAME)) {
        AZStorage.removeItem(STORAGE_KEY.LAST_NAME);
      }
      if (AZStorage.getItem(STORAGE_KEY.OWA_ID)) {
        AZStorage.removeItem(STORAGE_KEY.OWA_ID);
      }
      if (AZStorage.getItem(STORAGE_KEY.EMAIL)) {
        AZStorage.removeItem(STORAGE_KEY.EMAIL);
      }
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }, []);

  /**
   * @description store token both in storage and in app store.
   */
  const logIn = (token: string, role: Role, id: number) => {
    saveAuth(token, role, id.toString());
    dispatch(LoginSuccess({token, role, id}));
  };

  /**
   * @description remove token both from storage and from app store.
   */
  const logOut = () => {
    removeAuth();
    dispatch(Logout());
  };

  return {
    isLoggedIn,
    token,
    error,
    restoreAuth,
    saveAuth,
    isFetching,
    removeAuth,
    role,
    id,
    owaId,
    logIn,
    logOut,
  };
};

/**
 * @description Perform authentication for patients. Store data in storage and app store. Throws error on failure.
 *
 * @param credentials
 *
 * @throws error
 */
export const useLogin = (credentials: LoginCredentials) => {
  const dispatch = useDispatch();
  const {saveAuth} = useAuthentication();

  return useLazyQuery<LoginResponse>(GET_LOGIN, {
    fetchPolicy: 'no-cache',
    onCompleted: ({
      login: {
        token,
        user: {role, id},
      },
    }) => {
      saveAuth(token, role, id.toString());
      dispatch(LoginSuccess({token, role, id}));
    },
    variables: {
      ...credentials,
    },
  });
};

/**
 * @description Perform authentication for MMG. Store data in storage and app store. Throws error on failure.
 *
 * @param credentials
 *
 * @throws error
 */
export const useLoginDoctor = (credentials: LoginCredentials) => {
  const dispatch = useDispatch();
  const {saveAuth} = useAuthentication();

  return useLazyQuery<LoginDoctorResponse>(GET_LOGIN_DOCTOR, {
    fetchPolicy: 'no-cache',
    onCompleted: ({
      loginDoctor: {
        token,
        user: {role, id, owaId, email},
      },
    }) => {
      saveAuth(token, role, id.toString(), undefined, undefined, owaId, email);
      dispatch(LoginSuccess({token, role, id}));
      if (!token && id === -1) {
        window.location.href = '/complete-registration';
      }
    },
    variables: {
      ...credentials,
    },
  });
};

/**
 * @description Perform authentication for admin. Store data in storage and app store. Throws error on failure.
 *
 * @param credentials
 *
 * @throws error
 */
export const useLoginAdmin = (credentials: LoginCredentials) => {
  const dispatch = useDispatch();
  const {saveAuth} = useAuthentication();

  return useLazyQuery<LoginAdminResponse>(GET_LOGIN_ADMIN, {
    fetchPolicy: 'no-cache',
    onCompleted: ({
      loginAdmin: {
        token,
        user: {role, id, firstName, lastName},
      },
    }) => {
      saveAuth(token, role, id.toString(), firstName, lastName);
      dispatch(LoginSuccess({token, role, id, firstName, lastName}));
    },
    variables: {
      ...credentials,
    },
  });
};

export const useGetUserData = (filters: UserTokenQuery) =>
  useQuery<UserByToken>(GET_USER_BY_TOKEN, {
    variables: filters,
  });

export const useConfirmRegistration = () =>
  useMutation<UserResponse>(POST_CONFIRM_REGISTRATION);

export const useForgotPassword = () =>
  useMutation<ForgotPasswordData>(POST_FORGOT_PASSWORD);

export const useResetPassword = () =>
  useMutation<ResetPasswordData>(POST_RESET_PASSWORD);

export const useResendRegistrationEmail = () =>
  useMutation<ResendRegistrationMailResponse>(RESEND_REGISTRATION_EMAIL);
