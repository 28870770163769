import React, {useMemo} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';

// Others
import {useNavigation} from '../../../navigation';
import {useGetPathologies} from '../../../store/pathologies';

// Components
import {Admin} from '../../templates/Admin';
import {Button} from '../../atoms/Button';
import {Spinner} from '../../atoms/Spinner';
import {DataTablePathologies} from '../../organisms/DataTable/DataTablePathologies';

//Types
import {IProps as IconProps} from '../../atoms/IconSVG/interfaces';

// Style
import './AdminPathologies.scss';

const AdminPathologies = () => {
  const {t} = useTranslations();
  const {goToAdminPathologyCreate} = useNavigation();

  const {
    data: pathologiesData,
    loading,
    refetch,
  } = useGetPathologies({availableQuestionnairesOnly: false});

  const addIcon = useMemo<IconProps>(
    () => ({
      icon: 'icon-add',
      size: 23,
      className: 'text-white',
    }),
    [],
  );

  return (
    <Admin title={t('AdminPathologies.heading')}>
      <div className="az-admin-pathologies">
        <div className="flex justify-end">
          <div className="az-admin-pathologies__button-container">
            <Button
              label={t('AdminPathologies.newPathology')}
              rightIcon={addIcon}
              onClick={goToAdminPathologyCreate}
            />
          </div>
        </div>
        {loading && (
          <Spinner color="gray" className="az-admin-pathologies__spinner" />
        )}
        {pathologiesData?.pathologies.items && (
          <DataTablePathologies
            pathologiesData={pathologiesData?.pathologies?.items}
            totalPathologies={pathologiesData?.pathologies?.info.total}
            refetchData={refetch}
          />
        )}
      </div>
    </Admin>
  );
};

export default React.memo(AdminPathologies);
