// React api
import React, {useCallback, useContext, useState} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';

// Context
import {AlertContext} from '../../../store/alert/context';

// Others
import {noop} from '../../../utils/noop';
import {RiskLevel} from '../../../utils/riskLevelEnum';
import {RiskColor} from '../../../utils/riskColorEnum';

// Template
import {Base} from '../../templates/Base';

// Components
import {Button} from '../../atoms/Button';
import {LabeledInput} from '../../molecules/LabeledInput';
import {ButtonText} from '../../atoms/ButtonText';
import {CheckableInput} from '../../atoms/CheckableInput';
import {Link} from '../../atoms/Link';
import {QuestionnaireCard} from '../../molecules/QuestionnaireCard';
import {QuestionAccordion} from '../../organisms/QuestionAccordion';
import {RadioAnswer} from '../../molecules/RadioAnswer';
import ResultBox from '../../molecules/ResultBox/ResultBox';

// Types
import {IProps as IconProps} from '../../atoms/IconSVG/interfaces';
import {InputType} from '../../molecules/LabeledInput/enum';

// Style
import './Components.scss';
import {TypeQuestion} from '../../../store/structureQuestionnaire';

const Components = (): JSX.Element => {
  const {t} = useTranslations();
  const buttonIcon: IconProps = {
    icon: 'icon-add',
    size: 23,
    className: 'text-white',
  };

  // Checkbox state
  const [isCheckedPrivacy, setIsCheckedPrivacy] = useState<boolean>(false);
  const [isCheckedConditions, setIsCheckedConditions] =
    useState<boolean>(false);

  const onPrivacyCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsCheckedPrivacy(event.target.checked);
  };

  const onConditionCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsCheckedConditions(event.target.checked);
  };

  // State for Alerts
  const {setValue: setAlert} = useContext(AlertContext);

  // Callback for show the 3 typologies of Alert
  const showAlertSuccess = useCallback(
    () =>
      setAlert({
        type: 'SUCCESS',
        message: t('Alert.success.passwordDescription'),
      }),
    [t, setAlert],
  );

  const showAlertError = useCallback(
    () =>
      setAlert({
        type: 'ERROR',
        message: t('Alert.error.credentialsDescription'),
      }),
    [t, setAlert],
  );

  const showAlertInfo = useCallback(
    () =>
      setAlert({
        type: 'INFO',
        message: t('Alert.info.surveyDescription'),
        activeButtonLabel: t('Alert.info.activeSurveyButton'),
        cancelButtonLabel: t('Alert.info.cancelSurveyButton'),
      }),
    [t, setAlert],
  );

  const searchIcon: IconProps = {
    icon: 'icon-search',
    size: 21,
    className: 'text-primary self-center mb-1',
  };

  // Selected radio for answers of questionnaire
  const [selectedOption, setSelectedOption] = useState<string>();
  const onValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedOption(event.target.value);
    },
    [],
  );

  return (
    <Base isAuth>
      <div className="az-components">
        <h1 className="az-components__title">Components</h1>
        <h2 className="az-components__subtitle">Buttons</h2>
        <div className="az-components__button-wrapper">
          <div className="px-1 py-1">
            <Button width={140} label={t('Button.next')} />
          </div>
          <div className="px-1 py-1">
            <Button
              width={140}
              styleType="secondary"
              label={t('Button.cancel')}
            />
          </div>
          <div className="px-1 py-1">
            <Button
              width={180}
              label={t('Button.new')}
              rightIcon={buttonIcon}
            />
          </div>
          <div className="bg-primary px-1 py-1 w-60">
            <Button styleType="tertiary" label={t('Button.newSurvey')} />
          </div>
        </div>
        <h2 className="az-components__subtitle">Button Texts</h2>
        <div className="my-10">
          <ButtonText
            onClick={noop}
            label="Click me!"
            className="font-helvetica-az underline text-primary font-bold hover:opacity-70"
          />
        </div>
        <h2 className="az-components__subtitle">Text Inputs</h2>
        <div className="flex flex-col lg:flex-row lg:justify-between mb-10 mt-3">
          <div className="mt-5">
            <LabeledInput
              type={InputType.EMAIL}
              label={t('Login.email.label')}
              placeholder={t('Login.email.placeholder')}
              onChange={noop}
            />
          </div>
          <div className="mt-5">
            <LabeledInput
              type={InputType.EMAIL}
              label={t('Login.email.label')}
              error
              placeholder={t('Login.email.placeholder')}
              onChange={noop}
            />
          </div>
          <div className="mt-5">
            <LabeledInput
              type={InputType.PASSWORD}
              label={t('Login.password.label')}
              placeholder={t('Login.password.label')}
              onChange={noop}
            />
          </div>
          <div className="mt-5">
            <LabeledInput
              type={InputType.EMAIL}
              label={t('Login.email.label')}
              disabled
              placeholder={t('Login.email.placeholder')}
              value="pluto@mail.it"
              onChange={noop}
            />
          </div>
          <div className="mt-5">
            <LabeledInput
              type={InputType.TEXT}
              label={t('search.labelCF')}
              placeholder={t('search.placeholder')}
              onChange={noop}
              rightIcon={searchIcon}
            />
          </div>
        </div>
        <h2 className="az-components__subtitle">Alerts</h2>
        <div className="flex my-10 space-x-4">
          <Button
            width={200}
            label="Success Alert"
            styleType="secondary"
            className="flex items-center bg-active-alpha text-white"
            onClick={showAlertSuccess}
          />
          <Button
            width={200}
            label="Error Alert"
            styleType="secondary"
            className="flex items-center bg-error-alpha text-white"
            onClick={showAlertError}
          />
          <Button
            width={200}
            label="Info Alert"
            styleType="secondary"
            className="flex items-center bg-secondary text-white"
            onClick={showAlertInfo}
          />
        </div>
        <h2 className="az-components__subtitle">Checkbox</h2>
        <div className="flex flex-col my-10 space-y-4">
          <CheckableInput
            inputName="privacy"
            isChecked={isCheckedPrivacy}
            onChange={onPrivacyCheckboxChange}>
            <span>
              {t('SignUp.privacy.text')}
              <Link className="underline">{t('SignUp.privacy.link')}</Link>
            </span>
          </CheckableInput>
          <CheckableInput
            inputName="conditions1"
            isChecked={isCheckedConditions}
            onChange={onConditionCheckboxChange}>
            <span>
              {t('SignUp.conditions.text')}
              <Link className="underline">{t('SignUp.conditions.link')}</Link>
            </span>
          </CheckableInput>
        </div>
        <h2 className="az-components__subtitle">Survey Card</h2>
        <div className="flex my-10 space-y-4">
          <QuestionnaireCard
            title={t('QuestionnaireCard.title.lungCancer')}
            onClick={noop}
            onDelete={noop}
          />
        </div>
        <h2 className="az-components__subtitle">Result Box</h2>
        <div className="flex flex-col xl:flex-row justify-around space-y-6 xl:space-y-0 xl:space-x-4">
          <ResultBox
            score={1}
            totalScore={10}
            riskLevel={RiskLevel.LOW_RISK}
            riskColor={RiskColor.LOW_RISK}
          />
          <ResultBox
            score={7}
            totalScore={10}
            riskLevel={RiskLevel.MEDIUM_LOW_RISK}
            riskColor={RiskColor.MEDIUM_LOW_RISK}
          />
          <ResultBox
            score={1}
            totalScore={10}
            riskLevel={RiskLevel.MEDIUM_HIGH_RISK}
            riskColor={RiskColor.MEDIUM_HIGH_RISK}
          />
          <ResultBox
            score={7}
            totalScore={10}
            riskLevel={RiskLevel.HIGH_RISK}
            riskColor={RiskColor.HIGH_RISK}
          />
        </div>
        <h2 className="az-components__subtitle">Accordion</h2>
        <div className="flex my-10">
          <QuestionAccordion
            question={{
              id: 1,
              typeQuestion: TypeQuestion.SELECT,
              description: 'Fake description',
              reference: '',
              order: 1,
              title: 'Fake title',
              answers: [],
              start: true,
            }}
          />
        </div>
        <h2 className="az-components__subtitle">Radio answer</h2>
        <div className="flex flex-col my-10">
          <div className="mb-3">
            <RadioAnswer
              answer="lorem ipsum"
              onChange={onValueChange}
              value="test1"
              selectOption={setSelectedOption}
              isChecked={selectedOption! === 'test1'}
              riskColor={RiskLevel.HIGH_RISK}
            />
          </div>
          <div className="mb-3">
            <RadioAnswer
              answer="lorem ipsum"
              onChange={onValueChange}
              value="test2"
              selectOption={setSelectedOption}
              isChecked={selectedOption! === 'test2'}
              riskColor={RiskLevel.HIGH_RISK}
            />
          </div>
          <div className="mb-3">
            <RadioAnswer
              answer="lorem ipsum"
              value="test4"
              isDisabled
              riskColor={RiskLevel.HIGH_RISK}
            />
          </div>
          <div className="mb-3">
            <RadioAnswer
              answer="lorem ipsum"
              value="test3"
              isDisabled
              isChecked
              riskColor={RiskLevel.HIGH_RISK}
            />
          </div>
          <div className="mb-3">
            <RadioAnswer
              answer="lorem ipsum"
              value="test3"
              isDisabled
              isChecked
              riskColor={RiskLevel.MEDIUM_HIGH_RISK}
            />
          </div>
          <div className="mb-3">
            <RadioAnswer
              answer="lorem ipsum"
              value="test3"
              isDisabled
              isChecked
              riskColor={RiskLevel.MEDIUM_LOW_RISK}
            />
          </div>
          <div className="mb-3">
            <RadioAnswer
              answer="lorem ipsum"
              value="test3"
              isDisabled
              isChecked
              riskColor={RiskLevel.LOW_RISK}
            />
          </div>
        </div>
      </div>
    </Base>
  );
};

export default React.memo(Components);
