import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {
  GET_QUESTIONNAIRE,
  GET_QUESTIONNAIRES,
  GET_STRUCTURE_QUESTIONNAIRE_PUBLISHED,
  // POST_SAVE_QUESTIONNAIRE,
  DELETE_QUESTIONNAIRE,
  QUESTIONNAIRE_RESULT,
} from './queries';
import {
  QuestionnaireInput,
  QuestionnaireFilter,
  QuestionnaireStructureResponse,
} from './interfaces';

export const useGetQuestionnaires = (
  questionnaireFilter: QuestionnaireFilter,
  limit: number = 5,
  offset: number = 0,
) =>
  useQuery(GET_QUESTIONNAIRES, {
    variables: {input: {...questionnaireFilter}, limit, offset},
    fetchPolicy: 'no-cache',
  });

// Duplicated to access both lazy and non-lazy version of the same query
// -------
export const useGetLazyQuestionnaires = (
  questionnaireFilter: QuestionnaireFilter,
  limit: number = 5,
  offset: number = 0,
) =>
  useLazyQuery(GET_QUESTIONNAIRES, {
    variables: {input: {...questionnaireFilter}, limit, offset},
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

export const useGetQuestionnaire = (id: number) =>
  useQuery(GET_QUESTIONNAIRE, {
    variables: {id},
    fetchPolicy: 'no-cache',
  });
// -------

export const useGetStructureQuestionnairePublished = (pathologyId: number) => {
  return useQuery<{
    structureQuestionnairePublished: QuestionnaireStructureResponse;
  }>(GET_STRUCTURE_QUESTIONNAIRE_PUBLISHED, {
    variables: {
      pathologyId: pathologyId,
    },
  });
};

// export const useSaveQuestionnaire = (questionnaire: QuestionnaireInput) =>
//   useMutation<{saveQuestionnaire: {id: number}}>(POST_SAVE_QUESTIONNAIRE, {
//     variables: {
//       input: questionnaire,
//     },
//   });

export const useSaveQuestionnaire = (questionnaire: QuestionnaireInput) =>
  useLazyQuery<{saveQuestionnaire: {id: number}}>(QUESTIONNAIRE_RESULT, {
    variables: {
      input: questionnaire,
    },
  });

export const useDeleteQuestionnaire = () =>
  useMutation<{deleteQuestionnaire: boolean}>(DELETE_QUESTIONNAIRE);
