import {MEDIA_QUERY_LABELS} from './interfaces';

/**
 * @const MEDIA_QUERIES
 * @description A mapping of media query label with the corresponding CSS media query string
 * to be used with window.matchMedia API
 * @example window.matchMedia(MEDIA_QUERIES.LG)
 */
const MEDIA_QUERIES = new Map<MEDIA_QUERY_LABELS, string>();

MEDIA_QUERIES.set('SM', '(min-width: 640px)');
MEDIA_QUERIES.set('MD', '(min-width: 768px)');
MEDIA_QUERIES.set('LG', '(min-width: 1024px)');
MEDIA_QUERIES.set('XL', '(min-width: 1280px)');
MEDIA_QUERIES.set('2XL', '(min-width: 1536px)');

export {MEDIA_QUERIES};
