import React from 'react';
import {Redirect, RouteProps} from 'react-router-dom';

import {useAuthentication} from '../../store/authentication';
import {AnonymousRoutes} from '../routes';
import {Role} from '../../store/api';

import Admin from './Admin';
import MMG from './MMG';
import Patient from './Patient';

const Authenticated = (props: RouteProps) => {
  const {isLoggedIn, role} = useAuthentication();

  if (isLoggedIn && Object.values(Role).includes(role as Role)) {
    if (role === Role.ROLE_DOCTOR) {
      return <MMG {...props} />;
    }

    if (role === Role.ROLE_ADMIN) {
      return <Admin {...props} />;
    }

    if (role === Role.ROLE_PATIENT) {
      return <Patient {...props} />;
    }
  }

  return <Redirect to={AnonymousRoutes.MAIN} />;
};

export default React.memo(Authenticated);
