import React from 'react';
import classNames from 'classnames';

//Other components
import {HighlightBlock} from '../../molecules/HighlightBlock';

//Same component namespace
import {IProps} from './interfaces';
import './HighlightSection.scss';

const HighlightSection = (props: IProps) => {
  const {className, heading, highlightBlocks = []} = props;

  return (
    <section className={classNames('az-highlight-section', className)}>
      <h3 className="az-highlight-section__heading">{heading}</h3>
      <div className="az-highlight-section__blocks">
        {highlightBlocks.length > 0 &&
          highlightBlocks.map((highlightBlock, idx) => (
            <HighlightBlock
              key={`az-highlight-section__block-${idx}`}
              className="az-highlight-section__block"
              color={highlightBlock.color}
              highlightText={highlightBlock.highlightText}
              icon={highlightBlock.icon}
              title={highlightBlock.title}
            />
          ))}
      </div>
    </section>
  );
};

export default React.memo(HighlightSection);
