import {AuthenticationAction, AuthenticationActions} from './types.d';
import {Role} from '../api';

export const LoginLoading = (): AuthenticationAction => ({
  type: AuthenticationActions.loginLoading,
});

export const LoginSuccess = (data: {
  token: string;
  role: Role;
  id: number;
  firstName?: string;
  lastName?: string;
  owaId?: string | null;
}): AuthenticationAction => ({
  type: AuthenticationActions.loginSuccess,
  payload: data,
});

export const LoginFail = (error: Error): AuthenticationAction => ({
  type: AuthenticationActions.loginFail,
  payload: error,
});

export const Logout = (): AuthenticationAction => ({
  type: AuthenticationActions.logout,
});
