import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';

//Others
import {useNavigation} from '../../../navigation';
import {
  getLoginCredentials,
  useLoginAdmin,
} from '../../../store/authentication';
import {AlertContext} from '../../../store/alert/context';
import {InputType} from '../../molecules/LabeledInput/enum';

// Components
import {ButtonText} from '../../atoms/ButtonText';
import {Button} from '../../atoms/Button';
import {LabeledInput} from '../../molecules/LabeledInput';
import {Link} from '../../atoms/Link';
import {Logo} from '../../atoms/Logo';
import {Base} from '../../templates/Base';

// Style
import './AdminLogin.scss';

const AdminLogin = (): JSX.Element => {
  //Helpers
  const {t} = useTranslations();
  const {goToForgotPassword} = useNavigation();
  const {setValue: setAlert} = useContext(AlertContext);

  // Internal state
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loginError, setLoginError] = useState<boolean>(false);

  const loginCredentials = useMemo(
    () => getLoginCredentials(email, password),
    [email, password],
  );

  const [performLoginAdmin, {error, loading}] = useLoginAdmin(loginCredentials);

  const logIn = useCallback(() => {
    performLoginAdmin({variables: {...loginCredentials}});
  }, [performLoginAdmin, loginCredentials]);

  useEffect(() => {
    if (error) {
      setAlert({
        type: 'ERROR',
        message: t('Alert.error.credentialsDescription'),
        onDismiss: () => setLoginError(false),
      });
      setLoginError(true);
    }

    // Press enter key to submit
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        logIn();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [t, error, setAlert, setLoginError, logIn]);

  return (
    <Base isAuth={false} isAdmin>
      <div className="az-admin-login">
        <div className="az-admin-login__content">
          <Link href="/" className="az-admin-login__logo-link">
            <Logo className="az-admin-login__logo" type="full" />
          </Link>
          <div className="az-admin-login__inputs">
            <div className="lg:w-64">
              <LabeledInput
                error={loginError}
                type={InputType.EMAIL}
                label={t('Login.email.label')}
                placeholder={t('Login.email.placeholder')}
                onChange={setEmail}
              />
            </div>
            <div className="az-admin-login__password-content">
              <LabeledInput
                error={loginError}
                type={InputType.PASSWORD}
                label={t('Login.password.label')}
                placeholder={t('Login.password.label')}
                onChange={setPassword}
              />
              <ButtonText
                label={t('Login.forgotPassword')}
                className="az-admin-login__link az-admin-login__link--dark-gray"
                onClick={goToForgotPassword}
              />
            </div>
          </div>
          <Button
            disabled={loading}
            loading={loading}
            width={200}
            label={t('Login.button')}
            className="az-admin-login__cta"
            onClick={logIn}
          />
        </div>
      </div>
    </Base>
  );
};

export default React.memo(AdminLogin);
