export const taxCodeConstraint = {
  presence: true,
  format: {
    pattern:
      '/^(1\\s|1|)?((\\(\\d{3}\\))|\\d{3})(\\-|\\s)?(\\d{3})(\\-|\\s)?(\\d{4})$/',
  },
};

export const fiscalCodeConstraint = {
  presence: true,
  format: {
    pattern:
      /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/,
  },
};

export const nameConstraint = {
  presence: true,
  length: {
    minimum: 4,
  },
};

export const emailConstraint = {
  email: true,
  presence: true,
  length: {
    minimum: 1,
  },
};

export const phoneConstraint = {
  presence: true,
  format: {
    pattern: '/^(1s|1|)?(((d{3}))|d{3})(-|s)?(d{3})(-|s)?(d{4})$/',
  },
};

export const passwordConstraint = {
  presence: true,
  length: {
    minimum: 8,
  },
  format: {
    pattern: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
  },
};

export const checkboxConstraint = {
  presence: true,
  inclusion: {within: [true]},
};

export const confirmPasswordConstraint = {
  equality: 'password',
};

export const emptyFieldConstraint = {
  presence: true,
  length: {
    minimum: 1,
  },
};

export const numberConstraint = {
  presence: true,
  length: {
    minimum: 1,
  },
  format: {
    pattern: '^[0-9]+$',
  },
};

export const numericalityConstraint = {
  numericality: true,
};

export const integerConstraint = {
  numericality: {
    onlyInteger: true,
  },
};
