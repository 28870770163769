import {useMutation, useQuery} from '@apollo/client';

import {DELETE_CONTENT, GET_RESOURCES} from './queries';
import {ResourceFilter, ResourceQueryResult} from './interfaces';

/**
 * @description Returns a list of resources
 * @param filters
 */
export const useResources = (
  filters?: ResourceFilter,
  limit: number = 5,
  offset: number = 0,
) =>
  useQuery<ResourceQueryResult>(GET_RESOURCES, {
    variables: {input: {...filters}, limit, offset},
    fetchPolicy: 'no-cache',
  });

export const useDeleteContent = () =>
  useMutation<{deleteContent: boolean}>(DELETE_CONTENT);
