import {gql} from '@apollo/client';

export const CREATE_STRUCTURE_QUESTIONNAIRE = gql`
  mutation PostCreateStructureQuestionnaire(
    $input: QuestionnaireStructureCreateInput!
  ) {
    createStructureQuestionnaire(input: $input) {
      id
      pathology {
        id
        name
      }
      status
    }
  }
`;

export const UPDATE_STRUCTURE_QUESTIONNAIRE = gql`
  mutation PostUpdateStructureQuestionnaire(
    $input: QuestionnaireStructureUpdateInput!
  ) {
    updateStructureQuestionnaire(input: $input) {
      id
      pathology {
        id
        name
      }
      limitScore
      limitScore2
      status
    }
  }
`;

export const GET_STRUCTURE_QUESTIONNAIRES = gql`
  query GetStructureQuestionnaires(
    $input: QuestionnaireStructureFilter
    $limit: Int
    $offset: Int
  ) {
    structureQuestionnaires(input: $input, limit: $limit, offset: $offset) {
      items {
        id
        pathology {
          name
          isDeleted
        }
        status
        subTitle
      }
    }
  }
`;

export const GET_STRUCTURE_QUESTIONNAIRE = gql`
  query GetStructureQuestionnaires($id: Int!) {
    structureQuestionnaire(id: $id) {
      id
      pathology {
        id
        name
      }
      subTitle
      instructions
      status
      limitScore
      limitScore2
      createdAt
      questions {
        id
        start
        title
        description
        reference
        order
        typeQuestion
        previousQuestionId
        answers {
          id
          title
          description
          reference
          score
          nextQuestionId
          choice
          text
          riskLevel
        }
      }
    }
  }
`;

export const DELETE_STRUCTURE_QUESTIONNAIRE = gql`
  mutation PostDeleteStructureQuestionnaire($id: Int!) {
    deleteStructureQuestionnaire(id: $id)
  }
`;

export const PUBLISH_STRUCTURE_QUESTIONNAIRE = gql`
  mutation PostPublishStructureQuestionnaire($id: Int!) {
    publishStructureQuestionnaire(id: $id)
  }
`;
