import {gql} from '@apollo/client';

export const GET_ADMINS = gql`
  query GetAdmins($input: AdminFilter, $limit: Int, $offset: Int) {
    admins(input: $input, limit: $limit, offset: $offset) {
      items {
        id
        email
        firstName
        lastName
        status
      }
      info {
        total
      }
    }
  }
`;

export const GET_ADMIN = gql`
  query GetAdmin($id: Int!) {
    admin(id: $id) {
      id
      email
      firstName
      lastName
      status
    }
  }
`;

export const CREATE_ADMIN = gql`
  mutation CreateAdmin($input: UserCreateInput!) {
    createAdmin(input: $input) {
      id
    }
  }
`;

export const UPDATE_ADMIN = gql(
  `mutation UpdateAdmin($input: UserUpdateInput!) {
    updateAdmin(input: $input) {
      id
    }
  }`,
);

export const DELETE_USER = gql`
  mutation DeleteUser($id: Int!) {
    deleteUser(id: $id)
  }
`;
