import {LoginCredentials} from './interfaces';

export const getLoginCredentials = (
  email: string,
  password: string,
): LoginCredentials => ({
  input: {
    email,
    password,
  },
});
