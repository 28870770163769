import React from 'react';
import classNames from 'classnames';

//Other components
import {Marker} from '../../atoms/Marker';

//Component namespace
import {IProps} from './interfaces';
import './HeroBanner.scss';

const HeroBanner = ({className, subtitle, title}: IProps) => {
  return (
    <div className={classNames('az-hero-banner', className)}>
      <div className="az-hero-banner__content">
        <Marker className="az-hero-banner__marker" color="tertiary" />
        <h2 className="az-hero-banner__title">{title}</h2>
        <h6 className="az-hero-banner__subtitle">{subtitle}</h6>
      </div>
    </div>
  );
};

export default HeroBanner;
