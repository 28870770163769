import {gql} from '@apollo/client';

const GET_LOGIN = gql`
  query GetLogin($input: JwtAuthenticationRequest!) {
    login(input: $input) {
      token
      user {
        id
        role
      }
    }
  }
`;

const GET_LOGIN_DOCTOR = gql`
  query GetDoctorLogin($input: JwtAuthenticationRequest!) {
    loginDoctor(input: $input) {
      token
      user {
        id
        role
        owaId
        email
      }
    }
  }
`;

const DOCTOR_FINISH_REGISTRATION = gql`
  mutation CreateDoctor($input: DoctorCreateInput!) {
    createDoctor(input: $input) {
      id
      email
    }
  }
`;

const REMOVE_DOCTOR = gql`
  mutation RemoveDoctor {
    removeDoctor
  }
`;

export const GET_LOGIN_ADMIN = gql`
  query GetAdminLogin($input: JwtAuthenticationRequest!) {
    loginAdmin(input: $input) {
      token
      user {
        id
        role
        firstName
        lastName
      }
    }
  }
`;

const GET_USER_BY_TOKEN = gql`
  query GetUserByToken($token: String!) {
    userByToken(token: $token) {
      email
      fiscalCode
    }
  }
`;

const POST_CONFIRM_REGISTRATION = gql`
  mutation PostConfirmRegistration($input: UserRegistrationInput!) {
    confirmRegistration(input: $input) {
      email
    }
  }
`;

const POST_FORGOT_PASSWORD = gql`
  mutation PostForgotPassword($input: ForgotPasswordDTO) {
    forgotPassword(input: $input)
  }
`;

const POST_RESET_PASSWORD = gql`
  mutation PostResetPassword($input: UserResetPasswordInput) {
    resetPassword(input: $input)
  }
`;

const RESEND_REGISTRATION_EMAIL = gql`
  mutation resendRegistrationMail($id: Int!) {
    resendRegistrationMail(id: $id)
  }
`;

const GET_LOGGED_USER = gql`
  query GetLoggedUser {
    loggedUser {
      positivePathologies {
        id
        name
        isDeleted
      }
      validPathologies {
        id
        name
        isDeleted
      }
      fiscalCode
      email
      firstName
      lastName
      city
      province
      phone
      electronicCommunications
      automatedCallSystems
    }
  }
`;

const GET_PROFILE_PICTURE = gql`
  query GetLoggedUser {
    loggedUser {
      imageBase64
    }
  }
`;

const UPDATE_MMG = gql(
  `mutation UpdateDoctor($input: DoctorUpdateInput!) {
    updateDoctor(input: $input) {
      id
      email
    }
  }`,
);

export {
  GET_LOGIN,
  GET_LOGIN_DOCTOR,
  GET_USER_BY_TOKEN,
  POST_CONFIRM_REGISTRATION,
  POST_FORGOT_PASSWORD,
  POST_RESET_PASSWORD,
  RESEND_REGISTRATION_EMAIL,
  GET_LOGGED_USER,
  GET_PROFILE_PICTURE,
  DOCTOR_FINISH_REGISTRATION,
  UPDATE_MMG,
  REMOVE_DOCTOR,
};
