import {gql} from '@apollo/client';

export const GET_PATHOLOGIES = gql`
  query GetPathologies($input: PathologyFilter, $limit: Int, $offset: Int) {
    pathologies(input: $input, limit: $limit, offset: $offset) {
      items {
        id
        name
      }
      info {
        total
      }
    }
  }
`;

export const CREATE_PATHOLOGY = gql`
  mutation CreatePathology($input: PathologyInput!) {
    createPathology(input: $input) {
      id
    }
  }
`;

export const DELETE_PATHOLOGY = gql`
  mutation DeletePathology($id: Int!) {
    deletePathology(id: $id)
  }
`;
