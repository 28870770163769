import React, {useContext} from 'react';
import classNames from 'classnames';

//Others
import {AlertContext} from '../../../store/alert/context';
import {Role} from '../../../store/api';
import {useAuthentication} from '../../../store/authentication';

//Components
import {Alert} from '../../molecules/Alert';
import {BreadCrumb} from '../../molecules/BreadCrumb';
import {Footer} from '../../molecules/Footer';
import {Header} from '../../organisms/Header';
import {Modal} from '../../organisms/Modal';
import {ButtonText} from '../../atoms/ButtonText';

//Component namespace
import {IProps} from './interfaces';
import './AuthBase.scss';

const AuthBase = ({
  children,
  isAuth = true,
  pageHeading,
  cta,
  className,
  mobileAddBtn = false,
  buttonText1 = null,
  buttonText2 = null,
}: IProps) => {
  const {value: alert} = useContext(AlertContext);
  const {role} = useAuthentication();

  return (
    <div
      className={classNames(
        'az-page-template',
        'az-page-template--auth-base',
        className,
      )}>
      <Header
        isAuth={isAuth}
        isDoctor={role === Role.ROLE_DOCTOR}
        className="az-page-template__header"
      />
      <main className="az-page-template__main az-page-template__main--gray-light">
        <div className="az-page-template__container">
          {alert && <Alert />}
          <div
            className={classNames('az-page-template__heading', {
              'az-page-template__heading--add-mobile': mobileAddBtn,
            })}>
            <div className="az-page-template__heading-left-content">
              <h1 className="az-page-template__title">{pageHeading}</h1>
              <BreadCrumb className="az-page__breadcrumb" />
            </div>
            <div>
              {buttonText1 && (
                <ButtonText
                  className="az-page-template__cta-text"
                  {...buttonText1}
                />
              )}
              {buttonText2 && (
                <ButtonText
                  className="az-page-template__cta-text"
                  {...buttonText2}
                />
              )}
            </div>
            {cta && <div className="az-page-template__cta">{cta}</div>}
          </div>
          {children}
        </div>
      </main>
      <Footer className="az-page-template__footer" />
      <Modal className="az-page-template__modal" />
    </div>
  );
};

export default React.memo(AuthBase);
