import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import {useLazyQuery} from '@apollo/client';

//Others
import {
  GET_LOGGED_USER,
  useAuthentication,
} from '../../../store/authentication';
import {GET_PATHOLOGIES} from '../../../store/pathologies';
import {useBreadCrumb} from '../../../store/breadCrumb';
import {noop} from '../../../utils/noop';
import {useNavigation} from '../../../navigation';
import {Role} from '../../../store/api';
import {useResources} from '../../../store/resources/hooks';

// Template
import {AuthBase} from '../../templates/AuthBase';

//Components
import {Spinner} from '../../atoms/Spinner';
import {ResourceCard} from '../../organisms/ResourceCard';

//Component namespace
import {IProps} from './interfaces';
import './Resources.scss';
import classNames from 'classnames';

const Resources = (_props: IProps) => {
  // Get pathologies for MMG
  const [
    getPathologies,
    {
      data: pathologiesData,
      loading: pathologiesLoading,
      // error: pathologiesError,
    },
  ] = useLazyQuery(GET_PATHOLOGIES, {
    variables: {},
    fetchPolicy: 'cache-and-network',
  });
  // Get pathologies of logged patient
  const [
    getPatientPathologies,
    {
      data: patientPathologiesData,
      loading: patientPathologiesLoading,
      // error: pathologiesError,
    },
  ] = useLazyQuery(GET_LOGGED_USER, {
    variables: {},
    fetchPolicy: 'cache-and-network',
  });

  //Helpers
  const {t} = useTranslations();
  const {isLoggedIn, role} = useAuthentication();
  const {goToMain} = useNavigation();
  const {updateBreadCrumbItems} = useBreadCrumb();

  // State
  const [pathologiesList, setPathologiesList] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);

  const [selectedPathology, setSelectedPathology] = useState<number | null>(
    null,
  );

  const resourcesFilter = useMemo<any>(() => {
    return {
      pathologiesId: selectedPathology ? [selectedPathology] : [],
    };
  }, [selectedPathology]);
  // Get resources
  const {
    loading,
    error,
    data: {resources} = {resources: {items: []}},
    refetch,
  } = useResources(resourcesFilter, 999);

  //Set BreadCrumb items
  useEffect(() => {
    updateBreadCrumbItems([
      {
        label: t('Resources.home'),
        onClick: goToMain,
      },
      {
        label: t('Resources.title'),
        onClick: noop,
      },
    ]);
  }, [updateBreadCrumbItems, t, goToMain]);

  // MMG only
  // Get a list of all pathologies to fill dropdown
  useEffect(() => {
    if (role === Role.ROLE_DOCTOR) {
      getPathologies();
    }
    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Patient only
  // Get a list of patient's pathologies to fill dropdown
  useEffect(() => {
    // Query to get pathologies of patient
    if (role === Role.ROLE_PATIENT) {
      getPatientPathologies();
    }
    return () => {
      //
    };
    // Run this useEffect only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Fill pathologies list with all pathologies
    if (pathologiesData) {
      setPathologiesList(pathologiesData?.pathologies?.items);
    }
    // Fill pathologies list with patient's pathologies
    if (patientPathologiesData) {
      setPathologiesList(patientPathologiesData?.loggedUser?.validPathologies);
    }
    return () => {
      //
    };
    // Run this useEffect only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathologiesData, patientPathologiesData]);

  const changeFilters = useCallback((id: number | null) => {
    setSelectedPathology(id);
  }, []);

  useEffect(() => {
    refetch();
  }, [refetch, selectedPathology]);

  return (
    <AuthBase pageHeading={t('Resources.title')} isAuth={isLoggedIn}>
      <div className="az-resources__filters-box">
        <div
          className={classNames('az-resources__pathology-chip', {
            'az-resources__pathology-chip--selected': !selectedPathology,
          })}
          onClick={() => changeFilters(null)}>
          {t('Resources.filters.all')}
        </div>
        {pathologiesList?.map(pathology => {
          return (
            <div
              key={pathology.id}
              className={classNames('az-resources__pathology-chip', {
                'az-resources__pathology-chip--selected':
                  pathology.id === selectedPathology,
              })}
              onClick={() => changeFilters(pathology.id)}>
              {pathology.name}
            </div>
          );
        })}
      </div>
      <div className="mt-8">
        {loading && <Spinner color="gray" className="az-resources__spinner" />}
        {!loading && resources.items.length < 1 && (
          <div className="mt-12">{t('Resources.noResources')}</div>
        )}
        {!loading &&
          resources.items.map(resource => {
            return (
              <div
                key={resource.id}
                className="az-resources__resource-container">
                <ResourceCard
                  className="az-resources__resource"
                  description={resource.description}
                  imageUrl={resource.imageUrl}
                  title={resource.title}
                  url={resource.url}
                  pathologies={resource.pathologies}
                />
              </div>
            );
          })}
      </div>
    </AuthBase>
  );
};

export default React.memo(Resources);
