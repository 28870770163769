import React from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';

import {AuthenticatedRoutes, MMGRoutes} from '../routes';
import {useAuthentication} from '../../store/authentication';
import {AZStorage, STORAGE_KEY} from '../../utils/storage';

const MMG = (props: RouteProps) => {
  const {id, token} = useAuthentication();
  const owaId = AZStorage.getItem(STORAGE_KEY.OWA_ID);

  if (id === -1 && token === 'no-token' && owaId) {
    return <Redirect to={AuthenticatedRoutes.MMG_FIRST_ACCESS} />;
  }

  if (Object.values(MMGRoutes).includes(props.path as MMGRoutes)) {
    return <Route {...props} />;
  }

  return <Redirect to={AuthenticatedRoutes.MAIN} />;
};

export default React.memo(MMG);
