import React from 'react';
import classNames from 'classnames';

// Interface
import {IProps} from './interface';

// Style
import './InputLabel.scss';

const InputLabel = ({
  label = '',
  error = false,
  color,
  weight = 'normal',
  className,
}: IProps) => (
  <span
    className={classNames(className, {
      'az-input-label': true,
      [`az-input-label--${color}`]: true,
      'az-input-label--error': error,
      'az-input-label--bold': weight === 'bold',
    })}>
    {label}
  </span>
);

export default React.memo(InputLabel);
