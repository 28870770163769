import React, {useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import {useParams} from 'react-router-dom';

//Others
import {DEFAULT_MODAL_STATE, ModalContext} from '../../../store/modal/context';
import {PathologyResponse, useGetPathologies} from '../../../store/pathologies';
import {useNavigation} from '../../../navigation';

//Other components
import {Spinner} from '../../atoms/Spinner';
import {CheckableInput} from '../../atoms/CheckableInput';
import {Button} from '../../atoms/Button';

//Component namespace
import {IProps} from './interfaces';
import './NewQuestionnaireModal.scss';

const NewQuestionnaireModal = (_props: IProps) => {
  const {goToNewQuestionnaire} = useNavigation();

  // @REMOVE_PATIENTS_DATA
  // const {id: patientId}: {id: string} = useParams();

  //Internal state
  const [checkedPathologyId, setCheckedPathologyId] = useState<number>();

  //Refs
  const formRef = useRef<HTMLFormElement>(null);

  //Helpers
  const {t} = useTranslations();
  const {setValue: setModal} = useContext(ModalContext);
  const {data, loading} = useGetPathologies(
    {
      availableQuestionnairesOnly: true,
    },
    100,
  );

  const pathologies = useMemo<PathologyResponse[]>(
    () => data?.pathologies.items ?? [],
    [data],
  );

  const closeModal = useCallback(
    () => setModal(DEFAULT_MODAL_STATE),
    [setModal],
  );

  const isPathologyChecked = useCallback(
    (pathologyId: number) => pathologyId === checkedPathologyId,
    [checkedPathologyId],
  );

  const onPathologyChange = useCallback(
    (pathologyId: number) => () => setCheckedPathologyId(pathologyId),
    [],
  );

  const isFormValid = useMemo(() => {
    return (
      (checkedPathologyId !== undefined && formRef?.current?.checkValidity()) ??
      false
    );
  }, [formRef, checkedPathologyId]);

  const onSubmit = useCallback((ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
  }, []);

  const goToQuestionnaire = useCallback(() => {
    closeModal();
    // @REMOVE_PATIENTS_DATA
    // goToNewQuestionnaire(Number(patientId), checkedPathologyId!);
    goToNewQuestionnaire(checkedPathologyId!);
  }, [
    checkedPathologyId,
    closeModal,
    goToNewQuestionnaire,

    // @REMOVE_PATIENTS_DATA
    // patientId
  ]);

  return (
    <form
      className="az-new-questionnaire-modal"
      ref={formRef}
      onSubmit={onSubmit}>
      <h1 className="az-new-questionnaire-modal__title">
        {t('NewQuestionnaireModal.title')}
      </h1>
      <fieldset>
        {loading && (
          <Spinner
            color="primary"
            className="az-new-questionnaire-modal__spinner"
          />
        )}
        {pathologies.length && pathologies.length !== 0 && !loading
          ? pathologies.map((pathology, idx) => (
              <div
                className="az-new-questionnaire-modal__field"
                key={`field-pathology-${idx}`}>
                <CheckableInput
                  type="radio"
                  onChange={onPathologyChange(pathology.id)}
                  id={`${pathology.id}`}
                  inputName={`pathology-${idx}`}
                  isChecked={isPathologyChecked(pathology.id)}
                  value={pathology.id.toString()}
                />
                {/* {pathology.name} */}
                {t('NewQuestionnaireModal.forcedPlaceholder')}
              </div>
            ))
          : t('NewQuestionnaireModal.noQuestionnairesAvailable')}
      </fieldset>
      <div className="az-new-questionnaire-modal__cta-container">
        <Button
          label={t('NewQuestionnaireModal.cta.cancel')}
          onClick={closeModal}
          styleType="secondary"
          className="az-new-questionnaire-modal__cta az-new-questionnaire-modal__cta--delete"
        />
        <Button
          disabled={!isFormValid}
          label={t('NewQuestionnaireModal.cta.proceed')}
          styleType="primary"
          type="submit"
          className="az-new-questionnaire-modal__cta az-new-questionnaire-modal__cta--submit"
          onClick={goToQuestionnaire}
        />
      </div>
    </form>
  );
};

export default React.memo(NewQuestionnaireModal);
