import React from 'react';
import classNames from 'classnames';

//Component namespace
import {IProps} from './interfaces';
import './Spinner.scss';
import {ReactComponent as SpinnerSVG} from './spinner.svg';

const Spinner = (props: IProps) => {
  const {className} = props;

  return <SpinnerSVG className={classNames('az-spinner', className)} />;
};

export default Spinner;
