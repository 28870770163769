import React, {FunctionComponent, useEffect, useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {useTranslations} from '@vidiemme/react-i18n';

import {useAuthentication} from '../store/authentication';
import {useToast} from '../hooks';

//Other
import ScrollToTop from './utils/ScrollToTop';

import {NavigationSwitch} from './NavigationSwitch';

export const Navigation: FunctionComponent = () => {
  const [initialized, setInitialized] = useState<Boolean>(false);
  const {restoreAuth} = useAuthentication();
  const {showErrorToast} = useToast();
  const {t} = useTranslations();

  useEffect(() => {
    try {
      restoreAuth();
    } catch (e) {
      //@todo set alert here
      showErrorToast(t('authentication.authError'));
    }
    setInitialized(true);
  }, [setInitialized, restoreAuth, t, showErrorToast]);

  if (initialized) {
    return (
      <Router>
        <ScrollToTop />
        <NavigationSwitch />
      </Router>
    );
  } else {
    return <></>;
  }
};
