import React, {useContext, useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {useTranslations} from '@vidiemme/react-i18n';

// Others
import {noop} from '../../../utils/noop';
import {useAuthentication} from '../../../store/authentication';
import {useBreadCrumb} from '../../../store/breadCrumb';
// @REMOVE_PATIENTS_DATA
// import {PatientUIData, useGetPatient} from '../../../store/patients';
import {useGetStructureQuestionnairePublished} from '../../../store/questionnaire';
import {useNavigation} from '../../../navigation';
import {AlertContext} from '../../../store/alert/context';

// Components
// @REMOVE_PATIENTS_DATA
// import {PatientData} from '../../organisms/PatientData';
import {Marker} from '../../atoms/Marker';
import {Button} from '../../atoms/Button';

// Templates
import {AuthBase} from '../../templates/AuthBase';

// Interface
import {IProps} from './interface';

// Style
import './NewQuestionnaire.scss';

const NewQuestionnaire = (_props: IProps) => {
  //Internal state
  const {
    // @REMOVE_PATIENTS_DATA
    //patientId,
    id,
  }: {
    // @REMOVE_PATIENTS_DATA
    // patientId: string;
    id: string;
  } = useParams();
  const {setValue: setAlert} = useContext(AlertContext);

  //Helpers
  const {t} = useTranslations();
  const {isLoggedIn} = useAuthentication();
  const {goToQuestionnaire, goToActiveQuestionnaire} = useNavigation();
  const {updateBreadCrumbItems} = useBreadCrumb();

  const {data: questionnaireData, error: questionnaireError} =
    useGetStructureQuestionnairePublished(Number(id));

  //Set BreadCrumb items
  useEffect(() => {
    updateBreadCrumbItems([
      {
        label: t('NewQuestionnaire.breadCrumb.parentPage.label'),
        onClick: () => goToQuestionnaire(),
      },
    ]);
  }, [updateBreadCrumbItems, t, goToQuestionnaire]);

  useEffect(() => {
    if (questionnaireError) {
      setAlert({
        type: 'ERROR',
        message: t('Alert.error.questionnaireError'),
      });
    }
  }, [questionnaireError, setAlert, t]);

  return (
    <AuthBase isAuth={isLoggedIn} pageHeading={t('NewQuestionnaire.title')}>
      <div className="az-new-questionnaire">
        <div className="w-full pt-4">
          <p className="w-full">{t('NewQuestionnaire.disclaimer')}</p>
        </div>

        {questionnaireData?.structureQuestionnairePublished ? (
          <>
            <Marker
              color="tertiary"
              className="az-new-questionnaire__section-marker"
            />
            {/*             <div className="az-new-questionnaire__titles-container">
              <h3 className="az-new-questionnaire__title">
                {
                  questionnaireData?.structureQuestionnairePublished?.pathology
                    ?.name
                }
              </h3>
              <p className="az-new-questionnaire__subtitle">
                {questionnaireData?.structureQuestionnairePublished?.subTitle}
              </p>
            </div> */}

            {questionnaireData?.structureQuestionnairePublished
              ?.instructions ? (
              <div className="az-new-questionnaire__instructions-container">
                <h3 className="az-new-questionnaire__instructions-title">
                  {t('NewQuestionnaire.instructionsTitle')}
                </h3>
                <p className="az-new-questionnaire__instructions">
                  {
                    questionnaireData?.structureQuestionnairePublished
                      ?.instructions
                  }
                </p>
              </div>
            ) : (
              <></>
            )}

            <div className="az-new-questionnaire__titles-container">
              <h3 className="az-new-questionnaire__title">
                {t('NewQuestionnaire.bottomDisclaimer')}
              </h3>
            </div>

            <div className="az-new-questionnaire__buttons">
              <div className="az-new-questionnaire__button">
                <Button
                  styleType="secondary"
                  label={t('NewQuestionnaire.buttons.cancel')}
                  // @REMOVE_PATIENTS_DATA
                  // onClick={() => goToPatient(Number(patientId))}
                  onClick={goToQuestionnaire}
                />
              </div>
              <div className="az-new-questionnaire__button">
                <Button
                  label={t('NewQuestionnaire.buttons.start')}
                  onClick={() => goToActiveQuestionnaire(Number(id))}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="az-new-questionnaire__titles-container">
              {t('ResultQuestionnaire.generalError')}
            </div>
          </>
        )}
      </div>
    </AuthBase>
  );
};

export default React.memo(NewQuestionnaire);
