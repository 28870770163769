import React, {useCallback, useState} from 'react';
import classNames from 'classnames';

// Components
import {InputLabel} from '../../atoms/InputLabel';
import {IconSVG} from '../../atoms/IconSVG';
import {IconButton} from '../iconButton';

// Interface
import {IProps} from './interface';
import {IProps as IconProps} from '../../atoms/IconSVG/interfaces';
import {InputType} from './enum';

// Style
import './LabeledInput.scss';

const LabeledInput = ({
  defaultValue,
  label,
  error = false,
  disabled = false,
  onChange,
  rightIcon,
  type = InputType.TEXT,
  className,
  isValid,
  ...props
}: IProps): JSX.Element => {
  const handleChange = useCallback(
    e => {
      if (onChange) {
        onChange!(e.target.value);
      }
    },
    [onChange],
  );
  const showPasswordIcon: IconProps = {
    icon: 'icon-view',
    size: 23,
    className: 'text-gray-dark',
  };

  // State for toggle show password button
  const [passwordShown, setPasswordShown] = useState<boolean>(
    type !== 'password',
  );

  // Callback for show / hide password
  const togglePasswordVisibility = useCallback(() => {
    setPasswordShown(!passwordShown);
  }, [setPasswordShown, passwordShown]);

  // Input password
  if (type === InputType.PASSWORD) {
    return (
      <div className="az-input">
        <InputLabel label={label} error={error} color={'dark'} />
        <div
          className={classNames('az-input__input-wrapper', {
            'az-input__input-wrapper--error': error && !disabled,
          })}>
          <input
            className={classNames('az-input__input', {
              'az-input__input--disabled': disabled,
            })}
            type={passwordShown ? 'text' : 'password'}
            readOnly={disabled}
            {...props}
            onChange={handleChange}
          />
          <IconButton
            icon={showPasswordIcon}
            onClick={togglePasswordVisibility}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('az-input', className)}>
      <InputLabel label={label} error={error} color={'dark'} />
      <div
        className={classNames('az-input__input-wrapper', {
          'az-input__input-wrapper--error': error && !disabled,
          'az-input__input-wrapper--valid': isValid && !disabled,
          'az-input__input-wrapper--disabled': disabled,
        })}>
        <input
          className="az-input__input"
          type={type}
          readOnly={disabled}
          onChange={handleChange}
          step="0.5"
          {...(defaultValue !== undefined ? {defaultValue} : {})}
          {...props}
        />
        {rightIcon && (
          <div onClick={rightIcon.onClick || null}>
            <IconSVG
              icon={rightIcon.icon}
              size={rightIcon.size}
              className={rightIcon.className}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(LabeledInput);
