import React from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {AdminRoutes} from '../routes';

const Admin = (props: RouteProps) => {
  if (Object.values(AdminRoutes).includes(props.path as AdminRoutes)) {
    return <Route {...props} />;
  }

  return <Redirect to={AdminRoutes.QUESTIONNAIRES} />;
};

export default React.memo(Admin);
