import React, {useMemo} from 'react';
import classNames from 'classnames';

//Others
import {useBEMClass} from '../../../hooks/use-bem-class';
import {noop} from '../../../utils/noop';

//Other components
import {StatusLabel} from '../../atoms/StatusLabel';

//Component namespace
import {IProps} from './interfaces';
import './DataTableData.scss';
import {IconButton} from '../iconButton';
import {IProps as IconType} from '../../atoms/IconSVG/interfaces';

const DataTableData = ({
  color = 'dark',
  label = '',
  children,
  icon,
  className,
  status,
  onClick = noop,
  onClickAction,
  boxed = true,
  dataFor = '',
  isAdmin = false,
}: IProps) => {
  //Helpers
  const BEMClass = useBEMClass();

  const colorModifierClass = useMemo(
    () => BEMClass('az-table-data', '', color),
    [color, BEMClass],
  );

  const boxedModifierClass = useMemo(
    () => (boxed ? BEMClass('az-table-data', '', 'boxed') : ''),
    [boxed, BEMClass],
  );

  const iconData: IconType = {
    icon: icon!,
    size: 20,
    className: 'az-table-data__icon',
  };

  return (
    <td
      className={classNames(
        'az-table-data',
        colorModifierClass,
        boxedModifierClass,
        className,
      )}
      data-tip
      data-for={dataFor}
      onClick={onClick}>
      {status !== undefined ? (
        <StatusLabel status={status} isAdmin={isAdmin} />
      ) : (
        <>
          {label}
          {icon && <IconButton icon={iconData} onClick={onClickAction} />}
        </>
      )}
      {children}
      {}
    </td>
  );
};
export default React.memo(DataTableData);
