import React, {SyntheticEvent, useCallback, useContext, useMemo} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';

// Others
import {DEFAULT_MODAL_STATE, ModalContext} from '../../../store/modal/context';
import {useMediaQuery} from '../../../hooks';
import {MEDIA_QUERIES} from '../../../hooks/mediaQuery/contants';

//Other components
import {Button} from '../../atoms/Button';

//Component namespace
import {IProps} from './interface';
import './DeleteModal.scss';

const DeleteModal = ({
  modalTitle,
  body,
  subtitle,
  questionnaireName,
  isAlert = false,
  isDownload = false,
  callback,
}: IProps): JSX.Element => {
  //Helpers
  const {setValue: setModalContent} = useContext(ModalContext);
  const {t} = useTranslations();
  const {mediaQueryList} = useMediaQuery(MEDIA_QUERIES.get('LG')!);

  const closeModal = useCallback(
    () => setModalContent(DEFAULT_MODAL_STATE),
    [setModalContent],
  );

  const onSubmit = useCallback(
    (ev: SyntheticEvent) => {
      ev.preventDefault();

      callback();
      closeModal();
    },
    [callback, closeModal],
  );

  const ctaWidth = useMemo(
    () => (mediaQueryList.matches ? '45%' : '100%'),
    [mediaQueryList],
  );

  return (
    <div>
      <h1 className="az-add-patient__title">{t(modalTitle)}</h1>
      <p className="az-delete-modal__text">
        {t(body, questionnaireName ? {questionnaire: questionnaireName} : {})}
      </p>
      <p className="az-delete-modal__text">{t(subtitle)}</p>
      <div className="az-add-patient__cta-container">
        {!isAlert ? (
          <>
            <Button
              className="az-add-patient__cta az-add-patient__cta--delete"
              label={t('DeleteModal.cta.undo')}
              onClick={closeModal}
              styleType="secondary"
              type="button"
              width={ctaWidth}
            />
            <Button
              onClick={onSubmit}
              className="az-add-patient__cta az-add-patient__cta--submit"
              label={
                !isDownload
                  ? t('DeleteModal.cta.proceed')
                  : t('Sidebar.label.download')
              }
              styleType="primary"
              type="button"
              width={ctaWidth}
            />
          </>
        ) : (
          <>
            <Button
              className="az-delete-modal__cta az-delete-modal__cta--submit"
              label={t('DeleteModal.cta.ok')}
              onClick={closeModal}
              styleType="secondary"
              type="button"
              width={ctaWidth}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(DeleteModal);
