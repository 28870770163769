import React from 'react';
import classNames from 'classnames';

//Other Components
import {IconSVG} from '../../atoms/IconSVG';

//Others
import {useBEMClass} from '../../../hooks/use-bem-class';

//Component interface
import {IProps} from './interfaces';
import './HighlightBlock.scss';

const HighlightBlock = (props: IProps) => {
  const {className, color, icon, highlightText, title} = props;

  //Helpers
  const BEMClass = useBEMClass();
  const colorClassName = BEMClass('az-highlight-block', '', color);

  return (
    <div
      className={classNames('az-highlight-block', colorClassName, className)}>
      {icon && (
        <IconSVG icon={icon} size={50} className="az-highlight-block__icon" />
      )}
      {highlightText && (
        <em className="az-highlight-block__highlight-text">{highlightText}</em>
      )}
      {title && <p className="az-highlight-block__title">{title}</p>}
    </div>
  );
};

export default React.memo(HighlightBlock);
