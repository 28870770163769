import React from 'react';
import classNames from 'classnames';

// Other
import {useTranslations} from '@vidiemme/react-i18n';

// Components
import {Logo} from '../../atoms/Logo';
import {Link} from '../../atoms/Link';

// Component namespace
import './Footer.scss';
import {IProps} from './interface';

const Footer = ({className = ''}: IProps): JSX.Element => {
  const {t} = useTranslations();

  return (
    <footer className={classNames('az-footer', className)}>
      <div className="az-footer__element az-footer__element--info">
        <div>
          <p className="az-footer__description">
            {t('Footer.infoDescription')}
          </p>
        </div>
        <Logo className="az-footer__logo" type="white" />
      </div>
      <div className="az-footer__element az-footer__element--ref">
        <div className="az-footer__info-site">
          <Link
            className="az-footer__text"
            href="https://www.connessiallasalute.it/">
            {t('Footer.relatedToHealth')}
          </Link>
          <Link className="az-footer__text" href="https://www.astrazeneca.it/">
            {t('Footer.azWebsite')}
          </Link>
        </div>
        <div className="az-footer__links">
          <Link
            className="az-footer__link"
            href="https://www.astrazeneca.it/informazioni-legali-e-condizioni-di-utilizzo.html">
            {t('Footer.legalInfo')}
          </Link>
          <Link
            className="az-footer__link"
            href="https://www.globalprivacy.astrazeneca.com/it">
            {t('Footer.privacy')}
          </Link>
          <Link
            className="az-footer__link"
            href="https://www.astrazeneca.it/contattaci.html">
            {t('Footer.contacts')}
          </Link>
          <a
            href="//policy.cookiereports.com/d1362e07-it.html"
            className={classNames(['CookieReportsLink', 'az-footer__link'])}>
            {t('Footer.cookies')}
          </a>
          <Link
            className="az-footer__link"
            href="https://contactazmedical.astrazeneca.com/content/astrazeneca-champion/global/en/amp-form.html">
            {t('Footer.reports')}
          </Link>
        </div>
      </div>
      <div className="az-footer__element az-footer__element--contact-us">
        <div className="az-footer__icons">
          <Link
            className="az-footer__icon az-footer__linkedin"
            href="https://www.linkedin.com/company/astrazeneca/">
            <span className="hidden">{t('Footer.altIn')}</span>
          </Link>
          <Link
            className="az-footer__icon az-footer__facebook"
            href="https://www.facebook.com/AstraZeneca/">
            <span className="hidden">{t('Footer.altFb')}</span>
          </Link>
        </div>
        <p className="az-footer__id-text">{t('Footer.infoId')}</p>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
