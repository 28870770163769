import React, {useCallback, useContext, useState} from 'react';
import {useTranslations} from '@vidiemme/react-i18n';
import {useMutation} from '@apollo/client';
import classNames from 'classnames';

//Others
import {AlertContext} from '../../../../store/alert/context';
import {DELETE_PATHOLOGY} from '../../../../store/pathologies';

//Other components
import {DataTableHead} from '../../../atoms/DataTableHead';
import {DataTableData} from '../../../molecules/DataTableData';
import {Pagination} from '../../../molecules/Pagination';
import {ButtonText} from '../../../atoms/ButtonText';

//Component namespace
import {IProps} from './interface';
import '../DataTable.scss';
import './DataTablePathologies.scss';

const DataTablePathologies = ({
  pathologiesData,
  className,
  totalPathologies,
  refetchData,
}: IProps) => {
  //Helpers
  const {setValue: setAlert} = useContext(AlertContext);
  const {t} = useTranslations();

  // Delete pathology call
  const [deletePathology] = useMutation(DELETE_PATHOLOGY);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemPerPage = 5;

  // Pagination
  const setPage = useCallback(
    page => {
      setCurrentPage(page);
      // refetch data
      refetchData({offset: itemPerPage * (page - 1)});
    },
    [refetchData],
  );

  // Delete callback when press on delete pathology
  const onDelete = useCallback(
    (id: number, e: any) => {
      e.stopPropagation();

      setAlert({
        type: 'INFO',
        message: t('AdminPathologies.delete.confirm'),
        activeButtonLabel: t(
          'AdminQuestionnaireDetail.alert.cancel.activeButtonLabel',
        ),
        cancelButtonLabel: t(
          'AdminQuestionnaireDetail.alert.cancel.cancelButtonLabel',
        ),
        onClick: () => {
          deletePathology({
            variables: {id},
          })
            .catch(() => {
              setAlert({
                type: 'ERROR',
                message: t('AdminPathologies.delete.error'),
              });
            })
            .then((deletePathologyResponse: any) => {
              if (deletePathologyResponse?.data?.deletePathology) {
                setAlert({
                  type: 'SUCCESS',
                  message: t('AdminPathologies.delete.success'),
                });
                setPage(1);
              }
            });
        },
      });
    },
    [deletePathology, setAlert, setPage, t],
  );

  return (
    <div className="w-full mb-16">
      <div className="mb-16 az-data-table__table-container">
        <table
          className={classNames(
            'az-data-table',
            'az-data-table--pathologies',
            className,
          )}>
          <thead className="az-data-table__head">
            <tr>
              <DataTableHead label={t('AdminManage.tableHeading.firstName')} />
              <DataTableHead />
            </tr>
          </thead>
          <tbody>
            {pathologiesData.map((pathology, idx) => {
              return (
                <tr
                  className={classNames('az-data-table__pathologies-data-row')}
                  key={`admin-row-${idx}`}>
                  <DataTableData
                    label={pathology.name}
                    className={'az-data-table__data-cell w-10/12'}
                  />
                  <DataTableData className={'az-data-table__data-cell w-2/12'}>
                    <ButtonText
                      label={t('AdminManage.deleteAdmin')}
                      className="underline font-bold"
                      onClick={e => onDelete(pathology.id, e)}
                    />
                  </DataTableData>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={
          totalPathologies ? Math.ceil(totalPathologies / itemPerPage) : 0
        }
        setPage={setPage}
      />
    </div>
  );
};

export default React.memo(DataTablePathologies);
