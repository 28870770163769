import React from 'react';
import IcomoonReact from 'icomoon-react';

//Others
import iconSet from '../../../themes/base/assets/selection.json';

//Component namespace
import {IProps} from './interfaces';

const IconSVG = ({className, icon, size, color}: IProps): JSX.Element => (
  <IcomoonReact
    className={className}
    iconSet={iconSet}
    color={color ? 'fill' : 'currentColor'}
    size={size}
    icon={icon}
  />
);

export default React.memo(IconSVG);
