import {ReactNode} from 'react';

export enum ModalContent {
  // @REMOVE_PATIENTS_DATA
  // ADD_PATIENT = 'ADD_PATIENT',
  NEW_QUESTIONNAIRE = 'NEW_QUESTIONNAIRE',
  SCORE_THRESHOLD = 'SCORE_THRESHOLD',
  DELETE_MODAL = 'DELETE_MODAL',
  BOOK_AN_APPOINTMENT = 'BOOK_AN_APPOINTMENT',
}

export interface IModalState {
  content: ModalContent;
  props?: any;
}

export type IModalContext = {
  setValue: (value: IModalState | null) => void;
  value: IModalState | null;
};

export interface IProps {
  defaultValue: IModalState | null;
  children?: ReactNode[] | ReactNode;
}
