import React from 'react';
import classNames from 'classnames';

// Types
import {IProps} from './interface';

import './BurgerButton.scss';

// Component that draw a animated burger menu with tailwind classes
const BurgerButton = ({
  isOpen,
  onClick,
  className,
  isHomepage,
}: IProps): JSX.Element => {
  return (
    <button
      className={classNames(
        'az-burger-button',
        {'az-burger-button--open': isOpen},
        className,
      )}
      onClick={onClick}>
      <span
        className={classNames(
          'az-burger-button__line',
          'az-burger-button__line--one',
          {'az-burger-button__line--light': isHomepage},
        )}
      />
      <span
        className={classNames(
          'az-burger-button__line',
          'az-burger-button__line--two',
          {'az-burger-button__line--light': isHomepage},
        )}
      />
      <span
        className={classNames(
          'az-burger-button__line',
          'az-burger-button__line--three',
          {'az-burger-button__line--light': isHomepage},
        )}
      />
    </button>
  );
};

export default React.memo(BurgerButton);
